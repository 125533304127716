.dk-commentModalMain {
    color: #000;
    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 100%;
        min-width: 47% !important;
        max-width: 47% !important;
        height: 100%;
        right: -47%;
        transform: translate3d(0%, 0, 0);
        margin: 0 !important;
    }

    .modal-content {
        border-radius: 0;
        box-shadow: none;
        height: 100vh;

        .modal-body {
            background: #dee1e3;
            border-radius: 0 0 5px 5px;
            padding-bottom: 1.25rem;
            padding: 1rem !important;

            .dkg-addnew-scrollBox {
                // overflow: auto;
                // height: calc(100vh - 70px);
                width: 100%;
                ul.dkg-addnew-comments-list {
                    padding-left: 0;
                    margin-top: 1rem;
                    margin-bottom: 0;
                    position: relative;
                    list-style: none;
                    float: left;
                    width: 100%;

                    &::before {
                        content: '';
                        width: 2px;
                        height: 100%;
                        background: #c7cacb;
                        position: absolute;
                        left: 25px;
                        top: 0;
                        display: none;
                    }

                    li {
                        margin-bottom: 15px;
                        display: block;
                        position: relative;
                        float: left;
                        width: 100%;

                        .dkg-addnew-comment-main-level {
                            display: flex;

                            .dkg-addnew-comment-avatar {
                                width: 50px;
                                height: 45px;
                                position: relative;
                                z-index: 99;
                                float: left;
                                border: 3px solid #FFF;
                                border-radius: 4px;
                                overflow: visible;
                                margin-right: 15px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .dkg-addnew-comment-box {
                                width: 100%;
                                float: right;
                                position: relative;
                                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
                                padding: 0;

                                &::before {
                                    border-width: 11px 13px 11px 0;
                                    border-color: transparent rgba(0, 0, 0, 0.05);
                                    left: -12px;
                                }

                                &::after {
                                    content: '';
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    display: block;
                                    border-width: 10px 12px 10px 0;
                                    border-style: solid;
                                    border-color: transparent #FCFCFC;
                                    top: 8px;
                                    left: -11px;
                                }

                                .dkg-addnew-comment-head {
                                    background: #FCFCFC;
                                    padding: 0 12px;
                                    border-bottom: 1px solid #E5E5E5;
                                    *overflow: hidden;
                                    border-radius: 4px 4px 0 0;
                                    height: 38px;
                                    .d-none {
                                        display: none !important;
                                    }

                                    span {
                                        float: left;
                                        color: #999;
                                        font-size: 13px;
                                        position: relative;

                                        .date {
                                            margin-right: 10px;
                                            display: inline;
                                            font-size: 14px;
                                            font-weight: 400;

                                            i {
                                                display: inline-block;
                                                margin-right: 8px;
                                            }
                                        }
                                    }
                                    .dkg-case-stage-dropdownCon{
                                        display: flex;
                                        align-items: center;
                                        width: 50%;
                                        .dkg-stage-dropdown{
                                            width: 100%;
                                            .dkg-stage-dropdown-toogle{
                                                box-shadow: none;
                                                margin: 0;
                                                padding: 10px 7px !important;
                                                // border: 1px solid #ddd;
                                                text-transform: capitalize;
                                                // min-width: 240px;
                                                // max-width: 240px;
                                                width: 100%;
                                                text-align: left;
                                                font-size: 13px;
                                                color: #999;
                                                border-right: 1px solid #ddd;
                                                height: 37px;
                                                &::after{
                                                    position: absolute;
                                                    top: 18px;
                                                    right: 10px;
                                                }
                                            }
                                            .dkg-stage-dropdown-menu{
                                                width: 100%;
                                                padding: 0;
                                                border-radius: 0;
                                                .dkg-stage-dropdown-item{
                                                    border-bottom: 1px solid #ddd;
                                                    padding: 7px 10px;
                                                    &:last-child{
                                                        border-bottom: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .dkg-comment-sub-header{
                                    background: #FCFCFC;
                                    padding: 0 !important;
                                    border-bottom: 1px solid #E5E5E5;
                                    display: flex;
                                    align-items: center;
                                    .dkg-commsub-dropdwonCon{
                                        display: flex;
                                        align-items: center;
                                        width: 33.333%;
                                        .dkg-stage-dropdown{
                                            width: 100%;
                                            .dkg-stage-dropdown-toogle{
                                                width: 100%;
                                                .caseDiv{
                                                    margin: 0;
                                                    box-shadow: none;
                                                    padding: 10px 7px;
                                                    text-transform: capitalize;
                                                    width: 100%;
                                                    text-align: center;
                                                    font-size: 13px;
                                                    color: #fff;
                                                    height: 37px;
                                                    line-height: 19px;
                                                    font-weight: normal;
                                                }

                                                &::after{
                                                    position: absolute;
                                                    top: 15px;
                                                    right: 10px;
                                                }
                                                .dk-caseStatusDropCon{
                                                    .dropdown{
                                                        .dk-caseStatusfliterbtn.dropdown-toggle {
                                                            &::after{
                                                                display: inline-block;
                                                                position: absolute;
                                                                right: 10px;
                                                                top: 19px;
                                                                color: #fff;
                                                                font-size: 15px;
                                                            }
                                                        }
                                                        .caseStatusMenu.dropdown-menu{
                                                            width: 100% !important;
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-stage-dropdown-menu{
                                                width: 100%;
                                                padding: 0;
                                                border-radius: 0;
                                                .dkg-stage-dropdown-item{
                                                    border-bottom: 1px solid #ddd;
                                                    padding: 7px 10px;
                                                    &:last-child{
                                                        border-bottom: none;
                                                    }
                                                }
                                            }
                                        }
                                        &:first-child,
                                        &:nth-child(02){
                                            .dkg-stage-dropdown{
                                                .dkg-stage-dropdown-toogle{
                                                    border-right: 1px solid #ddd;
                                                }
                                            }
                                        }
                                    }
                                }
                                .headIcons {
                                    i {
                                        margin-left: 10px;
                                        display: inline-block;
                                        color: #666;
                                        cursor: pointer;

                                        &:hover {
                                            color: #03658c;
                                            transition: all 0.5s;
                                        }
                                    }
                                }

                                .dkg-addnew-comment-content {
                                    background: #FFF;
                                    padding: 5px 7px;
                                    font-size: 14px;
                                    color: #595959;
                                    border-radius: 0 0 4px 4px;
                                    font-weight: 400;

                                    span {
                                        strong {
                                            font-weight: 600 !important;
                                        }
                                    }

                                    .dkg-addnew-comment-textArea {
                                        border: none;
                                        text-align: left;
                                        height: 320px;
                                        overflow-y: auto;
                                        font-size: 14px;
                                    }

                                    .wrapper-class.rdw-editor-wrapper {
                                        padding: 0;
                                        border: none;

                                        .rdw-editor-toolbar.toolbar-class {
                                            border: none;
                                            border-bottom: 1px solid #ddd;
                                            padding: 0;
                                            margin: 0;
                                            display: none;

                                            .rdw-inline-wrapper {
                                                margin-bottom: inherit;

                                                .rdw-option-wrapper {

                                                    &:nth-child(04),
                                                    &:nth-child(05),
                                                    &:nth-child(06),
                                                    &:nth-child(07) {
                                                        display: none;
                                                    }
                                                }
                                            }

                                            .rdw-block-wrapper,
                                            .rdw-fontfamily-wrapper,
                                            .rdw-link-wrapper,
                                            .rdw-embedded-wrapper,
                                            .rdw-emoji-wrapper,
                                            .rdw-image-wrapper,
                                            .rdw-remove-wrapper,
                                            .rdw-history-wrapper {
                                                display: none;
                                            }
                                        }

                                        .editor-class.rdw-editor-main {
                                            min-height: inherit !important;
                                            max-height: inherit !important;
                                            height: calc(100vh - 420px);
                                        }
                                    }
                                }

                            }
                        }

                        .dkg-submitbtnCon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 18px;

                            .dkg-cancel-btn {
                                border: 1px solid #333;
                                color: #333;
                                background: transparent;
                                display: inline-block;
                                width: 100%;
                                max-width: 90px;
                                border-radius: 5px;
                                padding: 10px 0;
                                transition: all 0.5s;
                                outline: none;
                                text-align: center;
                                margin-right: 8px;

                                &:hover {
                                    background: #535353;
                                    border-color: #535353;
                                    color: #fff;
                                    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                                }
                            }

                            div {
                                div {
                                    .dk-sendBtn {
                                        background: #26597d;
                                        border: none;
                                        border-radius: 3px;
                                        color: #fff;
                                        display: inline-block;
                                        font-size: 14px;
                                        font-weight: 500;
                                        outline: none;
                                        padding: 10px 25px;
                                        text-transform: uppercase;

                                        &:hover {
                                            background: #234158;
                                            transition: all .5s;
                                        }
                                    }
                                }
                            }
                        }

                        .comment-main-level {
                            display: flex;
                        }
                    }

                    .comments-list-reply {
                        float: right;
                        width: 100%;
                        margin-top: 15px;
                        padding-left: 62px;

                        li {
                            position: relative;

                            &::before {
                                content: "";
                                width: 38px;
                                height: 2px;
                                background: #c7cacb;
                                position: absolute;
                                top: 25px;
                                left: -35px;
                            }

                            .comment-box {
                                width: 100%;
                            }

                        }
                    }
                }

                .dkg-pedefind-commentCon {
                    width: 100%;

                    .dkg-pre-maincomment-Row {
                        .dkg-pre-maincomment-sideMenu {
                            flex: 0 0 27%;
                            max-width: 27%;

                            .dkg-precomment-navpills {
                                .dkg-pre-maincomment-Navitem {
                                    border-radius: 0;
                                    background-color: #eaeaea;
                                    color: #333;
                                    border-bottom: 1px solid #ddd;

                                    .dkg-pre-maincomment-Navlink {
                                        background-color: #eaeaea;
                                        color: #525050;
                                        border-radius: 0;
                                        padding: 8px 9px;
                                        font-size: 13px;
                                        text-align: left;

                                        &.active {
                                            background-color: #fff;
                                            color: #333;
                                        }
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }

                        .dkg-pre-maincomment-tabconCol {
                            flex: 0 0 73%;
                            max-width: 73%;

                            .dkg-pre-comment-tabContent {
                                .dkg-pre-comment-tabpane {
                                    background-color: #fff;
                                    height: 295px;
                                    border: 1px solid #ddd;
                                    border-left: none;
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                    padding: 15px;
                                    position: relative;
                                    top: -1px;
                                }

                                .dkg-predefinedbtnCon {
                                    position: relative;
                                    top: -4px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-top: 24px;

                                    .dkg-edit-btn,
                                    .dkg-use-btn {
                                        border: 1px solid #333;
                                        color: #333;
                                        background: transparent;
                                        display: inline-block;
                                        width: 100%;
                                        max-width: 90px;
                                        border-radius: 5px;
                                        padding: 6px 7px;
                                        transition: all 0.5s;
                                        outline: none;
                                        text-align: center;
                                        margin-right: 8px;

                                        &:hover {
                                            background: #535353;
                                            border-color: #535353;
                                            color: #fff;
                                            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                                        }

                                        &.dkg-edit-bg {
                                            &:hover {
                                                background-color: #fee69c;
                                                border-color: #fee69c;
                                                color: #333;
                                            }
                                        }

                                        &.dkg-save-bg {
                                            &:hover {
                                                background-color: #3a9469;
                                                border-color: #3a9469;
                                                color: #fff;
                                            }
                                        }

                                        &.dkg-use-bg {
                                            &:hover {
                                                background-color: #26597d;
                                                border-color: #26597d;
                                                color: #fff;
                                            }
                                        }

                                    }

                                    div {
                                        div {
                                            .dk-sendBtn {
                                                background: #26597d;
                                                border: none;
                                                border-radius: 3px;
                                                color: #fff;
                                                display: inline-block;
                                                font-size: 14px;
                                                font-weight: 500;
                                                outline: none;
                                                padding: 8px 7px;
                                                text-transform: uppercase;

                                                &:hover {
                                                    background: #234158;
                                                    transition: all .5s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .dkg-app-progress-modal-body{
                    .dkg-cand-progress-app-mainCon{
                        width: 100%;
                        padding-left: 2.25rem;
                        .dkg-progress-app-listCon{
                            position: relative;
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            .dkg-progress-app-list{
                                position: relative;
                                min-height: 75px;
                                counter-increment: list;
                                padding-left: 0.5rem;
                                justify-content: center;
                                align-content: center;
                                position: middle;
                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: -1.55rem;
                                    top: 52px;
                                    height: 80%;
                                    width: 1px;
                                    border-left: 1px solid #848484;
                                }
                                &:after {
                                    content: counter(list);
                                    position: absolute;
                                    top: 26px;
                                    left: -2.5rem;
                                    width: 26px;
                                    height: 26px;
                                    border-radius: 50%;
                                    background: transparent;
                                    color: #848484;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 2rem;
                                    text-align: center;
                                    border: 1px solid #848484;
                                }
                                &.current{
                                    &:after {
                                        background: #fff;
                                        color: #777;
                                        font-weight: bold;
                                    }
                                }
                                &:last-child{
                                    &:before{
                                        height: 30%;
                                    }
                                }
                                .dkg-progress-app-card-Con{
                                    background-color: #fff;
                                    color: #333;
                                    border-radius: 5px;
                                    height: 75px;
                                    margin-bottom: 0.75rem;
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    .dkg-app-progress-imgcols{
                                        width: 70px;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        padding: 0 10px;
                                        .dkg-app-progress-img{
                                            width: 40px;
                                            height: 40px;
                                            display: inline-block;
                                            border-radius: 50%;
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            border: 1px solid #ddd;
    
                                        }
                                    }
                                    .dkg-app-porgess-statuscols{
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-between;
                                        .dkg-app-status-firstCols,
                                        .dkg-app-status-seocndCols,
                                        .dkg-app-status-thirdCols{
                                            width: 100%;
                                            text-align: center;
                                            .dkg-status-title{
                                                position: relative;
                                                top: 3px;
                                                font-size: 12px;
                                            }
                                            .dkg-progress-status-item{
                                                height: 30px;
                                                line-height: 15px;
                                                border-right: 1px solid #ddd;
                                                margin-top: 5px;
                                            }
                                            .dkg-progess-app-date{
                                                font-size: 12px; 
                                                margin-top: 12px;
                                            }
                                            .dkg-progess-app-time{
                                                font-size: 12px; 
                                                margin-top: 5px;
                                            }
    
                                        }
                                        .dkg-app-status-firstCols,
                                        .dkg-app-status-seocndCols{
                                            .dkg-progress-status-item{
                                                border-right: 1px solid #f2f2f2;
                                            }
                                        }
                                    }
                                    .dkg-app-progress-date-cols{
                                        width: 195px;
                                        display: flex;
                                        justify-content: center;
                                        display: none;
                                        .dkg-progess-app-date{
                                            position: relative;
                                            top: 12px;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dkg-case-progress-form-con{
                        .dkg-profile-frmgrp{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            margin-bottom: 1rem;
                            padding: 0 15px;
                            .dkg-profile-label{
                                min-width: 220px;
                                max-width: 220px;
                                margin-bottom: 0;
                                font-size: 13px;
                                .dkg-profile-star{
                                    color: #e2445b;
                                }
                            }
                            .dkg-app-select-dropdown-Con{
                                width: 100%;
                                .dkg-app-select-dropdown{
                                    width: 100%;
                                    .dkg-app-select-dropdown-toggle{
                                        margin: 0;
                                        width: 100%;
                                        box-shadow: none;
                                        text-align: center;
                                        border-radius: 0;
                                        text-transform: capitalize;
                                        position: relative;
                                        font-size: 0.9487rem;
                                        border: 1px solid #ddd;
                                        background-color: #f2f2f2;
                                        color: #666;
                                        height: calc(1.6em + 0.75rem + 2px);
                                        padding: 0.375rem 0.75rem;
                                        border-radius: 0;
                                        &::after{
                                            position: absolute;
                                            top: 14px;
                                            right: 12px;
                                            font-size: 18px;
                                            color: #666;
                                        }
                                    }
                                    .dkg-app-select-dropdown-menu{
                                        display: block;
                                        width: 100%;
                                        border-radius: 0;
                                        padding: 0;
                                        border-color: currentcolor #96c8da #96c8da;
                                        border-top: #96c8da;
                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                        .dkg-app-select-dropdown-item{
                                            border-radius: 0;
                                            border: none;
                                            border-bottom: 1px solid #ddd;
                                            text-align: left;
                                            font-weight: 500;
                                            font-size: 13px;
                                            padding: 9px 10px;
                                            color: #666;
                                            &:last-child{
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }
                                .dkg-app-select-dropdown ,.dk-jobssearchFilter{
                                    width: 100%;
                                    position: relative;
                                    .dkg-jobapplied-title{
                                        color: #2e75b6;
                                        position: relative;
                                        top: 0.45rem;
                                        font-size: 0.9rem;
                                    }
                                    .dkg-app-select-dropdown-toggle,
                                    .dkg-widthdraw-app-toggle{
                                        background-color: #fff;
                                        margin: 0;
                                        width: 100%;
                                        box-shadow: none;
                                        font-size: 14px;
                                        text-align: center;
                                        height: 32px;
                                        line-height: 16px;
                                        display: inline-block;
                                        border-radius: 0;
                                        text-transform: capitalize;
                                        padding: 9px 10px;
                                        position: relative;
                                        color: #777;
                                        width: 100%;
                                        &::after {
                                            display: inline-block;
                                            margin-left: 0.255em;
                                            vertical-align: 0.255em;
                                            content: "";
                                            border-top: 0.3em solid;
                                            border-right: 0.3em solid transparent;
                                            border-bottom: 0;
                                            border-left: 0.3em solid transparent;
                                            color: #8a8a8a;
                                            position: absolute;
                                            top: 14px;
                                            right: 10px;
                                            font-size: 18px;
                                        }
                                        a{
                                            color: #777;
                                            &::after {
                                                display: inline-block;
                                                margin-left: 0.255em;
                                                vertical-align: 0.255em;
                                                content: "";
                                                border-top: 0.3em solid;
                                                border-right: 0.3em solid transparent;
                                                border-bottom: 0;
                                                border-left: 0.3em solid transparent;
                                                color: #8a8a8a;
                                                position: absolute;
                                                top: 27px;
                                                right: 10px;
                                                font-size: 18px;
                                            }
                                        }
                                        // &::after{
                                        //     position: absolute;
                                        //     top: 26px;
                                        //     right: 12px;
                                        //     font-size: 18px;
                                        //     color: #777;
                                        // }
                                    }
                                    .dropdown{
                                        background-color: #fff;
                                        margin: 0;
                                        width: 100%;
                                        box-shadow: none;
                                        font-size: 14px;
                                        text-align: center;
                                        height: 32px;
                                        line-height: 16px;
                                        display: inline-block;
                                        border-radius: 0;
                                        text-transform: capitalize;
                                        padding: 9px 10px;
                                        position: relative;
                                        color: #777;
                                        width: 100%;
                                        a{
                                            color: #777;
                                            &::after {
                                                display: inline-block;
                                                margin-left: 0.255em;
                                                vertical-align: 0.255em;
                                                content: "";
                                                border-top: 0.3em solid;
                                                border-right: 0.3em solid transparent;
                                                border-bottom: 0;
                                                border-left: 0.3em solid transparent;
                                                color: #8a8a8a;
                                                position: absolute;
                                                top: 17px;
                                                right: 10px;
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                    .dkg-app-select-dropdown-menu ,
                                    .dropdown-menu{
                                        position: absolute;
                                        left: 0;
                                        display: block;
                                        width: 100%;
                                        border-radius: 0;
                                        padding: 0;
                                        border-color: currentcolor #96c8da #96c8da;
                                        border-top: #96c8da;
                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                        z-index: 999999;
                                        .dkg-app-select-dropdown-item{
                                            border-radius: 0;
                                            border: none;
                                            border-bottom: 1px solid #ddd;
                                            text-align: left;
                                            font-weight: 500;
                                            font-size: 13px;
                                            padding: 5px 7px;
                                            color: #333;
                                        }
                                        .dkg-job-applied-ul{
                                            max-height: 220px;
                                            overflow-y: auto;
                                            a{
                                                border-radius: 0;
                                                border: none;
                                                border-bottom: 1px solid #aaa;
                                                text-align: left;
                                                font-weight: 500;
                                                font-size: 13px;
                                                padding: 9px 10px;
                                                color: #333;
                                                height: 45px;
                                                &:last-child{
                                                    border-bottom: none;
                                                }
                                            }
                                            .dkg-jobapplied-dropitem{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                width: 100%;
                                                .dkg-joapplied-cross{
                                                    svg{
                                                        opacity: 0.85;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:last-child{
                                margin-bottom: 1rem;
                            }
                            .dkg-app-user-dropdrownCon{
                                position: relative;
                                .dkg-app-user-dropdrown{
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    .dkg-app-user-dropdrown-toggle{
                                        align-items: center;
                                        background-color: transparent;
                                        // border: 1px solid #ddd;
                                        border-radius: 50%;
                                        box-shadow: none;
                                        color: #8a8a8a;
                                        display: flex;
                                        height: 35px;
                                        justify-content: center;
                                        margin: 0;
                                        padding: 0;
                                        position: relative;
                                        width: 35px;
                                        svg{
                                            width: 22px;
                                            height: 22px;
                                        }
                                        &:after {
                                            position: absolute;
                                            top: 17px;
                                            right: -10px;
                                            font-size: 19px;
                                            display: none;
                                        }
                                    }
                                    .dkg-app-user-dropdrown-menu{
                                        // align-items: center;
                                        // background-color: #f2f2f2;
                                        // border: 1px solid #dddd;
                                        // display: flex;
                                        // left: inherit!important;
                                        // margin: 0!important;
                                        // min-width: inherit;
                                        // padding: 0!important;
                                        // position: absolute!important;
                                        // right: 68px !important;
                                        // top: -7px !important;
                                        // transform: inherit!important;
                                        background-color: #fff;
                                        left: 24px!important;
                                        padding: 5px;
                                        top: -10px!important;
                                        transform: translate(22px)!important;
                                        -webkit-transform: translate(22px)!important;
                                        &:before {
                                            border-color: #0000 #fff #0000 #0000;
                                            _border-color: #000 #fff #000 #000;
                                            border-style: solid;
                                            border-width: 8.5px 12px 8.5px 0;
                                            content: "";
                                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                            height: 0;
                                            position: absolute;
                                            top: 19px;
                                            left: -12px;
                                            line-height: 0;
                                            width: 0;
                                        }
                                        .d-flex{
                                            .dkg-app-user-dropdrown-item,
                                            .dropdown-item{
                                                display: inline-block;
                                                height: 35px;
                                                margin: 5px 3px;
                                                padding: 0;
                                                width: 35px;
                                               .dkg-app-user-img{
                                                    border-radius: 50%;
                                                    height: 35px;
                                                    width: 35px
                                               }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dkg-caseprogrees-textAreaCon{
                        padding:  15px;
                        .dkg-caseprogress-header{
                            background-color: #ddd;
                            border: 1px solid #ccc;
                            display: block;
                            padding: 7px 10px;
                            text-align: center;
                            text-transform: uppercase;
                            .dkg-caseprogress-title{
                                color: #333;
                                font-size: 14px;
                                font-weight: 700;
                            }
                        }
                        .dkg-caseprogress-subtitleCon{
                            background-color: #fff;
                            border: 1px solid #ddd;
                            border-top: none;
                            display: block;
                            padding: 7px 10px;
                            text-align: center;
                            .dkg-caseprogress-subtitle{
                                color: #666;
                                font-size: 13px;
                            }
                            .dkg-caseprogess-dflex{
                                display: flex;
                                .dkg-caseprogess-date {
                                    display: inline;
                                    font-size: 0.85rem;
                                    font-weight: 400;
                                    margin-right: 10px;
                                    color: #666;
                                    i {
                                        display: inline-block;
                                        margin-right: 8px;
                                        color: #999;
                                    }
                                }
                            }
                        }
                        .dkg-caseprogrees-textarea{
                            width: 100%;
                            .dkg-cp-frmcontrol-textarea{
                                background-color: #fff;
                                border: 1px solid #ddd;
                                border-top: none;
                                height: 160px;
                                overflow-y: auto;
                                text-align: left;
                            }
                        }
                        .dkg-cp-save-btncon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            margin: 1rem 0;
                            .dkg-cancel-btn {
                                background: #0000;
                                border: 1px solid #333;
                                border-radius: 5px;
                                color: #333;
                                display: inline-block;
                                margin-right: 8px;
                                max-width: 90px;
                                outline: none;
                                padding: 10px 0;
                                text-align: center;
                                transition: all .5s;
                                width: 100%;
                                &:hover {
                                    background: #535353;
                                    border-color: #535353;
                                    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
                                    color: #fff;
                                }
                            }
                            div{
                                div{
                                    .dkg-cp-save-btn{
                                        background-color: #3C97B6;
                                        color: #fff;
                                        border: none;
                                        border-radius: 3px;
                                        display: inline-block;
                                        font-size: 14px;
                                        font-weight: 500;
                                        outline: none;
                                        padding: 10px 25px;
                                        text-transform: uppercase;
                                    
                                    }
                                }
                            }
                        }
                    }
                    .dkg-caseupdated-calenderCon{
                        .SingleDatePicker{
                            div{
                                .SingleDatePickerInput{
                                    .DateInput{
                                        width: inherit;
                                        .DateInput_input{
                                            font-size: 0.85rem;
                                            padding: 7px 10px;
                                            border: 1px solid #ddd;
                                            width: 100%;
                                            text-align: center;
                                        
                                        }
                                    }
                                    // .SingleDatePicker_picker{
                                    //     .DayPicker{
                                    //         width: 240px !important;
                                    //         div{
                                    //             div{
                                    //                 width: 240px !important;
                                    //             }
                                    //         }
                                    //     }
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.dkg-caseprogress-mainModal{
        color: #03658c;
        .modal-dialog{
            min-width: 60% !important;
            max-width: 60% !important;
        }
    }
    .modal-header {
        background: #26597d;
        padding: 8px 10px;
    }

    .addMonthBtn {
        color: #fff;
        position: relative;
        left: 5px;
        font-size: 16px;

        &:hover {
            opacity: 0.85;
        }
    }

    .modal-title {
        margin-top: 0;
        font-size: 17px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        color: #fff;
    }

    .close {
        font-size: 1.58em;
        padding: 0px;
        right: 10px;
        position: absolute;
        top: 4px;
        opacity: 1;
        color: #fff !important;
        text-shadow: none;
        outline: none;
        font-weight: normal;
        padding: 0.35rem;
        margin: 0;
    }
    &.dkg-caseprogress-mainModal{
        .modal-dialog{
            // min-width: 60% !important;
            // max-width: 60% !important;
            // height: 100%;
            // right: -60%;
            .modal-header{
                background-color: #3c97b6;
                border-bottom: none;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 14px 15px !important;
                width: 100%;
            }
            .modal-body{
                .comments-container {
                    .scrollBox {
                        ul.comments-list{
                            li{
                                margin-bottom: 2rem;
                                .comment-main-level{
                                    .comment-box{
                                        .comment-head.dkg-comm-read-header{
                                            .dkg-comment-header-rightCon{
                                                justify-content: flex-end !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.dkg-casestatus-mainModal{
        .modal-dialog{
            .modal-header{
                background-color: #3c97b6;
                border-bottom: none;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 14px 15px !important;
                width: 100%;
            }
        }   
    }
    &.dkg-caseprogress-mainModal{
        .modal-dialog{
            min-width: 60%;
            max-width: 60%;
        }
    }
    
}

.modal.right.fade .modal-dialog {
    right: 0;
    transform: translate3d(0%, 0, 0);
    margin: 0;
}

.dk-commentTabs-panel {
    .comments-container {
        .scrollBox {
            height: calc(100vh - 330px);
        }
    }
}

.comments-container {
    width: 100%;

    .scrollBox {
        overflow: auto;
        height: calc(100vh - 85px);
        width: 100%;

        ul.comments-list {
            padding-left: 0;
            margin-top: 1rem;
            margin-bottom: 0;
            position: relative;
            list-style: none;
            float: left;
            width: 100%;

            &::before {
                content: '';
                width: 2px;
                height: 100%;
                background: #c7cacb;
                position: absolute;
                left: 25px;
                top: 0;
            }

            li {
                margin-bottom: 15px;
                display: block;
                position: relative;
                float: left;
                width: 100%;

                .comment-main-level {
                    .comment-avatar {
                        width: 50px;
                        height: 45px;
                        position: relative;
                        z-index: 99;
                        float: left;
                        border: 3px solid #FFF;
                        border-radius: 4px;
                        overflow: visible;
                        margin-right: 15px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .comment-box {
                        width: 100%;
                        float: right;
                        position: relative;
                        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
                        padding: 0;

                        &::before {
                            border-width: 11px 13px 11px 0;
                            border-color: transparent rgba(0, 0, 0, 0.05);
                            left: -12px;
                        }

                        &::after {
                            content: '';
                            height: 0;
                            width: 0;
                            position: absolute;
                            display: block;
                            border-width: 10px 12px 10px 0;
                            border-style: solid;
                            border-color: transparent #FCFCFC;
                            top: 8px;
                            left: -11px;
                        }

                        .comment-head {
                            background: #FCFCFC;
                            padding: 7px 12px;
                            border-bottom: 1px solid #E5E5E5;
                            *overflow: hidden;
                            border-radius: 4px 4px 0 0;

                            .d-none {
                                display: none !important;
                            }

                            span {
                                float: left;
                                color: #999;
                                font-size: 13px;
                                position: relative;

                                .date {
                                    margin-right: 10px;
                                    display: inline;
                                    font-size: 14px;
                                    font-weight: 400;

                                    i {
                                        display: inline-block;
                                        margin-right: 8px;
                                    }
                                }
                            }
                            &.dkg-comm-read-header{
                                height: 37px;
                                .dkg-case-stage-statusCon{
                                    .dkg-staus-btn{
                                        border-radius: 30px;
                                        cursor: text;
                                        display: inline-flex;
                                        font-size: 14px;
                                        font-weight: bold;
                                        list-style: none;
                                        padding: 3px 0;
                                        position: relative;
                                    }
                                }
                                .dkg-comment-header-rightCon{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 100%;
                                    .dkg-comment-readdropdwn-Con{
                                        .dkg-comment-readdropdwn{
                                            .dkg-comment-readdropdwn-toggle{
                                                padding: 0;
                                                margin: 0;
                                                border: none;
                                                box-shadow: none;
                                                &::after{
                                                    font-size: 22px;
                                                    position: relative;
                                                    top: 5px;
                                                    color: #999;
                                                }
                                            }
                                            .dkg-comment-readdropdwn-menu{
                                                border-radius: 0;
                                                padding: 0;
                                                width: 100%;
                                                min-width: 80px;
                                                max-width: 80px;
                                                .dkg-comment-readdropdwn-item{
                                                    border-bottom: 1px solid #ddd;
                                                    padding: 7px 18px;
                                                    &:last-child{
                                                        border-bottom: none;
                                                    }
                                                    i{
                                                        color: #999;
                                                    }
                                                }
                                            }
                                        }
                                        .date,
                                        .Date {
                                            i {
                                                margin-left: 10px;
                                                display: inline-block;
                                                color: #666;
                                                cursor: pointer;
                                                font-size: 14px;

                                                &:hover {
                                                    color: #03658c;
                                                    transition: all 0.5s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .headIcons {
                            i {
                                margin-left: 10px;
                                display: inline-block;
                                color: #666;
                                cursor: pointer;

                                &:hover {
                                    color: #03658c;
                                    transition: all 0.5s;
                                }
                            }
                        }
                        .dkg-comm-read-subheader{
                            background: #FCFCFC;
                            padding: 0;
                            border-bottom: 1px solid #E5E5E5;
                            // height: 37px;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            .dkg-comm-read-subject{
                                width: 100%;
                                .caseDiv{
                                    font-size: 13px;
                                    color: #fff;
                                    text-align: center;
                                    width: 100%;
                                    font-weight: normal;
                                    padding: 7px 12px;
                                    height: 36px;
                                }
                                &:first-child,
                                &:nth-child(02),
                                &:nth-child(03){
                                    border-right: 1px solid #ddd;
                                }
                            }
                        }
                        .comment-content {
                            background: #FFF;
                            padding: 12px;
                            font-size: 14px;
                            color: #595959;
                            border-radius: 0 0 4px 4px;
                            font-weight: 400;

                            span {
                                strong {
                                    font-weight: 600 !important;
                                }
                            }
                        }

                    }
                }

                .comment-main-level {
                    display: flex;
                }
            }

            .comments-list-reply {
                float: right;
                width: 100%;
                margin-top: 15px;
                padding-left: 62px;

                li {
                    position: relative;

                    &::before {
                        content: "";
                        width: 38px;
                        height: 2px;
                        background: #c7cacb;
                        position: absolute;
                        top: 25px;
                        left: -35px;
                    }

                    .comment-box {
                        width: 100%;
                    }

                }
            }
        }
    }


    .dk-commentReplyBox {
        width: 100%;
        margin-top: 0;
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 100%;
        padding: 0 25px;

        .rdw-editor-toolbar.toolbar-class {
            flex-wrap: nowrap;

            .rdw-colorpicker-wrapper {
                display: none;
            }

            .rdw-link-wrapper {
                display: none;
            }

            .rdw-embedded-wrapper {
                display: none;
            }

            .rdw-emoji-wrapper {
                display: none;
            }

            .rdw-image-wrapper {
                display: none;
            }

            .rdw-remove-wrapper {
                display: none;
            }

            .rdw-history-wrapper {
                display: none;
            }

            .rdw-list-wrapper {
                flex-wrap: nowrap;
            }

            .rdw-text-align-wrapper {
                flex-wrap: nowrap;
            }

            .rdw-inline-wrapper {
                flex-wrap: nowrap;

                .rdw-option-wrapper {
                    min-width: 20px;
                    margin: 0 2px;

                    img {
                        width: 100%;
                        max-width: 15px;
                    }

                    &:nth-child(04) {
                        display: none;
                    }

                    &:nth-child(05) {
                        display: none;
                    }

                    &:nth-child(06) {
                        display: none;
                    }

                    &:nth-child(07) {
                        display: none;
                    }
                }
            }

            .rdw-block-wrapper {
                .rdw-dropdown-wrapper {
                    width: 70px;

                    .rdw-dropdown-selectedtext {
                        font-size: 11px;

                        .rdw-dropdown-carettoopen {
                            top: 42%;
                            right: 10%;
                            border-top: 5px solid black;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                        }
                    }
                }
            }

            .rdw-fontsize-wrapper {
                .rdw-dropdown-wrapper {
                    .rdw-dropdown-selectedtext {
                        font-size: 12px;
                    }
                }
            }

            .rdw-fontfamily-wrapper {
                .rdw-dropdown-wrapper {
                    width: 68px;

                    .rdw-dropdown-selectedtext {
                        font-size: 12px;
                    }
                }
            }
        }

        textarea {
            border: 1px solid #ccc !important;
            box-shadow: none !important;
            height: 70px;
            resize: vertical;
            background: #fff;
            text-align: left;
            border-top: none !important;
        }

        .dk-commentSubmitBtn {
            display: block;
            margin-top: 10px;
            text-align: center;

            button {
                background: #26597d;
                display: inline-block;
                padding: 10px 25px;
                text-transform: uppercase;
                color: #fff;
                font-weight: 500;
                font-size: 14px;
                outline: none;
                border: none;
                border-radius: 3px;

                &:hover {
                    background: #234158;
                    transition: all 0.5s;
                }
            }
        }
    }

}
.dkg-comment-dropdownCon{
    .dkg-stage-dropdownCon{
        display: flex;
        align-items: center;
        .dkg-comment-label{
            font-size: 13px;
            color: #333;
            min-width: 80px;
            margin-bottom: 0;
        }
        .dkg-stage-dropdown{
            width: 100%;
            .dkg-stage-dropdown-toogle{
                width: 100%;
                box-shadow: none;
                background-color: #fff;
                border: 1px solid #ddd;
                margin: 0;
                text-align: left;
                text-transform: capitalize;
                font-size: 14px;
                padding: 10px 12px;
                height: 37px;
                position: relative;
                &::after{
                    position: absolute;
                    right: 10px;
                    top: 15px;
                }

            }
            .dkg-stage-dropdown-menu{
                padding: 0;
                width: 100%;
                border-radius: 0;
                .dkg-stage-dropdown-item{
                    border-bottom: 1px solid  #ddd;
                    font-size: 13px;
                    padding: 6px 10px;
                    text-align: left;
                    &:last-child{
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.dkg-comm-ContextMenu {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 3px 0 rgba(0,0,38,.15);
    cursor: pointer;
    padding: 5px 10px;
    position: relative;
    z-index: 999999 !important;
}