.dkg-documents-mainCon{
    margin-top: 152px;
    background-color: #eaeaea;
    height: calc(100vh - 152px);
    width: 100%;
    padding: 15px;
    padding-top: 20px;
    .dkg-documents-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .dkg-documents-headerleftCon{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .dk-mailshotsSearch{
                .input-group{
                    max-width: 145px;
                    min-width: 145px;
                    width: 100%;
                    input{
                        background-color: #fff!important;
                        border: 1px solid #ddd;
                        border-radius: 0;
                        box-shadow: none;
                        height: 35px;
                        line-height: 1.3rem;
                        margin: 0;
                        padding: 0.5rem 0.55rem;
                        text-align: left;
                    }
                    .input-group-append{
                        button{
                            background-color: #fff!important;
                            border: 1px solid #ddd;
                            border-radius: 0;
                            box-shadow: none;
                            height: 35px;
                            line-height: 1.3rem;
                            margin: 0;
                            padding: 0.5rem 0.55rem;
                            i{
                                color: #6c757d;
                            }
                        }
                    }
                }
            }
            .dkg-refresh-btn{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50%;
                box-shadow: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
        }
        .dkg-documents-centreCon{
            .dkg-docs-title{
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                color: #6c757d;
                display: flex;
                align-items: center;

                // .dkg-infoicon{
                //     color: #6c757d;
                //     width: 24px;
                //     height: 24px;
                //     cursor: pointer;
                // }
            }
        }
        .dkg-documents-rightCon{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dkg-refresh-btn{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50%;
                box-shadow: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
            .dkg-add-new-btn{
               background-color: #fff;
                border-radius: 0;
                color: #6c757d;
                display: inline-block;
                font-family: Roboto,sans-serif;
                font-size: 14px;
                padding: 8px 15px;
                text-decoration: none;
                white-space: nowrap;
                svg{
                    color: #6c757d;
                }
            }
        }
    }
    .dkg-document-tableCon{
        margin-top: 6px;
        .dkg-document-table{
            thead{
                tr{
                    th{
                        background-color: #1f394c;
                        padding: 10px 0.75rem;
                        vertical-align: middle;
                        font-size: 14px;
                        border: 1px solid #8a8a8a;
                        text-align: center;
                        color: #ddd;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        background-color: #fff;
                        padding: 10px 0.75rem;
                        vertical-align: middle;
                        font-weight: normal;
                        font-size: 14px;
                        border: 1px solid #ddd;
                        text-align: center;
                        color: #333;
                        .dkg-documents-cols{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-action-btn{
                                color: white;
                                padding: 0.4em 1em;
                                border: none;
                                border-radius: 4px;
                                &:hover {
                                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                    transition: all ease 0.5s;
                                }
                                &.open-btn{
                                    background-color: #ffb64d;
                                }
                                &.download-btn{
                                    background-color: #28a745;
                                }
                                &.edit-btn{
                                    background-color: #007bff;
                                }
                                &.delete-btn{
                                    color: #dc3545;
                                    background: none;
                                    border: 1px solid #dc3545;
                                    &:hover{
                                        background-color: #dc3545;
                                        color: #fff;
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .dkg-documents-mainCon{
        margin-top: 35px;
        height: calc(100vh - 49px);
        overflow-y: hidden;
        .dkg-documents-header{
            display: block;
            position: relative;
            width: 100%;
            margin-top: 10px;
            .dkg-documents-headerleftCon{
                display: inline-block;
                position: absolute;
                left: -2px;
                top: 25px;
                .dk-mailshotsSearch{
                    .input-group{
                        max-width: 145px;
                        min-width: 145px;
                        width: 100%;
                    }
                }
            }
            .dkg-documents-centreCon{
                position: relative;
                top: -15px;
                display: block;
                .dkg-docs-title{
                    width: 100%;
                    text-align: center;
                    display: inline-block;
                }
            }
            .dkg-documents-rightCon{
                .dkg-add-new-btn{
                    font-size: 15px;
                    padding: 5px 15px;
                }
            }
        }
        .dkg-document-tableCon{
            height: calc(100vh - 220px);
            overflow: auto;
            thead{
                tr{
                    th{
                        white-space: nowrap;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}