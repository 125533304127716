.cursor-pointer {
    cursor: pointer;
}

.dk-pipedocs-previewModal {
    min-width: 70%;
    &.dkg-pipeline-candModal-234{
        margin: 0  auto !important;
    }
    .modal-content {
        .dkg-mailbox-preview-header{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #234158;
            height: inherit;
            padding: 14px 15px !important;
            .modal-title{
                align-items: center;
                color: #fff;
                display: block;
                display: flex;
                font-size: 16px;
                justify-content: center;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
            
            }
        }
        .dk-pipedocs-preview-modalbody {
            padding: 0;

            iframe {
                display: block !important;
                width: 100%;
                height: calc(100vh - 46px);
            }
        }

        .modal-header {
            margin: 0 !important;
            padding: 0;
            border-bottom: none;

            // .close {
            //     padding: 0;
            //     display: inline-block;
            //     margin: 0;
            //     background: #26597d;
            //     position: absolute;
            //     right: -16px;
            //     width: 35px;
            //     height: 35px;
            //     z-index: 99;
            //     opacity: 1;
            //     text-shadow: none;
            //     color: #fff;
            //     border-radius: 50%;
            //     top: -13px;
            //     outline: none;
            //     box-shadow: none;

            //     &:hover {
            //         background: #4297d3;
            //         transition: all 0.5s;
            //     }
            // }
            .close {
                background-color: #234158;
                border-radius: 0;
                height: 45px;
                position: absolute;
                right: 10px !important;
                top: 16px !important;
                text-shadow: none;
                color: #fff;
                opacity: 0.9;
                &:hover{
                    background-color: #eaeaea;
                    color: #333;
                }
            }
        }

    }
}

.modal.fade.dk-modalCvRight .modal-dialog {
    right: -70%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show.dk-modalCvRight .modal-dialog {
    right: 0;
}

.modal.dk-modalCvRight {
    .modal-dialog {
        margin: 0 !important;
        float: right;
        min-width: 60%;

        .modal-content {
            .modal-header {
                background: #26597d;
                border-radius: 0;
                padding: 10px 12px;
                border-bottom: none;

                .modal-title {
                    color: #fff;
                    display: block;
                    font-size: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        color: #fff;
                        display: inline-block;
                        margin: 0 7px;

                        &:hover {
                            color: #fff;
                        }
                    }
                }

                .close {
                    color: #fff;
                    font-size: 22px;
                    margin: 0;
                    opacity: 1;
                    outline: none;
                    padding: 0;
                    position: absolute;
                    right: 8px;
                    text-shadow: none;
                    top: 10px;
                }
            }
        }
    }
}