.dkg-setting-contactus-mainCon{
    background-color: #eaeaea;
    margin-top: 154px;
    height: calc(100vh - 167px);
    overflow-y: auto;
    padding: 25px 0;
    padding-bottom: 0;
    &.dkg-contactus-mainCon{
        // overflow-y: hidden;
        .dkg-setting-header{
            color: #f2f2f2;
            background-color: #243b4c;
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-radius: 5px 5px 0 0;
            text-transform: capitalize;
            width: 100%;
            max-width: 75%;
            margin: 0 auto;
            .dkg-setting-header-title{
                margin: 0;
                font-size: 22px;
                font-weight: 600;
                text-align: center;
                color: #f2f2f2;
            }
        }
        .dkg-other-project-header{
            .dkg-otherprject-title{
                // background-color: #f2f2f2;
                color: #666;
                width: 100%;
                max-width: 75%;
                margin: 0 auto;    
            }
        }
    }
    &.dkg-socail-media-mainCon{
        height: calc(100vh - 195px) !important;
        background-color: transparent;
        border: none;
        overflow-y: hidden;
    }
    .dkg-other-project-header{
        .dkg-otherprject-title{
            font-size: 1rem;
            width: 100%;
            text-align: center;
            color: #333;
            padding: 6px 10px;
            background-color: #eaeaea;
            min-height: 55px;
            // border-bottom: 1px solid #ddd;
            line-height: 25px;
            border: 1px solid #ddd;
            border-bottom: none;
        }
    } 
    .dkg-contactus-cardCon{
        background-color: #fff;
        width: 100%;
        max-width: 75%;
        margin:  0 auto;
        padding: 10px 0;
        border: 1px solid #ddd;
        .dkg-contact-card{
            background-color: #243b4c;
            background-clip: border-box;
            border-radius: 1px;
            border: 1px solid #243b4c;
            width: 100%;
            max-width: 75%;
            margin: 20px auto;
            .dkg-contact-cardRow{
                .dkg-contact-card-leftCol{
                    .dkg-contact-card-detail{
                        background: #fff;
                        margin: 0 15% 0 -30%;
                        border: 1px solid #ccc;
                        padding: 30px 30px 5px;    
                        position: relative;
                        top: -15px;                        
                        .dkg-card-detail-title{
                            margin: 0 0 15px;
                            font-size: 18px;
                            color: #25455a;
                            font-weight: 600;
                        }
                        .dkg-contact-card-ul{
                            margin: 0;
                            padding: 0 0 0 0px;
                            list-style: none;
                            .dkg-contact-card-li{
                                margin: 0;
                                padding: 0 0 15px 15px;
                                color: #25455a;
                                position: relative;
                                i{
                                    position: absolute;
                                    left: 0;
                                    top: 5px
                                }
                                a{
                                    color: #333;
                                }
                            }
                        }
                        .dkg-contact_social{
                            padding-left: 22px;
                            a{
                                padding-right: 8px;
                                img{
                                    width: 35px;
                                    height: 35px;
                                }
                            }
                        }
                    }
                }
                .dkg-contact-card-rightCol{
                    color: #25455a;
                    form{
                        padding: 30px;
                        padding-top: 10px;
                        .row{
                            div{
                                h2{
                                    margin: 0 0 15px;
                                    font-size: 18px;
                                    color: #243b4c;
                                }
                            }
                            .form-group{
                                margin-bottom: 1rem;
                                .form-control{
                                    height: 34px;
                                    padding: 0.1rem 0.5rem;
                                    font-size: 13px;
                                    background-color: #fff;
                                    line-height: 1.3;
                                    border: #fff;
                                    text-align: left;
                                }
                                textarea.form-control {
                                    height: 90px;
                                    text-align: left;
                                }
                            }
                            
                        }
                        p{
                            margin-bottom: 1rem;
                            color: #ddd;
                            font-size: 14px;
                            input[type="checkbox"] {
                                cursor: pointer;
                                appearance: auto;
                                background: #fff;
                                border-radius: 0.25rem;
                                box-sizing: border-box;
                                position: relative;
                                width: 14px;
                                height: 14px;
                                border-width: 0;
                                transition: all 0.3s linear;
                                border: 1px solid #ddd;
                                margin-right: 0.5rem;
                                border-radius: 0;
                            }
                            a{
                                color: rgb(93, 157, 226);
                                margin: 0 0.5rem;
                            }
                        }
                        .btn-light {
                            color: #212529;
                            background-color: #f8f9fa;
                            border-color: #f8f9fa;
                            border: 1px solid transparent;
                            padding: 0.375rem 0.75rem;
                            font-size: 1rem;
                            line-height: 1.5;
                            border-radius: 0.25rem;
                        }
                        .form-group{
                            textarea{
                                text-align: left;
                                font-size: 13px;
                                padding: 0.25rem 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
    &.dkg-otherprojects-main{
        // background: url('../../../../../assets/images/login_bg.jpg') no-repeat;
        background-color: #fff;
        background-size: cover;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        height: calc(100vh - 220px);
        overflow-y: hidden;
        .dk-otherprojectMain { 
            width: 100%;  
            .dkg-other-project-header{
                .dkg-otherprject-page-title{
                    font-size: 1rem;
                    width: 100%;
                    text-align: center;
                    color: #666;
                    padding: 6px 10px;
                    background-color: #f2f2f2;
                    min-height: 55px;
                    border-bottom: 1px solid #ddd;
                    line-height: 25px;
                }
            }                         
            .dkg-visitwebaite-cardCon {
                width: 100%;
                margin-top: 10px;
                padding: 0 10px;
                height: calc(100vh - 300px);
                overflow-y: auto;
                // overflow: hidden;
                display: flex;
                align-items: center;
                flex-direction: column;
                .dk-otherproject-cols {
                    width: 100%;
                    height: 50%;
                    // max-width: 1100px;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    margin: 20px auto;
        
                    &.dkg-top-level-boxCon {
                        max-width: 366px;
                        margin-bottom: 30px;
                    }
        
                    .dk-otherproject-box {
                        display: inline-block;
                        margin: 0 10px;
                        width: 100%;
        
                        a {
                            padding: 30px 20px;
                            background: #fefefe;
                            border-radius: 8px 8px 0 0;
                            height: 90%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dk-visitLogo {
                                text-align: center;
                                display: block;
        
                                img {
                                    width: 100%;
                                    max-width: 150px;
                                    margin: auto;
                                    transform: scale(1);
                                    transition: all 0.5s;
                                    object-fit: cover;
                                }
        
                                .dkg-adminboard-title {
                                    color: #333;
                                    transition: all 0.5s;
                                    font-weight: 700;
                                    font-size: 20px;
                                }
                            }
                            
                            &:hover {
                                img {
                                    transform: scale(1.1);
                                    transition: all 0.5s;
                                }
                            }
        
                            .title {
                                font-size: 14px;
                                text-transform: uppercase;
                                color: #26596d;
                                font-weight: 600;
                                text-align: center;
                                display: block;
                                margin-top: 10px;
                            }
                            
                        }
                        .dkg-visit-prjbtn{
                            width: 100%;
                            border-top: 1px solid #ddd;
                            padding: 7px 10px;
                            display: block;
                            background-color: #f2f2f2;
                            color: #333;
                            text-transform: capitalize;
                            border-radius: 0 0 8px 8px;
                            margin: 0;
                            box-shadow: none;
                            font-size: 14px;
                            .dkg-resume-icon {
                                margin-left: 0.5rem;
                            }
                            &:hover{
                                background-color: #eaeaea;
                                transition: all 0.5s;
                            }
                        }
                        .dkg-project-btn-progess-Con{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            border-top: 1px solid #ddd;
                            // padding: 7px 10px;
                            display: block;
                            background-color: #f2f2f2;
                            color: #333;
                            text-transform: capitalize;
                            border-radius: 0 0 8px 8px;
                            .dkg-project-infobtn{
                                width: 30%;
                                svg{
                                    width: 23px;
                                    height: 23px;
                                }
                            }
                            .dkg-info-icon{
                                width: 50%;
                                border-right: 1px solid #ddd;
                                padding: 7px 10px;
                                text-align: center;
                                cursor: pointer;
                                color: #555;
                                &:hover{
                                    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                    background-color: #eaeaea;
                                    color: #3c97b6;
                                }
                                svg{
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            .dkg-progress-barCon{
                                width: 50%;
                                padding: 7px 10px;
                                text-align: center;
                                color: #555;
                            }

                        }
                    }
                    // &:first-child{
                    //     margin-bottom: 0;
                    // }
                    // &:last-child{
                    //     margin-top: 0;
                    // }
                }
            }
            .dkg-projectsbox-Con{
                width: 100%;
                padding: 15px;
                padding-left: 10px;
                padding-right: 0;
                background-color: #1f394c;
                height: calc(100vh - 285px);
                overflow-y: auto;
                .dkg-projectbox-mainCon{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .dk-compnyproject-cols{
                        display: flex;
                        width: 100%;
                        .dkg-compnyproject-box {
                            display: inline-block;
                            margin: 10px;
                            width: 100%;
                            a {
                                padding: 15px 20px;
                                background-color: #fff;
                                border-radius: 8px 8px 0 0;
                                height: 115px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #ddd;
                                .dk-visitLogo {
                                    text-align: center;
                                    display: block;
            
                                    img {
                                        width: 100%;
                                        max-width: 150px !important;
                                        margin: auto;
                                        // transform: scale(1);
                                        // transition: all 0.5s;
                                        object-fit: cover;
                                    }
            
                                    .dkg-adminboard-title {
                                        color: #333;
                                        transition: all 0.5s;
                                        font-weight: 700;
                                        font-size: 20px;
                                    }
                                }
                                
                                // &:hover {
                                //     img {
                                //         transform: scale(1.1);
                                //         transition: all 0.5s;
                                //     }
                                // }
            
                                .title {
                                    font-size: 14px;
                                    text-transform: uppercase;
                                    color: #26596d;
                                    font-weight: 600;
                                    text-align: center;
                                    display: block;
                                    margin-top: 10px;
                                }
                                
                            }
                            .dkg-project-content{
                                background-color: #eaeaea;
                                padding: 30px 15px;
                                padding-top: 10px;
                                border-radius: 0 0 5px 5px;
                                border: 1px solid #ddd;
                                border-top: none;
                                min-height: 170px;
                                max-height: 170px;
                                .dkg-content-title-desc{
                                    padding-bottom: 0.93rem;
                                    .dkg-pj-content-title{
                                        color: #333;
                                        font-size: 1rem;
                                        font-weight: 600;
                                        margin-bottom: 0.75rem;
                                        text-align: center;
                                        display: none;
                                    }
                                    .dkg-pj-content-desc{
                                        color: #333;
                                        font-size: 0.85rem;
                                        text-align: center;
                                        margin-bottom: 0.5rem;
                                    }
                                }
                                .dkg-content-bottom-sec{
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    .dkg-pj-leftCon{
                                        .dkg-pj-bottom-title{
                                            font-size: 0.9rem;
                                            color: #333;
                                        }
                                        .dkg-roject-cmplition-Con{
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .dkg-project-comletetion{
                                                width: 100%;
                                                max-width: 90%;
                                                margin: 0 auto;
                                                border: 1px solid #ddd;
                                                color: green;
                                            }
                                        }
                                    }
                                    .dkg-pj-rightCon{
                                        display: none;
                                        .dkg-pj-bottom-btn{
                                            display: inline-block;
                                            border: none;
                                            background-color: green;
                                            color: #fff;
                                            padding: 5px 9px;
                                            border-radius: 5px;
                                            font-size: 0.85rem;

                                        }
                                    }
                                }
                                .dkg-content-bottom-sec-Con{
                                    .dkg-roject-cmplition-Con{
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .dkg-project-comletetion{
                                            width: 100%;
                                            max-width: 75%;
                                            margin: 0 auto;
                                            border: 1px solid #ccc;
                                            color: #333;
                                            padding: 7px 10px;
                                            background-color: #eaeaea;
                                            box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
                                            &:focus{
                                                border:none;
                                                outline: none;
                                            }
                                            &:hover{
                                                background-color: #f2f2f2;
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-visit-prjbtn{
                                width: 100%;
                                border-top: 1px solid #ddd;
                                padding: 7px 10px;
                                display: block;
                                background-color: #f2f2f2;
                                color: #333;
                                text-transform: capitalize;
                                border-radius: 0 0 8px 8px;
                                margin: 0;
                                box-shadow: none;
                                font-size: 14px;
                                .dkg-resume-icon {
                                    margin-left: 0.5rem;
                                }
                                &:hover{
                                    background-color: #eaeaea;
                                    transition: all 0.5s;
                                }
                            }
                            .dkg-project-btn-progess-Con{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                border-top: 1px solid #ddd;
                                // padding: 7px 10px;
                                background-color: #f2f2f2;
                                color: #333;
                                text-transform: capitalize;
                                border-radius: 0 0 8px 8px;
                                display: none !important;
                                .dkg-project-infobtn{
                                    width: 30%;
                                    svg{
                                        width: 23px;
                                        height: 23px;
                                    }
                                }
                                .dkg-info-icon{
                                    width: 50%;
                                    border-right: 1px solid #ddd;
                                    padding: 7px 10px;
                                    text-align: center;
                                    cursor: pointer;
                                    color: #555;
                                    &:hover{
                                        box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                        background-color: #eaeaea;
                                        color: #3c97b6;
                                    }
                                    svg{
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                                .dkg-progress-barCon{
                                    width: 50%;
                                    padding: 7px 10px;
                                    text-align: center;
                                    color: #555;
                                }

                            }
                            &:last-child{
                                margin-right: 15px;
                            }
                        }
                        &:last-child{
                            padding-bottom: 11px !important;
                        }

                    }

                }
            }
        }
    }
    .dkg-myacc-companytab{
        .dkg-other-project-header{
            width: 100%;
            .dkg-other-prjects-title{
                font-size: 1rem;
                width: 100%;
                text-align: center;
                color: #666;
                padding: 6px 10px;
                background-color: #f2f2f2;
                min-height: 55px;
                border-bottom: 1px solid #ddd;
                line-height: 25px;
            }
        }
        .dkg-company-table-con{
            // height: calc(100vh - 247px);
            // overflow-y: auto;
            height: calc(100vh - 247px);
            .dkg-company-table{
                border: none !important;
                tbody{
                    tr{
                        td{
                            border: 1px solid #ddd;
                            padding: 8px;
                            text-align: left;
                            background-color: #fff;
                            vertical-align: middle;
                            &:nth-child(1) {
                                width: 22%;
                                background-color: #ddd;
                                color: #ccc;
                                font-size: 14px !important;
                                text-transform: uppercase;
                                border: 1px solid #cdcbcb;
                                border-left: none;
                                color: #333;

                            }
                            &:nth-child(02){
                                border-right: none;
                            }
                        }
                        // &:first-child{
                        //     td{
                        //         border-top: none;
                        //     }
                        // }
                    }
                }
            }
        }
    }
    .dkg-socialmedia-tab{
        height: calc(100vh - 255px);
        .dkg-social-box-cols{
            padding: 0;
            height: 100%;
            .dkg-social-media-box{
                display: flex;
                align-items: center;
                width: 100%;
                border: 1px solid #ddd;
                background-color: #fff;
                margin-bottom: 1rem;
                height: 100%;
                 max-height: 19%;
                .dkg-socail-media-icon{
                    width: 120px;
                    height: 85px;
                    border-right: 1px solid #ddd;
                    .dkg-sm-imgcon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        width: 100%;
                        .dkg-sm-img{
                            width: 55px;
                            height: 55px;
                        }
                    }
                }
                .dkg-send-urlbtn-con{
                    width: 140px;
                    height: 85px;
                    border-left: 1px solid #ddd;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    div{
                        div{
                            .dkg-send-urlbtn{
                                color: #fff;
                                background-color: #1f394c;
                                padding: 7px 10px;
                                border-radius: 5px;
                                font-size: 14px;
                                &:hover{
                                    background-color: #3C97B6;
                                }
                            
                            } 
                        }
                    }
                }
                .dkg-sm-nameurl-con{
                    width: 100%;
                    height: 85px;
                    .dkg-timeflex{
                        width: 100%;
                        .dkg-social-media-name{
                            display: block;
                            height: 43px;
                            line-height: 42px;
                            font-size: 13px;
                            border-bottom: 1px solid #ddd;
                            text-align: center;
                            width: 100%;
                            
                        }
                        .dkg-social-media-url{
                            display: block;
                            height: 42px;
                            line-height: 42px;
                            font-size: 13px;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
                // &:first-child{
                //     margin-top: 1.75rem;
                // }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .dkg-ourservices-mainCon{
        width: 100%;
        height: 100%;
        display: flex;
        .dkg-ourservices-firstRow{
            height: calc(100vh - 256px);
            border: 1px solid #ddd;
            width: 100%;
            margin-right: 0;
            background-color: #f2f2f2;
            border-radius: 5px;
            margin-left: 10px;
            .dkg-ourservice-header{
                padding: 10px 15px;
                border-bottom: 1px solid #ccc;
                border-radius: 5px 5px 0 0;
                display: block;
                text-align: center;
                background-color: #1f394c;
                text-transform: uppercase;
                .dkg-ourservice-title{
                    font-weight: 700;
                    color: #ddd;
                    font-size: 14px;

                }
            }
            .dkg-ourservice-body{
                background-color: #fff;
                padding: 10px 15px;
                height: calc(100vh - 300px);
                .dkg-service-desc{
                    font-size: 14px;
                    margin-bottom: 0;
                }
                &.dkg-ourservice-new-bodyCon{
                    padding: 10px 0 !important;
                    .dkg-service-desc{
                        font-size: 1rem;
                        margin-bottom: 0;
                        padding: 10px 15px !important;
                        background-color: #f2f2f2;
                        &.dkg-ourservice-desc{
                            max-height: 121px;
                            overflow-y: auto;
                            div{
                                p{
                                    margin-bottom: 0 !important;
                                    color: #666;
                                    span{
                                        margin-bottom: 0 !important;
                                        color: #666 !important;
                                    }
                                }
                            }
                        }
                       } 
                       &.dkg-ourservice-new-bodyCon{
                        padding: 0 !important;
                        padding-bottom: 10px !important;
                       }
                    .dkg-ourservice-tableCon{
                        height: calc(100vh - 380px);
                        overflow-y: auto;
                        .dkg-ourservice-table{
                            margin-bottom: 0;
                            border-left: none;
                            border-right: none;
                            tbody{
                                tr{
                                    td{
                                        padding: 0.65rem 0.7rem;
                                        &:nth-child(01){
                                            text-align: center;
                                            width: 45px;
                                            border-left: none;
                                        }
                                        &:nth-child(02){
                                            border-right: none;
                                        }
                                    }
                                    &:last-child{
                                        td{
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .dkg-services-middle-div{
            background-color: #eaeaea;
            height: 20px;
        }
        .dkg-ourservices-secondRow{
            height: calc(100vh - 256px);
            border: 1px solid #ddd;
            width: 100%;
            margin-left: 7px;
            background-color: #f2f2f2;
            border-radius: 5px;
            margin-right: 15px;
            .dkg-ourservice-header{
                padding: 10px 15px;
                border-bottom: 1px solid #ccc;
                border-radius: 5px 5px 0 0;
                display: block;
                text-align: center;
                background-color: #1f394c;
                text-transform: uppercase;
                .dkg-ourservice-title{
                    font-weight: 700;
                    color: #ddd;
                    font-size: 14px;

                }
            }
            .dkg-ourservice-body{
                background-color: #fff;
                padding: 10px 15px;
                height: calc(100vh - 300px);
               .dkg-service-desc{
                font-size: 1rem;
                margin-bottom: 0;
                padding: 10px 15px !important;
                background-color: #f2f2f2;
                &.dkg-ourservice-desc{
                    max-height: 121px;
                    overflow-y: auto;
                    div{
                        p{
                            margin-bottom: 0 !important;
                            color: #666;
                        }
                    }
                }
               } 
               &.dkg-ourservice-new-bodyCon{
                padding: 0 !important;
                padding-bottom: 10px !important;
                .dkg-ourservice-tableCon{
                    .dkg-ourservice-table{
                        margin-bottom: 0;
                        border-left: none;
                        border-right: none;
                        tbody{
                            tr{
                                td{
                                    padding: 0.65rem 0.7rem;
                                    &:nth-child(01){
                                        text-align: center;
                                        width: 45px;
                                        border-left: none;
                                    }
                                    &:nth-child(02){
                                        border-right: none;
                                    }
                                }
                                &:last-child{
                                    td{
                                        border-bottom: none;

                                    }
                                }
                            }
                        }
                    }
                }
               }
            }
        }
    }
    &.dkg-aboutus-servicesCon{
        border: none;
        height: calc(100vh - 210px);
        overflow-y: hidden;
        background-color: transparent;
    }
    &.dkg-company-tabpane-Con{
        height: calc(100vh - 245px);
        overflow-y: hidden;
        .dkg-company-table-con{
            height: calc(100vh - 287px);
            overflow-y: auto;
            .dkg-company-table {
                border: none !important;
                height: 97%;
                tbody{
                    tr{
                        &:last-child{
                            color: #234158;
                            td{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

    }
}