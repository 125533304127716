.dkg-paidservices-homeMain{
   margin-top: 155px;
   height: calc(100vh -  169px);
   overflow-y: auto;
   overflow-x: hidden;
   background-color: #eaeaea;
   .dkg-paidservices-main-banner{
      background-image: url(../../../../../assets/images/banner-service.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      left: 0px;
      top: 0;
      height: 440px;
      width: 100%;
      .dkg-paidservice-main-con{
         width: 100%;
         max-width: 1350px;
         margin: 0 auto;
         height: 100%;
         position: relative;
         .dkg-service-btncontent-con{
            // width: 385px;
            padding: 35px 20px;   
            position: relative;
            top: 5rem;     
            .dkg-paidservice-content{
               .dkg-paidservice-title{
                  color: #ddd;
                  // font-family: Aleo !important;
                  font-family: "Poppins", sans-serif !important;
                  font-weight: bolder;
                  font-size: 36px;
               }
               .dkg-paidservice-subtitle{
                  color: #ddd;
                  max-width: 500px;
                  font-size: 15px;
               }
            }
            .dkg-book-appointment-btnCon{
               margin-top: 30px;
               .dkg-book-appointment-btn{
                  display: inline-block;
                  padding: 10px 25px;
                  background-color: #00466b;
                  color: #e9e6e6;
                  text-transform: capitalize;
                  border-radius: 25px;
                  font-size: 1rem;
                  .dkg-calender-icon{
                     margin-left: 0.95rem;
                     svg{
                        color: #e9e6e6;
                     }
                  }
                  &:hover{
                     box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.16) inset, 0px 0px 10px 0px #33E7EA;
                     transition: 0.5s ease-in-out;
                  }
               }
            }
         }
         .dkg-paidservice-bottom-sec{
            position: absolute;
            left: 0;
            bottom: -95px;
            width: 100%;
            .dkg-paidservice-bottom-ul{
               list-style: none;
               display: flex;
               align-items: center;
               width: 100%;
               .dkg-paidservice-bottom-li{
                  background-color: #00466b;
                  color: #e9e6e6;
                  width: 100%;
                  max-width: 16.6666%;
                  height: 160px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  border: 1px solid #787474;
                  border-right: none;
                  cursor: pointer;
                  &:last-child{
                     border-right: 1px solid #787474;
                  }
                  &:hover{
                     box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                  }
                  .dkg-service-icon-con{
                    padding-bottom: 1rem;
                     .dkg-service-icon{
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                     }
                  }
                  .dkg-service-name{
                     font-size: 1.35rem;
                     text-transform: lowercase;
                     &:first-letter{
                        text-transform: capitalize;
                     }
                     
                  }
               }
               &:nth-child(01){
                  .dkg-paidservice-bottom-li{
                     border-bottom: none;
                  }
               }
            }
         }
      }
   }
   .dkg-service-second-sec{
      width: 100%;
      background-color: #fff;
      .dkg-service-header{
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 1rem 0;
         flex-direction: column;
         width: 100%;
         max-width: 75%;
         margin: 0 auto;
         padding-top: 2.65rem;
         padding-bottom: 1.95rem;
         .dkg-service-header-title{
            font-weight: 700;
            font-size: 26px;
            color: #333;
            line-height: 1.2em;
            text-align: center;
            text-transform: uppercase;
            display: flex;
            align-items: center;
         }
         .dkg-service-header-subtitle{
            font-size: 1.10rem;
            text-align: center;
         }
      }
      .dkg-service-maincontent{
         width: 100%;
         padding: 0;
         max-width: 1350px;
         margin: 0 auto;
         padding-bottom: 6rem;
         .dkg-paidservice-bottom-sec{
            // position: absolute;
            // left: 0;
            // bottom: -95px;
            width: 100%;
            .dkg-paidservice-bottom-ul{
               list-style: none;
               display: flex;
               flex-wrap: wrap;
               align-items: center;
               width: 100%;
               .dkg-paidservice-bottom-li{
                  background-color: #00466b;
                  color: #e9e6e6;
                  width: 100%;
                  max-width: 20%;
                  height: 160px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  border: 1px solid #787474;
                  border-right: none;
                  cursor: pointer;
                  &:last-child{
                     border-right: 1px solid #787474;
                  }
                  &:hover{
                     box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                     // box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
                     // transform: scale(0.10, 0.10);
                     background-color: #1f394c;
                     color: #fff;
                  }
                  .dkg-service-icon-con{
                    padding-bottom: 1rem;
                    .dkg-service-icon{
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    }
                  }
                  .dkg-service-name{
                     font-size: 1.35rem;
                     text-transform: lowercase;
                     &:first-letter{
                        text-transform: capitalize;
                     }
                     
                  }
               }
               &:nth-child(01){
                  .dkg-paidservice-bottom-li{
                     border-bottom: none;
                  }
               }
            }
         }
      }
   }
   .dkg-service-about-sec{
      .dkg-services-mainCol{
        background-color: #1f394c;
         padding: 15px 0;
         padding-top: 2.65rem;
         .dkg-services-page-title{
             font-weight: 700;
             font-size: 24px;
             color: #ddd;
             line-height: 1.2em;
             text-align: center;
             margin-top: 0;
             width: 100%;
             max-width: 75%;
            margin: 0 auto;
         }
         .dkg-services-page-text{
             font-size: 1.10rem;
             color: #ddd;
             text-align: justify;
             padding: 10px 0;
             padding-bottom: 0;
             width: 100%;
             text-align: center;
             max-width: 75%;
             margin: 0 auto;
         }
         .dkg-cnacc-legal-mainCon{
             margin-top: 30px;
             .dkg-cnacc-legal-banner{
                 width: 100%;
                 max-width: 52%;
                 margin: 0 auto;
                 background-color: #FFDE59;
                 position: relative; 
                 .dkg-banner-upper-cross{
                     color: #f3f3f3;
                     display: block;
                     width: 100%;
                     height: 100%;
                     padding: 0;
                     background-color: #FFDE59;
                     // margin-bottom: 1em;
 
                 }   
                 .dkg-banner-upper-cross {
                     border: 20px solid #2D2E2C;
                     border-width: 20px 0;
                     border-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, rgb(45, 46, 44) 5px, rgb(45, 46, 44) 10px) 20 0;
                     .dkg-pd-legal-content{
                     //    border-left: 2px solid #2D2E2C;
                     //    border-right: 2px solid #2D2E2C;
                        height: 100%;
                        padding: 1.25rem 0;
                        padding-bottom: 1.75rem;
                       .dkg-pd-legal-content-title{
                         font-size: 2.10rem;
                         text-align: center;
                         width: 100%;
                         color: #2D2E2C;
                         padding: 1rem 0;
                         padding-top: 1.75rem;
                         font-weight: bold;
                         .dkg-legal-doc-icon{
                             margin-left: 0.5rem;
                             position: relative;
                             top: -3px;
                            svg{
                             width: 26px;
                             height: 26px;
                             color: #EE404C;
                            } 
                         }
                       }
                       .dkg-pd-legal-content-body{
                         padding: 1.25rem 3.55rem;
                         padding-bottom: 3rem;
                         padding-top: 0.25rem;
                         .dkg-pd-legal-content-card{
                             background-color: #FEF1A3;
                             border-radius: 0;
                             display: flex;
                             justify-content: space-between;
                             width: 100%;
                             .dkg-legal-content-card-left{                                    
                                 width: 20%;
                                 display: flex;
                                 align-items: center;
                                 justify-content: center;
                                 border: 1px solid #a99f9f;
                                 .dkg-legal-content-imgCon{
                                      width: 105px;
                                      height: 105px;
                                     .dkg-legal-content-img{
                                         width: 100%;
                                         height: auto;
                                         background-size: cover;
                                     }
                                 }
                             }
                             .dkg-legal-content-card-right{
                                 width: 80%;
                                 display: flex;
                                 align-items: center;
                                 justify-content: center;
                                 border: 1px solid #a99f9f;
                                 border-left: none;
                                 .dkg-legal-card-content{
                                     text-align: justify;
                                     color: #2D2E2C;
                                     padding: 0 15px;
                                     font-size: 1rem;
                                 }
                             }
                         }
                       }
                    }
                 }
             }
             
         }
     }
     .dkg-services-container{
         width: 100%;
         max-width: 1200px;
         margin: 0 auto;
         .dkg-services-mainRow{
             .dkg-services-mainCol{
                 padding-bottom: 5px;
                 .dkg-services-page-title{
                     font-weight: 700;
                     font-size: 24px;
                     color: #333;
                     line-height: 1.2em;
                     text-align: center;
                     margin-top: 10px;
     
                 }
                 .dkg-services-page-text{
                     font-size: 1.10rem;
                     color: #333;
                     text-align: justify;
                     padding: 10px 0;
                     padding-bottom: 0;
                     width: 100%;
                     text-align: center;
                     max-width: 75%;
                     margin: 0 auto;
                 }
                 .dkg-cnacc-legal-mainCon{
                     margin-top: 30px;
                     .dkg-cnacc-legal-banner{
                         width: 100%;
                         max-width: 52%;
                         margin: 0 auto;
                         background-color: #FFDE59;
                         position: relative; 
                         .dkg-banner-upper-cross{
                             color: #f3f3f3;
                             display: block;
                             width: 100%;
                             height: 100%;
                             padding: 0;
                             background-color: #FFDE59;
                             // margin-bottom: 1em;
 
                         }   
                         .dkg-banner-upper-cross {
                             border: 20px solid #2D2E2C;
                             border-width: 20px 0;
                             border-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, rgb(45, 46, 44) 5px, rgb(45, 46, 44) 10px) 20 0;
                             .dkg-pd-legal-content{
                             //    border-left: 2px solid #2D2E2C;
                             //    border-right: 2px solid #2D2E2C;
                                height: 100%;
                                padding: 1.25rem 0;
                                padding-bottom: 1.75rem;
                               .dkg-pd-legal-content-title{
                                 font-size: 2.10rem;
                                 text-align: center;
                                 width: 100%;
                                 color: #2D2E2C;
                                 padding: 1rem 0;
                                 padding-top: 1.75rem;
                                 font-weight: bold;
                                 .dkg-legal-doc-icon{
                                     margin-left: 0.5rem;
                                     position: relative;
                                     top: -3px;
                                    svg{
                                     width: 26px;
                                     height: 26px;
                                     color: #EE404C;
                                    } 
                                 }
                               }
                               .dkg-pd-legal-content-body{
                                 padding: 1.25rem 3.55rem;
                                 padding-bottom: 3rem;
                                 padding-top: 0.25rem;
                                 .dkg-pd-legal-content-card{
                                     background-color: #FEF1A3;
                                     border-radius: 0;
                                     display: flex;
                                     justify-content: space-between;
                                     width: 100%;
                                     .dkg-legal-content-card-left{                                    
                                         width: 20%;
                                         display: flex;
                                         align-items: center;
                                         justify-content: center;
                                         border: 1px solid #a99f9f;
                                         .dkg-legal-content-imgCon{
                                              width: 105px;
                                              height: 105px;
                                             .dkg-legal-content-img{
                                                 width: 100%;
                                                 height: auto;
                                                 background-size: cover;
                                             }
                                         }
                                     }
                                     .dkg-legal-content-card-right{
                                         width: 80%;
                                         display: flex;
                                         align-items: center;
                                         justify-content: center;
                                         border: 1px solid #a99f9f;
                                         border-left: none;
                                         .dkg-legal-card-content{
                                             text-align: justify;
                                             color: #2D2E2C;
                                             padding: 0 15px;
                                             font-size: 1rem;
                                         }
                                     }
                                 }
                               }
                            }
                         }
                     }
                     
                 }
             }
             .dkg-services-box{
                 background-color: #fff;
                 padding: 15px;
                 border: 1px solid #ddd;
                 border-radius: 5px;
                 @media (min-width: 992px){
                     &.col-lg-4 {
                         flex: 0 0 32.40%;
                         max-width: 32.40%;
                         margin: 7px;
                     }
                 }
                 
                 @media (min-width: 768px){
                     &.col-md-4 {
                         flex: 0 0 32.40%;
                         max-width: 32.40%;
                         margin: 7px;
                     }
                 }
                 .dkg-services-imgCon{
                     width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                     .dkg-services-img{
                         width: 60px;
                         height: 60px;
                     }
                 }
                 .dkg-service-desc-textCon{
                     .dkg-service-desc{
                         font-weight: 700;
                         font-size: 20px;
                         color: #2a2a2a;
                         line-height: 1.4em;
                         text-align: center;
                         margin-top: 1.15rem;
                         width: 100%;
                     }
                     .dkg-service-text{
                         color: #000;
                         text-align: center;
                     }
                 }
                 .dkg-service-box-btnCon{
                     margin-top: 18px;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     .dkg-service-box-btn{
                         display: inline-block;
                         color: #3c97b6;
                         border-width: 1px;
                         border-radius: 40px;
                         letter-spacing: 0px;
                         font-size: 14px;
                         background-color: #ffffff;
                         padding: 7px 20px;
                         border: 1px solid #3c97b6;
                         text-transform: inherit;
                         box-shadow: none;
                         transition: all ease-in 0.5ms;
                         &:hover{
                             background-color: #3c97b6;
                             color: #fff;
                         }
                     }
                 }
             }
         }
         .dkg-services-boxsCon{
             width: 100%;
             .dkg-myservices-boxs{
                 display: flex;
                 .dkg-services-box{
                     background-color: #fff;
                     padding: 15px;
                     border: 1px solid #b0d8e5;
                     border-radius: 5px;
                     width: 100%;
                     max-width: 100%;
                     margin: 12px;
                     @media (min-width: 992px){
                         &.col-lg-4 {
                             flex: 0 0 32.40%;
                             max-width: 32.40%;
                             margin: 7px;
                         }
                     }
                     
                     @media (min-width: 768px){
                         &.col-md-4 {
                             flex: 0 0 32.40%;
                             max-width: 32.40%;
                             margin: 7px;
                         }
                     }
                     .dkg-services-imgCon{
                         width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                         .dkg-services-img{
                             width: 60px;
                             height: 60px;
                         }
                     }
                     .dkg-service-desc-textCon{
                         .dkg-service-desc{
                             font-weight: 700;
                             font-size: 18px;
                             color: #2a2a2a;
                             line-height: 1.4em;
                             text-align: center;
                             margin-top: 1.15rem;
                             width: 100%;
                         }
                         .dkg-service-text{
                             color: #000;
                             text-align: center;
                         }
                     }
                     .dkg-service-box-btnCon{
                         margin-top: 18px;
                         display: flex;
                         align-items: center;
                         justify-content: center;
                         .dkg-service-box-btn{
                             display: inline-block;
                             border-width: 1px;
                             border-radius: 40px;
                             letter-spacing: 0px;
                             font-size: 14px;
                             padding: 7px 20px;
                             border: 1px solid #3c97b6;
                             text-transform: inherit;
                             box-shadow: none;
                             transition: all ease-in 0.5ms;
                             background-color: #ffffff;
                             color: #3c97b6;
                             &:hover{
                                 background-color: #3c97b6;
                                 color: #fff;    
                                 border: 1px solid #3c97b6;
                             }
                         }
                     }
                     &:nth-child(01),
                     &:nth-child(04){
                         margin-left: 0;
                     }
                     &:nth-child(03),
                     &:nth-child(06){
                         margin-right: 0;
                     }
                 }
             }
         }
     }
     .dkg-about-service-blueCon{
        //  margin-top: 0.94375rem;
         border-top: 1px solid #1f394c;
         width: 100%;
         padding: 0.75rem 0.94375rem;
         background-color: #1f394c;
         padding-bottom: 2.65rem;
         padding-top: 1rem;
        //  height: calc(100vh - 295px);
        //  overflow-x: hidden;
        //  overflow-y: auto;
         .dkg-service-block-con{
             width: 100%;
             max-width: 1350px;
             margin: 0 auto;
             .dkg-service-mainRow{
                 .dkg-about-service-leftcon{
                     padding-right: 2.5rem;
                     .dkg-about-service-imgCon{
                         width: 100%;
                         height: 427px;
                         .dkg-about-service-img{
                             width: 100%;
                             height: 100%;
                             position: relative;
                             top: -1.92rem;
                             object-fit: scale-down;
                         }
                     }
                 }
                 .dkg-about-service-rightcon {
                     padding-left: 2.5rem;
                     .dkg-about-us-titleCon{
                         .dkg-about-us-title{
                             font-size: 18px;
                             line-height: 24px;
                             margin-bottom: 1rem;
                             font-weight: 600;
                             color: #ddd;
                         }
                     }
                     .dkg-about-us-subtitleCon{
                         .dkg-about-us-subtitle{
                             font-size: 38px;
                             line-height: 38px;
                             font-weight: 600;
                             color: #ddd;
                             margin-top: 2.35rem;
                             margin-bottom: 0.75rem;
                         }
                     }
                     .dkg-about-us-desc{
                         p{
                             color: #ddd;
                             font-size: 1.15rem;
                             margin-bottom: 0.75rem;
                             text-align: justify;
                         }
                     }
                     .dkg-aboutus-cardCon{
                         margin-top: 2rem;
                         .dkg-aboutus-card-ul{
                             display: flex;
                             align-items: center;
                             width: 100%;
                             margin: 0;
                             padding: 0;
                             gap: 4.5rem;
                             .dkg-aboutus-card-li{
                                 width: 100%;
                                 max-width: 30.33%;
                                 list-style: none;
 
                                 .dkg-aboutus-card{
                                     display: flex;
                                     align-items: center;
                                     justify-content: center;
                                     flex-direction: column;
                                     width: 100%;
                                     height: 100px;
                                     background-color: #eaeaea;
                                     text-align: center;
                                     gap: 1rem;
                                     border-radius: 5px;
                                     cursor: text;
                                    //  &:hover{
                                    //     background-color: #f2f2f2;
                                    //      -moz-transform: scale(1.1);
                                    //      -o-transform: scale(1.1);
                                    //      -ms-transform: scale(1.1);
                                    //      -webkit-transform: scale(1.1);
                                    //      transform: scale(1.1);
                                    //  }
                                     .dkg-aboutus-icon{
                                         svg{
                                             width: 24px;
                                             height: 24px;
                                             color: #666;
                                         }
                                         .dkg-aboutus-img{
                                             width: 45px;
                                             height: 45px;
                                         }
                                     }
                                     .dkg-aboutus-cardtitle{
                                         color: #333;
                                         font-size: 1.1rem;
                                         font-weight: 600;
                                     }
                                 }
                             }
                         }
                     }
                 }
             }
         }
      }
   }
   .dkg-service-team-sec{
      background-color: #fff;
      padding: 6rem 0.94375rem;
      padding-top: 2.65rem;
      .dkg-services-mainCol{
        width: 100%;
        max-width: 75%;
        margin: 0 auto;
        .dkg-services-page-title{
            font-weight: 700;
            font-size: 26px;
            color: #333;
            line-height: 1.2em;
            text-align: center;
            margin-top: 0;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        .dkg-services-page-text{
            font-size: 1.1rem;
            color: #333;
            padding: 10px 0;
            width: 100%;
            text-align: center;
            padding-bottom: 30px;
        }
      }
      .dkg-teams-box-main-con{
        width: 100%;
        max-width: 1350px;
        margin: 0 auto;
        .dkg-teams-box-main-Row{
            .dkg-teams-box-cols{
                .dkg-our-team{
                    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    // box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
                    //  rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
                    //   rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
                    //  rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                    background-color: #fff;
                    .dkg-our-team-img{
                        width: 100%;
                        height: 180px;
                        .dkg-teammate-img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .dkg-team-content{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 0.94375rem;
                        height: 145px;
                        .dkg-teammate-name{
                            font-size: 1.85rem;
                            font-weight: 700;

                        }
                        .dkg-team-post{
                            font-size: 1.15rem;

                        }
                    }
                    .dkg-our-team-social{
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        display: none;
                        li{
                            a{
                                i{
                                    color: #333;
                                }
                            }
                        }
                    }
                    &:hover{
                        position: relative;
                        // opacity: 0.1;
                        cursor: pointer;
                        .dkg-ourteam-overlay {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                            background: rgba(33, 38, 49, 0.8);
                            padding: 35px 40px 40px 40px;
                            transform-origin: top;
                            -moz-transform: perspective(15em) rotateX(-20deg);
                            -o-transform: perspective(15em) rotateX(-20deg);
                            -ms-transform: perspective(15em) rotateX(-20deg);
                            -webkit-transform: perspective(15em) rotateX(-20deg);
                            transform: perspective(15em) rotateX(-20deg);
                            -moz-transition: all 5000ms ease-out 0s;
                            -webkit-transition: all 5000ms ease-out 0s;
                            -ms-transition: all 5000ms ease-out 0s;
                            -o-transition: all 5000ms ease-out 0s;
                            transition: all 5000ms ease-out 0s;
                            opacity: 0;
                            .dkg-our-team-social{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                display: none;
                                li{
    
                                    a{
                                        width: 32px;
                                        height: 32px;
                                        display: inline-block;
                                        background-color: #1f394c;
                                        svg{
                                            color: #ddd;
                                            width: 14px;
                                            height: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .dkg-show-more-btnCon {
            align-items: center;
            display: flex;
            justify-content: center;
            top: 42px;
            position: relative;
            width: 100%;
            .dkg-show-more-btn {
                background-color: #eaeaea;
                border: 1px solid #ddd;
                color: #333;
                font-size: 13px;
                padding: 8px 20px;
                border-radius: 3px;
                &:hover{
                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                }

            }
        }
      }
      .dkg-consultants-sliderCon{
        width: 100%;
        max-width: 1240px !important;
        margin: 0 auto;
        .slick-slider{
            padding-bottom: 2.5rem;
            .slick-arrow{
                &.slick-prev,
                &.slick-next{
                    background-color: transparent;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    color: #333;
                    top: 175px;
                    &:hover{
                        color: #555;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                &.slick-prev{
                    left: -45px;
                }
                &.slick-next{
                    right: -45px;
                }
            }
            .slick-list{
                // display: flex;
                .slick-track{
                    .slick-slide{
                       div{
                        .dkg-consultants-cardCon{
                            display: flex;
                            align-items: center;
                            .dkg-consultantsCols-flex{
                                display: flex;
                                width: 100%;
                                .dkg-teams-box-cols{
                                    width: 100%;
                                    margin: 9px;
                                    .dkg-our-team{
                                        // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                        // box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
                                        //  rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
                                        //   rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
                                        //  rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
                                        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                                        background-color: #fff;
                                        .dkg-our-team-img{
                                            width: 100%;
                                            height: 180px;
                                            .dkg-teammate-img{
                                                width: 100%;
                                                height: 100%;
                                                object-fit: contain;
                                            }
                                        }
                                        .dkg-team-content{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            flex-direction: column;
                                            padding: 0.94375rem;
                                            height: 145px;
                                            .dkg-teammate-name{
                                                font-size: 1.85rem;
                                                font-weight: 700;
                    
                                            }
                                            .dkg-team-post{
                                                font-size: 1.15rem;
                    
                                            }
                                        }
                                        .dkg-our-team-social{
                                            list-style: none;
                                            margin: 0;
                                            padding: 0;
                                            display: none;
                                            li{
                                                a{
                                                    i{
                                                        color: #333;
                                                    }
                                                }
                                            }
                                        }
                                        &:hover{
                                            position: relative;
                                            // opacity: 0.1;
                                            cursor: pointer;
                                            .dkg-ourteam-overlay {
                                                position: absolute;
                                                left: 0;
                                                top: 0;
                                                width: 100%;
                                                height: 100%;
                                                z-index: 1;
                                                background: rgba(33, 38, 49, 0.8);
                                                padding: 35px 40px 40px 40px;
                                                transform-origin: top;
                                                -moz-transform: perspective(15em) rotateX(-20deg);
                                                -o-transform: perspective(15em) rotateX(-20deg);
                                                -ms-transform: perspective(15em) rotateX(-20deg);
                                                -webkit-transform: perspective(15em) rotateX(-20deg);
                                                transform: perspective(15em) rotateX(-20deg);
                                                -moz-transition: all 5000ms ease-out 0s;
                                                -webkit-transition: all 5000ms ease-out 0s;
                                                -ms-transition: all 5000ms ease-out 0s;
                                                -o-transition: all 5000ms ease-out 0s;
                                                transition: all 5000ms ease-out 0s;
                                                opacity: 0;
                                                .dkg-our-team-social{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 50%;
                                                    transform: translate(-50%, -50%);
                                                    display: none;
                                                    li{
                        
                                                        a{
                                                            width: 32px;
                                                            height: 32px;
                                                            display: inline-block;
                                                            background-color: #1f394c;
                                                            svg{
                                                                color: #ddd;
                                                                width: 14px;
                                                                height: 14px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                       }
                    }
                }
            }
        }
        .dkg-show-more-btnCon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .dkg-show-more-btn {
                background-color: #eaeaea;
                border: 1px solid #ddd;
                color: #333;
                font-size: 13px;
                padding: 8px 20px;
                border-radius: 3px;
                &:hover{
                    // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                    // box-shadow: 20px 20px 80px #c5a84a,-20px -20px 80px #ffe464
                    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 40px 4px;
                    background-color: #3c97b6;
                    border-color: #3c97b6;
                    color: #fff;
                }
            }
        }
     }
   }
   .dkg-serive-testimonial-sec{
     padding: 6rem 0.94375rem;
     background-color: #1f394c;
     padding-top: 2.65rem;
     .dkg-services-mainCol{
        width: 100%;
        max-width: 75%;
        margin: 0 auto;
        .dkg-services-page-title{
            font-weight: 700;
            font-size: 26px;
            color: #ddd;
            line-height: 1.2em;
            text-align: center;
            margin-top: 0;
            text-transform: uppercase;
            margin-bottom: 0;
            padding: 0;
        }
        .dkg-services-page-text{
            font-size: 1.10rem;
            color: #ddd;
            text-align: justify;
            padding: 10px 0;
            padding-bottom: 0;
            width: 100%;
            text-align: center;
            padding-bottom: 30px;
        }
     }
     .dkg-testimonial-sliderCon{
        width: 100%;
        max-width: 1240px !important;
        margin: 0 auto;
        .slick-slider{
            padding-bottom: 2.5rem;
            .slick-arrow{
                &.slick-prev,
                &.slick-next{
                    background-color: transparent;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    color: #fff;
                    top: 70px;
                    &:hover{
                        color: #eaeaea;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                &.slick-prev{
                    left: -45px;
                }
                &.slick-next{
                    right: -45px;
                }
            }
            .slick-list{
                .slick-track{
                    .slick-slide{
                       div{
                        .dkg-testimonial-cardCon{
                            display: flex;
                            align-items: center;
                            .dkg-testimonialCols-flex{
                                display: flex;
                                width: 100%;
                                .dkg-testimonialCols{
                                    margin: 0 12px;
                                    &:nth-child(01),
                                    &:nth-child(04),
                                    &:nth-child(07){
                                        margin-left: 0;
                                    }
                                    &:nth-child(03),
                                    &:nth-child(06),
                                    &:nth-child(09){
                                        margin-right: 0;
                                    }
                                    .dkg-testimonialtext{
                                       background-color: #fff;
                                        padding: 35px 20px;
                                        position: relative;
                                        margin: 0 0 34px;
                                        border-radius: 5px;
                                        color: #333;
                                       &:before {
                                            position: absolute;
                                            content: "";
                                            width: 0;
                                            height: 0;
                                            border-left: 15px solid transparent;
                                            border-right: 15px solid transparent;
                                            border-top: 15px solid #fff;
                                            bottom: -15px;
                                            left: 31px;
                                        }
                                    }
                                    .dkg-testimonialImg{
                                        .d-flex{
                                            .dkg-testiImage{
                                                width: 56px;
                                                height: 56px;
                                                border-radius: 50%;
                                                overflow: hidden;
                                                float: left;
                                                margin-right: 15px;
                                                margin-left: 25px;
                                                border: 1px solid #ddd;
                                                img{
                                                   width: 100%;
                                                   height: auto;
                                                }
                                            }
                                            .dkg-testiText{
                                                .dkg-testiTitle{
                                                    font-size: 18px;
                                                    line-height: 22px;
                                                    color: #ddd;
                                                    font-weight: 700;
                                                    margin: 0;
                                                    overflow: hidden;
                                                    padding-top: 7px;
                                                }
                                                .dkg-countryname{
                                                    font-size: 14px;
                                                    display: block;
                                                    font-weight: 400;
                                                    color: #ddd;
                                                    
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                       }
                    }
                }
            }
        }
        .dkg-show-more-btnCon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .dkg-show-more-btn {
                background-color: #eaeaea;
                border: 1px solid #ddd;
                color: #333;
                font-size: 13px;
                padding: 8px 20px;
                border-radius: 3px;
                &:hover{
                    // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                    // box-shadow: 20px 20px 80px #c5a84a,-20px -20px 80px #ffe464
                    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 40px 4px;
                    background-color: #3c97b6;
                    border-color: #3c97b6;
                    color: #fff;
                }
            }
        }
     }
   }
   .dkg-service-legal-sec{
        padding: 6rem 0;
        background-color: #fff;
        padding-top: 2.65rem;
        .dkg-legal-service-header{
         background-color: #fff;
         padding: 15px 0;
         padding-top: 0;
         padding-bottom: 1.95rem;
         .dkg-legal-services-page-title{
             font-weight: 700;
             font-size: 26px;
             color: #333;
             line-height: 1.2em;
             text-align: center;
             margin-top: 0;
             width: 100%;
             max-width: 1350px;
             margin: 0 auto;
         }
         .dkg-legal-services-page-text{
             font-size: 1.10rem;
             color: #333;
             text-align: justify;
             padding: 10px 0;
             padding-bottom: 0;
             width: 100%;
             text-align: center;
             max-width: 75%;
             margin: 0 auto;
         }
        }
        .dkg-legal-services-mainCol{
            background-color: transparent;
            padding: 15px;
            .dkg-legal-services-page-title{
                font-weight: 700;
                font-size: 24px;
                color: #333;
                line-height: 1.2em;
                text-align: center;
            }
            .dkg-legal-services-page-text{
                font-size: 1.10rem;
                color: #333;
                text-align: justify;
                padding: 10px 0;
                padding-bottom: 0;
                width: 100%;
                text-align: center;
                max-width: 75%;
                margin: 0 auto;
            }
            .dkg-cnacc-legal-mainCon{
                margin-top: 30px;
                .dkg-cnacc-legal-banner{
                    width: 100%;
                    max-width: 52%;
                    margin: 0 auto;
                    background-color: #FFDE59;
                    position: relative; 
                    .dkg-banner-upper-cross{
                        color: #f3f3f3;
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        background-color: #FFDE59;
                        // margin-bottom: 1em;
    
                    }   
                    .dkg-banner-upper-cross {
                        border: 20px solid #2D2E2C;
                        border-width: 20px 0;
                        border-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, rgb(45, 46, 44) 5px, rgb(45, 46, 44) 10px) 20 0;
                        .dkg-pd-legal-content{
                        //    border-left: 2px solid #2D2E2C;
                        //    border-right: 2px solid #2D2E2C;
                            height: 100%;
                            padding: 1.25rem 0;
                            padding-bottom: 1.75rem;
                        .dkg-pd-legal-content-title{
                            font-size: 2.10rem;
                            text-align: center;
                            width: 100%;
                            color: #2D2E2C;
                            padding: 1rem 0;
                            padding-top: 1.75rem;
                            font-weight: bold;
                            .dkg-legal-doc-icon{
                                margin-left: 0.5rem;
                                position: relative;
                                top: -3px;
                                svg{
                                width: 26px;
                                height: 26px;
                                color: #EE404C;
                                } 
                            }
                        }
                        .dkg-pd-legal-content-body{
                            padding: 1.25rem 3.55rem;
                            padding-bottom: 3rem;
                            padding-top: 0.25rem;
                            .dkg-pd-legal-content-card{
                                background-color: #FEF1A3;
                                border-radius: 0;
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                .dkg-legal-content-card-left{                                    
                                    width: 20%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 1px solid #a99f9f;
                                    .dkg-legal-content-imgCon{
                                        width: 105px;
                                        height: 105px;
                                        .dkg-legal-content-img{
                                            width: 100%;
                                            height: auto;
                                            background-size: cover;
                                        }
                                    }
                                }
                                .dkg-legal-content-card-right{
                                    width: 80%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 1px solid #a99f9f;
                                    border-left: none;
                                    .dkg-legal-card-content{
                                        text-align: justify;
                                        color: #2D2E2C;
                                        padding: 0 15px;
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                        }
                    }
                }
                
            }
        }
        .dkg-legal-services-container{
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            //  height: calc(100vh - 315px);
            .dkg-legal-services-mainRow{
                height: 100%;
                .dkg-legal-services-mainCol{
                    padding-bottom: 5px;
                    height: 100%;
                    .dkg-cnacc-legal-mainCon{
                        margin-top: 5px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .dkg-cnacc-legal-banner{
                            width: 100%;
                            max-width: 52%;
                            margin: 0 auto;
                            background-color: #FFDE59;
                            .dkg-banner-upper-cross{
                                color: #f3f3f3;
                                display: block;
                                width: 100%;
                                height: 100%;
                                padding: 0;
                                background-color: #FFDE59;
                                // margin-bottom: 1em;
    
                            }   
                            .dkg-banner-upper-cross {
                                border: 20px solid #2D2E2C;
                                border-width: 20px 0;
                                border-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, rgb(45, 46, 44) 5px, rgb(45, 46, 44) 10px) 20 0;
                                .dkg-pd-legal-content{
                                //    border-left: 2px solid #2D2E2C;
                                //    border-right: 2px solid #2D2E2C;
                                    height: 100%;
                                    padding: 1.25rem 0;
                                    padding-bottom: 1.75rem;
                                .dkg-pd-legal-content-title{
                                    font-size: 1.55rem;
                                    text-align: center;
                                    width: 100%;
                                    color: #2D2E2C;
                                    padding: 0;
                                    font-weight: bold;
                                    .dkg-legal-doc-icon{
                                        margin-left: 0.5rem;
                                        position: relative;
                                        top: -3px;
                                        svg{
                                        width: 26px;
                                        height: 26px;
                                        color: #EE404C;
                                        } 
                                    }
                                }
                                .dkg-pd-legal-content-body{
                                    padding: 1.25rem 3.55rem;
                                    //  padding-bottom: 1rem;
                                    .dkg-pd-legal-content-card{
                                        background-color: #FEF1A3;
                                        border-radius: 0;
                                        display: flex;
                                        justify-content: space-between;
                                        width: 100%;
                                        .dkg-legal-content-card-left{                                    
                                            width: 20%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: 1px solid #a99f9f;
                                            .dkg-legal-content-imgCon{
                                                width: 105px;
                                                height: 105px;
                                                .dkg-legal-content-img{
                                                    width: 100%;
                                                    height: auto;
                                                    background-size: cover;
                                                }
                                            }
                                        }
                                        .dkg-legal-content-card-right{
                                            width: 80%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: 1px solid #a99f9f;
                                            border-left: none;
                                            height: 193px;
                                            padding-top: 6rem;
                                            padding-bottom: 1rem;
                                            overflow-y: auto;
                                            .dkg-legal-card-content{
                                                text-align: justify;
                                                color: #2D2E2C;
                                                padding: 0 15px;
                                                font-size: 1rem;
    
                                            }
                                        }
                                    }
                                }
                                }
                            }
                        }
                        
                    }
                }
                .dkg-legal-services-box{
                    background-color: #fff;
                    padding: 15px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    @media (min-width: 992px){
                        &.col-lg-4 {
                            flex: 0 0 32.40%;
                            max-width: 32.40%;
                            margin: 7px;
                        }
                    }
                    
                    @media (min-width: 768px){
                        &.col-md-4 {
                            flex: 0 0 32.40%;
                            max-width: 32.40%;
                            margin: 7px;
                        }
                    }
                    .dkg-legal-services-imgCon{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .dkg-legal-services-img{
                            width: 60px;
                            height: 60px;
                        }
                    }
                    .dkg-service-desc-textCon{
                        .dkg-service-desc{
                            font-weight: 700;
                            font-size: 20px;
                            color: #2a2a2a;
                            line-height: 1.4em;
                            text-align: center;
                            margin-top: 1.15rem;
                            width: 100%;
                        }
                        .dkg-service-text{
                            color: #000;
                            text-align: center;
                        }
                    }
                    .dkg-service-box-btnCon{
                        margin-top: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .dkg-service-box-btn{
                            display: inline-block;
                            color: #3c97b6;
                            border-width: 1px;
                            border-radius: 40px;
                            letter-spacing: 0px;
                            font-size: 14px;
                            background-color: #ffffff;
                            padding: 7px 20px;
                            border: 1px solid #3c97b6;
                            text-transform: inherit;
                            box-shadow: none;
                            transition: all ease-in 0.5ms;
                            &:hover{
                                background-color: #3c97b6;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .dkg-legal-services-boxsCon{
                width: 100%;
                .dkg-myservices-boxs{
                    display: flex;
                    .dkg-legal-services-box{
                        background-color: #fff;
                        padding: 15px;
                        border: 1px solid #b0d8e5;
                        border-radius: 5px;
                        width: 100%;
                        max-width: 100%;
                        margin: 12px;
                        @media (min-width: 992px){
                            &.col-lg-4 {
                                flex: 0 0 32.40%;
                                max-width: 32.40%;
                                margin: 7px;
                            }
                        }
                        
                        @media (min-width: 768px){
                            &.col-md-4 {
                                flex: 0 0 32.40%;
                                max-width: 32.40%;
                                margin: 7px;
                            }
                        }
                        .dkg-legal-services-imgCon{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-legal-services-img{
                                width: 60px;
                                height: 60px;
                            }
                        }
                        .dkg-service-desc-textCon{
                            .dkg-service-desc{
                                font-weight: 700;
                                font-size: 18px;
                                color: #2a2a2a;
                                line-height: 1.4em;
                                text-align: center;
                                margin-top: 1.15rem;
                                width: 100%;
                            }
                            .dkg-service-text{
                                color: #000;
                                text-align: center;
                            }
                        }
                        .dkg-service-box-btnCon{
                            margin-top: 18px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-service-box-btn{
                                display: inline-block;
                                border-width: 1px;
                                border-radius: 40px;
                                letter-spacing: 0px;
                                font-size: 14px;
                                padding: 7px 20px;
                                border: 1px solid #3c97b6;
                                text-transform: inherit;
                                box-shadow: none;
                                transition: all ease-in 0.5ms;
                                background-color: #ffffff;
                                color: #3c97b6;
                                &:hover{
                                    background-color: #3c97b6;
                                    color: #fff;    
                                    border: 1px solid #3c97b6;
                                }
                            }
                        }
                        &:nth-child(01),
                        &:nth-child(04){
                            margin-left: 0;
                        }
                        &:nth-child(03),
                        &:nth-child(06){
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .dkg-pricing-services-container{
            width: 100%;
            max-width: 1350px;
            margin: 0 auto;
            padding: 0 15px;
            .dkg-pricePlan-mainContainer{
                .dk-pricePlan-main{
                    .table-responsive{
                        color: #000;
                    }
                    table{
                        margin: 0;
                        tr{
                            th{
                                text-transform: uppercase;
                                color: #fff;
                                text-align: center;
                                font-size: 20px;
                                font-weight: normal;
                                border: 1px solid #979aa0;
                                border-bottom: none;
                                position: relative;
                                vertical-align: middle;
                                padding-top: 17px;
                                padding-bottom: 17px;
                                span{
                                    img{
                                        width: 23px;
                                        margin-right: 9px;
                                        position: relative;
                                        top: -2px;
                                        cursor: pointer;
                                    }
                                }
                                .dkg-pricing-icon-con{
                                    svg{
                                        width: 23px;
                                        height: 23px;
                                    }
                                }
                                &:nth-child(01){
                                    background: #323d4f;
                                    width: 30%;
                                }
                                &:nth-child(02),
                                &:nth-child(03),
                                &:nth-child(04),
                                &:nth-child(05),
                                &:nth-child(06),
                                &:nth-child(07){
                                    background: #67818e;
                                    width: 14%;
                                }
                                // &:nth-child(03){
                                //     background-color: #9d265c;
                                //     width: 15%;
                                // }
                                // &:nth-child(04),
                                // &:nth-child(05),
                                // &:nth-child(06){
                                //     background-color: #9d265c;
                                //     width: 15%;

                                // }
                            }
                        }
                    }
                    table.table_2{
                        tr{
                            th{
                                font-weight: normal;
                                font-size: 14px!important;
                                border: 1px solid #979aa0;
                                padding: 10px 10px;
                                &:nth-child(01){
                                    width: 7%;
                                    color: #fff;
                                }
                                &:nth-child(02){
                                    width: 23%;
                                    background: #323d4f;
                                    text-align: left;
                                }
                                &:nth-child(03),
                                &:nth-child(04),
                                &:nth-child(05),
                                &:nth-child(06),
                                &:nth-child(07){
                                    background: #67818e;
                                    width: 14%;
                                }
                                // &:nth-child(04){
                                //     background: #9d265c;
                                //     width: 15%;
                                // }
                                // &:nth-child(05),
                                // &:nth-child(06),
                                // &:nth-child(07){
                                //     background: #9d265c;
                                //     width: 15%;
                                // }
                            }
                            td{
                                text-transform: uppercase;
                                font-size: 13px;
                                font-weight: 600;
                                text-align: center;
                                border: 1px solid #ccc;
                                color: #7a8798;
                                vertical-align: middle;
                                padding: 0.5rem 1rem;
                                span.pull-right{
                                    position: absolute;
                                    font-size: 20px;
                                    color: #ccc;
                                    top: 5px;
                                    right: 15px;
                                    z-index: 99;
                                    cursor: pointer;
                                }
                                span.cross{
                                    font-size: 22px;
                                    color: #da4759;
                                    line-height: 0;
                                }
                                span.check{
                                    font-size: 20px;
                                    color: #73bd2a;
                                    line-height: 0;
                                    &.euro-sign{
                                        color: #000;
                                    }
                                }
                                &:nth-child(01){
                                    text-align: center;
                                    color: #000;
                                    width: 7%;
                                }
                                &:nth-child(02){
                                    text-align: left;
                                    color: #000;
                                    position: relative;
                                    cursor: pointer;
                                    width: 23%;
                                }
                                &:nth-child(03),
                                &:nth-child(04),
                                &:nth-child(05),
                                &:nth-child(06),
                                &:nth-child(07){
                                    width: 14%;
                                }
                            }
                            &:last-child{
                                td{
                                    a{
                                        padding: 10px 12px;
                                        text-transform: uppercase;
                                        color: #fff;
                                        display: block;
                                        margin: 8px;
                                        &:hover{
                                            background: #243b4c!important;
                                            transition: all .5s;
                                            color: #fff;
                                        }
                                    }
                                    &:nth-child(03){
                                        a{
                                            
                                            background: #67818e;
                                        }
                                    }
                                    &:nth-child(04){
                                        a{
                                            
                                            background: #9d265c;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dkg-service-contact-sec{
        display: none;
        .dkg-feedback-service-container{
            width: 100%;
            background-color: #1f394c;
            padding: 6rem 0.94375rem;
            .dkg-feedback-service-mainRow{
                .dkg-feedback-service-mainCol{
                    padding: 0 15px;
                    background-color: #1f394c;
                    .dkg-fd-service-page-title{
                        font-weight: 700;
                        font-size: 24px;
                        color: #fff;
                        line-height: 1.2em;
                        text-align: center;
                        width: 100%;
                        max-width: 1350px;
                        margin: 0 auto;
                    }
                    .dkg-fd-service-page-text {
                        font-size: 1.10rem;
                        color: #fff;
                        padding: 10px 0;
                        padding-bottom: 0;
                        width: 100%;
                        text-align: center;
                        max-width: 1350px;
                        margin: 0 auto;
                        padding-bottom: 1rem;
                    }
                }
                .dkg-serivce-contact-maincon{
                    background-color: #1f394c;
                    padding: 1.25rem ;
                    width: 100%;

                    //  height: calc(100vh - 295px);
                    //  overflow-y: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 0;
                    .dkg-cn-feedback-maincard{
                        width: 100%;
                        max-width: 1040px;
                        margin: 15px auto;            
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: #fff;
                        border-radius: 5px;
                        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                        padding: 1.25rem 3rem;
                        // padding-bottom: 2.25rem;
                        margin-bottom: 0;
                        .dkg-cn-feedback-leftcon{
                            width: 50%;
                            position: relative;
                            top: -32px;
        
                            .dkg-cn-feedback-ul{
                                list-style: none;
                                margin-bottom: 0;
                                .dkg-cn-feedback-li{
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    margin-bottom: 2.04rem;
                                    .dkg-feedback-li-icon{
                                        .dkg-cn-feedback-icon{
                                            width: 42px;
                                            height: 42px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 5px;
                                            background-color: #f2f2f2;
                                            svg{
                                                width: 22px;
                                                height: 22px;
                                                color: #777;
                                            }
                                        }
                                    }
                                    .dkg-feedback-li-titleCon{
                                        padding-left: 1.12rem;
                                        .dkg-feedback-li-title{
                                            font-size: 1.32rem;
                                            font-weight: bold;
                                            color: #666;
                                            font-family: 'Roboto';
                                        }
                                        .dkg-feedback-li-subtitle{
                                            font-size: 0.95rem;
                                            color: #777;
        
                                        }
                                    }
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                        .dkg-cn-feedback-rihtcon{
                            width: 50%;
                            .cn-feedback-right-title{
                                font-size: 1.9rem;
                                font-weight: bold;
                                color: #333;
                                display: none;
                                display: none;
                            }
                            .cn-feedback-right-subtitle{
                                font-size: 1rem;
                                color: #777;
                            }
                            .dkg-cn-feedback-form{
                                margin-top: 1.25rem;
                                .dkg-cn-feedback-formgrp{
                                    margin-bottom: 1.25rem;
                                    &.dkg-cn-fd-form-inputgrp{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        .dkg-cn-feedback-input{
                                            background-color: #f2f2f2;
                                            color: #333;
                                            border: 1px solid #ddd;
                                            border-radius: 5px;
                                            padding: 10px 12px;
                                            text-align: left;
                                            &::placeholder {
                                                color: #999;
                                                opacity: 1; /* Firefox */
                                            }
                                            &::-ms-input-placeholder { /* Edge 12 -18 */
                                            color: #999;
                                            }
                                            
                                        }
                                    }
                                    .dkg-cn-feedback-message{
                                        width: 100%;
                                        background-color: #f2f2f2;
                                        border: 1px solid #ddd;
                                        border-radius: 5px;
                                        height: 130px;
                                        text-align: left;
                                        padding: 10px 12px;
                                        &::placeholder {
                                            color: #999;
                                            opacity: 1; /* Firefox */
                                        }
                                        &::-ms-input-placeholder { /* Edge 12 -18 */
                                        color: #999;
                                        }
                                    }
                                    .dkg-cn-feedback-dropdown{
                                        width: 100%;
                                        .dkg-cn-fd-dropdown-toogle{
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
                                            box-shadow: none;
                                            background-color: #f2f2f2;
                                            border: 1px solid #ddd;
                                            border-radius: 5px;
                                            padding: 5px 10px;
                                            margin: 0;
                                            color: #999;
                                            position: relative;
                                            text-transform: capitalize;
                                            font-size: 0.92rem;
                                            &::after{
                                                position: absolute;
                                                right: 10px;
                                                color: #777;
                                                margin-left: 0.40em;
                                                vertical-align: 0.40em;
                                                content: "";
                                                border-top: 0.43em solid;
                                                border-right: 0.43em solid transparent;
                                                border-bottom: 0;
                                                border-left: 0.35em solid transparent;
                                            }
                                        }
                                        .dkg-cn-fd-dropdown-menu{
                                            border-radius: 0;
                                            width: 100%;
                                            .dkg-cn-fd-dropdown-item{
                                                padding: 7px 10px;
                                                font-size: 0.95rem;
                                                text-align: left;
                                            }
                                        }
                                    }
                                    &:last-child{
                                        margin-bottom: none;
                                    }
                                }
                                .dkg-feedback-submit-btnCon{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    max-width: 185px;
                                    margin: 0 auto;
                                    .dkg-feedback-submit-btn{
                                        background-color: #3c97b6;
                                        color: #fff;
                                        width: 100%;
                                        margin: 0;
                                        padding: 10px 5px;
                                        border-radius: 5px;
                                        .dkg-feedback-plane{
                                            padding-left: 0.5rem;
                                        }
                                    }
        
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
   .dkg-paidservices-homeMain{
      margin-top: 35px;
      height: calc(100vh - 35px);
      .dkg-service-btncontent-con{
         .dkg-paidservice-content{
            .dkg-paidservice-title{
               font-size: 1.5rem !important;
               font-weight: 700 !important;
               text-align: center;
            }
            .dkg-paidservice-subtitle{
               font-size: 0.85rem !important;
               text-align: center;
            }
         }
         .dkg-book-appointment-btnCon{
            width: 100%;
            text-align: center;
            .dkg-book-appointment-btn{
               padding: 10px 12px;
               font-size: 0.95rem;
            }
         }
      }
      .dkg-paidservice-bottom-sec{
         position: relative !important;
         left: 0 !important;
         bottom: 0 !important;
         width: 100%;
         .dkg-paidservice-bottom-ul{
            flex-wrap: wrap;
            background-color: #00466b;
            .dkg-paidservice-bottom-li{
               max-width: 50% !important;
               &:nth-child(01),
               &:nth-child(02),
               &:nth-child(03),
               &:nth-child(04){
                  border-bottom: none !important;
               }
               &:nth-child(14){
                border-bottom: 1px solid #787474 !important;
               }
            }
         }
      }
      .dkg-service-second-sec{
        .dkg-service-header{
            max-width: 100%;
            padding:0.94375rem;
            padding-top: 1.25rem;
            .dkg-service-header-subtitle{
                text-align: justify;
            }
        }
        .dkg-service-maincontent{
            padding-bottom: 0;
        }
      }
      .dkg-service-about-sec{
        .dkg-services-mainCol{
            padding-top: 1.25rem;
        }
        .dkg-services-page-text{
            max-width: 100% !important;
            padding: 0.94375rem !important;
            text-align: justify !important;
            
        }
        .dkg-about-service-blueCon{
            padding-bottom: 0.94375rem;
            .dkg-service-block-con{
                .dkg-service-mainRow{
                    .dkg-about-service-leftcon {
                        padding: 0 0.94375rem;
                        .dkg-about-service-imgCon{
                            height: auto;
                        }
                    }
                    .dkg-about-service-rightcon{
                        padding-left: 0.94375rem;
                        .dkg-aboutus-cardCon{
                            margin-top: 1rem;
                            .dkg-aboutus-card-ul{
                                gap: 1rem;
                                .dkg-aboutus-card-li{
                                    .dkg-aboutus-card{
                                        .dkg-aboutus-icon{
                                            .dkg-aboutus-img{
                                                width: 32px;
                                                height: 32px;
                                            }
                                        }
                                        .dkg-aboutus-cardtitle{
                                            font-size: 0.95rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      }
      .dkg-service-team-sec{
        padding: 0.94375rem;
        padding-top: 1.25rem;
        .dkg-services-mainCol{
            max-width: 100%;
            .dkg-services-page-text{
                text-align: justify;
                padding-bottom: 0.94375rem;
            }
        }
        .dkg-consultants-sliderCon{
            width: 100%;
            max-width: 90% !important;
            margin: 0 auto;
            .slick-slider{
                padding-bottom: 1.5rem;
                .slick-arrow{
                    &.slick-prev,
                    &.slick-next{
                        background-color: transparent;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        color: #333;
                        top: 175px;
                        z-index: 9;
                        &:hover{
                            color: #555;
                        }
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                    &.slick-prev{
                        left: -20px;
                        color: #d2cfcf;
                        top: 135px;
                    }
                    &.slick-next{
                        right: -20px;
                        color: #d2cfcf;
                        top: 135px;
                    }
                }
                .slick-list{
                    .slick-track{
                        .slick-slide{
                           div{
                            .dkg-consultants-cardCon{
                                .dkg-consultantsCols-flex{
                                    .dkg-teams-box-cols{
                                        width: 100%;
                                        max-width: 75%;
                                        margin: 1px auto;
                                        .dkg-our-team {
                                            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
                                            .dkg-team-content{
                                                height: inherit;
                                            }
                                        }
                                    }
                                }
                            }
                           }
                        }
                    }
                }
            }
            
         }
      }
      .dkg-serive-testimonial-sec{
        padding: 0.94375rem;
        padding-top: 1.25rem;
        .dkg-services-mainCol{
            max-width: 100%;
            .dkg-services-page-text{
                padding-bottom: 0.94375rem;
                text-align: justify;
            }
        }
        .dkg-testimonial-sliderCon{
            width: 100%;
            max-width: 100% !important;
            .slick-slider{
                padding-bottom: 1rem;
                .slick-arrow{
                    &.slick-prev,
                    &.slick-next{
                        background-color: transparent;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        color: #fff;
                        top: 95px;
                        z-index: 9;
                        &:hover{
                            color: #eaeaea;
                        }
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                    &.slick-prev{
                        left: -5px;
                        top: 80px;
                        color: #d2cfcf;
                    }
                    &.slick-next{
                        top: 80px;
                        right: -5px;
                        color: #d2cfcf;
                    }
                }
                .slick-list{
                    .slick-track{
                        .slick-slide{
                           div{
                            .dkg-testimonial-cardCon{
                                display: flex;
                                align-items: center;
                                .dkg-testimonialCols-flex{
                                    display: flex;
                                    width: 100%;
                                    .dkg-testimonialCols{
                                        width: 100%;
                                        max-width: 75%;
                                        margin: 0 auto !important;    
                                        .dkg-testimonialtext{
                                            padding: 0.94375rem;
                                        } 
                                        .dkg-testimonialImg{
                                            .d-flex{
                                                .dkg-testiText{
                                                    .dkg-testiTitle{
                                                        font-size: 1rem;
                                                    }
                                                }
                                            }
                                        }                               
                                    }
                                }
                            }
                           }
                        }
                    }
                }
            }
          
         }
      }
      .dkg-service-legal-sec{
        padding: 0.94375rem 0;
        padding-top: 0;
        .dkg-legal-service-header{
            padding: 0.94375rem;
            .dkg-legal-services-page-text{
                max-width: 100%;
                text-align: justify;
                padding: 0;
                padding-top: 0.94375rem;
            }
        }
        .dkg-pricing-services-container{
            .dkg-pricePlan-mainContainer{
                .dk-pricePlan-main{
                    .table-responsive{
                        .table{
                            thead{
                                tr{
                                    th{
                                        &:nth-child(01){
                                            min-width: 280px;
                                        }
                                        &:nth-child(02),
                                        &:nth-child(03),
                                        &:nth-child(04),
                                        &:nth-child(05),
                                        &:nth-child(06),
                                        &:nth-child(07){
                                            min-width: 140px;
                                        }
                                    }
                                }
                            }
                        }
                        .table.table_2{
                            thead{
                                tr{
                                    th{
                                        &:nth-child(01){
                                            min-width: 50px;
                                        }
                                        &:nth-child(02){
                                            min-width: 230px;
                                        }
                                        &:nth-child(03),
                                        &:nth-child(04),
                                        &:nth-child(05),
                                        &:nth-child(06),
                                        &:nth-child(07){
                                            min-width: 140px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      }
      
   }
}
