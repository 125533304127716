.dkg-myreferals-mainCon{
    margin-top: 152px;
    background-color: #eaeaea;
    height: calc(100vh - 152px);
    width: 100%;
    padding: 15px;
    padding-top: 20px;
    .dkg-myreferals-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .dkg-myreferals-headerleftCon{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .dk-mailshotsSearch{
                .input-group{
                    max-width: 145px;
                    min-width: 145px;
                    width: 100%;
                    input{
                        background-color: #fff!important;
                        border: 1px solid #ddd;
                        border-radius: 0;
                        box-shadow: none;
                        height: 35px;
                        line-height: 1.3rem;
                        margin: 0;
                        padding: 0.5rem 0.55rem;
                        text-align: left;
                    }
                    .input-group-append{
                        button{
                            background-color: #fff!important;
                            border: 1px solid #ddd;
                            border-radius: 0;
                            box-shadow: none;
                            height: 35px;
                            line-height: 1.3rem;
                            margin: 0;
                            padding: 0.5rem 0.55rem;
                            i{
                                color: #6c757d;
                            }
                        }
                    }
                }
            }
            .dkg-refresh-btn{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50%;
                box-shadow: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
            .dkg-referal-addnew-btnCon{
                .dkg-referal-addnew-btn{
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    color: #848484;
                    display: inline-block;
                    font-size: 12px;
                    height: 33px;
                    line-height: 10px;
                    margin-left: 10px;
                    outline: none;
                    text-align: center;
                    width: 33px;
                }
            }
        }
        .dkg-myreferals-centreCon{
            .dkg-docs-title{
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                color: #6c757d;
                display: flex;
                align-items: center;

                // .dkg-infoicon{
                //     color: #6c757d;
                //     width: 24px;
                //     height: 24px;
                //     cursor: pointer;
                // }
            }
        }
        .dkg-myreferals-rightCon{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dkg-refresh-btn{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50%;
                box-shadow: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
            .dkg-add-new-btn{
               background-color: #fff;
                border-radius: 0;
                color: #6c757d;
                display: inline-block;
                font-family: Roboto,sans-serif;
                font-size: 14px;
                padding: 8px 15px;
                text-decoration: none;
                white-space: nowrap;
                svg{
                    color: #6c757d;
                }
            }
            .dkg-referal-status-drpdwn{
                
                .dkg-referal-status-drpdwn-toggle{
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid #ddd;
                    padding: 6px 10px;
                    text-transform: capitalize;
                    font-size: 13px;
                    background-color: #fff !important;
                    color: #6c757d;
                    border-radius: 3px;
                    min-width: 120px;
                    position: relative;
                    &::after {
                        position: absolute;
                        top: 15px;
                        right: 10px;
                        color: #6c757d;
                    }
                }
                .dkg-referal-status-drpdwn-menu{
                    border-radius: 0;
                    padding: 0;
                    z-index: 99999;
                    .dkg-referal-status-item{
                        padding: 8px 10px;
                        border-bottom: 1px solid transparent;
                        font-size: 13px;
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        .dkg-lv-statuscounter {
                            position: absolute;
                            right: 8px;
                            color: #fff;
                            background-color: #234158;
                            padding: 1px 5px;
                            font-size: 12px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
    .dkg-document-tableCon{
        margin-top: 6px;
        .dkg-document-table{
            thead{
                tr{
                    th{
                        background-color: #1f394c;
                        padding: 10px 0.75rem;
                        vertical-align: middle;
                        font-weight: normal;
                        font-size: 14px;
                        border: 1px solid #8a8a8a;
                        text-align: center;
                        color: #ddd;
                        white-space: nowrap;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        background-color: #fff;
                        padding: 10px 0.75rem;
                        vertical-align: middle;
                        font-weight: normal;
                        font-size: 13px;
                        border: 1px solid #ddd;
                        text-align: center;
                        color: #333;
                        white-space: nowrap;
                        .dkg-myreferals-cols{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-action-btn{
                                color: white;
                                padding: 0.4em 1em;
                                border: none;
                                border-radius: 4px;
                                &:hover {
                                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                    transition: all ease 0.5s;
                                }
                                &.open-btn{
                                    background-color: #ffb64d;
                                }
                                &.download-btn{
                                    background-color: #28a745;
                                }
                                &.edit-btn{
                                    background-color: #007bff;
                                }
                                &.delete-btn{
                                    color: #dc3545;
                                    background: none;
                                    border: 1px solid #dc3545;
                                    &:hover{
                                        background-color: #dc3545;
                                        color: #fff;
                                    }

                                }
                            }
                        }
                        .dkg-referal-status-drpdwn{
                            .dkg-referal-status-drpdwn-toggle{
                                margin: 0 !important;
                                padding: 0;
                                color: #fff;
                                width: 100%;
                                min-width: 85px;
                                max-width: 85px;
                                font-size: 0.85rem;
                                text-transform: capitalize;
                                &::after {
                                    display: none;
                                }

                                .statusDiv {
                                    border-radius: 3px;
                                    padding: 6px 5px;
                                }
                            }
                            .dkg-referal-status-drpdwn-menu{
                                padding: 0;
                                border-radius: 0;
                                margin: 0 !important;
                                top: 34px !important;
                                transform: inherit !important;
                                left: 0 !important;
                                right: inherit !important;
                                border: none;
                                min-width: 130px;
                                max-width: 130px;
                                .dkg-referal-status-item{
                                    display: block;
                                    width: 100%;
                                    font-size: 14px;
                                    padding: 6px 12px;
                                    border-bottom: transparent;
                                }
                            }
                        }
                        .dkg-agreements-btn-con,
                        .dkg-buy-btn-con{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-agreements-btn,
                            .dkg-buy-btn{
                                background-color: #3c97b6;
                                color: #fff;
                                padding: 0.5rem 0.75rem;
                                text-transform: capitalize;
                                white-space: nowrap;
                                .dkg-invoice-icon{
                                    margin-left: 0.5rem;
                                    position: relative;
                                    top: -1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .dkg-myreferals-mainCon{
        margin-top: 35px;
        height: calc(100vh - 49px);
        overflow-y: hidden;
        .dkg-myreferals-header{
            display: block;
            position: relative;
            width: 100%;
            margin-top: 10px;
            .dkg-myreferals-headerleftCon{
                display: inline-block;
                position: absolute;
                left: -2px;
                top: 25px;
                .dkg-refreral-srchinput{
                    max-width: 135px;
                    .dk-mailshotsSearch{
                        .input-group{
                            display: inline-block;
                            min-width: 135px;
                            max-width: 135px;
                        }
                    }
                }

            }
            .dkg-myreferals-centreCon{
                position: relative;
                top: -15px;
                display: block;
                .dkg-docs-title{
                    width: 100%;
                    text-align: center;
                    display: inline-block;
                }
            }
            // .dkg-myreferals-rightCon{

            // }
        }
        .dkg-document-tableCon{
            height: calc(100vh - 220px);
            overflow: auto;
        }
    }
    .dk-pipedocs-previewModal{
        &.dkg-pipeline-candModal-234{
            min-width: 97%;
            right: 0;
            .modal-content{
                .dkg-pipeline-candModal-header{
                    .close{
                        right: 0px;
                        width: 28px;
                        height: 28px;
                        top: -2px;
                    }
                }
            }
        }
    }
    .dk-notificationsModal{
        .modal-dialog{
            min-width: inherit !important;
            max-width: inherit !important;
            width: 97%;
            .modal-content{
                .dk-notificationsModalHeader{
                    .modal-title{
                        font-size: 0.95rem;
                    }
                }
                .dkg-addnew-doc-modalbody{
                    .dk-warningMain{
                        form{
                            .form-group{
                                display: block;
                                margin-bottom: 1rem;
                                .dkg-noti-label{
                                    font-size: 0.85rem;
                                    margin-bottom: 0.5rem;
                                }
                                .dkg-notification-task-drop12{
                                    .dkg-notification-task-dropdown{
                                        .dkg-noti-task-dropdown-toggle{
                                            border: 1px solid #ced4da;
                                        }
                                        .dropdown-menu{
                                            border-radius: 0;
                                            padding: 0;
                                            width: 100%;
                                            .dropdown-item{
                                                padding: 0.45rem 0.7rem;
                                                font-size: 0.85rem;
                                                border-bottom: 1px solid #ddd;
                                                &:last-child{
                                                    border-bottom: none;
                                                }
                                            }
                                        }
                                    }
                                }
                                .dk-notiAddnewBtn-Con{
                                    margin-top: 1rem;
                                    .dk-notiAddnewBtn{
                                        font-size: 0.85rem;
                                    }
                                    div{
                                        div{
                                            .dk-notiAddnewBtn{
                                                font-size: 0.85rem;
                                                padding: 6px 10px;
                                                background-color: #3c97b6;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}