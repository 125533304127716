.dk-cliBoardViewPageCont {
    margin-top: 160px;
    &.dkg-cand-jobmatching-mainCon{
        margin-top: 0 !important;
    }
    .commentBoxCon {
        display: flex;
        justify-content: center;
        align-items: center;

        .numCon {
            width: 25px;
            height: 25px;
            background-color: #eaeaea;
            border-radius: 50%;
            line-height: 25px;
            text-align: center;
            border: 1px solid #b9b9b9;
            cursor: pointer;

            &:hover {
                background-color: #234158;
                color: #fff;
            }
        }

        .activenumCon {
            width: 25px;
            height: 25px;
            background-color: #234158;
            border-radius: 50%;
            color: #fff;
            line-height: 25px;
            text-align: center;
            cursor: pointer;
        }
    }

    .dk-qulaifyTopHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;

        .dk-qulaifyLeftTopHead {
            display: flex;
            align-items: center;

            .dk-SrchInputBox {
                .input-group {
                    max-width: 160px;

                    input {
                        padding: 0.5rem 0.55rem;
                        background-color: #fff;
                        margin: 0;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        line-height: 1.3rem;
                        height: 35px;
                        text-align: left;
                        border-radius: 0;
                    }

                    input.form-control:focus {
                        box-shadow: none;
                        background: #fff;
                        outline: 0;
                    }

                    .input-group-append {
                        button {
                            padding: 0.5rem 0.55rem;
                            background-color: #eaeaea !important;
                            color: #6b6b6b;
                            margin: 0;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            line-height: 1.3rem;
                            height: 35px;
                            border-radius: 0;

                            i {
                                color: #6c757d;
                            }
                        }

                    }
                }
            }

            .dk-reclivejobsBox {
                .dk-AccwithNnoDropDwnCon {



                    .dk-AmDropDwn {
                        .dk-AmDropDwnToggle {
                            width: 35px;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #6c757d;
                            background: #fff;
                            border-radius: 50%;
                            border: 1px solid #ddd;
                        }
                    }

                    .dk-lrftCross {
                        position: absolute;
                        color: #000;
                        top: -6px;
                        margin-left: 23px;
                        font-size: 15px !important;
                        cursor: pointer;
                    }
                }
            }

            .dk-cricleBtn {
                display: inline-block;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #fff !important;
                color: #6c757d;
                text-align: center;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;
            }

            i.fa.fa-filter {
                font-size: 12px;
            }
        }

        .dk-qulaifyTopHeadTitle {
            h2.title {
                margin: 0;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                color: #333;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                top: 8px;
                text-transform: uppercase;
                display: none;
            }

            h3.title {
                font-weight: 700;
                font-size: 22px;
                color: #333;
                line-height: 1.2em;
                text-align: center;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                .dkg-jobs-faqicon{
                    margin-left: 0.5rem;
                    .dkg-jobsfaq-tab{
                        width: 1.75rem;
                        height: 1.75rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        border: 1px solid #ccc;
                        background-color: #ddd;
                        svg{
                            color: #555;
                        }
                        &:hover {
                            box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                            outline: 0;
                        }
                    }
                }
            }
            .dkg-mobinfo-title{
                display: none;
            }
        }

        .dk-qulaifyRightTopHead {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button.dk-reoderBtn {
                display: inline-block;
                background: #eaeaea;
                border: 1px solid #ddd;
                padding: 5px 10px;
                border-radius: 0;
                outline: 0;
                font-size: 15px;
                font-weight: 400;
            }
            .dkg-jobsfaq-tab{
                display: inline-block;
                background-color: #eaeaea;
                color: #333;
                border: 1px solid #ddd;
                padding: 5px 10px;
                border-radius: 0;
                outline: 0;
                font-size: 15px;
                font-weight: 400;
                &:hover{
                    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                    transition: all ease 0.5s;
                }
            }
            
            .dk-cricleBtn ,
             .dk-cricleBtn{
                display: inline-block;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #fff !important;
                color: #6c757d;
                text-align: center;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;
            }
            .dk-refreral-filter-btn {
                display: inline-block;
                height: 34px;
                line-height: 20px;
                padding: 7px 10px;
                background: #f2f2f2 !important;
                color: #6c757d;
                border: 1px solid #ddd;
                outline: none;
                box-shadow: none;
                svg {
                    width: 17px;
                    height: 17px;
                    padding-left: 0.35rem;
                }
                &:hover {
                    box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                    outline: 0;
                }
            }
            .dkg-openjobs-filter-btn-con{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 160px;
                position: relative;
                background-color: #fff;
                height: 34px;
                border: 1px solid #ddd;
                position: relative;
                padding: 0 10px;
                .dkg-openjobs-filter-btn {
                    display: inline-block;
                    height: 32px;
                    background: #f2f2f2 !important;
                    color: #6c757d;
                    border: none;
                    border-right: 1px solid #ddd;
                    outline: none;
                    box-shadow: none;
                    position: absolute;
                    left: 0;
                    top: 0;
                    svg {
                        width: 17px;
                        height: 17px;
                        padding-left: 0.35rem;
                    }
                   
                }
                .dkg-openjobs-filter-title{
                    text-transform: capitalize;
                }
                &:hover {
                    box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                    outline: 0;
                    cursor: pointer;
                }
            }
            i.fa.fa-filter {
                font-size: 12px;
            }
            button.btn.btn-secondary {
                padding: 0.5rem 0.55rem;
                background-color: #eaeaea !important;
                color: #6b6b6b;
                margin: 0;
                box-shadow: none;
                border: 1px solid #ddd;
                // line-height: 1.3rem;
                height: 34px;
                border-radius: 0;
                svg{
                    color: #6c757d;
                }
            }
            .dkg-referal-status-drpdwn{
                
                .dkg-referal-status-drpdwn-toggle{
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid #ddd;
                    padding: 6px 10px;
                    text-transform: capitalize !important;
                    font-size: 13px;
                    background-color: #fff !important;
                    color: #6c757d;
                    border-radius: 0;
                    min-width: 130px;
                    position: relative;
                    border-left: 0;
                    &::after {
                        position: absolute;
                        top: 15px;
                        right: 10px;
                        color: #6c757d;
                    }
                    &:hover {
                        box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                        outline: 0;
                    }
                }
                .dkg-referal-status-drpdwn-menu{
                    border-radius: 0;
                    padding: 0;
                    z-index: 99999;
                    .dkg-referal-status-item{
                        padding: 8px 10px;
                        border-bottom: 1px solid transparent;
                        font-size: 13px;
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        .dkg-lv-statuscounter {
                            position: absolute;
                            right: 8px;
                            color: #fff;
                            background-color: #234158;
                            padding: 1px 5px;
                            font-size: 12px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }

            .dk-qulaifyRightFilter {

                .ui.selection.dropdown.dk-overdues-task-slect,
                .ui.selection.dropdown.dk-overdues-task-slect:hover {
                    border-radius: 0;
                    min-width: 135px;
                    background-color: #eaeaea !important;
                }

                .ui.selection.dropdown .menu {
                    border-radius: 0;
                }

                .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
                .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
                    font-size: 13px;

                    .dropdown.icon {
                        top: 0.7381em;
                    }
                }
            }
        }
    }

    .dk-jobboardTableCon {
        padding-left: 0;
        overflow-y: auto;
        overflow-x: auto;
        background-color: #234158;
        height: calc(100vh - 194px);
        position: relative;
        padding-bottom: 0;
        margin-top: 10px;

        .table.table.dk-clBoardViewTable {
            margin-bottom: 0;
            margin-top: 0.94375rem;
            thead {
                tr th {
                    padding: 0px;
                    border: 0px;
                    &:nth-child(08),
                    &:nth-child(09),
                    &:nth-child(10),
                    &:nth-child(11),
                    &:nth-child(12),
                    &:nth-child(13){
                        width: 60px;
                    }
                }
            }

            tbody {
                tr td {
                    padding: 0.94375rem;
                    background-color: #234158;
                    font-weight: 400;
                    text-align: left;
                    vertical-align: middle;
                    font-size: 12px;
                    border: 0px !important;
                    color: #4d4b4b;

                    &:nth-child(09) {
                        text-align: left;

                        &:hover {
                            color: blue;
                            cursor: pointer;
                        }
                    }

                    &:nth-child(11) {
                        text-align: left;
                    }

                    .dk-recImgCon {
                        width: 32px;
                        height: 32px;

                        img {
                            width: 100%;
                            height: auto;
                            border-radius: 50%;
                            border: 1px solid #ddd;
                        }
                    }

                    .dk-SpecRes {
                        img.pdf-img {
                            width: 30px;
                            height: auto;
                        }
                    }

                    .dk-clupdeProfile i {
                        background: #297483;
                        padding: 3px 5px;
                        text-align: center;
                        border-radius: 2px;
                        color: #fff;
                        cursor: pointer;
                    }

                    a.dk-cllistStatusbtnt {
                        margin: 0;
                        border-radius: 3px;
                        border: none;
                        width: 100%;
                        min-width: 85px;
                        padding: 8px 0;
                        color: #fff !important;
                        outline: 0;
                        cursor: pointer;
                        font-size: 11px;
                        text-transform: uppercase;
                        display: inline-block;
                        font-weight: 500;
                        text-align: center;
                        vertical-align: middle;
                    }

                    a.dk-clientsActBtn {
                        background-color: #1b6867;
                    }

                    .dk-livejobsActionCols {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        a {
                            color: #333;
                            margin-right: 0.5rem;
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                        }

                        a:last-child {
                            margin-right: 0;
                        }
                    }

                    .dk-freqDrpdwnCon {
                        .dropdown.dk-freqDropDwon {
                            button {
                                margin: 0;
                                padding: .375rem 0rem !important;
                                min-width: 100%;
                                font-size: 12px !important;
                                text-transform: capitalize;
                                box-shadow: none;

                            }

                            button.dropdown-toggle::after {
                                display: none;
                            }

                            .dk-freqDrpMenu.dropdown-menu.show {
                                padding: 0;
                                border-radius: 0;
                                min-width: 90px;
                            }

                            .dk-freqDrpMenu.dropdown-menu {
                                .dropdown-item:first-child {
                                    background-color: #31a886;
                                    color: #fff;
                                }

                                .dropdown-item:last-child {
                                    background-color: #dc3545;
                                    color: #fff;
                                }
                            }
                        }
                    }
                    &:nth-child(08),
                    &:nth-child(09),
                    &:nth-child(10),
                    &:nth-child(11),
                    &:nth-child(12),
                    &:nth-child(13){
                        width: 60px;
                    }
                }
            }

            tfoot {
                tr td {
                    background-color: #234158;
                    padding-bottom: 21px;
                    border: 0px;

                    &:nth-child(10) {
                        text-align: center;
                    }
                }
            }
        }
        &.dkg-jobapplied-mainCon{
            .dkg-jobapplied-con-fluid{
                .dkg-jobapplied-mainRow{
                    .dkg-jobapplied-mainCol{
                        .dkg-app-applied-tableCon{
                            margin-top: 45px;
                            height: calc(100vh - 165px);
                            padding: 0;
                            overflow-y: auto;
                            overflow-x: auto;
                            position: relative;
                            .dkg-app-applied-table{
                                thead{
                                    tr{
                                        th{
                                            padding: 7px 10px;
                                            text-align: center;
                                            // position: sticky;
                                            // top: 0;
                                            // z-index: 9;
                                            background-color: transparent;
                                            border-bottom: none;
                                            text-transform: uppercase;
                                            color: #ddd;
                                            .dkg-jobapplied-th-cols{
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                // justify-content: space-between;
                                                .dkg-job-applied-th-title{
                                                    &:nth-child(01){
                                                        width: 100%;
                                                        max-width: 25%;
                                                    }
                                                    &:nth-child(02){
                                                        width: 100%;
                                                        max-width: 35%;
                                                    }
                                                    &:nth-child(03){
                                                        width: 100%;
                                                        max-width: 40%;
                                                    }

                                                }
                                            }
                                            &:nth-child(02){
                                                width: 100px;
                                            }
                                        }
                                    }
                                }
                                tbody{
                                    color: #2e75b6;
                                    tr{
                                        td{
                                            background-color: #fff;
                                            color: #333;
                                            padding: 7px 10px;
                                            height: 55px;
                                            border: 1px solid #ddd;
                                            vertical-align: middle;
                                            text-align: center;
                                            font-size: 14px;
                                            .dkg-jobappied-datetime{
                                                color: #999;
                                            }
                                            &:nth-child(01){
                                                width: 170px;
                                                .dkg-app-category-dropdown-Con{
                                                    width: 100%;
                                                    .dkg-jobapplied-title{
                                                        color: #2e75b6;
                                                        font-size: 0.9rem;
                                                        height: 2rem;
                                                        position: relative;
                                                        top: 0.3rem;
                                                    }
                                                    .dkg-app-select-dropdown ,.dk-jobssearchFilter{
                                                        width: 100%;
                                                        position: relative;
                                                        .dkg-jobapplied-title{
                                                            color: #2e75b6;
                                                            position: relative;
                                                            top: 0.45rem;
                                                            font-size: 0.9rem;
                                                        }
                                                        .dkg-app-select-dropdown-toggle, .dropdown{
                                                            background-color: transparent;
                                                            margin: 0;
                                                            width: 100%;
                                                            box-shadow: none;
                                                            font-size: 14px;
                                                            text-align: center;
                                                            height: 55px;
                                                            line-height: 38px;
                                                            border-radius: 0;
                                                            text-transform: capitalize;
                                                            padding: 9px 10px;
                                                            position: relative;
                                                            color: #777;
                                                            width: 100%;
                                                            a{
                                                                color: #777;
                                                                &::after {
                                                                    display: inline-block;
                                                                    margin-left: 0.255em;
                                                                    vertical-align: 0.255em;
                                                                    content: "";
                                                                    border-top: 0.3em solid;
                                                                    border-right: 0.3em solid transparent;
                                                                    border-bottom: 0;
                                                                    border-left: 0.3em solid transparent;
                                                                    color: #8a8a8a;
                                                                    position: absolute;
                                                                    top: 27px;
                                                                    right: 10px;
                                                                    font-size: 18px;
                                                                }
                                                            }
                                                            // &::after{
                                                            //     position: absolute;
                                                            //     top: 26px;
                                                            //     right: 12px;
                                                            //     font-size: 18px;
                                                            //     color: #777;
                                                            // }
                                                        }
                                                        .dkg-app-select-dropdown-menu ,
                                                        .dropdown-menu{
                                                            position: absolute;
                                                            left: 0;
                                                            display: block;
                                                            width: 100%;
                                                            border-radius: 0;
                                                            padding: 0;
                                                            border-color: currentcolor #96c8da #96c8da;
                                                            border-top: #96c8da;
                                                            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                                            z-index: 999999;
                                                            .dkg-app-select-dropdown-item{
                                                                border-radius: 0;
                                                                border: none;
                                                                border-bottom: 1px solid #ddd;
                                                                text-align: left;
                                                                font-weight: 500;
                                                                font-size: 13px;
                                                                padding: 5px 7px;
                                                                color: #333;
                                                            }
                                                            .dkg-job-applied-ul{
                                                                max-height: 220px;
                                                                overflow-y: auto;
                                                                a{
                                                                    border-radius: 0;
                                                                    border: none;
                                                                    border-bottom: 1px solid #aaa;
                                                                    text-align: left;
                                                                    font-weight: 500;
                                                                    font-size: 13px;
                                                                    padding: 9px 10px;
                                                                    color: #333;
                                                                    height: 45px;
                                                                    &:last-child{
                                                                        border-bottom: none;
                                                                    }
                                                                }
                                                                .dkg-jobapplied-dropitem{
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: space-between;
                                                                    width: 100%;
                                                                    .dkg-joapplied-cross{
                                                                        svg{
                                                                            opacity: 0.85;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .dkg-app-user-dropdrownCon{
                                                    position: relative;
                                                    .dkg-app-user-dropdrown{
                                                        width: 100%;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        .dkg-app-user-dropdrown-toggle{
                                                            align-items: center;
                                                            background-color: transparent;
                                                            // border: 1px solid #ddd;
                                                            border-radius: 50%;
                                                            box-shadow: none;
                                                            color: #8a8a8a;
                                                            display: flex;
                                                            height: 35px;
                                                            justify-content: center;
                                                            margin: 0;
                                                            padding: 0;
                                                            position: relative;
                                                            width: 35px;
                                                            svg{
                                                                width: 22px;
                                                                height: 22px;
                                                            }
                                                            &:after {
                                                                position: absolute;
                                                                top: 17px;
                                                                right: -10px;
                                                                font-size: 19px;
                                                                display: none;
                                                            }
                                                        }
                                                        .dkg-app-user-dropdrown-menu{
                                                            // align-items: center;
                                                            // background-color: #f2f2f2;
                                                            // border: 1px solid #dddd;
                                                            // display: flex;
                                                            // left: inherit!important;
                                                            // margin: 0!important;
                                                            // min-width: inherit;
                                                            // padding: 0!important;
                                                            // position: absolute!important;
                                                            // right: 68px !important;
                                                            // top: -7px !important;
                                                            // transform: inherit!important;
                                                            background-color: #fff;
                                                            left: 55px!important;
                                                            padding: 5px;
                                                            top: -10px!important;
                                                            transform: translate(22px)!important;
                                                            -webkit-transform: translate(22px)!important;
                                                            &:before {
                                                                border-color: #0000 #fff #0000 #0000;
                                                                _border-color: #000 #fff #000 #000;
                                                                border-style: solid;
                                                                border-width: 8.5px 12px 8.5px 0;
                                                                content: "";
                                                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                                                height: 0;
                                                                position: absolute;
                                                                top: 19px;
                                                                left: -12px;
                                                                line-height: 0;
                                                                width: 0;
                                                            }
                                                            .d-flex{
                                                                .dkg-app-user-dropdrown-item{
                                                                    display: inline-block;
                                                                    height: 35px;
                                                                    margin: 5px 3px;
                                                                    padding: 0;
                                                                    width: 35px;
                                                                   .dkg-app-user-img{
                                                                        border-radius: 50%;
                                                                        height: 35px;
                                                                        width: 35px
                                                                   }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-job-title-cols{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                .dkg-arrow-right{
                                                    svg{
                                                        color: #999;
                                                    }
                                                }
                                                .dkg-job-title{
                                                    color: #999;
                                                }
                                            }
                                            .dkg-blue-jobtitle{
                                                color: #2e75b6;
                                                &:hover{
                                                    color: #333;
                                                    cursor: pointer;
                                                    // text-shadow: 0 0 5px #03e9f4,0 0 25px #03e9f4, 0 0 50px #03e9f4,0 0 100px #03e9f4;
                                                }
                                            }
                                            .dkg-orange-jobtitle{
                                                color: #2e75b6;
                                                &:hover{
                                                    color: #333;
                                                    cursor: pointer;
                                                    // text-shadow: 0 0 5px #03e9f4,0 0 25px #03e9f4, 0 0 50px #03e9f4,0 0 100px #03e9f4;
                                                }
                                            }
                                            &.dkg-aapiled-app-td{
                                                padding: 0;
                                                height: inherit;
                                                width: 170px;
                                                a{
                                                    display: inline-block;
                                                    width: 100%;
                                                    height: 55px;
                                                    line-height: 55px;
                                                    cursor: text;
                                                    &:hover{
                                                        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1882352941), 0 6px 6px rgba(0, 0, 0, 0.231372549);
                                                        transition: all 0.5s ease;
                                                        cursor: pointer;
                                                    }
                                                    &.dkg-withdrawl-app-btn{
                                                        height: 55px;
                                                        line-height: 55px;
                                                    }
                                                    &.dkg-date-time-cols{
                                                        cursor: text !important;
                                                        border-left: 1px solid #ddd;
                                                        &:hover{
                                                            box-shadow: none;
                                                        }
                                                    }
                                                }
                                            }
                                            .dk-app-caseDrpDwn{
                                                .dk-app-status-toggle{
                                                    margin: 0;
                                                    width: 100%;
                                                    box-shadow: none;
                                                    font-size: 14px;
                                                    text-align: center;
                                                    height: 55px;
                                                    line-height: 55px;
                                                    border-radius: 0;
                                                    text-transform: capitalize;
                                                    margin: 0;
                                                    position: relative;
                                                    cursor: text !important;
                                                    &:hover{
                                                        color: #fff;
                                                    }
                                                    &::after{
                                                        position: absolute;
                                                        top: 28px;
                                                        right: 10px;
                                                        font-size: 18px;
                                                        display: none;
                                                    }
                                                }
                                                .dkg-caseStatus-appDropMenu {
                                                    display: block;
                                                    width: 100%;
                                                    border-radius: 0;
                                                    padding: 0;
                                                    border-color: currentcolor #96c8da #96c8da;
                                                    border-top: #96c8da;
                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                                    .dk-app-status-item{
                                                        border-radius: 0;
                                                        border: none;
                                                        border-bottom: 1px solid #aaa;
                                                        text-align: left;
                                                        font-weight: 500;
                                                        font-size: 13px;
                                                        padding: 9px 10px;
                                                    }
                                                }
                                            }
                                            .dkg-app-select-dropdown-Con{
                                                width: 100%;
                                                .dkg-app-select-dropdown ,.dk-jobssearchFilter{
                                                    width: 100%;
                                                    position: relative;
                                                    .dkg-jobapplied-title{
                                                        color: #2e75b6;
                                                        position: relative;
                                                        top: 0.45rem;
                                                        font-size: 0.9rem;
                                                    }
                                                    .dkg-app-select-dropdown-toggle, .dropdown{
                                                        background-color: transparent;
                                                        margin: 0;
                                                        width: 100%;
                                                        box-shadow: none;
                                                        font-size: 14px;
                                                        text-align: center;
                                                        height: 55px;
                                                        line-height: 38px;
                                                        border-radius: 0;
                                                        text-transform: capitalize;
                                                        padding: 9px 10px;
                                                        position: relative;
                                                        color: #777;
                                                        width: 100%;
                                                        a{
                                                            color: #777;
                                                        }
                                                        &::after{
                                                            position: absolute;
                                                            top: 26px;
                                                            right: 12px;
                                                            font-size: 18px;
                                                            color: #777;
                                                        }
                                                    }
                                                    .dkg-app-select-dropdown-menu ,
                                                    .dropdown-menu{
                                                        position: absolute;
                                                        left: 0;
                                                        display: block;
                                                        width: 100%;
                                                        border-radius: 0;
                                                        padding: 0;
                                                        border-color: currentcolor #96c8da #96c8da;
                                                        border-top: #96c8da;
                                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                                        z-index: 999999;
                                                        .dkg-app-select-dropdown-item{
                                                            border-radius: 0;
                                                            border: none;
                                                            border-bottom: 1px solid #ddd;
                                                            text-align: left;
                                                            font-weight: 500;
                                                            font-size: 13px;
                                                            padding: 5px 7px;
                                                            color: #333;
                                                        }
                                                        .dkg-job-applied-ul{
                                                            max-height: 220px;
                                                            overflow-y: auto;
                                                            a{
                                                                border-radius: 0;
                                                                border: none;
                                                                border-bottom: 1px solid #aaa;
                                                                text-align: left;
                                                                font-weight: 500;
                                                                font-size: 13px;
                                                                padding: 9px 10px;
                                                                color: #333;
                                                                height: 45px;
                                                                &:last-child{
                                                                    border-bottom: none;
                                                                }
                                                            }
                                                            .dkg-jobapplied-dropitem{
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: space-between;
                                                                width: 100%;
                                                                .dkg-joapplied-cross{
                                                                    svg{
                                                                        opacity: 0.85;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-joapplied-cross{
                                                        position: absolute;
                                                        left: 18px;
                                                        cursor: pointer;
                                                        z-index: 999;
                                                        &:hover{
                                                            svg{
                                                                color: #666;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-appied-action-cols{
                                                .dkg-appied-edit-cols{
                                                    svg{
                                                        width: 18px;
                                                        height: 18px;
                                                        color: #a29f9f;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                            .dkg-app-jobinfo-cols{
                                                &.dkg-jobapps-link-cols{
                                                    .dkg-app-select-dropdown-Con{
                                                        width: 100%;
                                                        position: relative;
                                                        .dkg-app-select-dropdown ,.dk-jobssearchFilter{
                                                            width: 100%;
                                                            position: relative;
                                                            .dkg-app-select-dropdown-toggle, .dropdown{
                                                                a{
                                                                    color: #777;
                                                                    &::after{
                                                                        display: inline-block;
                                                                        margin-left: 0.255em;
                                                                        vertical-align: 0.255em;
                                                                        content: "";
                                                                        border-top: 0.3em solid;
                                                                        border-right: 0.3em solid transparent;
                                                                        border-bottom: 0;
                                                                        border-left: 0.3em solid transparent;
                                                                        color: #8a8a8a;
                                                                        position: absolute;
                                                                        top: 27px;
                                                                        right: 3px;
                                                                        font-size: 18px;
                                                                    }
                                                                    .dkg-joapplied-cross{
                                                                        svg{
                                                                            position: absolute;
                                                                            right: 18px;
                                                                            top: 10px;
                                                                            cursor: pointer;
                                                                            &:hover{
                                                                                opacity: 0.8;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .dkg-app-select-dropdown-menu ,
                                                            .dropdown-menu{
                                                                min-width: 220px;
                                                                border-radius: 0;
                                                                padding: 0;
                                                                .dkg-app-select-dropdown-item{
                                                                    color: #333;
                                                                }
                                                                .dkg-job-applied-ul{
                                                                    margin-bottom: 0;
                                                                    a{
                                                                        
                                                                        color: #333;
                                                                        height: 45px;
                                                                        &:last-child{
                                                                            border-bottom: none;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .dkg-joapplied-cross{
                                                            position: absolute;
                                                            left: 6px;
                                                            top: 18px;
                                                            cursor: pointer;
                                                            z-index: 9999;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            width: 20px;
                                                            height: 20px;
                                                            background-color: #eaeaea;
                                                            color: red;
                                                            border-radius: 50%;

                                                            &:hover{
                                                                svg{
                                                                    color: red;
                                                                    width: 12px;
                                                                    height: 12px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .dkg-app-user-dropdrownCon{
                                            position: relative;
                                            .dkg-app-user-dropdrown{
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                .dkg-app-user-dropdrown-toggle{
                                                    background-color: transparent;
                                                    border-radius: 50%;
                                                    box-shadow: none;
                                                    color: #8a8a8a;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    margin: 0;
                                                    padding: 0;
                                                    position: relative;
                                                    .filterImg{
                                                        width: 35px;
                                                        height: 35px;
                                                        border-radius: 50%;
                                                        border: 1px solid #ddd;
                                                    }
                                                }
                                            }
                                        }
                                        &:nth-child(01){
                                            td{
                                                &:nth-child(02),
                                                &:nth-child(03),
                                                &:nth-child(04){
                                                    padding: 0 !important;
                                                    .dkg-job-applied-bg{
                                                        height: 100%;
                                                        line-height: 52px;
                                                    }
                                                    .dkg-app-st-status-dropdwnCon{
                                                        .dkg-app-st-status-dropdwn{
                                                            width: 100%;
                                                            font-size: 14px;
                                                            text-align: center;
                                                            height: 55px;
                                                            line-height: 55px;
                                                            &:nth-child(01){
                                                                max-width: 25%;
                                                            }
                                                            &:nth-child(02){
                                                                max-width: 35%;
                                                            }
                                                            &:nth-child(03){
                                                                max-width: 40%;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &:nth-child(01),
                                        &:nth-child(03){
                                            td{
                                                &:nth-child(03){
                                                    padding: 0;
                                                }
                                                &:nth-child(04){
                                                    padding: 0 !important;
                                                    .dkg-app-select-dropdown-Con{
                                                        width: 100%;
                                                        .dkg-app-select-dropdown{
                                                            width: 100%;
                                                            .dkg-app-select-dropdown-toggle{
                                                                background-color: transparent;
                                                                margin: 0;
                                                                width: 100%;
                                                                box-shadow: none;
                                                                font-size: 14px;
                                                                text-align: center;
                                                                height: 55px;
                                                                line-height: 9px;
                                                                border-radius: 0;
                                                                text-transform: capitalize;
                                                                padding: 9px 10px;
                                                                position: relative;
                                                                color: #333;
                                                                cursor: not-allowed;
                                                                &::after{
                                                                    position: absolute;
                                                                    top: 26px;
                                                                    right: 12px;
                                                                    font-size: 18px;
                                                                    display: none;
                                                                }
                                                                &.dkg-blue-jobtitle{
                                                                    color: rgb(46, 117, 182);

                                                                }
                                                                &.dkg-orange-jobtitle{
                                                                    color: #D58B5D;

                                                                }

                                                            }
                                                            .dkg-app-select-dropdown-menu{
                                                                display: block;
                                                                width: 100%;
                                                                border-radius: 0;
                                                                padding: 0;
                                                                border-color: currentcolor #96c8da #96c8da;
                                                                border-top: #96c8da;
                                                                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                                                .dkg-app-select-dropdown-item{
                                                                    border-radius: 0;
                                                                    border: none;
                                                                    border-bottom: 1px solid #aaa;
                                                                    text-align: left;
                                                                    font-weight: 500;
                                                                    font-size: 13px;
                                                                    padding: 9px 10px;
                                                                    color: #333;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &:nth-child(02){
                                            width: 100px;
                                        }
                                        &:last-child{
                                            td{
                                                &:nth-child(02){
                                                    padding: 0;
                                                    .dkg-app-jobinfo-four-cols{
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                        height: 100%;
                                                        .dkg-app-jobinfo-cols{
                                                            width: 100%;
                                                            max-width: 20%;
                                                            display: flex;
                                                            align-items: center;
                                                            flex-direction: column;
                                                            justify-content: center;
                                                            height: 100%;
                                                            .dkg-jobapplied-title{
                                                                color: #7e868a;
                                                                font-size: 0.9rem;
                                                            }
                                                            .dkg-app-select-dropdown-Con{
                                                                width: 100%;
                                                                font-size: 0.925rem;
                                                                position: relative;
                                                                top: 0.15rem;
                                                            }
                                                            &:nth-child(01),
                                                            &:nth-child(02),
                                                            &:nth-child(03),
                                                            &:nth-child(04){
                                                                border-right: 1px solid #ddd;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &::after {
                                        content: "-";
                                        display: block;
                                        line-height: 45px;
                                        color: transparent;

                                    }
                                    &:last-child{
                                        color: #2e75b6;
                                        &::after{
                                            display: none;
                                        }
                                    }
                                }
                               
                            }
                            &.dkg-app-main-tableCon{
                                height: calc(100vh - 220px);
                                overflow-y: auto;
                                padding-bottom: 0;
                                position: relative;
                                margin-top: 0;
                                .dkg-app-applied-table{
                                    margin-bottom: 25px;
                                    overflow-y: auto;
                                    padding-bottom: 0;
                                    position: relative;
                                    margin-top: 0;

                                }
                            }
                         }
                    }
                }
            }
            color: #234158;
        }
    }
}

.air-datepicker-global-container {
    z-index: 1000000;
}

.priority-click {
    margin-right: 0.4rem;
    cursor: pointer;
    margin-top: 0px;
    float: left;
    border-radius: 100%;
    height: 17px;
    width: 17px;
}

.priority-click-filter {
    margin-right: 0.1rem;
    cursor: pointer;
    margin-left: 6px;
    margin-top: 0px;
    border-radius: 100%;
    height: 17px;
    width: 17px;
}

#gray_circle {
    background: #d0cece;
}

#green_circle {
    background: #30887e;
}

#yellow_circle {
    background: #ffd966;
}

.priority-box .dk-AmDropDwn .dk-AmDropDwnMenu.dropdown-menu:after {
    top: 9px !important;
}

.dk-rightCross {
    position: absolute;
    color: #000;
    top: -6px;
    margin-left: 23px;
    font-size: 15px !important;
    cursor: pointer;
}

.dot {
    height: 7px;
    width: 7px;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 3px;
    background-color: #234158;
    border-radius: 50%;
    display: inline-block
}

.spec-1 {
    color: #000;
    font-size: 12px
}

.spec-1 i {
    color: #ada9a9;
    font-size: 13px
}

.job-title {
    font-weight: 400;
    color: #333;
    cursor: inherit;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    div{
        span.date-hover{
            padding-left: 10px;
        }
    }
    &:hover{
        color: #629bd3;
        cursor: pointer;
    }

}
.float-right.mr-4{
    white-space: nowrap !important;
}
.para {
    font-size: 16px
}

.status-badge span {
    border-radius: 0.125rem;
    display: inline-block;
    padding: 1em 1.2em 1em 1.2em;
    color: #fff !important;
    background-color: #30887e !important;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.company-image {
    width: 90%;
    padding: 10px;
}

.grid-table-box {
    height: 50%;
    font-size: 13px;
    color: #396a95;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #ccc;
    .dkg-livejobs-View-btn{
        background-color: #d58b5d;
        color: #fff;
        width: 100%;
        text-align: center;
        height: 100%;
        line-height: 52px;
        cursor: pointer;
        font-size: 1.15rem;
        transition: .3s;
        &:hover{
            animation: pulse 1s infinite;
            transition: .3s;
        }
        .dkg-livejobs-icon{
            padding-right: 8px;
            svg{
                width: 18px;
                height: 18px;
                color: #f2f2f2;
            }
        }

    
    }
    @keyframes pulse {
        0% {
          transform: scale(1);
        }
        70% {
          transform: scale(.9);
        }
          100% {
          transform: scale(1);
        }
      }
    .dkg-livejobs-apply-btn{
        background-color: #3a9469;
        color: #fff;
        width: 100%;
        text-align: center;
        height: 100%;
        line-height: 52px;
        cursor: pointer;
        font-size: 1.15rem;
        &:hover{
            animation: pulse 1s infinite;
            transition: .3s;
        }
        .dkg-livejobs-icon{
            padding-right: 8px;
            svg{
                width: 18px;
                height: 18px;
                color: #f2f2f2;
            }
        }
    }


    i {
        color: gray;
    }

    &:hover {
        background: #ddd;
    }
}

.dk-job-status-back {
    .dropdown-menu {
        padding: 0;
    }

    .dk-freqDropDwon {
        .btn {
            padding: 0;
            margin: 0;
            border-radius: unset;
            box-shadow: none;
        }

        .dropdown-toggle::after {
            display: none;
        }
    }
}

.grid-table-box2 {
    width: 100%;
    height: 50%;
    .dk-AmDropDwnCon {
        width: 20%;
        margin-left: 3%;
        float: left;
        display: flex;
    }
    .dkg-livejobs-apply-btn{
        background-color: #3a9469;
        color: #fff;
        width: 100%;
        text-align: center;
        height: 100%;
        line-height: 52px;
        cursor: pointer;
        font-size: 1.15rem;
        &:hover{
            animation: pulse 1s infinite;
            transition: .3s;
        }
        .dkg-livejobs-icon{
            padding-right: 8px;
            svg{
                width: 18px;
                height: 18px;
            }
        }
    }
}

.boxCols {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 35px 35px 0px 0px;
    line-height: 0px;
    position: absolute;
    left: 7px;
    top: -1px;
    z-index: 1;
    background: none;
}

.boxCols-text {
    position: absolute;
    z-index: 2;
    font-size: 12px;
    transform: rotate(316deg);
    color: #fff;
    left: 3px;
    top: 24px;
}

.grid-table-row {
    background-color: #eaeaea;
    // overflow: hidden;
    border-color: transparent;
    &.dkg-livejobs-two-card{
        border: none !important;
    }
    .dk-freqDrpdwnCon {
        width: 100% !important;
        border-right: 1px solid #ccc;
    }

    .dk-freqDropDwon {
        width: 100% !important;
    }

    .col-md-1 {
        flex: 0 0 13%;
        max-width: 13%;
    }

    .col-md-7 {
        flex: 0 0 70.333333%;
        max-width: 70.333333%;
    }

    .logoBox {
        height: 100%;
        border-right: 1px solid #ccc;

        .logoBoxH {
            height: 70%;
        }

        .dropdown-menu {
            a {
                color: #fff;

                &:hover {
                    color: #000;
                }
            }
        }

        .logoBoxText {
            height: 30%;
            border-top: 1px solid #ccc;
            color: #fff;
        }

        .col-md-6 {
            color: #000;
            height: 100%;
            .dk-freqDropDwon{
                width: 100%!important;
                color: #4d4b4b;
                .dropdown-toggle.btn {
                    border-radius: 0;
                    border-radius: initial;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    &::after{
                        display: none;
                    }
                    &.boxCols{
                        left: -1px;
                        top: 0px !important;
                    }
                }
                .dk-freqDrpMenu.dropdown-menu {
                    padding: 0;
                }
            }
        }
        .col-md-12 {
            color: #000;
            height: 100%;
            .dk-freqDropDwon{
                width: 100%!important;
                color: #4d4b4b;
                left: 0 !important;
                height: 100%;
                .dropdown-toggle.btn {
                    border-radius: 0;
                    border-radius: initial;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    &::after{
                        display: none;
                    }
                    &.boxCols{
                        left: -1px;
                        top: -4px !important;
                    }
                }
                .dk-freqDrpMenu.dropdown-menu {
                    padding: 0;
                    width: 100%;
                }
                .dkg-livejob-status-boxCols{
                    height: 100%;
                    text-transform: capitalize;
                }
            }
        }
    }

    .align-content-center {
        border-left: 1px solid #ccc;
    }

    .fa-info-circle {
        color: #646161 !important;
    }
}

.switch-job-btn {
    background: #fff !important;
    box-shadow: none;
    border: 1px solid #ddd;
    color: #333;
    margin: 0;
    padding: 8px 20px;
    min-width: 120px;
    max-width: 120px;
    font-size: 12px;
}
.dkg-openjobs-card-mainRow{
    .dkg-openjobs-card-mainCols{
        .dkg-openjobs-card{
            display: flex;
            width: 100%;
            background-color: #eaeaea;
            border-radius: 5px;
            overflow: hidden;
            .dkg-openjobs-card-leftCon{
                flex: 0 0 15%;
                max-width: 15%;
                border-right: 1px solid #ddd;
                .dkg-openjobs-card-left-cols{
                    height: 100%;
                    .dkg-openjobs-logo-con{
                        width: 100%;
                        height: 70%;
                        padding: 7px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .dkg-openjobs-company-image{
                            width: 100%;
                            height: auto;
                            object-fit: contain;    
                        }
                    }
                    .dkg-openjobs-status-drpdwncon{
                        height: 30%;
                        width: 100%;
                        .dkg-openjobs-status-drpdwn{
                            width: 100%;
                            height: 100%;
                            .dkg-livejob-status-boxCols{
                                padding: 0;
                                margin: 0;
                                width: 100%;
                                border-radius: 0;
                                height: 34px;
                                font-size: 1rem;
                                text-transform: capitalize;
                                box-shadow: none;
                                cursor: text;
                                &::after{
                                    display: none;
                                }
                            }
                            .dkg-openjobs-status-drpdwnMenu{
                                border-radius: 0;
                                padding: 0;
                                width: 100%;
                                .dropdown-item{
                                    color: #fff;
                                    font-size: 0.85rem;
                                    padding: 0.45rem 0.75rem;
                                }
                            }
                        }
                    }
                }
            }
            .dkg-openjobs-card-centreCon{
                flex: 0 0 70%;
                max-width: 70%;
                padding: 0.94375rem;
                border-right: 1px solid #ddd;
                .dkg-openjobs-title{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 0.5rem 0;
                    .dkg-openjobs-title-info{
                        color: #333;
                        display: flex;
                        font-weight: 700;
                        justify-content: flex-start;
                        width: 100%;
                        &:hover {
                            color: #629bd3;
                            cursor: pointer;
                        }
                        span.date-hover {
                            padding-left: 10px;
                        }
                    }
                    .dkg-postjobs-date{
                        font-size: 0.80rem;
                        color: rgb(57, 106, 149);
                        cursor: pointer;
                        white-space: nowrap;
                    }
                }
                .dkg-openjobs-label-con{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    .dkg-openjobs-label{
                        text-align: justify;
                        width: 100%;
                        div{
                            span{
                                color: #4d4b4b;
                                i,svg{
                                    color: #ada9a9;
                                    font-size: 13px; 
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                   
                }
            }
            .dkg-openjobs-card-rightCon{
                flex: 0 0 15%;
                max-width: 15%;
                .dkg-openjobs-card-right-cols{
                    width: 100%;
                    height: 50%;
                    div{
                        height: 100%;
                        &.dkg-livejobs-apply-btn {
                            display: inline-block;
                            color: #fff;
                            font-size: 1.15rem;
                            height: 100%;
                            line-height: 52px;
                            text-align: center;
                            width: 100%;
                        }
                    }
                    a{
                        height: 100%;
                        &.dkg-livejobs-View-btn {
                            display: inline-block;
                            background-color: #d58b5d;
                            color: #fff;
                            cursor: pointer;
                            font-size: 1.15rem;
                            height: 100%;
                            line-height: 52px;
                            text-align: center;
                            transition: .3s;
                            width: 100%;
                            &:hover{
                                animation: pulse 1s infinite;
                                transition: .3s;
                            }
                        }
                        &.dkg-livejobs-apply-btn {
                            display: inline-block;
                            background-color: #4A98B5;
                            color: #fff;
                            cursor: pointer;
                            font-size: 1.15rem;
                            height: 100%;
                            line-height: 52px;
                            text-align: center;
                            width: 100%;
                            &:hover{
                                animation: pulse 1s infinite;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-myacc-jobprofile-main-Con{
        margin-top: 35px;
        height: calc(100vh - 49px);
        .dk-cliBoardViewPageCont{
            .dk-qulaifyTopHead{
                padding-top: 30px;
                .dk-qulaifyLeftTopHead{
                    .dk-SrchInputBox{
                        width: 140px;
                    }
                }
                .dk-qulaifyTopHeadTitle{
                    position: absolute;
                    top: -19%;
                    left: 50%;
                    transform: translate(-50%, -19%);
                    display: block;
                    .dk-filterTagsCon{
                        display: flex;
                        align-items: center;
                        .title{
                            display: none;                           
                        }
                        .dkg-mobinfo-title {
                            font-weight: 700;
                            font-size: 22px;
                            color: #333;
                            line-height: 1.2em;
                            text-align: center;
                            text-transform: uppercase;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            .dkg-mo-pophvr-icon{
                                margin-left: 8px;
                                top: -2px;
                                position: relative;
                                svg {
                                    width: 20px;
                                    height: 20px;
                                    cursor: pointer;
                                }
                            }

                        }
                        .dk-filterbtnGrp{
                            .dk-filterbtntext{
                                white-space: nowrap;
                            }
                        }
                    }
                }
                .dk-qulaifyRightTopHead{
                    position: absolute;
                    right: 0;
                    .dk-refreral-filter-btn{
                        white-space: nowrap;
                    }
                }
            }
            .dk-jobboardTableCon{
                height: calc(100vh - 149px) !important;
                overflow-y: auto;
                padding: 0  !important;
                padding-top: 0.94375rem !important;
                .dk-clBoardViewTable{
                    tbody{
                        tr{
                            td{
                               
                                .dkg-openjobs-card-mainRow{
                                    .dkg-openjobs-card-mainCols{
                                        .dkg-openjobs-card{
                                            flex-direction: column;
                                            .dkg-openjobs-card-leftCon{
                                                flex: 0 0 100%;
                                                max-width: 100%;
                                                .dkg-openjobs-card-left-cols{
                                                    .dkg-openjobs-logo-con{
                                                        .dkg-openjobs-company-image{
                                                            height: 42px;
                                                        }
                                                    }

                                                }
                                            }
                                            .dkg-openjobs-card-centreCon{
                                                flex: 0 0 100%;
                                                max-width: 100%;
                                                .dkg-openjobs-title{
                                                    display: block;
                                                    padding: 0;
                                                    .dkg-openjobs-title-info{
                                                        display: block;
                                                        div{
                                                            display: inline-block;
                                                        }
                                                    }
                                                    .dkg-postjobs-date{
                                                        display: flex;
                                                        justify-content: flex-start;
                                                        width: 100%;
                                                        position: relative;
                                                        top: 0;
                                                        right: 0;
                                                        padding: 3px;
                                                    }
                                                }
                                                .dkg-openjobs-label-con{
                                                    display: flex;
                                                    flex-wrap: wrap;
                                                    width: 100%;
                                                    border: 1px solid #ddd;
                                                    margin-bottom: 0 !important;
                                                    .dkg-openjobs-label{
                                                        flex: 0 0 50%;
                                                        max-width: 50%;
                                                        padding: 7px 5px;
                                                        div{
                                                            span{
                                                                &:last-child{
                                                                    padding-left: 0.5rem;
                                                                }
                                                            }
                                                        }
                                                        &:nth-child(01),
                                                        &:nth-child(02){
                                                            border-bottom: 1px solid #ddd;
                                                        }
                                                        &:nth-child(02),
                                                        &:nth-child(04){
                                                            display: flex;
                                                            justify-content: flex-start;
                                                            border-left: 1px solid #ddd;
                                                        }
                                                    }
                                                    &.mb-2{
                                                        .dkg-openjobs-label{
                                                            &:nth-child(03),
                                                            &:nth-child(04){
                                                                border-bottom: none;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-openjobs-card-rightCon{
                                                flex: 0 0 100%;
                                                max-width: 100%;
                                                display: flex;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
            .dk-jobboardTableCon::-webkit-scrollbar {
                width: 10px;
                cursor: pointer;
            }
        
            .dk-jobboardTableCon::-webkit-scrollbar-track {
                background: #ddd;
            }
        
            .dk-jobboardTableCon::-webkit-scrollbar-thumb {
                background-color: #938d8d;
                border-radius: 4px;
                height: 40px;
                cursor: pointer;
            }
        }
    }
    
}
.job-tool {
    .tooltip-inner{
        background: #DA3D5E !important;
    }
    .arrow::before {
        border-bottom-color: #DA3D5E !important;
    }
}
