/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-cliBoardViewPageCont{
        margin-top: 35px !important;
        height: calc(100vh - 49px);
        padding-bottom: 0 !important;
        .dk-qulaifyTopHead{
            position: relative;
            .dk-qulaifyLeftTopHead{
                position: relative;
                top: -0.75rem;
                .dk-SrchInputBox{
                    .input-group{
                        input{
                            width: 120px;
                        }
                    }
                }
            }
            .dk-qulaifyTopHeadTitle{
                position: absolute;
                top: -18px;
                left: 125px; 
                color: #333; 
                .title{
                    display: none !important;
                }
               
            }
            .dk-qulaifyRightTopHead{
                position: relative;
                top: 1.25rem;
            }
            &.dkg-js-applied-job-topHead{
                .dk-qulaifyLeftTopHead {
                    &.dkg-js-applied-job-leftHead{
                        top: 1.28rem !important;
                    }
                }
            }
        }
        .dk-jobboardTableCon {
            &.dkg-jobapplied-mainCon{
                margin-top: 30px;
                height: calc(100vh - 140px);
                .dkg-jobapplied-con-fluid{
                    .dkg-jobapplied-mainRow{
                        .dkg-jobapplied-mainCol{
                            .dkg-app-applied-tableCon{
                                margin-top: 1rem;
                                .dkg-app-applied-table{
                                    thead{
                                        tr{
                                            th{
                                                white-space: nowrap;
                                            }
                                            &:nth-child(01){
                                                min-width: 170px;
                                            }
                                            &:nth-child(02){
                                                min-width: 140px;
                                            }
                                            &:nth-child(03){
                                                min-width: 600px !important;
                                                max-width: 600px !important;
                                            }
                                            
                                        }
                                    }
                                    tbody{
                                        tr{
                                            td{
                                                white-space: nowrap;
                                            }
                                            &:nth-child(01){
                                                td{
                                                    &:nth-child(01){
                                                        min-width: 170px;
                                                    }
                                                    &:nth-child(02){
                                                        min-width: 140px;
                                                    }
                                                    &:nth-child(03){
                                                        min-width: 600px !important;
                                                        max-width: 600px !important;
                                                    }
                                                    &:nth-child(04){
                                                        min-width: 160px !important;
                                                        max-width: 160px !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}