.dkg-myapps-mainpageCon{
    margin-top: 153px;
    height: calc(100vh - 167px);
    background-color: #eaeaea;
    .dkg-myapps-con{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        padding-top: 80px;
        .dk-myapp-boxCols {
            background: #fff;
            border-radius: 5px;
            border: 1px solid #ddd;
            width: 100%;
            margin: 12px;
            .title{
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-weight: 500;
                padding: 6px 10px;
                background-color: #1f394c;
                color: #fff;
                border-radius: 5px 5px 0 0;
                font-size: 15px;
                border-bottom: 1px solid #ccc;
            }
            .dkg-myapps-body{
                // height: 160px;
                .dkg-myapps-ul{
                    list-style: none;
                    margin-bottom: 0;
                    width: 100%;
                    padding: 0.9rem 0;
                    .dkg-myapps-li{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 1rem 0.80rem;
                        .dkg-myapps-lefttitle{
                            font-size: 13px;
                            color: #333;
                            text-transform: uppercase;
                        } 
                        .dkg-myapps-righttitle{
                            color: #2e97b6;
                            font-size: 13px;
                        }
                        &:first-child{
                            .dkg-myapps-lefttitle,
                            .dkg-myapps-righttitle{
                                color: #02a786;
                            }
                        }
                        &:last-child{
                            .dkg-myapps-lefttitle,
                            .dkg-myapps-righttitle{
                                color: red;
                            }
                        }
                    }
                }
            }
        }

    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-myapps-mainpageCon{
       margin-top: 35px;
       height: calc(100vh - 50px);
       overflow-y: auto;
       .dkg-myapps-con{
        display: block;
        max-width: 100%;
        margin: 0 ;
        padding: 0 1rem;
        .dk-myapp-boxCols{
            margin: 0;
            margin-bottom: 1rem !important;
            &:first-child{
                margin-top: 1rem !important;
            }
            .dkg-myapps-body{
                .dkg-myapps-ul{
                    .dkg-myapps-li{
                        span{
                            font-weight: bold;
                        }
                    }
                }
            }
        }
       }
    }
}