.dkg-referrals-mainCon{
    margin-top: 100px;
    height: calc(100vh - 100px);
    background-color: #eaeaea;
    padding: 30px 15px;
    overflow-y: auto;
    .dkg-referrals-content{
        width: 100%;
        // max-width: 1600px;
        // margin: 0 auto;
        // background-color: #243B4C;
        .dkg-referrals-mainRow{
            padding-top: 0;
           .dkg-referrals-mainCol{
            display: flex;
            align-items: center;   
            justify-content: space-between;
            padding: 0 15px;
            margin-bottom: 10px;
            width: 100%;
            // max-width: 1600px;
            // margin: 0 auto;
            .dkg-refreral-srchinputCon{
                .dkg-refreral-srchinput{
                    width: 100%;
                    max-width: 160px;
                    display: flex;
                    align-items: center;
                    .dkg-search-input{
                        padding: 0.5rem 0.55rem;
                        background-color: #fff;
                        margin: 0;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        line-height: 1.3rem;
                        height: 35px;
                        text-align: left;
                        border-radius: 0;
                        width: 100%;
                    
                        &:focus{
                            outline: 0;
                        }
                    }
                    .dkg-search-icon{
                        padding: 0.5rem 0.55rem;
                        background-color: #eaeaea !important;
                        color: #6b6b6b;
                        margin: 0;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        border-left: none;
                        line-height: 1.3rem;
                        height: 35px;
                        border-radius: 0;
                        svg{
                            color: #8a8a8a;
                        }
                        &:hover {
                            outline: 0;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }
                    }
                }
            }
            .dkg-referrals-navpills{
                width: 100%;
                padding-left: 30px;
                .dkg-referrals-navitem{
                    width: 100%;
                    max-width: 11.11%;
                    .dkg-referrals-navlink{
                        margin: 0;
                        padding: 9px 5px;
                        background-color: #254763;
                        color: #ccc;
                        width: 100%;
                        text-align: center;
                        display: block;
                        position: relative;
                        border-radius: 0;
                        .dkg-referal-navicon{
                            display: block;
                        }
                        &.active{
                            background-color: #2E5A7F;
                            color: #fff;

                        }
                    }
                }
            }
            .dkg-referrals-accordian-Con{
                width: 100%;
                padding-left: 30px;
                .dkg-referrals-accordian{
                    display: flex;
                    width: 100%;
                }
            }
            .dkg-prefrefreal-page-header{
                .dkg-services-page-title {
                    font-weight: 700;
                    font-size: 22px;
                    color: #333;
                    line-height: 1.2em;
                    text-align: center;
                    text-transform: uppercase;
                }
                .dkg-services-page-mo-title{
                    font-weight: 700;
                    font-size: 22px;
                    color: #333;
                    line-height: 1.2em;
                    text-align: center;
                    text-transform: uppercase;
                    display: none;

                }
            }
            .dkg-referrals-filter-Con{
                .dk-refreral-filter-btn{
                    display: inline-block;
                    height: 34px;
                    line-height: 20px;
                    padding: 7px 10px;
                    background: #f2f2f2 !important;
                    color: #6c757d;
                    border: 1px solid #ddd;
                    outline: none;
                    box-shadow: none;
                    transition: all .5s ease in;
                    i.fa.fa-filter {
                        font-size: 12px;
                        margin-top: 3px;
                    }
                    svg{
                        width: 19px;
                        height: 19px;
                        position: relative;
                        top: -2px;
                    }
                    &:hover {
                        box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                        outline: 0;
                    }
                }
                .dkg-openjobs-filter-btn-con{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 160px;
                    position: relative;
                    background-color: #fff;
                    height: 34px;
                    border: 1px solid #ddd;
                    position: relative;
                    padding: 0 10px;
                    .dkg-openjobs-filter-btn {
                        display: inline-block;
                        height: 32px;
                        background: #f2f2f2 !important;
                        color: #6c757d;
                        border: none;
                        border-right: 1px solid #ddd;
                        outline: none;
                        box-shadow: none;
                        position: absolute;
                        left: 0;
                        top: 0;
                        svg {
                            width: 17px;
                            height: 17px;
                            padding-left: 0.35rem;
                        }
                       
                    }
                    .dkg-openjobs-filter-title{
                        text-transform: capitalize;
                    }
                    &:hover {
                        box-shadow: 0 5px 11px 0 #0000002e, 0 4px 15px 0 #00000026;
                        outline: 0;
                        cursor: pointer;
                    }
                }
            }
           } 
           .dkg-referrals-Cols{
            padding: 0;
             .dkg-referrals-tabcontent{
                .dkg-referrals-tabpane{
                    width: 100%;
                    height: 60px;
                    background-color: #2E5A7F;
                    color: #fff;
                    .dkg-referal-tabpane-ul{
                        margin: 0 auto;
                        padding: 20px 0;
                        max-width: 1250px;
                        list-style: none;
                        display: -ms-flexbox;
                        display: -webkit-box;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        .dkg-referal-tabpane-li{
                            margin: 0;
                            padding: 0;
                            padding-left: 4%;
                            -ms-flex: 0 0 20%;
                            -webkit-box-flex: 0;
                            flex: 0 0 20%;
                            max-width: 20%;
                            .dkg-referal-link{
                                padding: 5px 10px;
                                display: block;
                                color: #fff;
                                font-size: 14px;
                                position: relative;
                                span.dkg-referal-nocounter{
                                    color: #ffc107;
                                    padding-left: 0.5rem;

                                }
                            }
                        
                        }

                    }
                }
             }
             &.dkg-referrals-col-12{
                background-color: #234158;
                padding: 15px 0;
                height: calc(100vh - 199px);
                margin-top: 10px;
                .dkg-referrals-tableCon{
                    padding: 15px;
                    .dkg-referrals-table{
                        thead{
                            tr{
                                th{
                                   background-color: #1f394c;
                                    padding: 0.8rem 0.75rem;
                                    vertical-align: middle;
                                    font-weight: normal;
                                    font-size: 14px;
                                    border: 1px solid #4f4d4d;
                                    text-align: center;
                                    color: #ccc;
                                    white-space: nowrap;
                                    &:nth-child(01){
                                        padding: 0;
                                        width: 120px;
                                    }
                                    &:nth-child(02){
                                        padding: 0;
                                        width: 120px;
                                    }
                                    &:nth-child(03){
                                        padding: 0;
                                        width: 250px;
                                    }
                                    &:nth-child(05),
                                    &:nth-child(06),
                                    &:nth-child(07),
                                    &:nth-child(08){
                                        width: 110px;
                                    }
    
                                }
                            }
                        }
                        tbody{
                            tr{
                                td{
                                    background-color: #fff;
                                    padding: 10px 0.75rem;
                                    vertical-align: middle;
                                    font-weight: normal;
                                    font-size: 14px;
                                    border: 1px solid #8a8a8a;
                                    text-align: center;
                                    color: #333;
                                    white-space: nowrap;
                                    vertical-align: middle;
                                    .dkg-refreral-comapny-logoCon{
                                        width: 170px;
                                        height: 90px;
                                        display: inline-block;
                                        line-height: 90px;
                                        .dkg-refreral-comapny-logo{
                                            width: 100%;
                                            height: auto;
                                            background-position: center;
                                            background-size: cover;
                                        }
                                    }
                                    &:nth-child(01){
                                        padding: 0;
                                        width: 120px;
                                        height: 100px;
                                        .dkg-referal-status-bg-cols{
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .dkg-referal-status-title{
                                                font-size: 1rem;
                                            }
                                        }
                                    }
                                    &:nth-child(02){
                                        width: 120px;
                                    }
                                    &:nth-child(03){
                                        padding: 0;
                                        width: 250px;
                                    }
                                    .dkg-referal-jobtitle-cols{
                                        h5.dkg-referal-jobtitle{
                                            font-size: 1.15rem;
                                            color: #333;
                                            width: 100%;
                                            text-align: left;
                                            cursor: pointer;
                                            font-family: Segoe UI, Arial, sans-serif !important;
                                            font-weight: 700;
                                            margin-bottom: 1rem;
                                            &:hover{
                                                color: #2E5A7F;
                                            }
                                        }
                                        .dkg-referal-bottom-sec{
                                            width: 100%;
                                            .dkg-referal-bottom-ul{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                width: 100%;
                                                list-style: none;
                                                gap: 15px;
                                                padding: 0;
                                                margin-bottom: 0.75rem;
                                                .dkg-referal-bottom-li{
                                                    width: 100%;
                                                    max-width: 25%;
                                                    text-align: justify;
                                                    color: #000;
                                                    font-size: 12px;
                                                    .dkg-referal-li-icon{
                                                        padding-right: 0.3rem;
                                                        i{
                                                            color: #ada9a9;
                                                            font-size: 13px;
                                                        }                                                
                                                    }
                                                    &:last-child{
                                                        text-align: left;
                                                    }
                                                }
                                            }
    
                                        }
                                    }
                                    .dkg-buy-btn-con{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .dkg-agreements-btn,
                                        .dkg-buy-btn{
                                            color: #3c97b6;
                                            padding: 0.5rem 0.75rem;
                                            text-transform: capitalize;
                                            white-space: nowrap;
                                            cursor: pointer;
                                            box-shadow: none;
                                           font-size: 14px;
                                            .dkg-invoice-icon{
                                                margin-left: 0.5rem;
                                                position: relative;
                                                top: -1px;
                                                display: none;
                                            }
                                            &:hover{
                                                color: #3c97b6;
                                            }
                                        }
                                    }
                                    &:nth-child(04){
                                        vertical-align: top;
                                        .dkg-referal-jobtitle-cols{
                                            height: 75px;
                                            position: relative;
                                            .dkg-referal-bottom-sec{
                                                position: absolute;
                                                left: 0;
                                                bottom: -15px;
                                            
                                            }
                                        }
                                        
                                    }
                                    &:nth-child(05),
                                    &:nth-child(06),
                                    &:nth-child(07),
                                    &:nth-child(08){
                                        width: 110px;
                                    }
                                }
                            }
                        }
                    }
                }
             }
             
           }
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-referrals-mainCon{
        margin-top: 35px !important;
        height: calc(100vh - 49px) !important;
        padding: 2.5rem 0 !important;
        overflow-y: hidden;
        .dkg-referrals-content{
            margin-top: 1.5rem;
            .dkg-referrals-mainRow{
                .dkg-referrals-mainCol{
                    display: block;
                    position: relative;
                    width: 100%;
                    padding: 0 0.94375rem !important;
                    .dkg-refreral-srchinputCon{
                        display: inline-block;
                        position: absolute;
                        left: 13px;
                        top: -8px;
                        .dkg-refreral-srchinput{
                            max-width: 135px;
                            // .dkg-search-input{

                            // }
                            // .dkg-search-icon{
                            //     svg{

                            //     }
                            // }
                        }
                    }
                    .dkg-prefrefreal-page-header{
                        position: relative;
                        top: -45px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .dkg-services-page-title{
                            font-size: 1.5rem;
                            white-space: nowrap;
                            display: none;
                        }
                        .dkg-services-page-mo-title{
                            display: block;
                            font-size: 1.5rem;
                            white-space: nowrap;
                            .dkg-mo-pophvr-icon {
                                margin-left: 8px;
                                top: -2px;
                                position: relative;
                                svg {
                                    width: 20px;
                                    height: 20px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .dkg-referrals-filter-Con{
                        display: inline-block;
                        position: absolute;
                        right: 15px;
                        top: -8px;
                        .dk-refreral-filter-btn{
                            min-width: 90px;
                            max-width: 90px;
                            background-color: #fff !important;
                            display: inline-block;
                            svg{
                                color: #555;
                            }
                        }
                    }
                }
                .dkg-referrals-Cols{
                    padding: 0.94375rem !important;
                    .dkg-referrals-tableCon{
                        height: calc(100vh - 160px);
                        overflow: auto;
                        padding: 0 !important;
                        .dkg-referrals-table{
                            thead{
                                tr{
                                    td{
                                        &:nth-child(01){
                                            min-width: 100px;
                                        }
                                    }
                                }
                            }
                            tbody{
                                tr{
                                    td{
                                        white-space: nowrap;
                                        &:nth-child(01){
                                            min-width: 100px;
                                        }
                                        .dkg-referal-jobtitle-cols{
                                            .dkg-referal-bottom-sec{
                                                .dkg-referal-bottom-ul{
                                                    .dkg-referal-bottom-li{
                                                        max-width: 34%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}