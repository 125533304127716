.dkg-testimonial-box-modal-21 {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 600px !important;
        max-width: 600px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dkg-testimonials-modalheader-21 {
                // background-color: #234158;
                background-color: #234158;
                border-radius: 0;
                padding: 14px 15px !important;
                width: 100%;
                border-bottom: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: relative;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    width: 100%;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 700;


                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: 0;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dkg-testimonials-modalbody-21 {
                background-color: #eaeaea;
                padding: 15px;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dk-testmonial-filter{
                    width: 100%;
                    margin: 10px 0;
                    background-color: #fff;
                    border-color: #fff;
                    padding: 20px 35px;
                    border-radius: 0.25rem;
                
                    .dk-testmonial-filter-search{
                        padding: 10px 0 0.94375rem 0;
                        width: 100%;
                        font-size: 0.85rem;
                        display: none;
                        .dk-testmonials-search-box{
                            position: relative;
                            .dk-test-search-input{
                                height: 32px;
                                font-size: 1rem;
                                background-color: #f2f2f2;
                                border: 1px solid #ddd;
                            }
                            .dkg-input-append{
                                position: absolute;
                                right: 0;
                                top: 0;
                                button{
                                    width: 32px;
                                    height: 32px;
                                    font-size: 0.95rem;
                                    color: #fff;
                                    background-color: #2d96b6 !important;
                                    border-color: #2d96b6 !important;
                                    padding: inherit;
                                    position: relative;
                                    top: 0;
                                    margin: 0;
                                                            
                                }
                            }
                        }
                    }
                    .dk-testmonial-filter-cols{
                        padding: 10px 0;
                        .dkg-filtertitle{
                            font-size: 1rem;
                            margin-bottom: 0.94375rem;
                            color: #333;
                            font-weight: 700;
                            border-bottom: 1px solid #8a8a8b;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            position: relative;
                            .dkg-testimonial-count{
                                position: absolute;
                                right: 0;
                                top: 0;
                                font-size: 1rem;
                                margin-bottom: 0.94375rem;
                                color: #333;
                                font-weight: 700;
                            }
                        }
                        label,
                        span{
                            color: #333;
                            cursor: pointer;
                            font-size: 0.85rem;
                        }
                        &:first-child{
                            padding-top: 0;
                        }
                    }
                }
                .dkg-testimonial-form{
                    width: 100%;
                    margin: 10px 0;
                    background-color: #fff;
                    border-color: #fff;
                    padding: 20px 0;
                    padding-top: 0;
                    border-radius: 0.25rem;
                    .dkg-testmonial-form-title{
                        h5{
                            padding: 7px 10px;
                            text-align: center;
                            width: 100%;
                            font-size: 1.5rem;
                            font-weight: 600;
                            padding-top: 0.94375rem !important;
                            border-bottom: 1px solid #2d96b6;
                        }
                        form{
                            .dkg-testimonial-frmgrp{
                                margin-bottom: 1.25rem;
                                padding: 0 10px;
                                .dkg-testimonial-label{
                                    font-size: 0.9rem;
                                    text-align: left;
                                    margin-bottom: 0.3rem;
                                    span.dkg-red-star{
                                        color: red;
                                    }
                                }
                                .dkg-testimonial-inputgrp{
                                    .dkg-testimonial-input{
                                        height: 34px;
                                        font-size: 1rem;
                                        background-color: #f2f2f2;
                                        border: 1px solid #ddd;
                                    
                                    }
                                }
                                .dkg-testimonial-drpdwn{
                                    .dkg-testimonial-drpdwn-toggle{
                                        height: 34px;
                                        font-size: 1rem;
                                        background-color: #f2f2f2;
                                        border: 1px solid #ddd;
                                        box-shadow: none;
                                        text-transform: capitalize;
                                        border-radius: 0;
                                        padding: 7px 10px;
                                        width: 100%;
                                        margin: 0;
                                        &::after{
                                            position: absolute;
                                            right: 12px;
                                            top: 17px;
                                        }
                                    }
                                }
                                .dkg-testimonial-textArea{
                                    height: 70px;
                                    font-size: 1rem;
                                    background-color: #f2f2f2;
                                    border: 1px solid #ddd;
                                }
                                .dkg-rating-service-radio{
                                    display: flex;
                                    align-items: center;
                                    flex-direction: row;
                                    width: 100%;
                                    max-width: 80%;
                                    margin: 0 auto;
                                    justify-content: center;
                                    .dkg-testimonial-inputgrp{
                                        display: flex;
                                        align-items: center;
                                        margin-top: 0.35rem;
                                        margin-right: 0.35rem;
                                        .dkg-testimonial-radio{
                                            width: 14px;
                                            height: 14px;
                                        }
                                        span.dkg-test-rating-no{
                                            margin-left: 0.23rem;
                                            font-size: 0.85rem;
                                        }
                                        &:last-child{
                                            margin-right: 0;
                                        }
                                    }
                                }
                                .dkg-testimoninal-btn-Con{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    margin-top: 1rem;
                                    div{
                                        div{
                                            .dkg-testmonial-sub-btn{
                                                background-color: #2d96b6;
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                                &:last-child{
                                    margin-bottom: 0.5rem;
                                }
                                .dkg-testimonial-rating-titlle{
                                    span.dkg-red-star{
                                        color: red;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

.modal.dkg-testimonial-box-modal-21.fade .modal-dialog {
    right: -600px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dkg-testimonial-box-modal-21.show .modal-dialog {
    right: 0;
}

.modal.dkg-testimonial-box-modal-21 {
    z-index: 9999 !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-testimonial-box-modal-21{
        .modal-dialog {
            min-width: 95% !important;
            max-width: 95% !important;
        }
    }
    // .modal.dkg-testimonial-box-modal-21.fade .modal-dialog{
    //     right: -95%;
    // }
}