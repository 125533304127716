.dkg-myprofile-mainpageCon{
    margin-top: 153px;
    height: calc(100vh - 167px);
    background-color: #eaeaea;
    .dkg-myprofile-con{
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        padding-top: 60px;
        .dkg-myprofile-mainRow{
            .dkg-myprofile-leftcon,
            .dkg-myprofile-rightcon{
                border: 1px solid #d7d7d7;
                padding: 0;
                border-radius: 5px;
                margin-top: 0;
                .dkg-profile-frm-title{
                    background: #1f394c;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    color: #ebdede;
                    font-size: 16px;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 10px;
                    text-align: center;
                    margin-bottom: 0;
                }
                .dkg-profile-frm-subtitle{
                    background-color: #f2f2f2;
                    color: #333;
                    padding: 0.85rem 15px;
                    width: 100%;
                    margin-bottom: 1rem;
                    text-align: center;
                    border-bottom: 1px solid #ddd;
                    .dkg-frm-subtitle-desc{
                        font-size: 14px;
                        text-align: center;
                        margin-bottom: 0;
                        color: #666;

                    }
                }
                .dkg-profile-frmgrp{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 1rem;
                    padding: 0 15px;
                    .dkg-profile-label{
                        min-width: 220px;
                        max-width: 220px;
                        margin-bottom: 0;
                        font-size: 13px;
                        .dkg-profile-star{
                            color: #e2445b;
                        }
                    }
                    .dkg-app-select-dropdown-Con{
                        width: 100%;
                        .dkg-app-select-dropdown{
                            width: 100%;
                            .dkg-app-select-dropdown-toggle{
                                margin: 0;
                                width: 100%;
                                box-shadow: none;
                                text-align: center;
                                border-radius: 0;
                                text-transform: capitalize;
                                position: relative;
                                font-size: 0.9487rem;
                                border: 1px solid #ddd;
                                background-color: #f2f2f2;
                                color: #2e97b6;
                                height: calc(1.6em + 0.75rem + 2px);
                                padding: 0.375rem 0.75rem;
                                border-radius: 0.25rem;
                                &::after{
                                    position: absolute;
                                    top: 14px;
                                    right: 12px;
                                    font-size: 18px;
                                    color: #2e97b6;
                                }
                            }
                            .dkg-app-select-dropdown-menu{
                                display: block;
                                width: 100%;
                                border-radius: 0;
                                padding: 0;
                                border-color: currentcolor #96c8da #96c8da;
                                border-top: #96c8da;
                                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                .dkg-app-select-dropdown-item{
                                    border-radius: 0;
                                    border: none;
                                    border-bottom: 1px solid #aaa;
                                    text-align: left;
                                    font-weight: 500;
                                    font-size: 13px;
                                    padding: 9px 10px;
                                    color: #333;
                                }
                            }
                        }
                    }
                    .dkg-profile-input{
                        font-size: 12px;
                        border: 1px solid #ddd;
                        background-color: #f2f2f2;
                        color: #2e97b6;
                        &::placeholder {
                            color: #2e97b6;
                            opacity: 1; /* Firefox */
                            font-size: 12px;
                        }  
                        &::-ms-input-placeholder { /* Edge 12-18 */
                            color: #2e97b6;
                            font-size: 11px;
                        }
                        &.dkg-dullgrey-input,
                        &.dkg-lightgrey-placeholder{
                            cursor: not-allowed;
                            &::placeholder {
                                color: #8a8a8a;
                                font-size: 12px;
                                opacity: 1; /* Firefox */
                            }  
                            &::-ms-input-placeholder { /* Edge 12-18 */
                                color: #8a8a8a;
                                font-size: 12px;
                            }
                        }
                        &.dkg-blue-placeholder{
                            &::placeholder {
                                color: #2e97b6;
                            }  
                            &::-ms-input-placeholder { /* Edge 12-18 */
                                color: #2e97b6;
                            }
                            &:focus{
                                outline: #2e97b6;
                                box-shadow: none;
                            }
                        }
                        
                    }
                    
                    .dkg-info-uplaod-image {
                        .dk-contactPerson-image {
                            width: 100%;
                            max-width: 120px;
                            border: 1px solid #ccc;
                            padding: 0;
                            margin-right: 15px;
                            height: 120px;
                            display: flex;
                            align-items: center;
                            background: #fff;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;

                            img {
                                width: 100%;
                                object-fit: cover;
                                border-radius: 50%;
                                height: 120px;
                                object-position: top center;
                            }
                        }

                        .dk-edit-userImgCon {
                            width: 100%;
                            max-width: 100px;
                            border: 1px solid #ccc;
                            padding: 0;
                            margin-right: 15px;
                            height: 100px;
                            display: flex;
                            align-items: center;
                            background: #fff;
                            justify-content: center;
                            // overflow: hidden;
                            border-radius: 50%;
                            margin-bottom: 10px;
                            position: relative;
                            input {
                                width: 100%;
                                height: 100px;
                                max-width: 100px;
                                position: relative;
                                z-index: 9;
                                opacity: 0;
                                cursor: pointer;
                            }

                            span.dk-myprofile-userImgCon {
                                position: absolute;
                                top: -1px;
                                left: 0;
                                cursor: pointer;
                                width: 100%;
                                max-width: 100px;
                                border-radius: 50%;

                                img.dk-myprofile-userImg {
                                    width: 100%;
                                    height: auto;
                                    border-radius: 50%;
                                }

                                figure {
                                    .dkg-update-profile-234 {
                                        max-width: 120px;
                                        border: 1px solid #ccc;
                                        padding: 0;
                                        height: 120px;
                                        display: flex;
                                        align-items: center;
                                        background: #fff;
                                        justify-content: center;
                                        overflow: hidden;
                                        border-radius: 50%;
                                        position: relative;
                                        right: 4px;
                                    }
                                }
                            }

                            span.dk-myprofile-editicon {
                                position: absolute;
                                bottom: 17px;
                                right: -2px;
                                background-color: #234158;
                                width: 30px;
                                height: 30px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;

                                svg {
                                    color: #fff;
                                }
                            }
                        }
                    }
                    .dkg-dob-inputCon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .dkg-dob-input{
                            margin-right: 0.7rem;
                            background-color: #f2f2f2;
                            border: 1px solid #ddd;
                            &:last-child{
                                margin-right: 0;
                            }
                            &.dkg-blue-placeholder{
                                color: #2e97b6;
                                &::placeholder {
                                    color: #2e97b6 !important;
                                }  
                                &::-ms-input-placeholder { /* Edge 12-18 */
                                    color: #2e97b6 !important;
                                }
                                &:focus{
                                    outline: #2e97b6 !important;
                                    box-shadow: none;
                                }
                            }
                        }
                        .dkg-blue-placeholder{
                            &::placeholder {
                                color: #2e97b6;
                            }  
                            &::-ms-input-placeholder { /* Edge 12-18 */
                                color: #2e97b6;
                            }
                            &:focus{
                                outline: #2e97b6;
                                box-shadow: none;
                            }
                        }
                    }
                    &:last-child{
                        margin-bottom: 1rem;
                    }
                }
                .dkg-resume-rightcon{
                    height: calc(100vh - 340px);
                }
                &.dkg-resume-leftcon,
                &.dkg-resume-rightcon{
                    border: none;
                    .dkg-resume-leftbody,
                    .dkg-resume-rightbody{
                        border: none;
                        height: 304px !important;
                        background-color: #fff;
                        border-radius: 0 0 5px 5px;
                        border: 1px solid #ddd;
                        border-top: none;
                    }
                    .dkg-profile-frm-title{
                        margin-bottom: 0;
                    }
                }
            }
            .dkg-myprofile-leftcon {
                background-color: #fff;
     
                @media (min-width: 768px){
                    &.col-md-6 {
                        flex: 0 0 46%;
                        max-width: 46%;
                        position: relative;
                        &::after{
                            content: "";
                            position: absolute;
                            right: -48px;
                            top: 0;
                            border-right: 0.5px solid #ccc;
                            height: 100%;
                            display: none;
                        }
                    }
                }
                &.dkg-resume-leftcon{
                    .dkg-resume-frm-title{
                        text-transform: inherit;
                        border: 1px solid #ddd;
                    }
                    .dkg-resume-leftbody{
                        width: 100%;
                        height: calc(100vh - 250px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        .dkg-white-block{
                            width: 150px;
                            height: 150px;
                            background-color: transparent;
                            border: 1px solid transparent;
                            position: relative;
                            top: 5px;
                            .dkg-myresume-cvimgCon{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: absolute;
                                top: -18px;
                                left: 0;
                                .dkg-myresume-cvimg{
                                    width: 100px;
                                    height: auto;
                                    background-repeat: no-repeat;
                                    background-size: cover;                                    
                                }
                                input {
                                    width: 100%;
                                    height: 100px;
                                    max-width: 100px;
                                    position: relative;
                                    z-index: 9;
                                    opacity: 0;
                                    cursor: pointer;
                                }
    
                                span.dkg-myresume-sapnimgCon {
                                    position: absolute;
                                    top: -1px;
                                    left: 0;
                                    cursor: pointer;
                                    width: 100%;
                                    max-width: 100px;
                                    border-radius: 50%;
    
                                    img.dkg-myresume-cvimg {
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                        }
                        .dkg-resume-view-btnCon{
                            margin-top: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            position: relative;
                            top: -10px;
                            .dkg-resume-view-btn{
                                display: inline-block;
                                background-color: #f2f2f2;
                                color: #333;
                                border: 1px solid #ddd;
                                text-transform: capitalize;
                                border-radius: 0;
                                box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                                padding: 7px 12px;
                                font-size: 0.875rem;
                                box-sizing: border-box;
                                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                font-weight: 500;
                                line-height: 1.75;
                                letter-spacing: 0.02857em;
                                &:hover{
                                    box-shadow: none;
                                    background-color: #3C97B6;
                                    color: #fff;
                                }
                                .dkg-resume-icon{
                                    margin-left: 0.5rem;
                                    svg{
                                        width: 0.95rem;
                                        height: 0.95rem;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }

            }
            .dkg-myprofile-rightcon{
                background-color: #fff;

                @media (min-width: 768px){
                    &.col-md-6 {
                        flex: 0 0 46%;
                        max-width: 46%;
                        margin-left: auto;
                    }
                }
                &.dkg-profile-info-rightCon{
                    color: inherit;
                }
                &.dkg-resume-rightcon{
                    .dkg-resume-frm-title{
                        text-transform: inherit;
                        border: 1px solid #ddd;
                    }
                    .dkg-resume-rightbody{
                        width: 100%;
                        height: calc(100vh - 250px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        .dkg-white-block{
                            width: 150px;
                            height: 150px;
                            background-color: transparent;
                            border: 1px solid transparent;
                            position: relative;
                            top: -5px;
                            left: 0;
                            .dkg-myresume-cvimgCon{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .dkg-myresume-cvimg{
                                    width: 100px;
                                    height: 100px;
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                }
                                input {
                                    width: 100%;
                                    height: 100px;
                                    max-width: 100px;
                                    position: relative;
                                    z-index: 9;
                                    opacity: 0;
                                    cursor: pointer;
                                }
    
                                span.dkg-myresume-sapnimgCon {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    cursor: pointer;
                                    width: 100%;
                                    max-width: 85px;
                                    border-radius: 50%;
                                    margin: 0 auto;
                                    img.dkg-myresume-cvimg {
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                        }
                        .dkg-resume-btngrp-Con{
                            margin-top: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            position: relative;
                            top: -10px;
                            .dkg-resume-btn{
                                .dkg-resume-view-btn{
                                    display: inline-block;
                                    background-color: #f2f2f2;
                                    color: #333;
                                    border: 1px solid #ddd;
                                    text-transform: capitalize;
                                    border-radius: 0;
                                    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                                    padding: 7px 12px;
                                    font-size: 0.875rem;
                                    box-sizing: border-box;
                                    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                    font-weight: 500;
                                    line-height: 1.75;
                                    letter-spacing: 0.02857em;
                                    &:hover{
                                        box-shadow: none;
                                        background-color: #3C97B6;
                                        color: #fff;
                                    }
                                    .dkg-resume-icon{
                                        margin-left: 0.5rem;
                                        svg{
                                            width: 0.95rem;
                                            height: 0.95rem;
                                            cursor: pointer;
                                        }
                                    }
                                }
                                &.dkg-myresume-cvopen-con{
                                    .dk-TopCntreBox.cvtextBox{
                                        .dk-cvImg{
                                            display: none !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.dkg-jobseeker-res-card{
            max-width: 600px;
            
        }
    }
    &.dkg-myresume-mainCon{
        .dkg-myresume-con{
            padding-top: 0;
            .dkg-myprofile-mainRow{
                &.dkg-myresume-MainRow{
                    .dkg-myprofile-rightcon.dkg-resume-rightcon{
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        margin: 0 auto !important;
                    }
                }
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-myprofile-mainpageCon{
        margin-top: 35px;
        // height: calc(100vh - 35px);
        height: 100%;
        padding: 1rem;
        .dkg-myprofile-con{
            padding-top: 0;
            .dkg-myprofile-mainRow {
                .dkg-myprofile-leftcon,
                .dkg-myprofile-rightcon{
                    margin-bottom: 1rem;
                    .dkg-profile-frm-title{
                        font-size: 0.9rem;
                        height: 35px;
                        line-height: 35px;
                    }
                    .dkg-profile-frm-subtitle{
                        padding: 0.5rem 0.65rem;
                        .dkg-frm-subtitle-desc{
                            font-size: 0.8rem;
                            text-align: justify;
                        }
                    }
                    .dkg-profile-frmgrp{
                        .dkg-profile-label{
                            font-size: 0.8rem;
                            min-width: 120px;
                            max-width: 120px;
                        }
                        .dkg-info-uplaod-image{
                            .dk-edit-userImgCon{
                                input {
                                    width: 100%;
                                    height: 100px;
                                    max-width: 100px;
                                    position: relative;
                                    z-index: 9;
                                    opacity: 0;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .dkg-profile-info-rightCon{
                    margin-bottom: 0;
                }
            } 
        }
        &.dkg-restlogin-mainpageCon{
            margin-top: 35px !important;
            height: calc(100vh - 49px) !important;
            padding: 1.25rem 1rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            .dk-billingHistroy-fluid{
                height: 100%;
                .dk-contactPerson-form{
                    height: 100%;
                    &.dkg-resetform-Con-234{
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin-top: 0 !important;
                        padding: 0.25rem 0.9375rem !important;
                        height: 100%;
                        .row{
                            height: 100%;
                            .col-md-7{
                                height: 100%;
                                display: flex;
                                align-items: center;
                                form{
                                    .rtitle{
                                        font-size: 0.95rem;
                                        height: 32px;
                                        line-height: 32px;
                                    }
                                    .dkg-reset-login-body{
                                        .dkg-delete-acc-header{
                                            width: 100%;
                                            p{
                                                padding: 0.75rem;
                                                font-size: 0.85rem;
                                            }
                                        }
                                        .form-group.dkg-reset-form-group{
                                            display: block;
                                            label{
                                                display: block;
                                                margin-bottom: 0.5rem;
                                                font-size: 0.85rem;
                                            }
                                            .dk-eyeICon.dk-passHideShow{
                                                top: 25px;
                                            }
                                        }
                                    }
                                }
                                .dk-complaince-main{
                                    .title{
                                        font-size: 0.95rem;
                                        height: 32px;
                                        line-height: 32px;
                                    }
                                    .dk-complaince-cols{
                                        &.dkg-delete-acc-cols{
                                            .dkg-delete-acc-header{
                                                p{
                                                    font-size: 0.85rem;
                                                    margin-bottom: 0 !important;
                                                }
                                            }
                                            .dkg-delete-acc-body{
                                                padding: 0.94375rem;
                                                .sb-title{
                                                    font-size: 0.95rem;
                                                    margin-bottom: 0.45rem;
                                                }
                                                ul{
                                                    li {
                                                        font-size: 0.85rem;
                                                    }
                                                }
                                                .dkg-delete-checkbox-div{
                                                    .dk-smsTableCheckbox{
                                                        position: relative;
                                                        input[type=checkbox] {
                                                            cursor: pointer;
                                                            appearance: none;
                                                            background: #fff;
                                                            border: 1px solid #ddd;
                                                            width: 0.9rem;
                                                            height: 0.9rem;
                                                        }
                                                    }
                                                    label{
                                                        font-size: 0.85rem;
                                                    }
                                                }
                                                .dk-complaince-field{
                                                    .d-flex{
                                                        .dk-deleteBtn{
                                                            padding: 4px 7px;
                                                            /* display: inline-block; */
                                                            min-width: 155px !important;
                                                            font-size: 0.85rem;
                                                            height: 30px;
                                                            line-height: 11px;
                                                            margin-top: 0.5rem !important;                                                        
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.dkg-candidate-myres-mainCon{
            height: calc(100vh - 35px);
            .dkg-myprofile-con.dkg-myresume-con{
                .dkg-myprofile-mainRow.dkg-myresume-MainRow{
                    .dkg-myprofile-rightcon.dkg-resume-rightcon{
                        flex: 0 0 60.666667% !important;
                        max-width: 60.666667% !important;
                    }
                }
            }
        }
        &.dkg-candidate-myres-mainCon{
            height: calc(100vh - 49px);
            overflow: hidden;
            .dkg-myprofile-con.dkg-myresume-con{
                .dkg-myprofile-mainRow.dkg-myresume-MainRow{
                    .dkg-myprofile-rightcon.dkg-resume-rightcon{
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                    }
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}