.MuiButton-startIcon {
  margin-right: 0 !important;
}

.MuiButton-endIcon {
  margin-left: 0 !important;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: #b7b4b4;
  border-radius: 0;
  box-sizing: border-box;
  position: relative;
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  border-width: 0;
  transition: all 0.3s linear;
  border: solid #ccc 1px;
}

.modal-dialog.dkg-applyjob-ModalDailog {
  min-width: 80%;
  position: fixed;
  right: 2px;
  top: -15px;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);

  .modal-content {
    background-color: transparent;

    .step-div {
      p {
        margin: 0 !important;
      }

      h1,
      h2,
      h4,
      h5 {
        margin-left: 0 !important;
      }

      h3 {
        margin-bottom: 20px !important;
      }

      strong {
        font-weight: bold !important;
      }

      .MuiInputBase-input::placeholder {
        color: #000
      }
    }

    .step-div1 {
      height: calc(100vh - 210px);
      overflow-y: auto;
      background: #eaeaea;

      p {
        margin: 0 !important;
      }

      h1,
      h2,
      h4,
      h5 {
        margin-left: 0 !important;
      }

      h3 {
        margin-bottom: 20px !important;
      }

      strong {
        font-weight: bold !important;
      }

      .MuiInputBase-input::placeholder {
        color: #000
      }
    }


    .dkg-applyjob-header {
      border-radius: 0;
      padding: 0.65rem 0;
      background-color: #26597d;
      border-bottom: 1px solid darkgrey;

      .dk-detViewTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .close {
        color: #fff;
        opacity: 1;
        font-size: 1.8rem !important;

        span {
          color: #fff;
          padding: 0.30rem 1rem 0.95rem 1rem;
          font-weight: normal;
        }

        span:hover {
          background-color: #eaeaea;
          padding: 0.30rem 1rem 0.95rem 1rem;
          color: #333;
        }
      }
    }

    .dkg-applyjob-modal-body {
      padding: 0;
      border-radius: 0;
      background-color: #eaeaea;
      height: calc(100vh - 40px);
    }
  }
}

.step-white-bg {

  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  ul,
  li {
    background: #eaeaea !important;
  }
}

.step-white-bg1 {

  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  ul,
  li {
    background: #eaeaea !important;
  }
}

.step-white-bg2 {

  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  ul,
  li {
    background: #eaeaea !important;
  }
}

.step-white-bg3 {

  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  ul,
  li {
    background: #eaeaea !important;
  }
}

.modal .modal-dialog.dkg-applyjob-ModalDailog {
  right: -60%;
  -webkit-transition: opacity 0.3s linear, right .3s ease-out;
  -moz-transition: opacity 0.3s linear, right .3s ease-out;
  -o-transition: opacity 0.3s linear, right .3s ease-out;
  transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show .modal-dialog {
  right: 0;
}

.MuiInputBase-fullWidth::before {
  border-bottom: 0 !important;
}

.MuiFormControlLabel-asterisk {
  color: red;
}

.cat-line {
  border-top: 1px solid #e1d5d5;
}

.MuiStepper-horizontal {
  border-bottom: 1px solid #e1d5d5;
}

.fr-wrapper>div:first-child {
  display: none;
}

.fr-logo {
  display: none;
}


@keyframes bounceInLeft {

  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate(-3000px, 0);
  }

  75% {
    opacity: 1;
    transform: translate(20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes wheelSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(640deg);
  }
}

[class|=confetti] {
  position: absolute;
}

/* Config */
.confetti-left-0,
.confetti-right-0 {
  width: 6px;
  height: 2.4px;
  background-color: #f04813;
  top: calc(25% - 6px);
  left: calc(50% - 1.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-0 {
  animation: drop-left-0 1.3750830305s 0.4754253858s forwards;
}

.confetti-right-0 {
  animation: drop-right-0 1.6725637573s 1.100935788s forwards;
}

@keyframes drop-left-0 {
  0.0000% {
    transform: translate(0px, 0px) rotate(23deg);
    opacity: 0.8208946962;
  }

  10.7882% {
    transform: translate(18.9px, -45px) rotate(23deg);
    opacity: 0.8208946962;
  }

  21.2266% {
    transform: translate(42px, -86.4px) rotate(23deg);
    opacity: 0.8208946962;
  }

  30.4208% {
    transform: translate(69.15px, -118.05px) rotate(23deg);
    opacity: 0.8208946962;
  }

  34.4519% {
    transform: translate(84.3px, -128.4px) rotate(23deg);
    opacity: 0.8208946962;
  }

  38.2085% {
    transform: translate(100.35px, -134.1px) rotate(23deg);
    opacity: 0.8208946962;
  }

  41.9607% {
    transform: translate(117.3px, -134.4px) rotate(23deg);
    opacity: 0.8208946962;
  }

  46.1202% {
    transform: translate(135.3px, -128.7px) rotate(23deg);
    opacity: 0.8208946962;
  }

  51.1287% {
    transform: translate(154.2px, -116.1px) rotate(23deg);
    opacity: 0.8208946962;
  }

  57.3654% {
    transform: translate(174px, -96px) rotate(23deg);
    opacity: 0.8208946962;
  }

  65.1344% {
    transform: translate(194.85px, -67.5px) rotate(23deg);
    opacity: 0.8208946962;
  }

  74.6872% {
    transform: translate(216.45px, -30px) rotate(23deg);
    opacity: 0.8208946962;
  }

  86.2425% {
    transform: translate(239.1px, 17.25px) rotate(23deg);
    opacity: 0.4104473481;
  }

  100.0000% {
    transform: translate(262.5px, 75px) rotate(23deg);
    opacity: 0;
  }
}

@keyframes drop-right-0 {
  0.0000% {
    transform: translate(0px, 0px) rotate(99deg);
    opacity: 1.086141708;
  }

  10.7882% {
    transform: translate(-28.35px, -67.5px) rotate(99deg);
    opacity: 1.086141708;
  }

  21.2266% {
    transform: translate(-63px, -129.6px) rotate(99deg);
    opacity: 1.086141708;
  }

  30.4208% {
    transform: translate(-103.725px, -177.075px) rotate(99deg);
    opacity: 1.086141708;
  }

  34.4519% {
    transform: translate(-126.45px, -192.6px) rotate(99deg);
    opacity: 1.086141708;
  }

  38.2085% {
    transform: translate(-150.525px, -201.15px) rotate(99deg);
    opacity: 1.086141708;
  }

  41.9607% {
    transform: translate(-175.95px, -201.6px) rotate(99deg);
    opacity: 1.086141708;
  }

  46.1202% {
    transform: translate(-202.95px, -193.05px) rotate(99deg);
    opacity: 1.086141708;
  }

  51.1287% {
    transform: translate(-231.3px, -174.15px) rotate(99deg);
    opacity: 1.086141708;
  }

  57.3654% {
    transform: translate(-261px, -144px) rotate(99deg);
    opacity: 1.086141708;
  }

  65.1344% {
    transform: translate(-292.275px, -101.25px) rotate(99deg);
    opacity: 1.086141708;
  }

  74.6872% {
    transform: translate(-324.675px, -45px) rotate(99deg);
    opacity: 1.086141708;
  }

  86.2425% {
    transform: translate(-358.65px, 25.875px) rotate(99deg);
    opacity: 0.543070854;
  }

  100.0000% {
    transform: translate(-393.75px, 112.5px) rotate(99deg);
    opacity: 0;
  }
}

.confetti-left-1,
.confetti-right-1 {
  width: 8px;
  height: 3.2px;
  background-color: #00a4ff;
  top: calc(25% - 8px);
  left: calc(50% - 2.4px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-1 {
  animation: drop-left-1 1.1935766345s 1.0450545438s forwards;
}

.confetti-right-1 {
  animation: drop-right-1 1.4182848818s 0.7289957214s forwards;
}

@keyframes drop-left-1 {
  0.0000% {
    transform: translate(0px, 0px) rotate(330deg);
    opacity: 1.4714375911;
  }

  10.7882% {
    transform: translate(9.45px, -22.5px) rotate(330deg);
    opacity: 1.4714375911;
  }

  21.2266% {
    transform: translate(21px, -43.2px) rotate(330deg);
    opacity: 1.4714375911;
  }

  30.4208% {
    transform: translate(34.575px, -59.025px) rotate(330deg);
    opacity: 1.4714375911;
  }

  34.4519% {
    transform: translate(42.15px, -64.2px) rotate(330deg);
    opacity: 1.4714375911;
  }

  38.2085% {
    transform: translate(50.175px, -67.05px) rotate(330deg);
    opacity: 1.4714375911;
  }

  41.9607% {
    transform: translate(58.65px, -67.2px) rotate(330deg);
    opacity: 1.4714375911;
  }

  46.1202% {
    transform: translate(67.65px, -64.35px) rotate(330deg);
    opacity: 1.4714375911;
  }

  51.1287% {
    transform: translate(77.1px, -58.05px) rotate(330deg);
    opacity: 1.4714375911;
  }

  57.3654% {
    transform: translate(87px, -48px) rotate(330deg);
    opacity: 1.4714375911;
  }

  65.1344% {
    transform: translate(97.425px, -33.75px) rotate(330deg);
    opacity: 1.4714375911;
  }

  74.6872% {
    transform: translate(108.225px, -15px) rotate(330deg);
    opacity: 1.4714375911;
  }

  86.2425% {
    transform: translate(119.55px, 8.625px) rotate(330deg);
    opacity: 0.7357187956;
  }

  100.0000% {
    transform: translate(131.25px, 37.5px) rotate(330deg);
    opacity: 0;
  }
}

@keyframes drop-right-1 {
  0.0000% {
    transform: translate(0px, 0px) rotate(243deg);
    opacity: 1.2018215724;
  }

  10.7882% {
    transform: translate(-3.15px, -7.5px) rotate(243deg);
    opacity: 1.2018215724;
  }

  21.2266% {
    transform: translate(-7px, -14.4px) rotate(243deg);
    opacity: 1.2018215724;
  }

  30.4208% {
    transform: translate(-11.525px, -19.675px) rotate(243deg);
    opacity: 1.2018215724;
  }

  34.4519% {
    transform: translate(-14.05px, -21.4px) rotate(243deg);
    opacity: 1.2018215724;
  }

  38.2085% {
    transform: translate(-16.725px, -22.35px) rotate(243deg);
    opacity: 1.2018215724;
  }

  41.9607% {
    transform: translate(-19.55px, -22.4px) rotate(243deg);
    opacity: 1.2018215724;
  }

  46.1202% {
    transform: translate(-22.55px, -21.45px) rotate(243deg);
    opacity: 1.2018215724;
  }

  51.1287% {
    transform: translate(-25.7px, -19.35px) rotate(243deg);
    opacity: 1.2018215724;
  }

  57.3654% {
    transform: translate(-29px, -16px) rotate(243deg);
    opacity: 1.2018215724;
  }

  65.1344% {
    transform: translate(-32.475px, -11.25px) rotate(243deg);
    opacity: 1.2018215724;
  }

  74.6872% {
    transform: translate(-36.075px, -5px) rotate(243deg);
    opacity: 1.2018215724;
  }

  86.2425% {
    transform: translate(-39.85px, 2.875px) rotate(243deg);
    opacity: 0.6009107862;
  }

  100.0000% {
    transform: translate(-43.75px, 12.5px) rotate(243deg);
    opacity: 0;
  }
}

.confetti-left-2,
.confetti-right-2 {
  width: 6.6px;
  height: 6.6px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #00a4ff;
  top: calc(25% - 11px);
  left: calc(50% - 3.3px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-2 {
  animation: drop-left-2 1.6482888454s 0.5073834181s forwards;
}

.confetti-right-2 {
  animation: drop-right-2 1.55453308s 0.4722463551s forwards;
}

@keyframes drop-left-2 {
  0.0000% {
    transform: translate(0px, 0px) rotate(130deg);
    opacity: 1.2595750672;
  }

  10.7882% {
    transform: translate(28.35px, -67.5px) rotate(130deg);
    opacity: 1.2595750672;
  }

  21.2266% {
    transform: translate(63px, -129.6px) rotate(130deg);
    opacity: 1.2595750672;
  }

  30.4208% {
    transform: translate(103.725px, -177.075px) rotate(130deg);
    opacity: 1.2595750672;
  }

  34.4519% {
    transform: translate(126.45px, -192.6px) rotate(130deg);
    opacity: 1.2595750672;
  }

  38.2085% {
    transform: translate(150.525px, -201.15px) rotate(130deg);
    opacity: 1.2595750672;
  }

  41.9607% {
    transform: translate(175.95px, -201.6px) rotate(130deg);
    opacity: 1.2595750672;
  }

  46.1202% {
    transform: translate(202.95px, -193.05px) rotate(130deg);
    opacity: 1.2595750672;
  }

  51.1287% {
    transform: translate(231.3px, -174.15px) rotate(130deg);
    opacity: 1.2595750672;
  }

  57.3654% {
    transform: translate(261px, -144px) rotate(130deg);
    opacity: 1.2595750672;
  }

  65.1344% {
    transform: translate(292.275px, -101.25px) rotate(130deg);
    opacity: 1.2595750672;
  }

  74.6872% {
    transform: translate(324.675px, -45px) rotate(130deg);
    opacity: 1.2595750672;
  }

  86.2425% {
    transform: translate(358.65px, 25.875px) rotate(130deg);
    opacity: 0.6297875336;
  }

  100.0000% {
    transform: translate(393.75px, 112.5px) rotate(130deg);
    opacity: 0;
  }
}

@keyframes drop-right-2 {
  0.0000% {
    transform: translate(0px, 0px) rotate(196deg);
    opacity: 0.524429232;
  }

  10.7882% {
    transform: translate(-25.2px, -60px) rotate(196deg);
    opacity: 0.524429232;
  }

  21.2266% {
    transform: translate(-56px, -115.2px) rotate(196deg);
    opacity: 0.524429232;
  }

  30.4208% {
    transform: translate(-92.2px, -157.4px) rotate(196deg);
    opacity: 0.524429232;
  }

  34.4519% {
    transform: translate(-112.4px, -171.2px) rotate(196deg);
    opacity: 0.524429232;
  }

  38.2085% {
    transform: translate(-133.8px, -178.8px) rotate(196deg);
    opacity: 0.524429232;
  }

  41.9607% {
    transform: translate(-156.4px, -179.2px) rotate(196deg);
    opacity: 0.524429232;
  }

  46.1202% {
    transform: translate(-180.4px, -171.6px) rotate(196deg);
    opacity: 0.524429232;
  }

  51.1287% {
    transform: translate(-205.6px, -154.8px) rotate(196deg);
    opacity: 0.524429232;
  }

  57.3654% {
    transform: translate(-232px, -128px) rotate(196deg);
    opacity: 0.524429232;
  }

  65.1344% {
    transform: translate(-259.8px, -90px) rotate(196deg);
    opacity: 0.524429232;
  }

  74.6872% {
    transform: translate(-288.6px, -40px) rotate(196deg);
    opacity: 0.524429232;
  }

  86.2425% {
    transform: translate(-318.8px, 23px) rotate(196deg);
    opacity: 0.262214616;
  }

  100.0000% {
    transform: translate(-350px, 100px) rotate(196deg);
    opacity: 0;
  }
}

.confetti-left-3,
.confetti-right-3 {
  width: 4.2px;
  height: 4.2px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #00a4ff;
  top: calc(25% - 7px);
  left: calc(50% - 2.1px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-3 {
  animation: drop-left-3 1.2649452436s 0.9145400375s forwards;
}

.confetti-right-3 {
  animation: drop-right-3 1.0581707731s 1.4384837152s forwards;
}

@keyframes drop-left-3 {
  0.0000% {
    transform: translate(0px, 0px) rotate(158deg);
    opacity: 1.4674158008;
  }

  10.7882% {
    transform: translate(15.75px, -37.5px) rotate(158deg);
    opacity: 1.4674158008;
  }

  21.2266% {
    transform: translate(35px, -72px) rotate(158deg);
    opacity: 1.4674158008;
  }

  30.4208% {
    transform: translate(57.625px, -98.375px) rotate(158deg);
    opacity: 1.4674158008;
  }

  34.4519% {
    transform: translate(70.25px, -107px) rotate(158deg);
    opacity: 1.4674158008;
  }

  38.2085% {
    transform: translate(83.625px, -111.75px) rotate(158deg);
    opacity: 1.4674158008;
  }

  41.9607% {
    transform: translate(97.75px, -112px) rotate(158deg);
    opacity: 1.4674158008;
  }

  46.1202% {
    transform: translate(112.75px, -107.25px) rotate(158deg);
    opacity: 1.4674158008;
  }

  51.1287% {
    transform: translate(128.5px, -96.75px) rotate(158deg);
    opacity: 1.4674158008;
  }

  57.3654% {
    transform: translate(145px, -80px) rotate(158deg);
    opacity: 1.4674158008;
  }

  65.1344% {
    transform: translate(162.375px, -56.25px) rotate(158deg);
    opacity: 1.4674158008;
  }

  74.6872% {
    transform: translate(180.375px, -25px) rotate(158deg);
    opacity: 1.4674158008;
  }

  86.2425% {
    transform: translate(199.25px, 14.375px) rotate(158deg);
    opacity: 0.7337079004;
  }

  100.0000% {
    transform: translate(218.75px, 62.5px) rotate(158deg);
    opacity: 0;
  }
}

@keyframes drop-right-3 {
  0.0000% {
    transform: translate(0px, 0px) rotate(255deg);
    opacity: 0.8975112263;
  }

  10.7882% {
    transform: translate(-3.15px, -7.5px) rotate(255deg);
    opacity: 0.8975112263;
  }

  21.2266% {
    transform: translate(-7px, -14.4px) rotate(255deg);
    opacity: 0.8975112263;
  }

  30.4208% {
    transform: translate(-11.525px, -19.675px) rotate(255deg);
    opacity: 0.8975112263;
  }

  34.4519% {
    transform: translate(-14.05px, -21.4px) rotate(255deg);
    opacity: 0.8975112263;
  }

  38.2085% {
    transform: translate(-16.725px, -22.35px) rotate(255deg);
    opacity: 0.8975112263;
  }

  41.9607% {
    transform: translate(-19.55px, -22.4px) rotate(255deg);
    opacity: 0.8975112263;
  }

  46.1202% {
    transform: translate(-22.55px, -21.45px) rotate(255deg);
    opacity: 0.8975112263;
  }

  51.1287% {
    transform: translate(-25.7px, -19.35px) rotate(255deg);
    opacity: 0.8975112263;
  }

  57.3654% {
    transform: translate(-29px, -16px) rotate(255deg);
    opacity: 0.8975112263;
  }

  65.1344% {
    transform: translate(-32.475px, -11.25px) rotate(255deg);
    opacity: 0.8975112263;
  }

  74.6872% {
    transform: translate(-36.075px, -5px) rotate(255deg);
    opacity: 0.8975112263;
  }

  86.2425% {
    transform: translate(-39.85px, 2.875px) rotate(255deg);
    opacity: 0.4487556131;
  }

  100.0000% {
    transform: translate(-43.75px, 12.5px) rotate(255deg);
    opacity: 0;
  }
}

.confetti-left-4,
.confetti-right-4 {
  background-color: transparent;
  border-bottom: 4.5px solid #f04813;
  border-left: 2.25px solid transparent;
  border-right: 2.25px solid transparent;
  height: 0;
  width: 4.5px;
  top: calc(25% - 9px);
  left: calc(50% - 2.7px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-4 {
  animation: drop-left-4 1.2681012878s 0.7987682262s forwards;
}

.confetti-right-4 {
  animation: drop-right-4 1.5182764109s 0.4615739856s forwards;
}

@keyframes drop-left-4 {
  0.0000% {
    transform: translate(0px, 0px) rotate(268deg);
    opacity: 1.447013268;
  }

  10.7882% {
    transform: translate(12.6px, -30px) rotate(268deg);
    opacity: 1.447013268;
  }

  21.2266% {
    transform: translate(28px, -57.6px) rotate(268deg);
    opacity: 1.447013268;
  }

  30.4208% {
    transform: translate(46.1px, -78.7px) rotate(268deg);
    opacity: 1.447013268;
  }

  34.4519% {
    transform: translate(56.2px, -85.6px) rotate(268deg);
    opacity: 1.447013268;
  }

  38.2085% {
    transform: translate(66.9px, -89.4px) rotate(268deg);
    opacity: 1.447013268;
  }

  41.9607% {
    transform: translate(78.2px, -89.6px) rotate(268deg);
    opacity: 1.447013268;
  }

  46.1202% {
    transform: translate(90.2px, -85.8px) rotate(268deg);
    opacity: 1.447013268;
  }

  51.1287% {
    transform: translate(102.8px, -77.4px) rotate(268deg);
    opacity: 1.447013268;
  }

  57.3654% {
    transform: translate(116px, -64px) rotate(268deg);
    opacity: 1.447013268;
  }

  65.1344% {
    transform: translate(129.9px, -45px) rotate(268deg);
    opacity: 1.447013268;
  }

  74.6872% {
    transform: translate(144.3px, -20px) rotate(268deg);
    opacity: 1.447013268;
  }

  86.2425% {
    transform: translate(159.4px, 11.5px) rotate(268deg);
    opacity: 0.723506634;
  }

  100.0000% {
    transform: translate(175px, 50px) rotate(268deg);
    opacity: 0;
  }
}

@keyframes drop-right-4 {
  0.0000% {
    transform: translate(0px, 0px) rotate(305deg);
    opacity: 1.3690598771;
  }

  10.7882% {
    transform: translate(-22.05px, -52.5px) rotate(305deg);
    opacity: 1.3690598771;
  }

  21.2266% {
    transform: translate(-49px, -100.8px) rotate(305deg);
    opacity: 1.3690598771;
  }

  30.4208% {
    transform: translate(-80.675px, -137.725px) rotate(305deg);
    opacity: 1.3690598771;
  }

  34.4519% {
    transform: translate(-98.35px, -149.8px) rotate(305deg);
    opacity: 1.3690598771;
  }

  38.2085% {
    transform: translate(-117.075px, -156.45px) rotate(305deg);
    opacity: 1.3690598771;
  }

  41.9607% {
    transform: translate(-136.85px, -156.8px) rotate(305deg);
    opacity: 1.3690598771;
  }

  46.1202% {
    transform: translate(-157.85px, -150.15px) rotate(305deg);
    opacity: 1.3690598771;
  }

  51.1287% {
    transform: translate(-179.9px, -135.45px) rotate(305deg);
    opacity: 1.3690598771;
  }

  57.3654% {
    transform: translate(-203px, -112px) rotate(305deg);
    opacity: 1.3690598771;
  }

  65.1344% {
    transform: translate(-227.325px, -78.75px) rotate(305deg);
    opacity: 1.3690598771;
  }

  74.6872% {
    transform: translate(-252.525px, -35px) rotate(305deg);
    opacity: 1.3690598771;
  }

  86.2425% {
    transform: translate(-278.95px, 20.125px) rotate(305deg);
    opacity: 0.6845299385;
  }

  100.0000% {
    transform: translate(-306.25px, 87.5px) rotate(305deg);
    opacity: 0;
  }
}

.confetti-left-5,
.confetti-right-5 {
  width: 5.4px;
  height: 5.4px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f04813;
  top: calc(25% - 9px);
  left: calc(50% - 2.7px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-5 {
  animation: drop-left-5 1.8192885946s 0.4720384011s forwards;
}

.confetti-right-5 {
  animation: drop-right-5 1.3636851455s 1.3451349038s forwards;
}

@keyframes drop-left-5 {
  0.0000% {
    transform: translate(0px, 0px) rotate(105deg);
    opacity: 0.5176258971;
  }

  10.7882% {
    transform: translate(31.5px, -75px) rotate(105deg);
    opacity: 0.5176258971;
  }

  21.2266% {
    transform: translate(70px, -144px) rotate(105deg);
    opacity: 0.5176258971;
  }

  30.4208% {
    transform: translate(115.25px, -196.75px) rotate(105deg);
    opacity: 0.5176258971;
  }

  34.4519% {
    transform: translate(140.5px, -214px) rotate(105deg);
    opacity: 0.5176258971;
  }

  38.2085% {
    transform: translate(167.25px, -223.5px) rotate(105deg);
    opacity: 0.5176258971;
  }

  41.9607% {
    transform: translate(195.5px, -224px) rotate(105deg);
    opacity: 0.5176258971;
  }

  46.1202% {
    transform: translate(225.5px, -214.5px) rotate(105deg);
    opacity: 0.5176258971;
  }

  51.1287% {
    transform: translate(257px, -193.5px) rotate(105deg);
    opacity: 0.5176258971;
  }

  57.3654% {
    transform: translate(290px, -160px) rotate(105deg);
    opacity: 0.5176258971;
  }

  65.1344% {
    transform: translate(324.75px, -112.5px) rotate(105deg);
    opacity: 0.5176258971;
  }

  74.6872% {
    transform: translate(360.75px, -50px) rotate(105deg);
    opacity: 0.5176258971;
  }

  86.2425% {
    transform: translate(398.5px, 28.75px) rotate(105deg);
    opacity: 0.2588129485;
  }

  100.0000% {
    transform: translate(437.5px, 125px) rotate(105deg);
    opacity: 0;
  }
}

@keyframes drop-right-5 {
  0.0000% {
    transform: translate(0px, 0px) rotate(140deg);
    opacity: 0.6538170215;
  }

  10.7882% {
    transform: translate(-31.5px, -75px) rotate(140deg);
    opacity: 0.6538170215;
  }

  21.2266% {
    transform: translate(-70px, -144px) rotate(140deg);
    opacity: 0.6538170215;
  }

  30.4208% {
    transform: translate(-115.25px, -196.75px) rotate(140deg);
    opacity: 0.6538170215;
  }

  34.4519% {
    transform: translate(-140.5px, -214px) rotate(140deg);
    opacity: 0.6538170215;
  }

  38.2085% {
    transform: translate(-167.25px, -223.5px) rotate(140deg);
    opacity: 0.6538170215;
  }

  41.9607% {
    transform: translate(-195.5px, -224px) rotate(140deg);
    opacity: 0.6538170215;
  }

  46.1202% {
    transform: translate(-225.5px, -214.5px) rotate(140deg);
    opacity: 0.6538170215;
  }

  51.1287% {
    transform: translate(-257px, -193.5px) rotate(140deg);
    opacity: 0.6538170215;
  }

  57.3654% {
    transform: translate(-290px, -160px) rotate(140deg);
    opacity: 0.6538170215;
  }

  65.1344% {
    transform: translate(-324.75px, -112.5px) rotate(140deg);
    opacity: 0.6538170215;
  }

  74.6872% {
    transform: translate(-360.75px, -50px) rotate(140deg);
    opacity: 0.6538170215;
  }

  86.2425% {
    transform: translate(-398.5px, 28.75px) rotate(140deg);
    opacity: 0.3269085107;
  }

  100.0000% {
    transform: translate(-437.5px, 125px) rotate(140deg);
    opacity: 0;
  }
}

.confetti-left-6,
.confetti-right-6 {
  width: 4px;
  height: 1.6px;
  background-color: #03a561;
  top: calc(25% - 4px);
  left: calc(50% - 1.2px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-6 {
  animation: drop-left-6 1.1466706173s 0.8039901064s forwards;
}

.confetti-right-6 {
  animation: drop-right-6 1.8269870502s 1.0594112383s forwards;
}

@keyframes drop-left-6 {
  0.0000% {
    transform: translate(0px, 0px) rotate(249deg);
    opacity: 0.8956009095;
  }

  10.7882% {
    transform: translate(6.3px, -15px) rotate(249deg);
    opacity: 0.8956009095;
  }

  21.2266% {
    transform: translate(14px, -28.8px) rotate(249deg);
    opacity: 0.8956009095;
  }

  30.4208% {
    transform: translate(23.05px, -39.35px) rotate(249deg);
    opacity: 0.8956009095;
  }

  34.4519% {
    transform: translate(28.1px, -42.8px) rotate(249deg);
    opacity: 0.8956009095;
  }

  38.2085% {
    transform: translate(33.45px, -44.7px) rotate(249deg);
    opacity: 0.8956009095;
  }

  41.9607% {
    transform: translate(39.1px, -44.8px) rotate(249deg);
    opacity: 0.8956009095;
  }

  46.1202% {
    transform: translate(45.1px, -42.9px) rotate(249deg);
    opacity: 0.8956009095;
  }

  51.1287% {
    transform: translate(51.4px, -38.7px) rotate(249deg);
    opacity: 0.8956009095;
  }

  57.3654% {
    transform: translate(58px, -32px) rotate(249deg);
    opacity: 0.8956009095;
  }

  65.1344% {
    transform: translate(64.95px, -22.5px) rotate(249deg);
    opacity: 0.8956009095;
  }

  74.6872% {
    transform: translate(72.15px, -10px) rotate(249deg);
    opacity: 0.8956009095;
  }

  86.2425% {
    transform: translate(79.7px, 5.75px) rotate(249deg);
    opacity: 0.4478004548;
  }

  100.0000% {
    transform: translate(87.5px, 25px) rotate(249deg);
    opacity: 0;
  }
}

@keyframes drop-right-6 {
  0.0000% {
    transform: translate(0px, 0px) rotate(52deg);
    opacity: 0.8148412891;
  }

  10.7882% {
    transform: translate(-12.6px, -30px) rotate(52deg);
    opacity: 0.8148412891;
  }

  21.2266% {
    transform: translate(-28px, -57.6px) rotate(52deg);
    opacity: 0.8148412891;
  }

  30.4208% {
    transform: translate(-46.1px, -78.7px) rotate(52deg);
    opacity: 0.8148412891;
  }

  34.4519% {
    transform: translate(-56.2px, -85.6px) rotate(52deg);
    opacity: 0.8148412891;
  }

  38.2085% {
    transform: translate(-66.9px, -89.4px) rotate(52deg);
    opacity: 0.8148412891;
  }

  41.9607% {
    transform: translate(-78.2px, -89.6px) rotate(52deg);
    opacity: 0.8148412891;
  }

  46.1202% {
    transform: translate(-90.2px, -85.8px) rotate(52deg);
    opacity: 0.8148412891;
  }

  51.1287% {
    transform: translate(-102.8px, -77.4px) rotate(52deg);
    opacity: 0.8148412891;
  }

  57.3654% {
    transform: translate(-116px, -64px) rotate(52deg);
    opacity: 0.8148412891;
  }

  65.1344% {
    transform: translate(-129.9px, -45px) rotate(52deg);
    opacity: 0.8148412891;
  }

  74.6872% {
    transform: translate(-144.3px, -20px) rotate(52deg);
    opacity: 0.8148412891;
  }

  86.2425% {
    transform: translate(-159.4px, 11.5px) rotate(52deg);
    opacity: 0.4074206446;
  }

  100.0000% {
    transform: translate(-175px, 50px) rotate(52deg);
    opacity: 0;
  }
}

.confetti-left-7,
.confetti-right-7 {
  width: 1.8px;
  height: 1.8px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f04813;
  top: calc(25% - 3px);
  left: calc(50% - 0.9px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-7 {
  animation: drop-left-7 1.6126176204s 0.5109913293s forwards;
}

.confetti-right-7 {
  animation: drop-right-7 1.732012963s 0.9709397071s forwards;
}

@keyframes drop-left-7 {
  0.0000% {
    transform: translate(0px, 0px) rotate(240deg);
    opacity: 1.4883760686;
  }

  10.7882% {
    transform: translate(6.3px, -15px) rotate(240deg);
    opacity: 1.4883760686;
  }

  21.2266% {
    transform: translate(14px, -28.8px) rotate(240deg);
    opacity: 1.4883760686;
  }

  30.4208% {
    transform: translate(23.05px, -39.35px) rotate(240deg);
    opacity: 1.4883760686;
  }

  34.4519% {
    transform: translate(28.1px, -42.8px) rotate(240deg);
    opacity: 1.4883760686;
  }

  38.2085% {
    transform: translate(33.45px, -44.7px) rotate(240deg);
    opacity: 1.4883760686;
  }

  41.9607% {
    transform: translate(39.1px, -44.8px) rotate(240deg);
    opacity: 1.4883760686;
  }

  46.1202% {
    transform: translate(45.1px, -42.9px) rotate(240deg);
    opacity: 1.4883760686;
  }

  51.1287% {
    transform: translate(51.4px, -38.7px) rotate(240deg);
    opacity: 1.4883760686;
  }

  57.3654% {
    transform: translate(58px, -32px) rotate(240deg);
    opacity: 1.4883760686;
  }

  65.1344% {
    transform: translate(64.95px, -22.5px) rotate(240deg);
    opacity: 1.4883760686;
  }

  74.6872% {
    transform: translate(72.15px, -10px) rotate(240deg);
    opacity: 1.4883760686;
  }

  86.2425% {
    transform: translate(79.7px, 5.75px) rotate(240deg);
    opacity: 0.7441880343;
  }

  100.0000% {
    transform: translate(87.5px, 25px) rotate(240deg);
    opacity: 0;
  }
}

@keyframes drop-right-7 {
  0.0000% {
    transform: translate(0px, 0px) rotate(9deg);
    opacity: 0.7304513044;
  }

  10.7882% {
    transform: translate(-18.9px, -45px) rotate(9deg);
    opacity: 0.7304513044;
  }

  21.2266% {
    transform: translate(-42px, -86.4px) rotate(9deg);
    opacity: 0.7304513044;
  }

  30.4208% {
    transform: translate(-69.15px, -118.05px) rotate(9deg);
    opacity: 0.7304513044;
  }

  34.4519% {
    transform: translate(-84.3px, -128.4px) rotate(9deg);
    opacity: 0.7304513044;
  }

  38.2085% {
    transform: translate(-100.35px, -134.1px) rotate(9deg);
    opacity: 0.7304513044;
  }

  41.9607% {
    transform: translate(-117.3px, -134.4px) rotate(9deg);
    opacity: 0.7304513044;
  }

  46.1202% {
    transform: translate(-135.3px, -128.7px) rotate(9deg);
    opacity: 0.7304513044;
  }

  51.1287% {
    transform: translate(-154.2px, -116.1px) rotate(9deg);
    opacity: 0.7304513044;
  }

  57.3654% {
    transform: translate(-174px, -96px) rotate(9deg);
    opacity: 0.7304513044;
  }

  65.1344% {
    transform: translate(-194.85px, -67.5px) rotate(9deg);
    opacity: 0.7304513044;
  }

  74.6872% {
    transform: translate(-216.45px, -30px) rotate(9deg);
    opacity: 0.7304513044;
  }

  86.2425% {
    transform: translate(-239.1px, 17.25px) rotate(9deg);
    opacity: 0.3652256522;
  }

  100.0000% {
    transform: translate(-262.5px, 75px) rotate(9deg);
    opacity: 0;
  }
}

.confetti-left-8,
.confetti-right-8 {
  width: 15px;
  height: 6px;
  background-color: #03a561;
  top: calc(25% - 15px);
  left: calc(50% - 4.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-8 {
  animation: drop-left-8 1.3450232461s 0.7516431609s forwards;
}

.confetti-right-8 {
  animation: drop-right-8 1.3626158214s 0.5739916629s forwards;
}

@keyframes drop-left-8 {
  0.0000% {
    transform: translate(0px, 0px) rotate(353deg);
    opacity: 1.191105146;
  }

  10.7882% {
    transform: translate(12.6px, -30px) rotate(353deg);
    opacity: 1.191105146;
  }

  21.2266% {
    transform: translate(28px, -57.6px) rotate(353deg);
    opacity: 1.191105146;
  }

  30.4208% {
    transform: translate(46.1px, -78.7px) rotate(353deg);
    opacity: 1.191105146;
  }

  34.4519% {
    transform: translate(56.2px, -85.6px) rotate(353deg);
    opacity: 1.191105146;
  }

  38.2085% {
    transform: translate(66.9px, -89.4px) rotate(353deg);
    opacity: 1.191105146;
  }

  41.9607% {
    transform: translate(78.2px, -89.6px) rotate(353deg);
    opacity: 1.191105146;
  }

  46.1202% {
    transform: translate(90.2px, -85.8px) rotate(353deg);
    opacity: 1.191105146;
  }

  51.1287% {
    transform: translate(102.8px, -77.4px) rotate(353deg);
    opacity: 1.191105146;
  }

  57.3654% {
    transform: translate(116px, -64px) rotate(353deg);
    opacity: 1.191105146;
  }

  65.1344% {
    transform: translate(129.9px, -45px) rotate(353deg);
    opacity: 1.191105146;
  }

  74.6872% {
    transform: translate(144.3px, -20px) rotate(353deg);
    opacity: 1.191105146;
  }

  86.2425% {
    transform: translate(159.4px, 11.5px) rotate(353deg);
    opacity: 0.595552573;
  }

  100.0000% {
    transform: translate(175px, 50px) rotate(353deg);
    opacity: 0;
  }
}

@keyframes drop-right-8 {
  0.0000% {
    transform: translate(0px, 0px) rotate(198deg);
    opacity: 0.875827006;
  }

  10.7882% {
    transform: translate(-18.9px, -45px) rotate(198deg);
    opacity: 0.875827006;
  }

  21.2266% {
    transform: translate(-42px, -86.4px) rotate(198deg);
    opacity: 0.875827006;
  }

  30.4208% {
    transform: translate(-69.15px, -118.05px) rotate(198deg);
    opacity: 0.875827006;
  }

  34.4519% {
    transform: translate(-84.3px, -128.4px) rotate(198deg);
    opacity: 0.875827006;
  }

  38.2085% {
    transform: translate(-100.35px, -134.1px) rotate(198deg);
    opacity: 0.875827006;
  }

  41.9607% {
    transform: translate(-117.3px, -134.4px) rotate(198deg);
    opacity: 0.875827006;
  }

  46.1202% {
    transform: translate(-135.3px, -128.7px) rotate(198deg);
    opacity: 0.875827006;
  }

  51.1287% {
    transform: translate(-154.2px, -116.1px) rotate(198deg);
    opacity: 0.875827006;
  }

  57.3654% {
    transform: translate(-174px, -96px) rotate(198deg);
    opacity: 0.875827006;
  }

  65.1344% {
    transform: translate(-194.85px, -67.5px) rotate(198deg);
    opacity: 0.875827006;
  }

  74.6872% {
    transform: translate(-216.45px, -30px) rotate(198deg);
    opacity: 0.875827006;
  }

  86.2425% {
    transform: translate(-239.1px, 17.25px) rotate(198deg);
    opacity: 0.437913503;
  }

  100.0000% {
    transform: translate(-262.5px, 75px) rotate(198deg);
    opacity: 0;
  }
}

.confetti-left-9,
.confetti-right-9 {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #03a561;
  top: calc(25% - 5px);
  left: calc(50% - 1.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-9 {
  animation: drop-left-9 1.2824245999s 0.7797859236s forwards;
}

.confetti-right-9 {
  animation: drop-right-9 1.2950357702s 1.3313903933s forwards;
}

@keyframes drop-left-9 {
  0.0000% {
    transform: translate(0px, 0px) rotate(115deg);
    opacity: 1.0168624997;
  }

  10.7882% {
    transform: translate(6.3px, -15px) rotate(115deg);
    opacity: 1.0168624997;
  }

  21.2266% {
    transform: translate(14px, -28.8px) rotate(115deg);
    opacity: 1.0168624997;
  }

  30.4208% {
    transform: translate(23.05px, -39.35px) rotate(115deg);
    opacity: 1.0168624997;
  }

  34.4519% {
    transform: translate(28.1px, -42.8px) rotate(115deg);
    opacity: 1.0168624997;
  }

  38.2085% {
    transform: translate(33.45px, -44.7px) rotate(115deg);
    opacity: 1.0168624997;
  }

  41.9607% {
    transform: translate(39.1px, -44.8px) rotate(115deg);
    opacity: 1.0168624997;
  }

  46.1202% {
    transform: translate(45.1px, -42.9px) rotate(115deg);
    opacity: 1.0168624997;
  }

  51.1287% {
    transform: translate(51.4px, -38.7px) rotate(115deg);
    opacity: 1.0168624997;
  }

  57.3654% {
    transform: translate(58px, -32px) rotate(115deg);
    opacity: 1.0168624997;
  }

  65.1344% {
    transform: translate(64.95px, -22.5px) rotate(115deg);
    opacity: 1.0168624997;
  }

  74.6872% {
    transform: translate(72.15px, -10px) rotate(115deg);
    opacity: 1.0168624997;
  }

  86.2425% {
    transform: translate(79.7px, 5.75px) rotate(115deg);
    opacity: 0.5084312499;
  }

  100.0000% {
    transform: translate(87.5px, 25px) rotate(115deg);
    opacity: 0;
  }
}

@keyframes drop-right-9 {
  0.0000% {
    transform: translate(0px, 0px) rotate(130deg);
    opacity: 0.8673118854;
  }

  10.7882% {
    transform: translate(-15.75px, -37.5px) rotate(130deg);
    opacity: 0.8673118854;
  }

  21.2266% {
    transform: translate(-35px, -72px) rotate(130deg);
    opacity: 0.8673118854;
  }

  30.4208% {
    transform: translate(-57.625px, -98.375px) rotate(130deg);
    opacity: 0.8673118854;
  }

  34.4519% {
    transform: translate(-70.25px, -107px) rotate(130deg);
    opacity: 0.8673118854;
  }

  38.2085% {
    transform: translate(-83.625px, -111.75px) rotate(130deg);
    opacity: 0.8673118854;
  }

  41.9607% {
    transform: translate(-97.75px, -112px) rotate(130deg);
    opacity: 0.8673118854;
  }

  46.1202% {
    transform: translate(-112.75px, -107.25px) rotate(130deg);
    opacity: 0.8673118854;
  }

  51.1287% {
    transform: translate(-128.5px, -96.75px) rotate(130deg);
    opacity: 0.8673118854;
  }

  57.3654% {
    transform: translate(-145px, -80px) rotate(130deg);
    opacity: 0.8673118854;
  }

  65.1344% {
    transform: translate(-162.375px, -56.25px) rotate(130deg);
    opacity: 0.8673118854;
  }

  74.6872% {
    transform: translate(-180.375px, -25px) rotate(130deg);
    opacity: 0.8673118854;
  }

  86.2425% {
    transform: translate(-199.25px, 14.375px) rotate(130deg);
    opacity: 0.4336559427;
  }

  100.0000% {
    transform: translate(-218.75px, 62.5px) rotate(130deg);
    opacity: 0;
  }
}

.confetti-left-10,
.confetti-right-10 {
  width: 7px;
  height: 2.8px;
  background-color: #f04813;
  top: calc(25% - 7px);
  left: calc(50% - 2.1px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-10 {
  animation: drop-left-10 1.8319390751s 1.0484788744s forwards;
}

.confetti-right-10 {
  animation: drop-right-10 1.5808628404s 0.8244348134s forwards;
}

@keyframes drop-left-10 {
  0.0000% {
    transform: translate(0px, 0px) rotate(56deg);
    opacity: 1.1839481077;
  }

  10.7882% {
    transform: translate(3.15px, -7.5px) rotate(56deg);
    opacity: 1.1839481077;
  }

  21.2266% {
    transform: translate(7px, -14.4px) rotate(56deg);
    opacity: 1.1839481077;
  }

  30.4208% {
    transform: translate(11.525px, -19.675px) rotate(56deg);
    opacity: 1.1839481077;
  }

  34.4519% {
    transform: translate(14.05px, -21.4px) rotate(56deg);
    opacity: 1.1839481077;
  }

  38.2085% {
    transform: translate(16.725px, -22.35px) rotate(56deg);
    opacity: 1.1839481077;
  }

  41.9607% {
    transform: translate(19.55px, -22.4px) rotate(56deg);
    opacity: 1.1839481077;
  }

  46.1202% {
    transform: translate(22.55px, -21.45px) rotate(56deg);
    opacity: 1.1839481077;
  }

  51.1287% {
    transform: translate(25.7px, -19.35px) rotate(56deg);
    opacity: 1.1839481077;
  }

  57.3654% {
    transform: translate(29px, -16px) rotate(56deg);
    opacity: 1.1839481077;
  }

  65.1344% {
    transform: translate(32.475px, -11.25px) rotate(56deg);
    opacity: 1.1839481077;
  }

  74.6872% {
    transform: translate(36.075px, -5px) rotate(56deg);
    opacity: 1.1839481077;
  }

  86.2425% {
    transform: translate(39.85px, 2.875px) rotate(56deg);
    opacity: 0.5919740538;
  }

  100.0000% {
    transform: translate(43.75px, 12.5px) rotate(56deg);
    opacity: 0;
  }
}

@keyframes drop-right-10 {
  0.0000% {
    transform: translate(0px, 0px) rotate(34deg);
    opacity: 0.868106351;
  }

  10.7882% {
    transform: translate(-22.05px, -52.5px) rotate(34deg);
    opacity: 0.868106351;
  }

  21.2266% {
    transform: translate(-49px, -100.8px) rotate(34deg);
    opacity: 0.868106351;
  }

  30.4208% {
    transform: translate(-80.675px, -137.725px) rotate(34deg);
    opacity: 0.868106351;
  }

  34.4519% {
    transform: translate(-98.35px, -149.8px) rotate(34deg);
    opacity: 0.868106351;
  }

  38.2085% {
    transform: translate(-117.075px, -156.45px) rotate(34deg);
    opacity: 0.868106351;
  }

  41.9607% {
    transform: translate(-136.85px, -156.8px) rotate(34deg);
    opacity: 0.868106351;
  }

  46.1202% {
    transform: translate(-157.85px, -150.15px) rotate(34deg);
    opacity: 0.868106351;
  }

  51.1287% {
    transform: translate(-179.9px, -135.45px) rotate(34deg);
    opacity: 0.868106351;
  }

  57.3654% {
    transform: translate(-203px, -112px) rotate(34deg);
    opacity: 0.868106351;
  }

  65.1344% {
    transform: translate(-227.325px, -78.75px) rotate(34deg);
    opacity: 0.868106351;
  }

  74.6872% {
    transform: translate(-252.525px, -35px) rotate(34deg);
    opacity: 0.868106351;
  }

  86.2425% {
    transform: translate(-278.95px, 20.125px) rotate(34deg);
    opacity: 0.4340531755;
  }

  100.0000% {
    transform: translate(-306.25px, 87.5px) rotate(34deg);
    opacity: 0;
  }
}

.confetti-left-11,
.confetti-right-11 {
  background-color: transparent;
  border-bottom: 7.5px solid #03a561;
  border-left: 3.75px solid transparent;
  border-right: 3.75px solid transparent;
  height: 0;
  width: 7.5px;
  top: calc(25% - 15px);
  left: calc(50% - 4.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-11 {
  animation: drop-left-11 1.7716566021s 0.6043223714s forwards;
}

.confetti-right-11 {
  animation: drop-right-11 1.5461598504s 1.2158756442s forwards;
}

@keyframes drop-left-11 {
  0.0000% {
    transform: translate(0px, 0px) rotate(25deg);
    opacity: 1.0701773442;
  }

  10.7882% {
    transform: translate(25.2px, -60px) rotate(25deg);
    opacity: 1.0701773442;
  }

  21.2266% {
    transform: translate(56px, -115.2px) rotate(25deg);
    opacity: 1.0701773442;
  }

  30.4208% {
    transform: translate(92.2px, -157.4px) rotate(25deg);
    opacity: 1.0701773442;
  }

  34.4519% {
    transform: translate(112.4px, -171.2px) rotate(25deg);
    opacity: 1.0701773442;
  }

  38.2085% {
    transform: translate(133.8px, -178.8px) rotate(25deg);
    opacity: 1.0701773442;
  }

  41.9607% {
    transform: translate(156.4px, -179.2px) rotate(25deg);
    opacity: 1.0701773442;
  }

  46.1202% {
    transform: translate(180.4px, -171.6px) rotate(25deg);
    opacity: 1.0701773442;
  }

  51.1287% {
    transform: translate(205.6px, -154.8px) rotate(25deg);
    opacity: 1.0701773442;
  }

  57.3654% {
    transform: translate(232px, -128px) rotate(25deg);
    opacity: 1.0701773442;
  }

  65.1344% {
    transform: translate(259.8px, -90px) rotate(25deg);
    opacity: 1.0701773442;
  }

  74.6872% {
    transform: translate(288.6px, -40px) rotate(25deg);
    opacity: 1.0701773442;
  }

  86.2425% {
    transform: translate(318.8px, 23px) rotate(25deg);
    opacity: 0.5350886721;
  }

  100.0000% {
    transform: translate(350px, 100px) rotate(25deg);
    opacity: 0;
  }
}

@keyframes drop-right-11 {
  0.0000% {
    transform: translate(0px, 0px) rotate(244deg);
    opacity: 0.752060452;
  }

  10.7882% {
    transform: translate(-25.2px, -60px) rotate(244deg);
    opacity: 0.752060452;
  }

  21.2266% {
    transform: translate(-56px, -115.2px) rotate(244deg);
    opacity: 0.752060452;
  }

  30.4208% {
    transform: translate(-92.2px, -157.4px) rotate(244deg);
    opacity: 0.752060452;
  }

  34.4519% {
    transform: translate(-112.4px, -171.2px) rotate(244deg);
    opacity: 0.752060452;
  }

  38.2085% {
    transform: translate(-133.8px, -178.8px) rotate(244deg);
    opacity: 0.752060452;
  }

  41.9607% {
    transform: translate(-156.4px, -179.2px) rotate(244deg);
    opacity: 0.752060452;
  }

  46.1202% {
    transform: translate(-180.4px, -171.6px) rotate(244deg);
    opacity: 0.752060452;
  }

  51.1287% {
    transform: translate(-205.6px, -154.8px) rotate(244deg);
    opacity: 0.752060452;
  }

  57.3654% {
    transform: translate(-232px, -128px) rotate(244deg);
    opacity: 0.752060452;
  }

  65.1344% {
    transform: translate(-259.8px, -90px) rotate(244deg);
    opacity: 0.752060452;
  }

  74.6872% {
    transform: translate(-288.6px, -40px) rotate(244deg);
    opacity: 0.752060452;
  }

  86.2425% {
    transform: translate(-318.8px, 23px) rotate(244deg);
    opacity: 0.376030226;
  }

  100.0000% {
    transform: translate(-350px, 100px) rotate(244deg);
    opacity: 0;
  }
}

.confetti-left-12,
.confetti-right-12 {
  width: 10px;
  height: 4px;
  background-color: #03a561;
  top: calc(25% - 10px);
  left: calc(50% - 3px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-12 {
  animation: drop-left-12 1.5127707413s 0.6381228421s forwards;
}

.confetti-right-12 {
  animation: drop-right-12 1.3059919632s 1.1477079984s forwards;
}

@keyframes drop-left-12 {
  0.0000% {
    transform: translate(0px, 0px) rotate(154deg);
    opacity: 0.7847697659;
  }

  10.7882% {
    transform: translate(25.2px, -60px) rotate(154deg);
    opacity: 0.7847697659;
  }

  21.2266% {
    transform: translate(56px, -115.2px) rotate(154deg);
    opacity: 0.7847697659;
  }

  30.4208% {
    transform: translate(92.2px, -157.4px) rotate(154deg);
    opacity: 0.7847697659;
  }

  34.4519% {
    transform: translate(112.4px, -171.2px) rotate(154deg);
    opacity: 0.7847697659;
  }

  38.2085% {
    transform: translate(133.8px, -178.8px) rotate(154deg);
    opacity: 0.7847697659;
  }

  41.9607% {
    transform: translate(156.4px, -179.2px) rotate(154deg);
    opacity: 0.7847697659;
  }

  46.1202% {
    transform: translate(180.4px, -171.6px) rotate(154deg);
    opacity: 0.7847697659;
  }

  51.1287% {
    transform: translate(205.6px, -154.8px) rotate(154deg);
    opacity: 0.7847697659;
  }

  57.3654% {
    transform: translate(232px, -128px) rotate(154deg);
    opacity: 0.7847697659;
  }

  65.1344% {
    transform: translate(259.8px, -90px) rotate(154deg);
    opacity: 0.7847697659;
  }

  74.6872% {
    transform: translate(288.6px, -40px) rotate(154deg);
    opacity: 0.7847697659;
  }

  86.2425% {
    transform: translate(318.8px, 23px) rotate(154deg);
    opacity: 0.392384883;
  }

  100.0000% {
    transform: translate(350px, 100px) rotate(154deg);
    opacity: 0;
  }
}

@keyframes drop-right-12 {
  0.0000% {
    transform: translate(0px, 0px) rotate(52deg);
    opacity: 1.0031523477;
  }

  10.7882% {
    transform: translate(-15.75px, -37.5px) rotate(52deg);
    opacity: 1.0031523477;
  }

  21.2266% {
    transform: translate(-35px, -72px) rotate(52deg);
    opacity: 1.0031523477;
  }

  30.4208% {
    transform: translate(-57.625px, -98.375px) rotate(52deg);
    opacity: 1.0031523477;
  }

  34.4519% {
    transform: translate(-70.25px, -107px) rotate(52deg);
    opacity: 1.0031523477;
  }

  38.2085% {
    transform: translate(-83.625px, -111.75px) rotate(52deg);
    opacity: 1.0031523477;
  }

  41.9607% {
    transform: translate(-97.75px, -112px) rotate(52deg);
    opacity: 1.0031523477;
  }

  46.1202% {
    transform: translate(-112.75px, -107.25px) rotate(52deg);
    opacity: 1.0031523477;
  }

  51.1287% {
    transform: translate(-128.5px, -96.75px) rotate(52deg);
    opacity: 1.0031523477;
  }

  57.3654% {
    transform: translate(-145px, -80px) rotate(52deg);
    opacity: 1.0031523477;
  }

  65.1344% {
    transform: translate(-162.375px, -56.25px) rotate(52deg);
    opacity: 1.0031523477;
  }

  74.6872% {
    transform: translate(-180.375px, -25px) rotate(52deg);
    opacity: 1.0031523477;
  }

  86.2425% {
    transform: translate(-199.25px, 14.375px) rotate(52deg);
    opacity: 0.5015761739;
  }

  100.0000% {
    transform: translate(-218.75px, 62.5px) rotate(52deg);
    opacity: 0;
  }
}

.confetti-left-13,
.confetti-right-13 {
  width: 1px;
  height: 0.4px;
  background-color: #f04813;
  top: calc(25% - 1px);
  left: calc(50% - 0.3px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-13 {
  animation: drop-left-13 1.377414188s 1.0466867883s forwards;
}

.confetti-right-13 {
  animation: drop-right-13 1.2834813899s 1.3331776841s forwards;
}

@keyframes drop-left-13 {
  0.0000% {
    transform: translate(0px, 0px) rotate(173deg);
    opacity: 1.1320108301;
  }

  10.7882% {
    transform: translate(25.2px, -60px) rotate(173deg);
    opacity: 1.1320108301;
  }

  21.2266% {
    transform: translate(56px, -115.2px) rotate(173deg);
    opacity: 1.1320108301;
  }

  30.4208% {
    transform: translate(92.2px, -157.4px) rotate(173deg);
    opacity: 1.1320108301;
  }

  34.4519% {
    transform: translate(112.4px, -171.2px) rotate(173deg);
    opacity: 1.1320108301;
  }

  38.2085% {
    transform: translate(133.8px, -178.8px) rotate(173deg);
    opacity: 1.1320108301;
  }

  41.9607% {
    transform: translate(156.4px, -179.2px) rotate(173deg);
    opacity: 1.1320108301;
  }

  46.1202% {
    transform: translate(180.4px, -171.6px) rotate(173deg);
    opacity: 1.1320108301;
  }

  51.1287% {
    transform: translate(205.6px, -154.8px) rotate(173deg);
    opacity: 1.1320108301;
  }

  57.3654% {
    transform: translate(232px, -128px) rotate(173deg);
    opacity: 1.1320108301;
  }

  65.1344% {
    transform: translate(259.8px, -90px) rotate(173deg);
    opacity: 1.1320108301;
  }

  74.6872% {
    transform: translate(288.6px, -40px) rotate(173deg);
    opacity: 1.1320108301;
  }

  86.2425% {
    transform: translate(318.8px, 23px) rotate(173deg);
    opacity: 0.566005415;
  }

  100.0000% {
    transform: translate(350px, 100px) rotate(173deg);
    opacity: 0;
  }
}

@keyframes drop-right-13 {
  0.0000% {
    transform: translate(0px, 0px) rotate(9deg);
    opacity: 1.4037553635;
  }

  10.7882% {
    transform: translate(-18.9px, -45px) rotate(9deg);
    opacity: 1.4037553635;
  }

  21.2266% {
    transform: translate(-42px, -86.4px) rotate(9deg);
    opacity: 1.4037553635;
  }

  30.4208% {
    transform: translate(-69.15px, -118.05px) rotate(9deg);
    opacity: 1.4037553635;
  }

  34.4519% {
    transform: translate(-84.3px, -128.4px) rotate(9deg);
    opacity: 1.4037553635;
  }

  38.2085% {
    transform: translate(-100.35px, -134.1px) rotate(9deg);
    opacity: 1.4037553635;
  }

  41.9607% {
    transform: translate(-117.3px, -134.4px) rotate(9deg);
    opacity: 1.4037553635;
  }

  46.1202% {
    transform: translate(-135.3px, -128.7px) rotate(9deg);
    opacity: 1.4037553635;
  }

  51.1287% {
    transform: translate(-154.2px, -116.1px) rotate(9deg);
    opacity: 1.4037553635;
  }

  57.3654% {
    transform: translate(-174px, -96px) rotate(9deg);
    opacity: 1.4037553635;
  }

  65.1344% {
    transform: translate(-194.85px, -67.5px) rotate(9deg);
    opacity: 1.4037553635;
  }

  74.6872% {
    transform: translate(-216.45px, -30px) rotate(9deg);
    opacity: 1.4037553635;
  }

  86.2425% {
    transform: translate(-239.1px, 17.25px) rotate(9deg);
    opacity: 0.7018776818;
  }

  100.0000% {
    transform: translate(-262.5px, 75px) rotate(9deg);
    opacity: 0;
  }
}

.confetti-left-14,
.confetti-right-14 {
  background-color: transparent;
  border-bottom: 9px solid #f04813;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  height: 0;
  width: 9px;
  top: calc(25% - 18px);
  left: calc(50% - 5.4px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-14 {
  animation: drop-left-14 1.2862209779s 1.4475366355s forwards;
}

.confetti-right-14 {
  animation: drop-right-14 1.7203513229s 1.1206132543s forwards;
}

@keyframes drop-left-14 {
  0.0000% {
    transform: translate(0px, 0px) rotate(132deg);
    opacity: 0.5933876613;
  }

  10.7882% {
    transform: translate(6.3px, -15px) rotate(132deg);
    opacity: 0.5933876613;
  }

  21.2266% {
    transform: translate(14px, -28.8px) rotate(132deg);
    opacity: 0.5933876613;
  }

  30.4208% {
    transform: translate(23.05px, -39.35px) rotate(132deg);
    opacity: 0.5933876613;
  }

  34.4519% {
    transform: translate(28.1px, -42.8px) rotate(132deg);
    opacity: 0.5933876613;
  }

  38.2085% {
    transform: translate(33.45px, -44.7px) rotate(132deg);
    opacity: 0.5933876613;
  }

  41.9607% {
    transform: translate(39.1px, -44.8px) rotate(132deg);
    opacity: 0.5933876613;
  }

  46.1202% {
    transform: translate(45.1px, -42.9px) rotate(132deg);
    opacity: 0.5933876613;
  }

  51.1287% {
    transform: translate(51.4px, -38.7px) rotate(132deg);
    opacity: 0.5933876613;
  }

  57.3654% {
    transform: translate(58px, -32px) rotate(132deg);
    opacity: 0.5933876613;
  }

  65.1344% {
    transform: translate(64.95px, -22.5px) rotate(132deg);
    opacity: 0.5933876613;
  }

  74.6872% {
    transform: translate(72.15px, -10px) rotate(132deg);
    opacity: 0.5933876613;
  }

  86.2425% {
    transform: translate(79.7px, 5.75px) rotate(132deg);
    opacity: 0.2966938306;
  }

  100.0000% {
    transform: translate(87.5px, 25px) rotate(132deg);
    opacity: 0;
  }
}

@keyframes drop-right-14 {
  0.0000% {
    transform: translate(0px, 0px) rotate(312deg);
    opacity: 0.5282030249;
  }

  10.7882% {
    transform: translate(-25.2px, -60px) rotate(312deg);
    opacity: 0.5282030249;
  }

  21.2266% {
    transform: translate(-56px, -115.2px) rotate(312deg);
    opacity: 0.5282030249;
  }

  30.4208% {
    transform: translate(-92.2px, -157.4px) rotate(312deg);
    opacity: 0.5282030249;
  }

  34.4519% {
    transform: translate(-112.4px, -171.2px) rotate(312deg);
    opacity: 0.5282030249;
  }

  38.2085% {
    transform: translate(-133.8px, -178.8px) rotate(312deg);
    opacity: 0.5282030249;
  }

  41.9607% {
    transform: translate(-156.4px, -179.2px) rotate(312deg);
    opacity: 0.5282030249;
  }

  46.1202% {
    transform: translate(-180.4px, -171.6px) rotate(312deg);
    opacity: 0.5282030249;
  }

  51.1287% {
    transform: translate(-205.6px, -154.8px) rotate(312deg);
    opacity: 0.5282030249;
  }

  57.3654% {
    transform: translate(-232px, -128px) rotate(312deg);
    opacity: 0.5282030249;
  }

  65.1344% {
    transform: translate(-259.8px, -90px) rotate(312deg);
    opacity: 0.5282030249;
  }

  74.6872% {
    transform: translate(-288.6px, -40px) rotate(312deg);
    opacity: 0.5282030249;
  }

  86.2425% {
    transform: translate(-318.8px, 23px) rotate(312deg);
    opacity: 0.2641015124;
  }

  100.0000% {
    transform: translate(-350px, 100px) rotate(312deg);
    opacity: 0;
  }
}

.confetti-left-15,
.confetti-right-15 {
  width: 1.2px;
  height: 1.2px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fed500;
  top: calc(25% - 2px);
  left: calc(50% - 0.6px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-15 {
  animation: drop-left-15 1.9868577547s 0.9584538366s forwards;
}

.confetti-right-15 {
  animation: drop-right-15 1.4598323331s 0.5129159311s forwards;
}

@keyframes drop-left-15 {
  0.0000% {
    transform: translate(0px, 0px) rotate(159deg);
    opacity: 0.7257788055;
  }

  10.7882% {
    transform: translate(31.5px, -75px) rotate(159deg);
    opacity: 0.7257788055;
  }

  21.2266% {
    transform: translate(70px, -144px) rotate(159deg);
    opacity: 0.7257788055;
  }

  30.4208% {
    transform: translate(115.25px, -196.75px) rotate(159deg);
    opacity: 0.7257788055;
  }

  34.4519% {
    transform: translate(140.5px, -214px) rotate(159deg);
    opacity: 0.7257788055;
  }

  38.2085% {
    transform: translate(167.25px, -223.5px) rotate(159deg);
    opacity: 0.7257788055;
  }

  41.9607% {
    transform: translate(195.5px, -224px) rotate(159deg);
    opacity: 0.7257788055;
  }

  46.1202% {
    transform: translate(225.5px, -214.5px) rotate(159deg);
    opacity: 0.7257788055;
  }

  51.1287% {
    transform: translate(257px, -193.5px) rotate(159deg);
    opacity: 0.7257788055;
  }

  57.3654% {
    transform: translate(290px, -160px) rotate(159deg);
    opacity: 0.7257788055;
  }

  65.1344% {
    transform: translate(324.75px, -112.5px) rotate(159deg);
    opacity: 0.7257788055;
  }

  74.6872% {
    transform: translate(360.75px, -50px) rotate(159deg);
    opacity: 0.7257788055;
  }

  86.2425% {
    transform: translate(398.5px, 28.75px) rotate(159deg);
    opacity: 0.3628894027;
  }

  100.0000% {
    transform: translate(437.5px, 125px) rotate(159deg);
    opacity: 0;
  }
}

@keyframes drop-right-15 {
  0.0000% {
    transform: translate(0px, 0px) rotate(149deg);
    opacity: 0.5430034395;
  }

  10.7882% {
    transform: translate(-22.05px, -52.5px) rotate(149deg);
    opacity: 0.5430034395;
  }

  21.2266% {
    transform: translate(-49px, -100.8px) rotate(149deg);
    opacity: 0.5430034395;
  }

  30.4208% {
    transform: translate(-80.675px, -137.725px) rotate(149deg);
    opacity: 0.5430034395;
  }

  34.4519% {
    transform: translate(-98.35px, -149.8px) rotate(149deg);
    opacity: 0.5430034395;
  }

  38.2085% {
    transform: translate(-117.075px, -156.45px) rotate(149deg);
    opacity: 0.5430034395;
  }

  41.9607% {
    transform: translate(-136.85px, -156.8px) rotate(149deg);
    opacity: 0.5430034395;
  }

  46.1202% {
    transform: translate(-157.85px, -150.15px) rotate(149deg);
    opacity: 0.5430034395;
  }

  51.1287% {
    transform: translate(-179.9px, -135.45px) rotate(149deg);
    opacity: 0.5430034395;
  }

  57.3654% {
    transform: translate(-203px, -112px) rotate(149deg);
    opacity: 0.5430034395;
  }

  65.1344% {
    transform: translate(-227.325px, -78.75px) rotate(149deg);
    opacity: 0.5430034395;
  }

  74.6872% {
    transform: translate(-252.525px, -35px) rotate(149deg);
    opacity: 0.5430034395;
  }

  86.2425% {
    transform: translate(-278.95px, 20.125px) rotate(149deg);
    opacity: 0.2715017197;
  }

  100.0000% {
    transform: translate(-306.25px, 87.5px) rotate(149deg);
    opacity: 0;
  }
}

.confetti-left-16,
.confetti-right-16 {
  background-color: transparent;
  border-bottom: 5.5px solid #f04813;
  border-left: 2.75px solid transparent;
  border-right: 2.75px solid transparent;
  height: 0;
  width: 5.5px;
  top: calc(25% - 11px);
  left: calc(50% - 3.3px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-16 {
  animation: drop-left-16 1.8001344811s 0.6163140799s forwards;
}

.confetti-right-16 {
  animation: drop-right-16 1.8008534912s 1.1134632125s forwards;
}

@keyframes drop-left-16 {
  0.0000% {
    transform: translate(0px, 0px) rotate(328deg);
    opacity: 1.4793144661;
  }

  10.7882% {
    transform: translate(9.45px, -22.5px) rotate(328deg);
    opacity: 1.4793144661;
  }

  21.2266% {
    transform: translate(21px, -43.2px) rotate(328deg);
    opacity: 1.4793144661;
  }

  30.4208% {
    transform: translate(34.575px, -59.025px) rotate(328deg);
    opacity: 1.4793144661;
  }

  34.4519% {
    transform: translate(42.15px, -64.2px) rotate(328deg);
    opacity: 1.4793144661;
  }

  38.2085% {
    transform: translate(50.175px, -67.05px) rotate(328deg);
    opacity: 1.4793144661;
  }

  41.9607% {
    transform: translate(58.65px, -67.2px) rotate(328deg);
    opacity: 1.4793144661;
  }

  46.1202% {
    transform: translate(67.65px, -64.35px) rotate(328deg);
    opacity: 1.4793144661;
  }

  51.1287% {
    transform: translate(77.1px, -58.05px) rotate(328deg);
    opacity: 1.4793144661;
  }

  57.3654% {
    transform: translate(87px, -48px) rotate(328deg);
    opacity: 1.4793144661;
  }

  65.1344% {
    transform: translate(97.425px, -33.75px) rotate(328deg);
    opacity: 1.4793144661;
  }

  74.6872% {
    transform: translate(108.225px, -15px) rotate(328deg);
    opacity: 1.4793144661;
  }

  86.2425% {
    transform: translate(119.55px, 8.625px) rotate(328deg);
    opacity: 0.7396572331;
  }

  100.0000% {
    transform: translate(131.25px, 37.5px) rotate(328deg);
    opacity: 0;
  }
}

@keyframes drop-right-16 {
  0.0000% {
    transform: translate(0px, 0px) rotate(222deg);
    opacity: 1.3895228121;
  }

  10.7882% {
    transform: translate(-12.6px, -30px) rotate(222deg);
    opacity: 1.3895228121;
  }

  21.2266% {
    transform: translate(-28px, -57.6px) rotate(222deg);
    opacity: 1.3895228121;
  }

  30.4208% {
    transform: translate(-46.1px, -78.7px) rotate(222deg);
    opacity: 1.3895228121;
  }

  34.4519% {
    transform: translate(-56.2px, -85.6px) rotate(222deg);
    opacity: 1.3895228121;
  }

  38.2085% {
    transform: translate(-66.9px, -89.4px) rotate(222deg);
    opacity: 1.3895228121;
  }

  41.9607% {
    transform: translate(-78.2px, -89.6px) rotate(222deg);
    opacity: 1.3895228121;
  }

  46.1202% {
    transform: translate(-90.2px, -85.8px) rotate(222deg);
    opacity: 1.3895228121;
  }

  51.1287% {
    transform: translate(-102.8px, -77.4px) rotate(222deg);
    opacity: 1.3895228121;
  }

  57.3654% {
    transform: translate(-116px, -64px) rotate(222deg);
    opacity: 1.3895228121;
  }

  65.1344% {
    transform: translate(-129.9px, -45px) rotate(222deg);
    opacity: 1.3895228121;
  }

  74.6872% {
    transform: translate(-144.3px, -20px) rotate(222deg);
    opacity: 1.3895228121;
  }

  86.2425% {
    transform: translate(-159.4px, 11.5px) rotate(222deg);
    opacity: 0.6947614061;
  }

  100.0000% {
    transform: translate(-175px, 50px) rotate(222deg);
    opacity: 0;
  }
}

.confetti-left-17,
.confetti-right-17 {
  width: 8.4px;
  height: 8.4px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fed500;
  top: calc(25% - 14px);
  left: calc(50% - 4.2px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-17 {
  animation: drop-left-17 1.5163085235s 0.7802220137s forwards;
}

.confetti-right-17 {
  animation: drop-right-17 1.9611932429s 1.3073480459s forwards;
}

@keyframes drop-left-17 {
  0.0000% {
    transform: translate(0px, 0px) rotate(157deg);
    opacity: 0.9930961195;
  }

  10.7882% {
    transform: translate(6.3px, -15px) rotate(157deg);
    opacity: 0.9930961195;
  }

  21.2266% {
    transform: translate(14px, -28.8px) rotate(157deg);
    opacity: 0.9930961195;
  }

  30.4208% {
    transform: translate(23.05px, -39.35px) rotate(157deg);
    opacity: 0.9930961195;
  }

  34.4519% {
    transform: translate(28.1px, -42.8px) rotate(157deg);
    opacity: 0.9930961195;
  }

  38.2085% {
    transform: translate(33.45px, -44.7px) rotate(157deg);
    opacity: 0.9930961195;
  }

  41.9607% {
    transform: translate(39.1px, -44.8px) rotate(157deg);
    opacity: 0.9930961195;
  }

  46.1202% {
    transform: translate(45.1px, -42.9px) rotate(157deg);
    opacity: 0.9930961195;
  }

  51.1287% {
    transform: translate(51.4px, -38.7px) rotate(157deg);
    opacity: 0.9930961195;
  }

  57.3654% {
    transform: translate(58px, -32px) rotate(157deg);
    opacity: 0.9930961195;
  }

  65.1344% {
    transform: translate(64.95px, -22.5px) rotate(157deg);
    opacity: 0.9930961195;
  }

  74.6872% {
    transform: translate(72.15px, -10px) rotate(157deg);
    opacity: 0.9930961195;
  }

  86.2425% {
    transform: translate(79.7px, 5.75px) rotate(157deg);
    opacity: 0.4965480597;
  }

  100.0000% {
    transform: translate(87.5px, 25px) rotate(157deg);
    opacity: 0;
  }
}

@keyframes drop-right-17 {
  0.0000% {
    transform: translate(0px, 0px) rotate(333deg);
    opacity: 1.2759349455;
  }

  10.7882% {
    transform: translate(-9.45px, -22.5px) rotate(333deg);
    opacity: 1.2759349455;
  }

  21.2266% {
    transform: translate(-21px, -43.2px) rotate(333deg);
    opacity: 1.2759349455;
  }

  30.4208% {
    transform: translate(-34.575px, -59.025px) rotate(333deg);
    opacity: 1.2759349455;
  }

  34.4519% {
    transform: translate(-42.15px, -64.2px) rotate(333deg);
    opacity: 1.2759349455;
  }

  38.2085% {
    transform: translate(-50.175px, -67.05px) rotate(333deg);
    opacity: 1.2759349455;
  }

  41.9607% {
    transform: translate(-58.65px, -67.2px) rotate(333deg);
    opacity: 1.2759349455;
  }

  46.1202% {
    transform: translate(-67.65px, -64.35px) rotate(333deg);
    opacity: 1.2759349455;
  }

  51.1287% {
    transform: translate(-77.1px, -58.05px) rotate(333deg);
    opacity: 1.2759349455;
  }

  57.3654% {
    transform: translate(-87px, -48px) rotate(333deg);
    opacity: 1.2759349455;
  }

  65.1344% {
    transform: translate(-97.425px, -33.75px) rotate(333deg);
    opacity: 1.2759349455;
  }

  74.6872% {
    transform: translate(-108.225px, -15px) rotate(333deg);
    opacity: 1.2759349455;
  }

  86.2425% {
    transform: translate(-119.55px, 8.625px) rotate(333deg);
    opacity: 0.6379674728;
  }

  100.0000% {
    transform: translate(-131.25px, 37.5px) rotate(333deg);
    opacity: 0;
  }
}

.confetti-left-18,
.confetti-right-18 {
  width: 2px;
  height: 0.8px;
  background-color: #00a4ff;
  top: calc(25% - 2px);
  left: calc(50% - 0.6px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-18 {
  animation: drop-left-18 1.5232716435s 0.5980886668s forwards;
}

.confetti-right-18 {
  animation: drop-right-18 1.5669452245s 0.4948562437s forwards;
}

@keyframes drop-left-18 {
  0.0000% {
    transform: translate(0px, 0px) rotate(251deg);
    opacity: 1.4664107675;
  }

  10.7882% {
    transform: translate(3.15px, -7.5px) rotate(251deg);
    opacity: 1.4664107675;
  }

  21.2266% {
    transform: translate(7px, -14.4px) rotate(251deg);
    opacity: 1.4664107675;
  }

  30.4208% {
    transform: translate(11.525px, -19.675px) rotate(251deg);
    opacity: 1.4664107675;
  }

  34.4519% {
    transform: translate(14.05px, -21.4px) rotate(251deg);
    opacity: 1.4664107675;
  }

  38.2085% {
    transform: translate(16.725px, -22.35px) rotate(251deg);
    opacity: 1.4664107675;
  }

  41.9607% {
    transform: translate(19.55px, -22.4px) rotate(251deg);
    opacity: 1.4664107675;
  }

  46.1202% {
    transform: translate(22.55px, -21.45px) rotate(251deg);
    opacity: 1.4664107675;
  }

  51.1287% {
    transform: translate(25.7px, -19.35px) rotate(251deg);
    opacity: 1.4664107675;
  }

  57.3654% {
    transform: translate(29px, -16px) rotate(251deg);
    opacity: 1.4664107675;
  }

  65.1344% {
    transform: translate(32.475px, -11.25px) rotate(251deg);
    opacity: 1.4664107675;
  }

  74.6872% {
    transform: translate(36.075px, -5px) rotate(251deg);
    opacity: 1.4664107675;
  }

  86.2425% {
    transform: translate(39.85px, 2.875px) rotate(251deg);
    opacity: 0.7332053838;
  }

  100.0000% {
    transform: translate(43.75px, 12.5px) rotate(251deg);
    opacity: 0;
  }
}

@keyframes drop-right-18 {
  0.0000% {
    transform: translate(0px, 0px) rotate(325deg);
    opacity: 1.4493487987;
  }

  10.7882% {
    transform: translate(-31.5px, -75px) rotate(325deg);
    opacity: 1.4493487987;
  }

  21.2266% {
    transform: translate(-70px, -144px) rotate(325deg);
    opacity: 1.4493487987;
  }

  30.4208% {
    transform: translate(-115.25px, -196.75px) rotate(325deg);
    opacity: 1.4493487987;
  }

  34.4519% {
    transform: translate(-140.5px, -214px) rotate(325deg);
    opacity: 1.4493487987;
  }

  38.2085% {
    transform: translate(-167.25px, -223.5px) rotate(325deg);
    opacity: 1.4493487987;
  }

  41.9607% {
    transform: translate(-195.5px, -224px) rotate(325deg);
    opacity: 1.4493487987;
  }

  46.1202% {
    transform: translate(-225.5px, -214.5px) rotate(325deg);
    opacity: 1.4493487987;
  }

  51.1287% {
    transform: translate(-257px, -193.5px) rotate(325deg);
    opacity: 1.4493487987;
  }

  57.3654% {
    transform: translate(-290px, -160px) rotate(325deg);
    opacity: 1.4493487987;
  }

  65.1344% {
    transform: translate(-324.75px, -112.5px) rotate(325deg);
    opacity: 1.4493487987;
  }

  74.6872% {
    transform: translate(-360.75px, -50px) rotate(325deg);
    opacity: 1.4493487987;
  }

  86.2425% {
    transform: translate(-398.5px, 28.75px) rotate(325deg);
    opacity: 0.7246743993;
  }

  100.0000% {
    transform: translate(-437.5px, 125px) rotate(325deg);
    opacity: 0;
  }
}

.confetti-left-19,
.confetti-right-19 {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fed500;
  top: calc(25% - 5px);
  left: calc(50% - 1.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-19 {
  animation: drop-left-19 1.8062516158s 0.690791275s forwards;
}

.confetti-right-19 {
  animation: drop-right-19 1.4948830116s 0.9244258316s forwards;
}

@keyframes drop-left-19 {
  0.0000% {
    transform: translate(0px, 0px) rotate(226deg);
    opacity: 0.6230120873;
  }

  10.7882% {
    transform: translate(18.9px, -45px) rotate(226deg);
    opacity: 0.6230120873;
  }

  21.2266% {
    transform: translate(42px, -86.4px) rotate(226deg);
    opacity: 0.6230120873;
  }

  30.4208% {
    transform: translate(69.15px, -118.05px) rotate(226deg);
    opacity: 0.6230120873;
  }

  34.4519% {
    transform: translate(84.3px, -128.4px) rotate(226deg);
    opacity: 0.6230120873;
  }

  38.2085% {
    transform: translate(100.35px, -134.1px) rotate(226deg);
    opacity: 0.6230120873;
  }

  41.9607% {
    transform: translate(117.3px, -134.4px) rotate(226deg);
    opacity: 0.6230120873;
  }

  46.1202% {
    transform: translate(135.3px, -128.7px) rotate(226deg);
    opacity: 0.6230120873;
  }

  51.1287% {
    transform: translate(154.2px, -116.1px) rotate(226deg);
    opacity: 0.6230120873;
  }

  57.3654% {
    transform: translate(174px, -96px) rotate(226deg);
    opacity: 0.6230120873;
  }

  65.1344% {
    transform: translate(194.85px, -67.5px) rotate(226deg);
    opacity: 0.6230120873;
  }

  74.6872% {
    transform: translate(216.45px, -30px) rotate(226deg);
    opacity: 0.6230120873;
  }

  86.2425% {
    transform: translate(239.1px, 17.25px) rotate(226deg);
    opacity: 0.3115060437;
  }

  100.0000% {
    transform: translate(262.5px, 75px) rotate(226deg);
    opacity: 0;
  }
}

@keyframes drop-right-19 {
  0.0000% {
    transform: translate(0px, 0px) rotate(343deg);
    opacity: 1.3468512143;
  }

  10.7882% {
    transform: translate(-31.5px, -75px) rotate(343deg);
    opacity: 1.3468512143;
  }

  21.2266% {
    transform: translate(-70px, -144px) rotate(343deg);
    opacity: 1.3468512143;
  }

  30.4208% {
    transform: translate(-115.25px, -196.75px) rotate(343deg);
    opacity: 1.3468512143;
  }

  34.4519% {
    transform: translate(-140.5px, -214px) rotate(343deg);
    opacity: 1.3468512143;
  }

  38.2085% {
    transform: translate(-167.25px, -223.5px) rotate(343deg);
    opacity: 1.3468512143;
  }

  41.9607% {
    transform: translate(-195.5px, -224px) rotate(343deg);
    opacity: 1.3468512143;
  }

  46.1202% {
    transform: translate(-225.5px, -214.5px) rotate(343deg);
    opacity: 1.3468512143;
  }

  51.1287% {
    transform: translate(-257px, -193.5px) rotate(343deg);
    opacity: 1.3468512143;
  }

  57.3654% {
    transform: translate(-290px, -160px) rotate(343deg);
    opacity: 1.3468512143;
  }

  65.1344% {
    transform: translate(-324.75px, -112.5px) rotate(343deg);
    opacity: 1.3468512143;
  }

  74.6872% {
    transform: translate(-360.75px, -50px) rotate(343deg);
    opacity: 1.3468512143;
  }

  86.2425% {
    transform: translate(-398.5px, 28.75px) rotate(343deg);
    opacity: 0.6734256071;
  }

  100.0000% {
    transform: translate(-437.5px, 125px) rotate(343deg);
    opacity: 0;
  }
}

.confetti-left-20,
.confetti-right-20 {
  width: 13px;
  height: 5.2px;
  background-color: #fed500;
  top: calc(25% - 13px);
  left: calc(50% - 3.9px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-20 {
  animation: drop-left-20 1.720693095s 0.6640126334s forwards;
}

.confetti-right-20 {
  animation: drop-right-20 1.8945998419s 1.2535216653s forwards;
}

@keyframes drop-left-20 {
  0.0000% {
    transform: translate(0px, 0px) rotate(265deg);
    opacity: 0.9195577278;
  }

  10.7882% {
    transform: translate(18.9px, -45px) rotate(265deg);
    opacity: 0.9195577278;
  }

  21.2266% {
    transform: translate(42px, -86.4px) rotate(265deg);
    opacity: 0.9195577278;
  }

  30.4208% {
    transform: translate(69.15px, -118.05px) rotate(265deg);
    opacity: 0.9195577278;
  }

  34.4519% {
    transform: translate(84.3px, -128.4px) rotate(265deg);
    opacity: 0.9195577278;
  }

  38.2085% {
    transform: translate(100.35px, -134.1px) rotate(265deg);
    opacity: 0.9195577278;
  }

  41.9607% {
    transform: translate(117.3px, -134.4px) rotate(265deg);
    opacity: 0.9195577278;
  }

  46.1202% {
    transform: translate(135.3px, -128.7px) rotate(265deg);
    opacity: 0.9195577278;
  }

  51.1287% {
    transform: translate(154.2px, -116.1px) rotate(265deg);
    opacity: 0.9195577278;
  }

  57.3654% {
    transform: translate(174px, -96px) rotate(265deg);
    opacity: 0.9195577278;
  }

  65.1344% {
    transform: translate(194.85px, -67.5px) rotate(265deg);
    opacity: 0.9195577278;
  }

  74.6872% {
    transform: translate(216.45px, -30px) rotate(265deg);
    opacity: 0.9195577278;
  }

  86.2425% {
    transform: translate(239.1px, 17.25px) rotate(265deg);
    opacity: 0.4597788639;
  }

  100.0000% {
    transform: translate(262.5px, 75px) rotate(265deg);
    opacity: 0;
  }
}

@keyframes drop-right-20 {
  0.0000% {
    transform: translate(0px, 0px) rotate(220deg);
    opacity: 1.3241425627;
  }

  10.7882% {
    transform: translate(-22.05px, -52.5px) rotate(220deg);
    opacity: 1.3241425627;
  }

  21.2266% {
    transform: translate(-49px, -100.8px) rotate(220deg);
    opacity: 1.3241425627;
  }

  30.4208% {
    transform: translate(-80.675px, -137.725px) rotate(220deg);
    opacity: 1.3241425627;
  }

  34.4519% {
    transform: translate(-98.35px, -149.8px) rotate(220deg);
    opacity: 1.3241425627;
  }

  38.2085% {
    transform: translate(-117.075px, -156.45px) rotate(220deg);
    opacity: 1.3241425627;
  }

  41.9607% {
    transform: translate(-136.85px, -156.8px) rotate(220deg);
    opacity: 1.3241425627;
  }

  46.1202% {
    transform: translate(-157.85px, -150.15px) rotate(220deg);
    opacity: 1.3241425627;
  }

  51.1287% {
    transform: translate(-179.9px, -135.45px) rotate(220deg);
    opacity: 1.3241425627;
  }

  57.3654% {
    transform: translate(-203px, -112px) rotate(220deg);
    opacity: 1.3241425627;
  }

  65.1344% {
    transform: translate(-227.325px, -78.75px) rotate(220deg);
    opacity: 1.3241425627;
  }

  74.6872% {
    transform: translate(-252.525px, -35px) rotate(220deg);
    opacity: 1.3241425627;
  }

  86.2425% {
    transform: translate(-278.95px, 20.125px) rotate(220deg);
    opacity: 0.6620712813;
  }

  100.0000% {
    transform: translate(-306.25px, 87.5px) rotate(220deg);
    opacity: 0;
  }
}

.confetti-left-21,
.confetti-right-21 {
  width: 7px;
  height: 2.8px;
  background-color: #03a561;
  top: calc(25% - 7px);
  left: calc(50% - 2.1px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-21 {
  animation: drop-left-21 1.997053964s 0.6628344709s forwards;
}

.confetti-right-21 {
  animation: drop-right-21 1.6522350556s 0.849799347s forwards;
}

@keyframes drop-left-21 {
  0.0000% {
    transform: translate(0px, 0px) rotate(93deg);
    opacity: 0.6744873247;
  }

  10.7882% {
    transform: translate(22.05px, -52.5px) rotate(93deg);
    opacity: 0.6744873247;
  }

  21.2266% {
    transform: translate(49px, -100.8px) rotate(93deg);
    opacity: 0.6744873247;
  }

  30.4208% {
    transform: translate(80.675px, -137.725px) rotate(93deg);
    opacity: 0.6744873247;
  }

  34.4519% {
    transform: translate(98.35px, -149.8px) rotate(93deg);
    opacity: 0.6744873247;
  }

  38.2085% {
    transform: translate(117.075px, -156.45px) rotate(93deg);
    opacity: 0.6744873247;
  }

  41.9607% {
    transform: translate(136.85px, -156.8px) rotate(93deg);
    opacity: 0.6744873247;
  }

  46.1202% {
    transform: translate(157.85px, -150.15px) rotate(93deg);
    opacity: 0.6744873247;
  }

  51.1287% {
    transform: translate(179.9px, -135.45px) rotate(93deg);
    opacity: 0.6744873247;
  }

  57.3654% {
    transform: translate(203px, -112px) rotate(93deg);
    opacity: 0.6744873247;
  }

  65.1344% {
    transform: translate(227.325px, -78.75px) rotate(93deg);
    opacity: 0.6744873247;
  }

  74.6872% {
    transform: translate(252.525px, -35px) rotate(93deg);
    opacity: 0.6744873247;
  }

  86.2425% {
    transform: translate(278.95px, 20.125px) rotate(93deg);
    opacity: 0.3372436624;
  }

  100.0000% {
    transform: translate(306.25px, 87.5px) rotate(93deg);
    opacity: 0;
  }
}

@keyframes drop-right-21 {
  0.0000% {
    transform: translate(0px, 0px) rotate(211deg);
    opacity: 1.2565877719;
  }

  10.7882% {
    transform: translate(-15.75px, -37.5px) rotate(211deg);
    opacity: 1.2565877719;
  }

  21.2266% {
    transform: translate(-35px, -72px) rotate(211deg);
    opacity: 1.2565877719;
  }

  30.4208% {
    transform: translate(-57.625px, -98.375px) rotate(211deg);
    opacity: 1.2565877719;
  }

  34.4519% {
    transform: translate(-70.25px, -107px) rotate(211deg);
    opacity: 1.2565877719;
  }

  38.2085% {
    transform: translate(-83.625px, -111.75px) rotate(211deg);
    opacity: 1.2565877719;
  }

  41.9607% {
    transform: translate(-97.75px, -112px) rotate(211deg);
    opacity: 1.2565877719;
  }

  46.1202% {
    transform: translate(-112.75px, -107.25px) rotate(211deg);
    opacity: 1.2565877719;
  }

  51.1287% {
    transform: translate(-128.5px, -96.75px) rotate(211deg);
    opacity: 1.2565877719;
  }

  57.3654% {
    transform: translate(-145px, -80px) rotate(211deg);
    opacity: 1.2565877719;
  }

  65.1344% {
    transform: translate(-162.375px, -56.25px) rotate(211deg);
    opacity: 1.2565877719;
  }

  74.6872% {
    transform: translate(-180.375px, -25px) rotate(211deg);
    opacity: 1.2565877719;
  }

  86.2425% {
    transform: translate(-199.25px, 14.375px) rotate(211deg);
    opacity: 0.628293886;
  }

  100.0000% {
    transform: translate(-218.75px, 62.5px) rotate(211deg);
    opacity: 0;
  }
}

.confetti-left-22,
.confetti-right-22 {
  width: 3px;
  height: 1.2px;
  background-color: #03a561;
  top: calc(25% - 3px);
  left: calc(50% - 0.9px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-22 {
  animation: drop-left-22 1.4414234892s 0.5933072751s forwards;
}

.confetti-right-22 {
  animation: drop-right-22 1.1855090382s 1.3762118595s forwards;
}

@keyframes drop-left-22 {
  0.0000% {
    transform: translate(0px, 0px) rotate(261deg);
    opacity: 1.031338932;
  }

  10.7882% {
    transform: translate(6.3px, -15px) rotate(261deg);
    opacity: 1.031338932;
  }

  21.2266% {
    transform: translate(14px, -28.8px) rotate(261deg);
    opacity: 1.031338932;
  }

  30.4208% {
    transform: translate(23.05px, -39.35px) rotate(261deg);
    opacity: 1.031338932;
  }

  34.4519% {
    transform: translate(28.1px, -42.8px) rotate(261deg);
    opacity: 1.031338932;
  }

  38.2085% {
    transform: translate(33.45px, -44.7px) rotate(261deg);
    opacity: 1.031338932;
  }

  41.9607% {
    transform: translate(39.1px, -44.8px) rotate(261deg);
    opacity: 1.031338932;
  }

  46.1202% {
    transform: translate(45.1px, -42.9px) rotate(261deg);
    opacity: 1.031338932;
  }

  51.1287% {
    transform: translate(51.4px, -38.7px) rotate(261deg);
    opacity: 1.031338932;
  }

  57.3654% {
    transform: translate(58px, -32px) rotate(261deg);
    opacity: 1.031338932;
  }

  65.1344% {
    transform: translate(64.95px, -22.5px) rotate(261deg);
    opacity: 1.031338932;
  }

  74.6872% {
    transform: translate(72.15px, -10px) rotate(261deg);
    opacity: 1.031338932;
  }

  86.2425% {
    transform: translate(79.7px, 5.75px) rotate(261deg);
    opacity: 0.515669466;
  }

  100.0000% {
    transform: translate(87.5px, 25px) rotate(261deg);
    opacity: 0;
  }
}

@keyframes drop-right-22 {
  0.0000% {
    transform: translate(0px, 0px) rotate(17deg);
    opacity: 1.0942689127;
  }

  10.7882% {
    transform: translate(-15.75px, -37.5px) rotate(17deg);
    opacity: 1.0942689127;
  }

  21.2266% {
    transform: translate(-35px, -72px) rotate(17deg);
    opacity: 1.0942689127;
  }

  30.4208% {
    transform: translate(-57.625px, -98.375px) rotate(17deg);
    opacity: 1.0942689127;
  }

  34.4519% {
    transform: translate(-70.25px, -107px) rotate(17deg);
    opacity: 1.0942689127;
  }

  38.2085% {
    transform: translate(-83.625px, -111.75px) rotate(17deg);
    opacity: 1.0942689127;
  }

  41.9607% {
    transform: translate(-97.75px, -112px) rotate(17deg);
    opacity: 1.0942689127;
  }

  46.1202% {
    transform: translate(-112.75px, -107.25px) rotate(17deg);
    opacity: 1.0942689127;
  }

  51.1287% {
    transform: translate(-128.5px, -96.75px) rotate(17deg);
    opacity: 1.0942689127;
  }

  57.3654% {
    transform: translate(-145px, -80px) rotate(17deg);
    opacity: 1.0942689127;
  }

  65.1344% {
    transform: translate(-162.375px, -56.25px) rotate(17deg);
    opacity: 1.0942689127;
  }

  74.6872% {
    transform: translate(-180.375px, -25px) rotate(17deg);
    opacity: 1.0942689127;
  }

  86.2425% {
    transform: translate(-199.25px, 14.375px) rotate(17deg);
    opacity: 0.5471344564;
  }

  100.0000% {
    transform: translate(-218.75px, 62.5px) rotate(17deg);
    opacity: 0;
  }
}

.confetti-left-23,
.confetti-right-23 {
  background-color: transparent;
  border-bottom: 3.5px solid #ff04d2;
  border-left: 1.75px solid transparent;
  border-right: 1.75px solid transparent;
  height: 0;
  width: 3.5px;
  top: calc(25% - 7px);
  left: calc(50% - 2.1px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-23 {
  animation: drop-left-23 1.0678420442s 1.3493568715s forwards;
}

.confetti-right-23 {
  animation: drop-right-23 1.636626982s 1.0233263298s forwards;
}

@keyframes drop-left-23 {
  0.0000% {
    transform: translate(0px, 0px) rotate(343deg);
    opacity: 1.0886356078;
  }

  10.7882% {
    transform: translate(9.45px, -22.5px) rotate(343deg);
    opacity: 1.0886356078;
  }

  21.2266% {
    transform: translate(21px, -43.2px) rotate(343deg);
    opacity: 1.0886356078;
  }

  30.4208% {
    transform: translate(34.575px, -59.025px) rotate(343deg);
    opacity: 1.0886356078;
  }

  34.4519% {
    transform: translate(42.15px, -64.2px) rotate(343deg);
    opacity: 1.0886356078;
  }

  38.2085% {
    transform: translate(50.175px, -67.05px) rotate(343deg);
    opacity: 1.0886356078;
  }

  41.9607% {
    transform: translate(58.65px, -67.2px) rotate(343deg);
    opacity: 1.0886356078;
  }

  46.1202% {
    transform: translate(67.65px, -64.35px) rotate(343deg);
    opacity: 1.0886356078;
  }

  51.1287% {
    transform: translate(77.1px, -58.05px) rotate(343deg);
    opacity: 1.0886356078;
  }

  57.3654% {
    transform: translate(87px, -48px) rotate(343deg);
    opacity: 1.0886356078;
  }

  65.1344% {
    transform: translate(97.425px, -33.75px) rotate(343deg);
    opacity: 1.0886356078;
  }

  74.6872% {
    transform: translate(108.225px, -15px) rotate(343deg);
    opacity: 1.0886356078;
  }

  86.2425% {
    transform: translate(119.55px, 8.625px) rotate(343deg);
    opacity: 0.5443178039;
  }

  100.0000% {
    transform: translate(131.25px, 37.5px) rotate(343deg);
    opacity: 0;
  }
}

@keyframes drop-right-23 {
  0.0000% {
    transform: translate(0px, 0px) rotate(298deg);
    opacity: 0.769651035;
  }

  10.7882% {
    transform: translate(-9.45px, -22.5px) rotate(298deg);
    opacity: 0.769651035;
  }

  21.2266% {
    transform: translate(-21px, -43.2px) rotate(298deg);
    opacity: 0.769651035;
  }

  30.4208% {
    transform: translate(-34.575px, -59.025px) rotate(298deg);
    opacity: 0.769651035;
  }

  34.4519% {
    transform: translate(-42.15px, -64.2px) rotate(298deg);
    opacity: 0.769651035;
  }

  38.2085% {
    transform: translate(-50.175px, -67.05px) rotate(298deg);
    opacity: 0.769651035;
  }

  41.9607% {
    transform: translate(-58.65px, -67.2px) rotate(298deg);
    opacity: 0.769651035;
  }

  46.1202% {
    transform: translate(-67.65px, -64.35px) rotate(298deg);
    opacity: 0.769651035;
  }

  51.1287% {
    transform: translate(-77.1px, -58.05px) rotate(298deg);
    opacity: 0.769651035;
  }

  57.3654% {
    transform: translate(-87px, -48px) rotate(298deg);
    opacity: 0.769651035;
  }

  65.1344% {
    transform: translate(-97.425px, -33.75px) rotate(298deg);
    opacity: 0.769651035;
  }

  74.6872% {
    transform: translate(-108.225px, -15px) rotate(298deg);
    opacity: 0.769651035;
  }

  86.2425% {
    transform: translate(-119.55px, 8.625px) rotate(298deg);
    opacity: 0.3848255175;
  }

  100.0000% {
    transform: translate(-131.25px, 37.5px) rotate(298deg);
    opacity: 0;
  }
}

.confetti-left-24,
.confetti-right-24 {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #03a561;
  top: calc(25% - 5px);
  left: calc(50% - 1.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-24 {
  animation: drop-left-24 1.368643446s 1.2496747515s forwards;
}

.confetti-right-24 {
  animation: drop-right-24 1.155043546s 0.8260046959s forwards;
}

@keyframes drop-left-24 {
  0.0000% {
    transform: translate(0px, 0px) rotate(81deg);
    opacity: 0.765523559;
  }

  10.7882% {
    transform: translate(15.75px, -37.5px) rotate(81deg);
    opacity: 0.765523559;
  }

  21.2266% {
    transform: translate(35px, -72px) rotate(81deg);
    opacity: 0.765523559;
  }

  30.4208% {
    transform: translate(57.625px, -98.375px) rotate(81deg);
    opacity: 0.765523559;
  }

  34.4519% {
    transform: translate(70.25px, -107px) rotate(81deg);
    opacity: 0.765523559;
  }

  38.2085% {
    transform: translate(83.625px, -111.75px) rotate(81deg);
    opacity: 0.765523559;
  }

  41.9607% {
    transform: translate(97.75px, -112px) rotate(81deg);
    opacity: 0.765523559;
  }

  46.1202% {
    transform: translate(112.75px, -107.25px) rotate(81deg);
    opacity: 0.765523559;
  }

  51.1287% {
    transform: translate(128.5px, -96.75px) rotate(81deg);
    opacity: 0.765523559;
  }

  57.3654% {
    transform: translate(145px, -80px) rotate(81deg);
    opacity: 0.765523559;
  }

  65.1344% {
    transform: translate(162.375px, -56.25px) rotate(81deg);
    opacity: 0.765523559;
  }

  74.6872% {
    transform: translate(180.375px, -25px) rotate(81deg);
    opacity: 0.765523559;
  }

  86.2425% {
    transform: translate(199.25px, 14.375px) rotate(81deg);
    opacity: 0.3827617795;
  }

  100.0000% {
    transform: translate(218.75px, 62.5px) rotate(81deg);
    opacity: 0;
  }
}

@keyframes drop-right-24 {
  0.0000% {
    transform: translate(0px, 0px) rotate(243deg);
    opacity: 1.4744474745;
  }

  10.7882% {
    transform: translate(-15.75px, -37.5px) rotate(243deg);
    opacity: 1.4744474745;
  }

  21.2266% {
    transform: translate(-35px, -72px) rotate(243deg);
    opacity: 1.4744474745;
  }

  30.4208% {
    transform: translate(-57.625px, -98.375px) rotate(243deg);
    opacity: 1.4744474745;
  }

  34.4519% {
    transform: translate(-70.25px, -107px) rotate(243deg);
    opacity: 1.4744474745;
  }

  38.2085% {
    transform: translate(-83.625px, -111.75px) rotate(243deg);
    opacity: 1.4744474745;
  }

  41.9607% {
    transform: translate(-97.75px, -112px) rotate(243deg);
    opacity: 1.4744474745;
  }

  46.1202% {
    transform: translate(-112.75px, -107.25px) rotate(243deg);
    opacity: 1.4744474745;
  }

  51.1287% {
    transform: translate(-128.5px, -96.75px) rotate(243deg);
    opacity: 1.4744474745;
  }

  57.3654% {
    transform: translate(-145px, -80px) rotate(243deg);
    opacity: 1.4744474745;
  }

  65.1344% {
    transform: translate(-162.375px, -56.25px) rotate(243deg);
    opacity: 1.4744474745;
  }

  74.6872% {
    transform: translate(-180.375px, -25px) rotate(243deg);
    opacity: 1.4744474745;
  }

  86.2425% {
    transform: translate(-199.25px, 14.375px) rotate(243deg);
    opacity: 0.7372237373;
  }

  100.0000% {
    transform: translate(-218.75px, 62.5px) rotate(243deg);
    opacity: 0;
  }
}

.confetti-left-25,
.confetti-right-25 {
  background-color: transparent;
  border-bottom: 2.5px solid #00a4ff;
  border-left: 1.25px solid transparent;
  border-right: 1.25px solid transparent;
  height: 0;
  width: 2.5px;
  top: calc(25% - 5px);
  left: calc(50% - 1.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-25 {
  animation: drop-left-25 1.9875424137s 1.4093171292s forwards;
}

.confetti-right-25 {
  animation: drop-right-25 1.2332532194s 1.1832867288s forwards;
}

@keyframes drop-left-25 {
  0.0000% {
    transform: translate(0px, 0px) rotate(64deg);
    opacity: 0.8342673832;
  }

  10.7882% {
    transform: translate(15.75px, -37.5px) rotate(64deg);
    opacity: 0.8342673832;
  }

  21.2266% {
    transform: translate(35px, -72px) rotate(64deg);
    opacity: 0.8342673832;
  }

  30.4208% {
    transform: translate(57.625px, -98.375px) rotate(64deg);
    opacity: 0.8342673832;
  }

  34.4519% {
    transform: translate(70.25px, -107px) rotate(64deg);
    opacity: 0.8342673832;
  }

  38.2085% {
    transform: translate(83.625px, -111.75px) rotate(64deg);
    opacity: 0.8342673832;
  }

  41.9607% {
    transform: translate(97.75px, -112px) rotate(64deg);
    opacity: 0.8342673832;
  }

  46.1202% {
    transform: translate(112.75px, -107.25px) rotate(64deg);
    opacity: 0.8342673832;
  }

  51.1287% {
    transform: translate(128.5px, -96.75px) rotate(64deg);
    opacity: 0.8342673832;
  }

  57.3654% {
    transform: translate(145px, -80px) rotate(64deg);
    opacity: 0.8342673832;
  }

  65.1344% {
    transform: translate(162.375px, -56.25px) rotate(64deg);
    opacity: 0.8342673832;
  }

  74.6872% {
    transform: translate(180.375px, -25px) rotate(64deg);
    opacity: 0.8342673832;
  }

  86.2425% {
    transform: translate(199.25px, 14.375px) rotate(64deg);
    opacity: 0.4171336916;
  }

  100.0000% {
    transform: translate(218.75px, 62.5px) rotate(64deg);
    opacity: 0;
  }
}

@keyframes drop-right-25 {
  0.0000% {
    transform: translate(0px, 0px) rotate(78deg);
    opacity: 1.1006919969;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(78deg);
    opacity: 1.1006919969;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(78deg);
    opacity: 1.1006919969;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(78deg);
    opacity: 1.1006919969;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(78deg);
    opacity: 1.1006919969;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(78deg);
    opacity: 1.1006919969;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(78deg);
    opacity: 1.1006919969;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(78deg);
    opacity: 1.1006919969;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(78deg);
    opacity: 1.1006919969;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(78deg);
    opacity: 1.1006919969;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(78deg);
    opacity: 1.1006919969;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(78deg);
    opacity: 1.1006919969;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(78deg);
    opacity: 0.5503459984;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(78deg);
    opacity: 0;
  }
}

.confetti-left-26,
.confetti-right-26 {
  background-color: transparent;
  border-bottom: 7.5px solid #03a561;
  border-left: 3.75px solid transparent;
  border-right: 3.75px solid transparent;
  height: 0;
  width: 7.5px;
  top: calc(25% - 15px);
  left: calc(50% - 4.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-26 {
  animation: drop-left-26 1.7033953188s 0.9581178076s forwards;
}

.confetti-right-26 {
  animation: drop-right-26 1.4394924218s 1.2529190373s forwards;
}

@keyframes drop-left-26 {
  0.0000% {
    transform: translate(0px, 0px) rotate(148deg);
    opacity: 0.8859349725;
  }

  10.7882% {
    transform: translate(9.45px, -22.5px) rotate(148deg);
    opacity: 0.8859349725;
  }

  21.2266% {
    transform: translate(21px, -43.2px) rotate(148deg);
    opacity: 0.8859349725;
  }

  30.4208% {
    transform: translate(34.575px, -59.025px) rotate(148deg);
    opacity: 0.8859349725;
  }

  34.4519% {
    transform: translate(42.15px, -64.2px) rotate(148deg);
    opacity: 0.8859349725;
  }

  38.2085% {
    transform: translate(50.175px, -67.05px) rotate(148deg);
    opacity: 0.8859349725;
  }

  41.9607% {
    transform: translate(58.65px, -67.2px) rotate(148deg);
    opacity: 0.8859349725;
  }

  46.1202% {
    transform: translate(67.65px, -64.35px) rotate(148deg);
    opacity: 0.8859349725;
  }

  51.1287% {
    transform: translate(77.1px, -58.05px) rotate(148deg);
    opacity: 0.8859349725;
  }

  57.3654% {
    transform: translate(87px, -48px) rotate(148deg);
    opacity: 0.8859349725;
  }

  65.1344% {
    transform: translate(97.425px, -33.75px) rotate(148deg);
    opacity: 0.8859349725;
  }

  74.6872% {
    transform: translate(108.225px, -15px) rotate(148deg);
    opacity: 0.8859349725;
  }

  86.2425% {
    transform: translate(119.55px, 8.625px) rotate(148deg);
    opacity: 0.4429674863;
  }

  100.0000% {
    transform: translate(131.25px, 37.5px) rotate(148deg);
    opacity: 0;
  }
}

@keyframes drop-right-26 {
  0.0000% {
    transform: translate(0px, 0px) rotate(210deg);
    opacity: 1.4066259803;
  }

  10.7882% {
    transform: translate(-18.9px, -45px) rotate(210deg);
    opacity: 1.4066259803;
  }

  21.2266% {
    transform: translate(-42px, -86.4px) rotate(210deg);
    opacity: 1.4066259803;
  }

  30.4208% {
    transform: translate(-69.15px, -118.05px) rotate(210deg);
    opacity: 1.4066259803;
  }

  34.4519% {
    transform: translate(-84.3px, -128.4px) rotate(210deg);
    opacity: 1.4066259803;
  }

  38.2085% {
    transform: translate(-100.35px, -134.1px) rotate(210deg);
    opacity: 1.4066259803;
  }

  41.9607% {
    transform: translate(-117.3px, -134.4px) rotate(210deg);
    opacity: 1.4066259803;
  }

  46.1202% {
    transform: translate(-135.3px, -128.7px) rotate(210deg);
    opacity: 1.4066259803;
  }

  51.1287% {
    transform: translate(-154.2px, -116.1px) rotate(210deg);
    opacity: 1.4066259803;
  }

  57.3654% {
    transform: translate(-174px, -96px) rotate(210deg);
    opacity: 1.4066259803;
  }

  65.1344% {
    transform: translate(-194.85px, -67.5px) rotate(210deg);
    opacity: 1.4066259803;
  }

  74.6872% {
    transform: translate(-216.45px, -30px) rotate(210deg);
    opacity: 1.4066259803;
  }

  86.2425% {
    transform: translate(-239.1px, 17.25px) rotate(210deg);
    opacity: 0.7033129901;
  }

  100.0000% {
    transform: translate(-262.5px, 75px) rotate(210deg);
    opacity: 0;
  }
}

.confetti-left-27,
.confetti-right-27 {
  width: 3.6px;
  height: 3.6px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #00a4ff;
  top: calc(25% - 6px);
  left: calc(50% - 1.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-27 {
  animation: drop-left-27 1.9480315866s 0.4827799664s forwards;
}

.confetti-right-27 {
  animation: drop-right-27 1.290072706s 0.868205258s forwards;
}

@keyframes drop-left-27 {
  0.0000% {
    transform: translate(0px, 0px) rotate(52deg);
    opacity: 1.1517739674;
  }

  10.7882% {
    transform: translate(28.35px, -67.5px) rotate(52deg);
    opacity: 1.1517739674;
  }

  21.2266% {
    transform: translate(63px, -129.6px) rotate(52deg);
    opacity: 1.1517739674;
  }

  30.4208% {
    transform: translate(103.725px, -177.075px) rotate(52deg);
    opacity: 1.1517739674;
  }

  34.4519% {
    transform: translate(126.45px, -192.6px) rotate(52deg);
    opacity: 1.1517739674;
  }

  38.2085% {
    transform: translate(150.525px, -201.15px) rotate(52deg);
    opacity: 1.1517739674;
  }

  41.9607% {
    transform: translate(175.95px, -201.6px) rotate(52deg);
    opacity: 1.1517739674;
  }

  46.1202% {
    transform: translate(202.95px, -193.05px) rotate(52deg);
    opacity: 1.1517739674;
  }

  51.1287% {
    transform: translate(231.3px, -174.15px) rotate(52deg);
    opacity: 1.1517739674;
  }

  57.3654% {
    transform: translate(261px, -144px) rotate(52deg);
    opacity: 1.1517739674;
  }

  65.1344% {
    transform: translate(292.275px, -101.25px) rotate(52deg);
    opacity: 1.1517739674;
  }

  74.6872% {
    transform: translate(324.675px, -45px) rotate(52deg);
    opacity: 1.1517739674;
  }

  86.2425% {
    transform: translate(358.65px, 25.875px) rotate(52deg);
    opacity: 0.5758869837;
  }

  100.0000% {
    transform: translate(393.75px, 112.5px) rotate(52deg);
    opacity: 0;
  }
}

@keyframes drop-right-27 {
  0.0000% {
    transform: translate(0px, 0px) rotate(204deg);
    opacity: 0.5877365274;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(204deg);
    opacity: 0.5877365274;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(204deg);
    opacity: 0.5877365274;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(204deg);
    opacity: 0.5877365274;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(204deg);
    opacity: 0.5877365274;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(204deg);
    opacity: 0.5877365274;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(204deg);
    opacity: 0.5877365274;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(204deg);
    opacity: 0.5877365274;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(204deg);
    opacity: 0.5877365274;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(204deg);
    opacity: 0.5877365274;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(204deg);
    opacity: 0.5877365274;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(204deg);
    opacity: 0.5877365274;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(204deg);
    opacity: 0.2938682637;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(204deg);
    opacity: 0;
  }
}

.confetti-left-28,
.confetti-right-28 {
  background-color: transparent;
  border-bottom: 1.5px solid #ff04d2;
  border-left: 0.75px solid transparent;
  border-right: 0.75px solid transparent;
  height: 0;
  width: 1.5px;
  top: calc(25% - 3px);
  left: calc(50% - 0.9px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-28 {
  animation: drop-left-28 1.936160848s 0.816580343s forwards;
}

.confetti-right-28 {
  animation: drop-right-28 1.9472085967s 0.5845182653s forwards;
}

@keyframes drop-left-28 {
  0.0000% {
    transform: translate(0px, 0px) rotate(26deg);
    opacity: 1.1681955482;
  }

  10.7882% {
    transform: translate(9.45px, -22.5px) rotate(26deg);
    opacity: 1.1681955482;
  }

  21.2266% {
    transform: translate(21px, -43.2px) rotate(26deg);
    opacity: 1.1681955482;
  }

  30.4208% {
    transform: translate(34.575px, -59.025px) rotate(26deg);
    opacity: 1.1681955482;
  }

  34.4519% {
    transform: translate(42.15px, -64.2px) rotate(26deg);
    opacity: 1.1681955482;
  }

  38.2085% {
    transform: translate(50.175px, -67.05px) rotate(26deg);
    opacity: 1.1681955482;
  }

  41.9607% {
    transform: translate(58.65px, -67.2px) rotate(26deg);
    opacity: 1.1681955482;
  }

  46.1202% {
    transform: translate(67.65px, -64.35px) rotate(26deg);
    opacity: 1.1681955482;
  }

  51.1287% {
    transform: translate(77.1px, -58.05px) rotate(26deg);
    opacity: 1.1681955482;
  }

  57.3654% {
    transform: translate(87px, -48px) rotate(26deg);
    opacity: 1.1681955482;
  }

  65.1344% {
    transform: translate(97.425px, -33.75px) rotate(26deg);
    opacity: 1.1681955482;
  }

  74.6872% {
    transform: translate(108.225px, -15px) rotate(26deg);
    opacity: 1.1681955482;
  }

  86.2425% {
    transform: translate(119.55px, 8.625px) rotate(26deg);
    opacity: 0.5840977741;
  }

  100.0000% {
    transform: translate(131.25px, 37.5px) rotate(26deg);
    opacity: 0;
  }
}

@keyframes drop-right-28 {
  0.0000% {
    transform: translate(0px, 0px) rotate(137deg);
    opacity: 0.588378326;
  }

  10.7882% {
    transform: translate(-12.6px, -30px) rotate(137deg);
    opacity: 0.588378326;
  }

  21.2266% {
    transform: translate(-28px, -57.6px) rotate(137deg);
    opacity: 0.588378326;
  }

  30.4208% {
    transform: translate(-46.1px, -78.7px) rotate(137deg);
    opacity: 0.588378326;
  }

  34.4519% {
    transform: translate(-56.2px, -85.6px) rotate(137deg);
    opacity: 0.588378326;
  }

  38.2085% {
    transform: translate(-66.9px, -89.4px) rotate(137deg);
    opacity: 0.588378326;
  }

  41.9607% {
    transform: translate(-78.2px, -89.6px) rotate(137deg);
    opacity: 0.588378326;
  }

  46.1202% {
    transform: translate(-90.2px, -85.8px) rotate(137deg);
    opacity: 0.588378326;
  }

  51.1287% {
    transform: translate(-102.8px, -77.4px) rotate(137deg);
    opacity: 0.588378326;
  }

  57.3654% {
    transform: translate(-116px, -64px) rotate(137deg);
    opacity: 0.588378326;
  }

  65.1344% {
    transform: translate(-129.9px, -45px) rotate(137deg);
    opacity: 0.588378326;
  }

  74.6872% {
    transform: translate(-144.3px, -20px) rotate(137deg);
    opacity: 0.588378326;
  }

  86.2425% {
    transform: translate(-159.4px, 11.5px) rotate(137deg);
    opacity: 0.294189163;
  }

  100.0000% {
    transform: translate(-175px, 50px) rotate(137deg);
    opacity: 0;
  }
}

.confetti-left-29,
.confetti-right-29 {
  background-color: transparent;
  border-bottom: 0.5px solid #03a561;
  border-left: 0.25px solid transparent;
  border-right: 0.25px solid transparent;
  height: 0;
  width: 0.5px;
  top: calc(25% - 1px);
  left: calc(50% - 0.3px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-29 {
  animation: drop-left-29 1.4525618167s 0.7719546422s forwards;
}

.confetti-right-29 {
  animation: drop-right-29 1.5173046981s 1.4427447326s forwards;
}

@keyframes drop-left-29 {
  0.0000% {
    transform: translate(0px, 0px) rotate(307deg);
    opacity: 0.6664334181;
  }

  10.7882% {
    transform: translate(9.45px, -22.5px) rotate(307deg);
    opacity: 0.6664334181;
  }

  21.2266% {
    transform: translate(21px, -43.2px) rotate(307deg);
    opacity: 0.6664334181;
  }

  30.4208% {
    transform: translate(34.575px, -59.025px) rotate(307deg);
    opacity: 0.6664334181;
  }

  34.4519% {
    transform: translate(42.15px, -64.2px) rotate(307deg);
    opacity: 0.6664334181;
  }

  38.2085% {
    transform: translate(50.175px, -67.05px) rotate(307deg);
    opacity: 0.6664334181;
  }

  41.9607% {
    transform: translate(58.65px, -67.2px) rotate(307deg);
    opacity: 0.6664334181;
  }

  46.1202% {
    transform: translate(67.65px, -64.35px) rotate(307deg);
    opacity: 0.6664334181;
  }

  51.1287% {
    transform: translate(77.1px, -58.05px) rotate(307deg);
    opacity: 0.6664334181;
  }

  57.3654% {
    transform: translate(87px, -48px) rotate(307deg);
    opacity: 0.6664334181;
  }

  65.1344% {
    transform: translate(97.425px, -33.75px) rotate(307deg);
    opacity: 0.6664334181;
  }

  74.6872% {
    transform: translate(108.225px, -15px) rotate(307deg);
    opacity: 0.6664334181;
  }

  86.2425% {
    transform: translate(119.55px, 8.625px) rotate(307deg);
    opacity: 0.333216709;
  }

  100.0000% {
    transform: translate(131.25px, 37.5px) rotate(307deg);
    opacity: 0;
  }
}

@keyframes drop-right-29 {
  0.0000% {
    transform: translate(0px, 0px) rotate(15deg);
    opacity: 0.6019666452;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(15deg);
    opacity: 0.6019666452;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(15deg);
    opacity: 0.6019666452;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(15deg);
    opacity: 0.6019666452;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(15deg);
    opacity: 0.6019666452;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(15deg);
    opacity: 0.6019666452;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(15deg);
    opacity: 0.6019666452;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(15deg);
    opacity: 0.6019666452;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(15deg);
    opacity: 0.6019666452;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(15deg);
    opacity: 0.6019666452;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(15deg);
    opacity: 0.6019666452;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(15deg);
    opacity: 0.6019666452;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(15deg);
    opacity: 0.3009833226;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(15deg);
    opacity: 0;
  }
}

.confetti-left-30,
.confetti-right-30 {
  background-color: transparent;
  border-bottom: 0.5px solid #03a561;
  border-left: 0.25px solid transparent;
  border-right: 0.25px solid transparent;
  height: 0;
  width: 0.5px;
  top: calc(25% - 1px);
  left: calc(50% - 0.3px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-30 {
  animation: drop-left-30 1.4159723668s 0.6072758743s forwards;
}

.confetti-right-30 {
  animation: drop-right-30 1.198072154s 1.4188420005s forwards;
}

@keyframes drop-left-30 {
  0.0000% {
    transform: translate(0px, 0px) rotate(83deg);
    opacity: 0.5176568347;
  }

  10.7882% {
    transform: translate(15.75px, -37.5px) rotate(83deg);
    opacity: 0.5176568347;
  }

  21.2266% {
    transform: translate(35px, -72px) rotate(83deg);
    opacity: 0.5176568347;
  }

  30.4208% {
    transform: translate(57.625px, -98.375px) rotate(83deg);
    opacity: 0.5176568347;
  }

  34.4519% {
    transform: translate(70.25px, -107px) rotate(83deg);
    opacity: 0.5176568347;
  }

  38.2085% {
    transform: translate(83.625px, -111.75px) rotate(83deg);
    opacity: 0.5176568347;
  }

  41.9607% {
    transform: translate(97.75px, -112px) rotate(83deg);
    opacity: 0.5176568347;
  }

  46.1202% {
    transform: translate(112.75px, -107.25px) rotate(83deg);
    opacity: 0.5176568347;
  }

  51.1287% {
    transform: translate(128.5px, -96.75px) rotate(83deg);
    opacity: 0.5176568347;
  }

  57.3654% {
    transform: translate(145px, -80px) rotate(83deg);
    opacity: 0.5176568347;
  }

  65.1344% {
    transform: translate(162.375px, -56.25px) rotate(83deg);
    opacity: 0.5176568347;
  }

  74.6872% {
    transform: translate(180.375px, -25px) rotate(83deg);
    opacity: 0.5176568347;
  }

  86.2425% {
    transform: translate(199.25px, 14.375px) rotate(83deg);
    opacity: 0.2588284173;
  }

  100.0000% {
    transform: translate(218.75px, 62.5px) rotate(83deg);
    opacity: 0;
  }
}

@keyframes drop-right-30 {
  0.0000% {
    transform: translate(0px, 0px) rotate(170deg);
    opacity: 0.9267437088;
  }

  10.7882% {
    transform: translate(-22.05px, -52.5px) rotate(170deg);
    opacity: 0.9267437088;
  }

  21.2266% {
    transform: translate(-49px, -100.8px) rotate(170deg);
    opacity: 0.9267437088;
  }

  30.4208% {
    transform: translate(-80.675px, -137.725px) rotate(170deg);
    opacity: 0.9267437088;
  }

  34.4519% {
    transform: translate(-98.35px, -149.8px) rotate(170deg);
    opacity: 0.9267437088;
  }

  38.2085% {
    transform: translate(-117.075px, -156.45px) rotate(170deg);
    opacity: 0.9267437088;
  }

  41.9607% {
    transform: translate(-136.85px, -156.8px) rotate(170deg);
    opacity: 0.9267437088;
  }

  46.1202% {
    transform: translate(-157.85px, -150.15px) rotate(170deg);
    opacity: 0.9267437088;
  }

  51.1287% {
    transform: translate(-179.9px, -135.45px) rotate(170deg);
    opacity: 0.9267437088;
  }

  57.3654% {
    transform: translate(-203px, -112px) rotate(170deg);
    opacity: 0.9267437088;
  }

  65.1344% {
    transform: translate(-227.325px, -78.75px) rotate(170deg);
    opacity: 0.9267437088;
  }

  74.6872% {
    transform: translate(-252.525px, -35px) rotate(170deg);
    opacity: 0.9267437088;
  }

  86.2425% {
    transform: translate(-278.95px, 20.125px) rotate(170deg);
    opacity: 0.4633718544;
  }

  100.0000% {
    transform: translate(-306.25px, 87.5px) rotate(170deg);
    opacity: 0;
  }
}

.confetti-left-31,
.confetti-right-31 {
  background-color: transparent;
  border-bottom: 3px solid #03a561;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
  height: 0;
  width: 3px;
  top: calc(25% - 6px);
  left: calc(50% - 1.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-31 {
  animation: drop-left-31 1.9988414667s 0.7761045197s forwards;
}

.confetti-right-31 {
  animation: drop-right-31 1.1008846355s 0.8028662544s forwards;
}

@keyframes drop-left-31 {
  0.0000% {
    transform: translate(0px, 0px) rotate(232deg);
    opacity: 0.9383583206;
  }

  10.7882% {
    transform: translate(25.2px, -60px) rotate(232deg);
    opacity: 0.9383583206;
  }

  21.2266% {
    transform: translate(56px, -115.2px) rotate(232deg);
    opacity: 0.9383583206;
  }

  30.4208% {
    transform: translate(92.2px, -157.4px) rotate(232deg);
    opacity: 0.9383583206;
  }

  34.4519% {
    transform: translate(112.4px, -171.2px) rotate(232deg);
    opacity: 0.9383583206;
  }

  38.2085% {
    transform: translate(133.8px, -178.8px) rotate(232deg);
    opacity: 0.9383583206;
  }

  41.9607% {
    transform: translate(156.4px, -179.2px) rotate(232deg);
    opacity: 0.9383583206;
  }

  46.1202% {
    transform: translate(180.4px, -171.6px) rotate(232deg);
    opacity: 0.9383583206;
  }

  51.1287% {
    transform: translate(205.6px, -154.8px) rotate(232deg);
    opacity: 0.9383583206;
  }

  57.3654% {
    transform: translate(232px, -128px) rotate(232deg);
    opacity: 0.9383583206;
  }

  65.1344% {
    transform: translate(259.8px, -90px) rotate(232deg);
    opacity: 0.9383583206;
  }

  74.6872% {
    transform: translate(288.6px, -40px) rotate(232deg);
    opacity: 0.9383583206;
  }

  86.2425% {
    transform: translate(318.8px, 23px) rotate(232deg);
    opacity: 0.4691791603;
  }

  100.0000% {
    transform: translate(350px, 100px) rotate(232deg);
    opacity: 0;
  }
}

@keyframes drop-right-31 {
  0.0000% {
    transform: translate(0px, 0px) rotate(160deg);
    opacity: 1.2483477265;
  }

  10.7882% {
    transform: translate(-15.75px, -37.5px) rotate(160deg);
    opacity: 1.2483477265;
  }

  21.2266% {
    transform: translate(-35px, -72px) rotate(160deg);
    opacity: 1.2483477265;
  }

  30.4208% {
    transform: translate(-57.625px, -98.375px) rotate(160deg);
    opacity: 1.2483477265;
  }

  34.4519% {
    transform: translate(-70.25px, -107px) rotate(160deg);
    opacity: 1.2483477265;
  }

  38.2085% {
    transform: translate(-83.625px, -111.75px) rotate(160deg);
    opacity: 1.2483477265;
  }

  41.9607% {
    transform: translate(-97.75px, -112px) rotate(160deg);
    opacity: 1.2483477265;
  }

  46.1202% {
    transform: translate(-112.75px, -107.25px) rotate(160deg);
    opacity: 1.2483477265;
  }

  51.1287% {
    transform: translate(-128.5px, -96.75px) rotate(160deg);
    opacity: 1.2483477265;
  }

  57.3654% {
    transform: translate(-145px, -80px) rotate(160deg);
    opacity: 1.2483477265;
  }

  65.1344% {
    transform: translate(-162.375px, -56.25px) rotate(160deg);
    opacity: 1.2483477265;
  }

  74.6872% {
    transform: translate(-180.375px, -25px) rotate(160deg);
    opacity: 1.2483477265;
  }

  86.2425% {
    transform: translate(-199.25px, 14.375px) rotate(160deg);
    opacity: 0.6241738632;
  }

  100.0000% {
    transform: translate(-218.75px, 62.5px) rotate(160deg);
    opacity: 0;
  }
}

.confetti-left-32,
.confetti-right-32 {
  width: 4.2px;
  height: 4.2px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #00a4ff;
  top: calc(25% - 7px);
  left: calc(50% - 2.1px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-32 {
  animation: drop-left-32 1.8760630024s 0.9649909374s forwards;
}

.confetti-right-32 {
  animation: drop-right-32 1.9149713438s 1.4370194132s forwards;
}

@keyframes drop-left-32 {
  0.0000% {
    transform: translate(0px, 0px) rotate(71deg);
    opacity: 1.3743966802;
  }

  10.7882% {
    transform: translate(3.15px, -7.5px) rotate(71deg);
    opacity: 1.3743966802;
  }

  21.2266% {
    transform: translate(7px, -14.4px) rotate(71deg);
    opacity: 1.3743966802;
  }

  30.4208% {
    transform: translate(11.525px, -19.675px) rotate(71deg);
    opacity: 1.3743966802;
  }

  34.4519% {
    transform: translate(14.05px, -21.4px) rotate(71deg);
    opacity: 1.3743966802;
  }

  38.2085% {
    transform: translate(16.725px, -22.35px) rotate(71deg);
    opacity: 1.3743966802;
  }

  41.9607% {
    transform: translate(19.55px, -22.4px) rotate(71deg);
    opacity: 1.3743966802;
  }

  46.1202% {
    transform: translate(22.55px, -21.45px) rotate(71deg);
    opacity: 1.3743966802;
  }

  51.1287% {
    transform: translate(25.7px, -19.35px) rotate(71deg);
    opacity: 1.3743966802;
  }

  57.3654% {
    transform: translate(29px, -16px) rotate(71deg);
    opacity: 1.3743966802;
  }

  65.1344% {
    transform: translate(32.475px, -11.25px) rotate(71deg);
    opacity: 1.3743966802;
  }

  74.6872% {
    transform: translate(36.075px, -5px) rotate(71deg);
    opacity: 1.3743966802;
  }

  86.2425% {
    transform: translate(39.85px, 2.875px) rotate(71deg);
    opacity: 0.6871983401;
  }

  100.0000% {
    transform: translate(43.75px, 12.5px) rotate(71deg);
    opacity: 0;
  }
}

@keyframes drop-right-32 {
  0.0000% {
    transform: translate(0px, 0px) rotate(14deg);
    opacity: 0.7987372135;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(14deg);
    opacity: 0.7987372135;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(14deg);
    opacity: 0.7987372135;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(14deg);
    opacity: 0.7987372135;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(14deg);
    opacity: 0.7987372135;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(14deg);
    opacity: 0.7987372135;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(14deg);
    opacity: 0.7987372135;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(14deg);
    opacity: 0.7987372135;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(14deg);
    opacity: 0.7987372135;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(14deg);
    opacity: 0.7987372135;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(14deg);
    opacity: 0.7987372135;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(14deg);
    opacity: 0.7987372135;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(14deg);
    opacity: 0.3993686068;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(14deg);
    opacity: 0;
  }
}

.confetti-left-33,
.confetti-right-33 {
  background-color: transparent;
  border-bottom: 9px solid #00a4ff;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  height: 0;
  width: 9px;
  top: calc(25% - 18px);
  left: calc(50% - 5.4px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-33 {
  animation: drop-left-33 1.5132600002s 0.4765755766s forwards;
}

.confetti-right-33 {
  animation: drop-right-33 1.4438024161s 0.8915863434s forwards;
}

@keyframes drop-left-33 {
  0.0000% {
    transform: translate(0px, 0px) rotate(152deg);
    opacity: 0.5995801705;
  }

  10.7882% {
    transform: translate(15.75px, -37.5px) rotate(152deg);
    opacity: 0.5995801705;
  }

  21.2266% {
    transform: translate(35px, -72px) rotate(152deg);
    opacity: 0.5995801705;
  }

  30.4208% {
    transform: translate(57.625px, -98.375px) rotate(152deg);
    opacity: 0.5995801705;
  }

  34.4519% {
    transform: translate(70.25px, -107px) rotate(152deg);
    opacity: 0.5995801705;
  }

  38.2085% {
    transform: translate(83.625px, -111.75px) rotate(152deg);
    opacity: 0.5995801705;
  }

  41.9607% {
    transform: translate(97.75px, -112px) rotate(152deg);
    opacity: 0.5995801705;
  }

  46.1202% {
    transform: translate(112.75px, -107.25px) rotate(152deg);
    opacity: 0.5995801705;
  }

  51.1287% {
    transform: translate(128.5px, -96.75px) rotate(152deg);
    opacity: 0.5995801705;
  }

  57.3654% {
    transform: translate(145px, -80px) rotate(152deg);
    opacity: 0.5995801705;
  }

  65.1344% {
    transform: translate(162.375px, -56.25px) rotate(152deg);
    opacity: 0.5995801705;
  }

  74.6872% {
    transform: translate(180.375px, -25px) rotate(152deg);
    opacity: 0.5995801705;
  }

  86.2425% {
    transform: translate(199.25px, 14.375px) rotate(152deg);
    opacity: 0.2997900853;
  }

  100.0000% {
    transform: translate(218.75px, 62.5px) rotate(152deg);
    opacity: 0;
  }
}

@keyframes drop-right-33 {
  0.0000% {
    transform: translate(0px, 0px) rotate(347deg);
    opacity: 1.2240025339;
  }

  10.7882% {
    transform: translate(-9.45px, -22.5px) rotate(347deg);
    opacity: 1.2240025339;
  }

  21.2266% {
    transform: translate(-21px, -43.2px) rotate(347deg);
    opacity: 1.2240025339;
  }

  30.4208% {
    transform: translate(-34.575px, -59.025px) rotate(347deg);
    opacity: 1.2240025339;
  }

  34.4519% {
    transform: translate(-42.15px, -64.2px) rotate(347deg);
    opacity: 1.2240025339;
  }

  38.2085% {
    transform: translate(-50.175px, -67.05px) rotate(347deg);
    opacity: 1.2240025339;
  }

  41.9607% {
    transform: translate(-58.65px, -67.2px) rotate(347deg);
    opacity: 1.2240025339;
  }

  46.1202% {
    transform: translate(-67.65px, -64.35px) rotate(347deg);
    opacity: 1.2240025339;
  }

  51.1287% {
    transform: translate(-77.1px, -58.05px) rotate(347deg);
    opacity: 1.2240025339;
  }

  57.3654% {
    transform: translate(-87px, -48px) rotate(347deg);
    opacity: 1.2240025339;
  }

  65.1344% {
    transform: translate(-97.425px, -33.75px) rotate(347deg);
    opacity: 1.2240025339;
  }

  74.6872% {
    transform: translate(-108.225px, -15px) rotate(347deg);
    opacity: 1.2240025339;
  }

  86.2425% {
    transform: translate(-119.55px, 8.625px) rotate(347deg);
    opacity: 0.612001267;
  }

  100.0000% {
    transform: translate(-131.25px, 37.5px) rotate(347deg);
    opacity: 0;
  }
}

.confetti-left-34,
.confetti-right-34 {
  width: 10.8px;
  height: 10.8px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ff04d2;
  top: calc(25% - 18px);
  left: calc(50% - 5.4px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-34 {
  animation: drop-left-34 1.2951957909s 0.9390836914s forwards;
}

.confetti-right-34 {
  animation: drop-right-34 1.3816229847s 0.7897595678s forwards;
}

@keyframes drop-left-34 {
  0.0000% {
    transform: translate(0px, 0px) rotate(26deg);
    opacity: 1.4815326845;
  }

  10.7882% {
    transform: translate(3.15px, -7.5px) rotate(26deg);
    opacity: 1.4815326845;
  }

  21.2266% {
    transform: translate(7px, -14.4px) rotate(26deg);
    opacity: 1.4815326845;
  }

  30.4208% {
    transform: translate(11.525px, -19.675px) rotate(26deg);
    opacity: 1.4815326845;
  }

  34.4519% {
    transform: translate(14.05px, -21.4px) rotate(26deg);
    opacity: 1.4815326845;
  }

  38.2085% {
    transform: translate(16.725px, -22.35px) rotate(26deg);
    opacity: 1.4815326845;
  }

  41.9607% {
    transform: translate(19.55px, -22.4px) rotate(26deg);
    opacity: 1.4815326845;
  }

  46.1202% {
    transform: translate(22.55px, -21.45px) rotate(26deg);
    opacity: 1.4815326845;
  }

  51.1287% {
    transform: translate(25.7px, -19.35px) rotate(26deg);
    opacity: 1.4815326845;
  }

  57.3654% {
    transform: translate(29px, -16px) rotate(26deg);
    opacity: 1.4815326845;
  }

  65.1344% {
    transform: translate(32.475px, -11.25px) rotate(26deg);
    opacity: 1.4815326845;
  }

  74.6872% {
    transform: translate(36.075px, -5px) rotate(26deg);
    opacity: 1.4815326845;
  }

  86.2425% {
    transform: translate(39.85px, 2.875px) rotate(26deg);
    opacity: 0.7407663422;
  }

  100.0000% {
    transform: translate(43.75px, 12.5px) rotate(26deg);
    opacity: 0;
  }
}

@keyframes drop-right-34 {
  0.0000% {
    transform: translate(0px, 0px) rotate(356deg);
    opacity: 1.4041079274;
  }

  10.7882% {
    transform: translate(-28.35px, -67.5px) rotate(356deg);
    opacity: 1.4041079274;
  }

  21.2266% {
    transform: translate(-63px, -129.6px) rotate(356deg);
    opacity: 1.4041079274;
  }

  30.4208% {
    transform: translate(-103.725px, -177.075px) rotate(356deg);
    opacity: 1.4041079274;
  }

  34.4519% {
    transform: translate(-126.45px, -192.6px) rotate(356deg);
    opacity: 1.4041079274;
  }

  38.2085% {
    transform: translate(-150.525px, -201.15px) rotate(356deg);
    opacity: 1.4041079274;
  }

  41.9607% {
    transform: translate(-175.95px, -201.6px) rotate(356deg);
    opacity: 1.4041079274;
  }

  46.1202% {
    transform: translate(-202.95px, -193.05px) rotate(356deg);
    opacity: 1.4041079274;
  }

  51.1287% {
    transform: translate(-231.3px, -174.15px) rotate(356deg);
    opacity: 1.4041079274;
  }

  57.3654% {
    transform: translate(-261px, -144px) rotate(356deg);
    opacity: 1.4041079274;
  }

  65.1344% {
    transform: translate(-292.275px, -101.25px) rotate(356deg);
    opacity: 1.4041079274;
  }

  74.6872% {
    transform: translate(-324.675px, -45px) rotate(356deg);
    opacity: 1.4041079274;
  }

  86.2425% {
    transform: translate(-358.65px, 25.875px) rotate(356deg);
    opacity: 0.7020539637;
  }

  100.0000% {
    transform: translate(-393.75px, 112.5px) rotate(356deg);
    opacity: 0;
  }
}

.confetti-left-35,
.confetti-right-35 {
  background-color: transparent;
  border-bottom: 3px solid #03a561;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
  height: 0;
  width: 3px;
  top: calc(25% - 6px);
  left: calc(50% - 1.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-35 {
  animation: drop-left-35 1.2750393134s 0.7268466627s forwards;
}

.confetti-right-35 {
  animation: drop-right-35 1.406095299s 1.1576944952s forwards;
}

@keyframes drop-left-35 {
  0.0000% {
    transform: translate(0px, 0px) rotate(347deg);
    opacity: 1.2369684273;
  }

  10.7882% {
    transform: translate(15.75px, -37.5px) rotate(347deg);
    opacity: 1.2369684273;
  }

  21.2266% {
    transform: translate(35px, -72px) rotate(347deg);
    opacity: 1.2369684273;
  }

  30.4208% {
    transform: translate(57.625px, -98.375px) rotate(347deg);
    opacity: 1.2369684273;
  }

  34.4519% {
    transform: translate(70.25px, -107px) rotate(347deg);
    opacity: 1.2369684273;
  }

  38.2085% {
    transform: translate(83.625px, -111.75px) rotate(347deg);
    opacity: 1.2369684273;
  }

  41.9607% {
    transform: translate(97.75px, -112px) rotate(347deg);
    opacity: 1.2369684273;
  }

  46.1202% {
    transform: translate(112.75px, -107.25px) rotate(347deg);
    opacity: 1.2369684273;
  }

  51.1287% {
    transform: translate(128.5px, -96.75px) rotate(347deg);
    opacity: 1.2369684273;
  }

  57.3654% {
    transform: translate(145px, -80px) rotate(347deg);
    opacity: 1.2369684273;
  }

  65.1344% {
    transform: translate(162.375px, -56.25px) rotate(347deg);
    opacity: 1.2369684273;
  }

  74.6872% {
    transform: translate(180.375px, -25px) rotate(347deg);
    opacity: 1.2369684273;
  }

  86.2425% {
    transform: translate(199.25px, 14.375px) rotate(347deg);
    opacity: 0.6184842137;
  }

  100.0000% {
    transform: translate(218.75px, 62.5px) rotate(347deg);
    opacity: 0;
  }
}

@keyframes drop-right-35 {
  0.0000% {
    transform: translate(0px, 0px) rotate(21deg);
    opacity: 0.9533581054;
  }

  10.7882% {
    transform: translate(-3.15px, -7.5px) rotate(21deg);
    opacity: 0.9533581054;
  }

  21.2266% {
    transform: translate(-7px, -14.4px) rotate(21deg);
    opacity: 0.9533581054;
  }

  30.4208% {
    transform: translate(-11.525px, -19.675px) rotate(21deg);
    opacity: 0.9533581054;
  }

  34.4519% {
    transform: translate(-14.05px, -21.4px) rotate(21deg);
    opacity: 0.9533581054;
  }

  38.2085% {
    transform: translate(-16.725px, -22.35px) rotate(21deg);
    opacity: 0.9533581054;
  }

  41.9607% {
    transform: translate(-19.55px, -22.4px) rotate(21deg);
    opacity: 0.9533581054;
  }

  46.1202% {
    transform: translate(-22.55px, -21.45px) rotate(21deg);
    opacity: 0.9533581054;
  }

  51.1287% {
    transform: translate(-25.7px, -19.35px) rotate(21deg);
    opacity: 0.9533581054;
  }

  57.3654% {
    transform: translate(-29px, -16px) rotate(21deg);
    opacity: 0.9533581054;
  }

  65.1344% {
    transform: translate(-32.475px, -11.25px) rotate(21deg);
    opacity: 0.9533581054;
  }

  74.6872% {
    transform: translate(-36.075px, -5px) rotate(21deg);
    opacity: 0.9533581054;
  }

  86.2425% {
    transform: translate(-39.85px, 2.875px) rotate(21deg);
    opacity: 0.4766790527;
  }

  100.0000% {
    transform: translate(-43.75px, 12.5px) rotate(21deg);
    opacity: 0;
  }
}

.confetti-left-36,
.confetti-right-36 {
  background-color: transparent;
  border-bottom: 5px solid #fed500;
  border-left: 2.5px solid transparent;
  border-right: 2.5px solid transparent;
  height: 0;
  width: 5px;
  top: calc(25% - 10px);
  left: calc(50% - 3px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-36 {
  animation: drop-left-36 1.7993972739s 1.0577832898s forwards;
}

.confetti-right-36 {
  animation: drop-right-36 1.6538203516s 1.3520869935s forwards;
}

@keyframes drop-left-36 {
  0.0000% {
    transform: translate(0px, 0px) rotate(293deg);
    opacity: 0.7212262133;
  }

  10.7882% {
    transform: translate(3.15px, -7.5px) rotate(293deg);
    opacity: 0.7212262133;
  }

  21.2266% {
    transform: translate(7px, -14.4px) rotate(293deg);
    opacity: 0.7212262133;
  }

  30.4208% {
    transform: translate(11.525px, -19.675px) rotate(293deg);
    opacity: 0.7212262133;
  }

  34.4519% {
    transform: translate(14.05px, -21.4px) rotate(293deg);
    opacity: 0.7212262133;
  }

  38.2085% {
    transform: translate(16.725px, -22.35px) rotate(293deg);
    opacity: 0.7212262133;
  }

  41.9607% {
    transform: translate(19.55px, -22.4px) rotate(293deg);
    opacity: 0.7212262133;
  }

  46.1202% {
    transform: translate(22.55px, -21.45px) rotate(293deg);
    opacity: 0.7212262133;
  }

  51.1287% {
    transform: translate(25.7px, -19.35px) rotate(293deg);
    opacity: 0.7212262133;
  }

  57.3654% {
    transform: translate(29px, -16px) rotate(293deg);
    opacity: 0.7212262133;
  }

  65.1344% {
    transform: translate(32.475px, -11.25px) rotate(293deg);
    opacity: 0.7212262133;
  }

  74.6872% {
    transform: translate(36.075px, -5px) rotate(293deg);
    opacity: 0.7212262133;
  }

  86.2425% {
    transform: translate(39.85px, 2.875px) rotate(293deg);
    opacity: 0.3606131066;
  }

  100.0000% {
    transform: translate(43.75px, 12.5px) rotate(293deg);
    opacity: 0;
  }
}

@keyframes drop-right-36 {
  0.0000% {
    transform: translate(0px, 0px) rotate(250deg);
    opacity: 1.0352849362;
  }

  10.7882% {
    transform: translate(-9.45px, -22.5px) rotate(250deg);
    opacity: 1.0352849362;
  }

  21.2266% {
    transform: translate(-21px, -43.2px) rotate(250deg);
    opacity: 1.0352849362;
  }

  30.4208% {
    transform: translate(-34.575px, -59.025px) rotate(250deg);
    opacity: 1.0352849362;
  }

  34.4519% {
    transform: translate(-42.15px, -64.2px) rotate(250deg);
    opacity: 1.0352849362;
  }

  38.2085% {
    transform: translate(-50.175px, -67.05px) rotate(250deg);
    opacity: 1.0352849362;
  }

  41.9607% {
    transform: translate(-58.65px, -67.2px) rotate(250deg);
    opacity: 1.0352849362;
  }

  46.1202% {
    transform: translate(-67.65px, -64.35px) rotate(250deg);
    opacity: 1.0352849362;
  }

  51.1287% {
    transform: translate(-77.1px, -58.05px) rotate(250deg);
    opacity: 1.0352849362;
  }

  57.3654% {
    transform: translate(-87px, -48px) rotate(250deg);
    opacity: 1.0352849362;
  }

  65.1344% {
    transform: translate(-97.425px, -33.75px) rotate(250deg);
    opacity: 1.0352849362;
  }

  74.6872% {
    transform: translate(-108.225px, -15px) rotate(250deg);
    opacity: 1.0352849362;
  }

  86.2425% {
    transform: translate(-119.55px, 8.625px) rotate(250deg);
    opacity: 0.5176424681;
  }

  100.0000% {
    transform: translate(-131.25px, 37.5px) rotate(250deg);
    opacity: 0;
  }
}

.confetti-left-37,
.confetti-right-37 {
  background-color: transparent;
  border-bottom: 0.5px solid #03a561;
  border-left: 0.25px solid transparent;
  border-right: 0.25px solid transparent;
  height: 0;
  width: 0.5px;
  top: calc(25% - 1px);
  left: calc(50% - 0.3px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-37 {
  animation: drop-left-37 1.0729638507s 0.9865175385s forwards;
}

.confetti-right-37 {
  animation: drop-right-37 1.0219021134s 0.8354074368s forwards;
}

@keyframes drop-left-37 {
  0.0000% {
    transform: translate(0px, 0px) rotate(212deg);
    opacity: 0.5855658076;
  }

  10.7882% {
    transform: translate(18.9px, -45px) rotate(212deg);
    opacity: 0.5855658076;
  }

  21.2266% {
    transform: translate(42px, -86.4px) rotate(212deg);
    opacity: 0.5855658076;
  }

  30.4208% {
    transform: translate(69.15px, -118.05px) rotate(212deg);
    opacity: 0.5855658076;
  }

  34.4519% {
    transform: translate(84.3px, -128.4px) rotate(212deg);
    opacity: 0.5855658076;
  }

  38.2085% {
    transform: translate(100.35px, -134.1px) rotate(212deg);
    opacity: 0.5855658076;
  }

  41.9607% {
    transform: translate(117.3px, -134.4px) rotate(212deg);
    opacity: 0.5855658076;
  }

  46.1202% {
    transform: translate(135.3px, -128.7px) rotate(212deg);
    opacity: 0.5855658076;
  }

  51.1287% {
    transform: translate(154.2px, -116.1px) rotate(212deg);
    opacity: 0.5855658076;
  }

  57.3654% {
    transform: translate(174px, -96px) rotate(212deg);
    opacity: 0.5855658076;
  }

  65.1344% {
    transform: translate(194.85px, -67.5px) rotate(212deg);
    opacity: 0.5855658076;
  }

  74.6872% {
    transform: translate(216.45px, -30px) rotate(212deg);
    opacity: 0.5855658076;
  }

  86.2425% {
    transform: translate(239.1px, 17.25px) rotate(212deg);
    opacity: 0.2927829038;
  }

  100.0000% {
    transform: translate(262.5px, 75px) rotate(212deg);
    opacity: 0;
  }
}

@keyframes drop-right-37 {
  0.0000% {
    transform: translate(0px, 0px) rotate(124deg);
    opacity: 0.5508498991;
  }

  10.7882% {
    transform: translate(-18.9px, -45px) rotate(124deg);
    opacity: 0.5508498991;
  }

  21.2266% {
    transform: translate(-42px, -86.4px) rotate(124deg);
    opacity: 0.5508498991;
  }

  30.4208% {
    transform: translate(-69.15px, -118.05px) rotate(124deg);
    opacity: 0.5508498991;
  }

  34.4519% {
    transform: translate(-84.3px, -128.4px) rotate(124deg);
    opacity: 0.5508498991;
  }

  38.2085% {
    transform: translate(-100.35px, -134.1px) rotate(124deg);
    opacity: 0.5508498991;
  }

  41.9607% {
    transform: translate(-117.3px, -134.4px) rotate(124deg);
    opacity: 0.5508498991;
  }

  46.1202% {
    transform: translate(-135.3px, -128.7px) rotate(124deg);
    opacity: 0.5508498991;
  }

  51.1287% {
    transform: translate(-154.2px, -116.1px) rotate(124deg);
    opacity: 0.5508498991;
  }

  57.3654% {
    transform: translate(-174px, -96px) rotate(124deg);
    opacity: 0.5508498991;
  }

  65.1344% {
    transform: translate(-194.85px, -67.5px) rotate(124deg);
    opacity: 0.5508498991;
  }

  74.6872% {
    transform: translate(-216.45px, -30px) rotate(124deg);
    opacity: 0.5508498991;
  }

  86.2425% {
    transform: translate(-239.1px, 17.25px) rotate(124deg);
    opacity: 0.2754249495;
  }

  100.0000% {
    transform: translate(-262.5px, 75px) rotate(124deg);
    opacity: 0;
  }
}

.confetti-left-38,
.confetti-right-38 {
  width: 5px;
  height: 2px;
  background-color: #03a561;
  top: calc(25% - 5px);
  left: calc(50% - 1.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-38 {
  animation: drop-left-38 1.0661683181s 1.0937458816s forwards;
}

.confetti-right-38 {
  animation: drop-right-38 1.6467449711s 0.7964615282s forwards;
}

@keyframes drop-left-38 {
  0.0000% {
    transform: translate(0px, 0px) rotate(17deg);
    opacity: 1.3720644127;
  }

  10.7882% {
    transform: translate(12.6px, -30px) rotate(17deg);
    opacity: 1.3720644127;
  }

  21.2266% {
    transform: translate(28px, -57.6px) rotate(17deg);
    opacity: 1.3720644127;
  }

  30.4208% {
    transform: translate(46.1px, -78.7px) rotate(17deg);
    opacity: 1.3720644127;
  }

  34.4519% {
    transform: translate(56.2px, -85.6px) rotate(17deg);
    opacity: 1.3720644127;
  }

  38.2085% {
    transform: translate(66.9px, -89.4px) rotate(17deg);
    opacity: 1.3720644127;
  }

  41.9607% {
    transform: translate(78.2px, -89.6px) rotate(17deg);
    opacity: 1.3720644127;
  }

  46.1202% {
    transform: translate(90.2px, -85.8px) rotate(17deg);
    opacity: 1.3720644127;
  }

  51.1287% {
    transform: translate(102.8px, -77.4px) rotate(17deg);
    opacity: 1.3720644127;
  }

  57.3654% {
    transform: translate(116px, -64px) rotate(17deg);
    opacity: 1.3720644127;
  }

  65.1344% {
    transform: translate(129.9px, -45px) rotate(17deg);
    opacity: 1.3720644127;
  }

  74.6872% {
    transform: translate(144.3px, -20px) rotate(17deg);
    opacity: 1.3720644127;
  }

  86.2425% {
    transform: translate(159.4px, 11.5px) rotate(17deg);
    opacity: 0.6860322064;
  }

  100.0000% {
    transform: translate(175px, 50px) rotate(17deg);
    opacity: 0;
  }
}

@keyframes drop-right-38 {
  0.0000% {
    transform: translate(0px, 0px) rotate(13deg);
    opacity: 0.7270018068;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(13deg);
    opacity: 0.7270018068;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(13deg);
    opacity: 0.7270018068;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(13deg);
    opacity: 0.7270018068;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(13deg);
    opacity: 0.7270018068;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(13deg);
    opacity: 0.7270018068;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(13deg);
    opacity: 0.7270018068;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(13deg);
    opacity: 0.7270018068;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(13deg);
    opacity: 0.7270018068;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(13deg);
    opacity: 0.7270018068;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(13deg);
    opacity: 0.7270018068;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(13deg);
    opacity: 0.7270018068;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(13deg);
    opacity: 0.3635009034;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(13deg);
    opacity: 0;
  }
}

.confetti-left-39,
.confetti-right-39 {
  width: 6px;
  height: 2.4px;
  background-color: #fed500;
  top: calc(25% - 6px);
  left: calc(50% - 1.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-39 {
  animation: drop-left-39 1.924234531s 0.6769215386s forwards;
}

.confetti-right-39 {
  animation: drop-right-39 1.7810611545s 0.9480237173s forwards;
}

@keyframes drop-left-39 {
  0.0000% {
    transform: translate(0px, 0px) rotate(63deg);
    opacity: 0.9413026968;
  }

  10.7882% {
    transform: translate(3.15px, -7.5px) rotate(63deg);
    opacity: 0.9413026968;
  }

  21.2266% {
    transform: translate(7px, -14.4px) rotate(63deg);
    opacity: 0.9413026968;
  }

  30.4208% {
    transform: translate(11.525px, -19.675px) rotate(63deg);
    opacity: 0.9413026968;
  }

  34.4519% {
    transform: translate(14.05px, -21.4px) rotate(63deg);
    opacity: 0.9413026968;
  }

  38.2085% {
    transform: translate(16.725px, -22.35px) rotate(63deg);
    opacity: 0.9413026968;
  }

  41.9607% {
    transform: translate(19.55px, -22.4px) rotate(63deg);
    opacity: 0.9413026968;
  }

  46.1202% {
    transform: translate(22.55px, -21.45px) rotate(63deg);
    opacity: 0.9413026968;
  }

  51.1287% {
    transform: translate(25.7px, -19.35px) rotate(63deg);
    opacity: 0.9413026968;
  }

  57.3654% {
    transform: translate(29px, -16px) rotate(63deg);
    opacity: 0.9413026968;
  }

  65.1344% {
    transform: translate(32.475px, -11.25px) rotate(63deg);
    opacity: 0.9413026968;
  }

  74.6872% {
    transform: translate(36.075px, -5px) rotate(63deg);
    opacity: 0.9413026968;
  }

  86.2425% {
    transform: translate(39.85px, 2.875px) rotate(63deg);
    opacity: 0.4706513484;
  }

  100.0000% {
    transform: translate(43.75px, 12.5px) rotate(63deg);
    opacity: 0;
  }
}

@keyframes drop-right-39 {
  0.0000% {
    transform: translate(0px, 0px) rotate(215deg);
    opacity: 1.1149048909;
  }

  10.7882% {
    transform: translate(-3.15px, -7.5px) rotate(215deg);
    opacity: 1.1149048909;
  }

  21.2266% {
    transform: translate(-7px, -14.4px) rotate(215deg);
    opacity: 1.1149048909;
  }

  30.4208% {
    transform: translate(-11.525px, -19.675px) rotate(215deg);
    opacity: 1.1149048909;
  }

  34.4519% {
    transform: translate(-14.05px, -21.4px) rotate(215deg);
    opacity: 1.1149048909;
  }

  38.2085% {
    transform: translate(-16.725px, -22.35px) rotate(215deg);
    opacity: 1.1149048909;
  }

  41.9607% {
    transform: translate(-19.55px, -22.4px) rotate(215deg);
    opacity: 1.1149048909;
  }

  46.1202% {
    transform: translate(-22.55px, -21.45px) rotate(215deg);
    opacity: 1.1149048909;
  }

  51.1287% {
    transform: translate(-25.7px, -19.35px) rotate(215deg);
    opacity: 1.1149048909;
  }

  57.3654% {
    transform: translate(-29px, -16px) rotate(215deg);
    opacity: 1.1149048909;
  }

  65.1344% {
    transform: translate(-32.475px, -11.25px) rotate(215deg);
    opacity: 1.1149048909;
  }

  74.6872% {
    transform: translate(-36.075px, -5px) rotate(215deg);
    opacity: 1.1149048909;
  }

  86.2425% {
    transform: translate(-39.85px, 2.875px) rotate(215deg);
    opacity: 0.5574524454;
  }

  100.0000% {
    transform: translate(-43.75px, 12.5px) rotate(215deg);
    opacity: 0;
  }
}

.confetti-left-40,
.confetti-right-40 {
  width: 2px;
  height: 0.8px;
  background-color: #ff04d2;
  top: calc(25% - 2px);
  left: calc(50% - 0.6px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-40 {
  animation: drop-left-40 1.1238545123s 0.7583718132s forwards;
}

.confetti-right-40 {
  animation: drop-right-40 1.5167235985s 0.8200465727s forwards;
}

@keyframes drop-left-40 {
  0.0000% {
    transform: translate(0px, 0px) rotate(248deg);
    opacity: 0.6120569286;
  }

  10.7882% {
    transform: translate(3.15px, -7.5px) rotate(248deg);
    opacity: 0.6120569286;
  }

  21.2266% {
    transform: translate(7px, -14.4px) rotate(248deg);
    opacity: 0.6120569286;
  }

  30.4208% {
    transform: translate(11.525px, -19.675px) rotate(248deg);
    opacity: 0.6120569286;
  }

  34.4519% {
    transform: translate(14.05px, -21.4px) rotate(248deg);
    opacity: 0.6120569286;
  }

  38.2085% {
    transform: translate(16.725px, -22.35px) rotate(248deg);
    opacity: 0.6120569286;
  }

  41.9607% {
    transform: translate(19.55px, -22.4px) rotate(248deg);
    opacity: 0.6120569286;
  }

  46.1202% {
    transform: translate(22.55px, -21.45px) rotate(248deg);
    opacity: 0.6120569286;
  }

  51.1287% {
    transform: translate(25.7px, -19.35px) rotate(248deg);
    opacity: 0.6120569286;
  }

  57.3654% {
    transform: translate(29px, -16px) rotate(248deg);
    opacity: 0.6120569286;
  }

  65.1344% {
    transform: translate(32.475px, -11.25px) rotate(248deg);
    opacity: 0.6120569286;
  }

  74.6872% {
    transform: translate(36.075px, -5px) rotate(248deg);
    opacity: 0.6120569286;
  }

  86.2425% {
    transform: translate(39.85px, 2.875px) rotate(248deg);
    opacity: 0.3060284643;
  }

  100.0000% {
    transform: translate(43.75px, 12.5px) rotate(248deg);
    opacity: 0;
  }
}

@keyframes drop-right-40 {
  0.0000% {
    transform: translate(0px, 0px) rotate(75deg);
    opacity: 0.7612118329;
  }

  10.7882% {
    transform: translate(-28.35px, -67.5px) rotate(75deg);
    opacity: 0.7612118329;
  }

  21.2266% {
    transform: translate(-63px, -129.6px) rotate(75deg);
    opacity: 0.7612118329;
  }

  30.4208% {
    transform: translate(-103.725px, -177.075px) rotate(75deg);
    opacity: 0.7612118329;
  }

  34.4519% {
    transform: translate(-126.45px, -192.6px) rotate(75deg);
    opacity: 0.7612118329;
  }

  38.2085% {
    transform: translate(-150.525px, -201.15px) rotate(75deg);
    opacity: 0.7612118329;
  }

  41.9607% {
    transform: translate(-175.95px, -201.6px) rotate(75deg);
    opacity: 0.7612118329;
  }

  46.1202% {
    transform: translate(-202.95px, -193.05px) rotate(75deg);
    opacity: 0.7612118329;
  }

  51.1287% {
    transform: translate(-231.3px, -174.15px) rotate(75deg);
    opacity: 0.7612118329;
  }

  57.3654% {
    transform: translate(-261px, -144px) rotate(75deg);
    opacity: 0.7612118329;
  }

  65.1344% {
    transform: translate(-292.275px, -101.25px) rotate(75deg);
    opacity: 0.7612118329;
  }

  74.6872% {
    transform: translate(-324.675px, -45px) rotate(75deg);
    opacity: 0.7612118329;
  }

  86.2425% {
    transform: translate(-358.65px, 25.875px) rotate(75deg);
    opacity: 0.3806059165;
  }

  100.0000% {
    transform: translate(-393.75px, 112.5px) rotate(75deg);
    opacity: 0;
  }
}

.confetti-left-41,
.confetti-right-41 {
  background-color: transparent;
  border-bottom: 6.5px solid #03a561;
  border-left: 3.25px solid transparent;
  border-right: 3.25px solid transparent;
  height: 0;
  width: 6.5px;
  top: calc(25% - 13px);
  left: calc(50% - 3.9px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-41 {
  animation: drop-left-41 1.9989152806s 1.0711837949s forwards;
}

.confetti-right-41 {
  animation: drop-right-41 1.646949832s 0.6917403384s forwards;
}

@keyframes drop-left-41 {
  0.0000% {
    transform: translate(0px, 0px) rotate(4deg);
    opacity: 1.0966792032;
  }

  10.7882% {
    transform: translate(15.75px, -37.5px) rotate(4deg);
    opacity: 1.0966792032;
  }

  21.2266% {
    transform: translate(35px, -72px) rotate(4deg);
    opacity: 1.0966792032;
  }

  30.4208% {
    transform: translate(57.625px, -98.375px) rotate(4deg);
    opacity: 1.0966792032;
  }

  34.4519% {
    transform: translate(70.25px, -107px) rotate(4deg);
    opacity: 1.0966792032;
  }

  38.2085% {
    transform: translate(83.625px, -111.75px) rotate(4deg);
    opacity: 1.0966792032;
  }

  41.9607% {
    transform: translate(97.75px, -112px) rotate(4deg);
    opacity: 1.0966792032;
  }

  46.1202% {
    transform: translate(112.75px, -107.25px) rotate(4deg);
    opacity: 1.0966792032;
  }

  51.1287% {
    transform: translate(128.5px, -96.75px) rotate(4deg);
    opacity: 1.0966792032;
  }

  57.3654% {
    transform: translate(145px, -80px) rotate(4deg);
    opacity: 1.0966792032;
  }

  65.1344% {
    transform: translate(162.375px, -56.25px) rotate(4deg);
    opacity: 1.0966792032;
  }

  74.6872% {
    transform: translate(180.375px, -25px) rotate(4deg);
    opacity: 1.0966792032;
  }

  86.2425% {
    transform: translate(199.25px, 14.375px) rotate(4deg);
    opacity: 0.5483396016;
  }

  100.0000% {
    transform: translate(218.75px, 62.5px) rotate(4deg);
    opacity: 0;
  }
}

@keyframes drop-right-41 {
  0.0000% {
    transform: translate(0px, 0px) rotate(63deg);
    opacity: 1.0720766556;
  }

  10.7882% {
    transform: translate(-15.75px, -37.5px) rotate(63deg);
    opacity: 1.0720766556;
  }

  21.2266% {
    transform: translate(-35px, -72px) rotate(63deg);
    opacity: 1.0720766556;
  }

  30.4208% {
    transform: translate(-57.625px, -98.375px) rotate(63deg);
    opacity: 1.0720766556;
  }

  34.4519% {
    transform: translate(-70.25px, -107px) rotate(63deg);
    opacity: 1.0720766556;
  }

  38.2085% {
    transform: translate(-83.625px, -111.75px) rotate(63deg);
    opacity: 1.0720766556;
  }

  41.9607% {
    transform: translate(-97.75px, -112px) rotate(63deg);
    opacity: 1.0720766556;
  }

  46.1202% {
    transform: translate(-112.75px, -107.25px) rotate(63deg);
    opacity: 1.0720766556;
  }

  51.1287% {
    transform: translate(-128.5px, -96.75px) rotate(63deg);
    opacity: 1.0720766556;
  }

  57.3654% {
    transform: translate(-145px, -80px) rotate(63deg);
    opacity: 1.0720766556;
  }

  65.1344% {
    transform: translate(-162.375px, -56.25px) rotate(63deg);
    opacity: 1.0720766556;
  }

  74.6872% {
    transform: translate(-180.375px, -25px) rotate(63deg);
    opacity: 1.0720766556;
  }

  86.2425% {
    transform: translate(-199.25px, 14.375px) rotate(63deg);
    opacity: 0.5360383278;
  }

  100.0000% {
    transform: translate(-218.75px, 62.5px) rotate(63deg);
    opacity: 0;
  }
}

.confetti-left-42,
.confetti-right-42 {
  width: 16px;
  height: 6.4px;
  background-color: #fed500;
  top: calc(25% - 16px);
  left: calc(50% - 4.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-42 {
  animation: drop-left-42 1.6622070589s 0.9105107084s forwards;
}

.confetti-right-42 {
  animation: drop-right-42 1.2348088074s 1.3789448727s forwards;
}

@keyframes drop-left-42 {
  0.0000% {
    transform: translate(0px, 0px) rotate(215deg);
    opacity: 1.2493712252;
  }

  10.7882% {
    transform: translate(12.6px, -30px) rotate(215deg);
    opacity: 1.2493712252;
  }

  21.2266% {
    transform: translate(28px, -57.6px) rotate(215deg);
    opacity: 1.2493712252;
  }

  30.4208% {
    transform: translate(46.1px, -78.7px) rotate(215deg);
    opacity: 1.2493712252;
  }

  34.4519% {
    transform: translate(56.2px, -85.6px) rotate(215deg);
    opacity: 1.2493712252;
  }

  38.2085% {
    transform: translate(66.9px, -89.4px) rotate(215deg);
    opacity: 1.2493712252;
  }

  41.9607% {
    transform: translate(78.2px, -89.6px) rotate(215deg);
    opacity: 1.2493712252;
  }

  46.1202% {
    transform: translate(90.2px, -85.8px) rotate(215deg);
    opacity: 1.2493712252;
  }

  51.1287% {
    transform: translate(102.8px, -77.4px) rotate(215deg);
    opacity: 1.2493712252;
  }

  57.3654% {
    transform: translate(116px, -64px) rotate(215deg);
    opacity: 1.2493712252;
  }

  65.1344% {
    transform: translate(129.9px, -45px) rotate(215deg);
    opacity: 1.2493712252;
  }

  74.6872% {
    transform: translate(144.3px, -20px) rotate(215deg);
    opacity: 1.2493712252;
  }

  86.2425% {
    transform: translate(159.4px, 11.5px) rotate(215deg);
    opacity: 0.6246856126;
  }

  100.0000% {
    transform: translate(175px, 50px) rotate(215deg);
    opacity: 0;
  }
}

@keyframes drop-right-42 {
  0.0000% {
    transform: translate(0px, 0px) rotate(137deg);
    opacity: 1.2549894559;
  }

  10.7882% {
    transform: translate(-9.45px, -22.5px) rotate(137deg);
    opacity: 1.2549894559;
  }

  21.2266% {
    transform: translate(-21px, -43.2px) rotate(137deg);
    opacity: 1.2549894559;
  }

  30.4208% {
    transform: translate(-34.575px, -59.025px) rotate(137deg);
    opacity: 1.2549894559;
  }

  34.4519% {
    transform: translate(-42.15px, -64.2px) rotate(137deg);
    opacity: 1.2549894559;
  }

  38.2085% {
    transform: translate(-50.175px, -67.05px) rotate(137deg);
    opacity: 1.2549894559;
  }

  41.9607% {
    transform: translate(-58.65px, -67.2px) rotate(137deg);
    opacity: 1.2549894559;
  }

  46.1202% {
    transform: translate(-67.65px, -64.35px) rotate(137deg);
    opacity: 1.2549894559;
  }

  51.1287% {
    transform: translate(-77.1px, -58.05px) rotate(137deg);
    opacity: 1.2549894559;
  }

  57.3654% {
    transform: translate(-87px, -48px) rotate(137deg);
    opacity: 1.2549894559;
  }

  65.1344% {
    transform: translate(-97.425px, -33.75px) rotate(137deg);
    opacity: 1.2549894559;
  }

  74.6872% {
    transform: translate(-108.225px, -15px) rotate(137deg);
    opacity: 1.2549894559;
  }

  86.2425% {
    transform: translate(-119.55px, 8.625px) rotate(137deg);
    opacity: 0.627494728;
  }

  100.0000% {
    transform: translate(-131.25px, 37.5px) rotate(137deg);
    opacity: 0;
  }
}

.confetti-left-43,
.confetti-right-43 {
  background-color: transparent;
  border-bottom: 1px solid #00a4ff;
  border-left: 0.5px solid transparent;
  border-right: 0.5px solid transparent;
  height: 0;
  width: 1px;
  top: calc(25% - 2px);
  left: calc(50% - 0.6px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-43 {
  animation: drop-left-43 1.2755700978s 0.760623032s forwards;
}

.confetti-right-43 {
  animation: drop-right-43 1.2345351358s 0.6414880985s forwards;
}

@keyframes drop-left-43 {
  0.0000% {
    transform: translate(0px, 0px) rotate(33deg);
    opacity: 1.0368900181;
  }

  10.7882% {
    transform: translate(15.75px, -37.5px) rotate(33deg);
    opacity: 1.0368900181;
  }

  21.2266% {
    transform: translate(35px, -72px) rotate(33deg);
    opacity: 1.0368900181;
  }

  30.4208% {
    transform: translate(57.625px, -98.375px) rotate(33deg);
    opacity: 1.0368900181;
  }

  34.4519% {
    transform: translate(70.25px, -107px) rotate(33deg);
    opacity: 1.0368900181;
  }

  38.2085% {
    transform: translate(83.625px, -111.75px) rotate(33deg);
    opacity: 1.0368900181;
  }

  41.9607% {
    transform: translate(97.75px, -112px) rotate(33deg);
    opacity: 1.0368900181;
  }

  46.1202% {
    transform: translate(112.75px, -107.25px) rotate(33deg);
    opacity: 1.0368900181;
  }

  51.1287% {
    transform: translate(128.5px, -96.75px) rotate(33deg);
    opacity: 1.0368900181;
  }

  57.3654% {
    transform: translate(145px, -80px) rotate(33deg);
    opacity: 1.0368900181;
  }

  65.1344% {
    transform: translate(162.375px, -56.25px) rotate(33deg);
    opacity: 1.0368900181;
  }

  74.6872% {
    transform: translate(180.375px, -25px) rotate(33deg);
    opacity: 1.0368900181;
  }

  86.2425% {
    transform: translate(199.25px, 14.375px) rotate(33deg);
    opacity: 0.5184450091;
  }

  100.0000% {
    transform: translate(218.75px, 62.5px) rotate(33deg);
    opacity: 0;
  }
}

@keyframes drop-right-43 {
  0.0000% {
    transform: translate(0px, 0px) rotate(318deg);
    opacity: 0.9779873891;
  }

  10.7882% {
    transform: translate(-12.6px, -30px) rotate(318deg);
    opacity: 0.9779873891;
  }

  21.2266% {
    transform: translate(-28px, -57.6px) rotate(318deg);
    opacity: 0.9779873891;
  }

  30.4208% {
    transform: translate(-46.1px, -78.7px) rotate(318deg);
    opacity: 0.9779873891;
  }

  34.4519% {
    transform: translate(-56.2px, -85.6px) rotate(318deg);
    opacity: 0.9779873891;
  }

  38.2085% {
    transform: translate(-66.9px, -89.4px) rotate(318deg);
    opacity: 0.9779873891;
  }

  41.9607% {
    transform: translate(-78.2px, -89.6px) rotate(318deg);
    opacity: 0.9779873891;
  }

  46.1202% {
    transform: translate(-90.2px, -85.8px) rotate(318deg);
    opacity: 0.9779873891;
  }

  51.1287% {
    transform: translate(-102.8px, -77.4px) rotate(318deg);
    opacity: 0.9779873891;
  }

  57.3654% {
    transform: translate(-116px, -64px) rotate(318deg);
    opacity: 0.9779873891;
  }

  65.1344% {
    transform: translate(-129.9px, -45px) rotate(318deg);
    opacity: 0.9779873891;
  }

  74.6872% {
    transform: translate(-144.3px, -20px) rotate(318deg);
    opacity: 0.9779873891;
  }

  86.2425% {
    transform: translate(-159.4px, 11.5px) rotate(318deg);
    opacity: 0.4889936945;
  }

  100.0000% {
    transform: translate(-175px, 50px) rotate(318deg);
    opacity: 0;
  }
}

.confetti-left-44,
.confetti-right-44 {
  width: 3.6px;
  height: 3.6px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #00a4ff;
  top: calc(25% - 6px);
  left: calc(50% - 1.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-44 {
  animation: drop-left-44 1.5855423706s 0.8210552329s forwards;
}

.confetti-right-44 {
  animation: drop-right-44 1.104783461s 0.5040832963s forwards;
}

@keyframes drop-left-44 {
  0.0000% {
    transform: translate(0px, 0px) rotate(353deg);
    opacity: 1.2535597185;
  }

  10.7882% {
    transform: translate(6.3px, -15px) rotate(353deg);
    opacity: 1.2535597185;
  }

  21.2266% {
    transform: translate(14px, -28.8px) rotate(353deg);
    opacity: 1.2535597185;
  }

  30.4208% {
    transform: translate(23.05px, -39.35px) rotate(353deg);
    opacity: 1.2535597185;
  }

  34.4519% {
    transform: translate(28.1px, -42.8px) rotate(353deg);
    opacity: 1.2535597185;
  }

  38.2085% {
    transform: translate(33.45px, -44.7px) rotate(353deg);
    opacity: 1.2535597185;
  }

  41.9607% {
    transform: translate(39.1px, -44.8px) rotate(353deg);
    opacity: 1.2535597185;
  }

  46.1202% {
    transform: translate(45.1px, -42.9px) rotate(353deg);
    opacity: 1.2535597185;
  }

  51.1287% {
    transform: translate(51.4px, -38.7px) rotate(353deg);
    opacity: 1.2535597185;
  }

  57.3654% {
    transform: translate(58px, -32px) rotate(353deg);
    opacity: 1.2535597185;
  }

  65.1344% {
    transform: translate(64.95px, -22.5px) rotate(353deg);
    opacity: 1.2535597185;
  }

  74.6872% {
    transform: translate(72.15px, -10px) rotate(353deg);
    opacity: 1.2535597185;
  }

  86.2425% {
    transform: translate(79.7px, 5.75px) rotate(353deg);
    opacity: 0.6267798592;
  }

  100.0000% {
    transform: translate(87.5px, 25px) rotate(353deg);
    opacity: 0;
  }
}

@keyframes drop-right-44 {
  0.0000% {
    transform: translate(0px, 0px) rotate(290deg);
    opacity: 1.2249995958;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(290deg);
    opacity: 1.2249995958;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(290deg);
    opacity: 1.2249995958;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(290deg);
    opacity: 1.2249995958;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(290deg);
    opacity: 1.2249995958;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(290deg);
    opacity: 1.2249995958;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(290deg);
    opacity: 1.2249995958;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(290deg);
    opacity: 1.2249995958;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(290deg);
    opacity: 1.2249995958;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(290deg);
    opacity: 1.2249995958;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(290deg);
    opacity: 1.2249995958;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(290deg);
    opacity: 1.2249995958;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(290deg);
    opacity: 0.6124997979;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(290deg);
    opacity: 0;
  }
}

.confetti-left-45,
.confetti-right-45 {
  background-color: transparent;
  border-bottom: 6.5px solid #00a4ff;
  border-left: 3.25px solid transparent;
  border-right: 3.25px solid transparent;
  height: 0;
  width: 6.5px;
  top: calc(25% - 13px);
  left: calc(50% - 3.9px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-45 {
  animation: drop-left-45 1.0199402814s 1.3600077252s forwards;
}

.confetti-right-45 {
  animation: drop-right-45 1.4417091188s 1.09969986s forwards;
}

@keyframes drop-left-45 {
  0.0000% {
    transform: translate(0px, 0px) rotate(157deg);
    opacity: 0.784708788;
  }

  10.7882% {
    transform: translate(12.6px, -30px) rotate(157deg);
    opacity: 0.784708788;
  }

  21.2266% {
    transform: translate(28px, -57.6px) rotate(157deg);
    opacity: 0.784708788;
  }

  30.4208% {
    transform: translate(46.1px, -78.7px) rotate(157deg);
    opacity: 0.784708788;
  }

  34.4519% {
    transform: translate(56.2px, -85.6px) rotate(157deg);
    opacity: 0.784708788;
  }

  38.2085% {
    transform: translate(66.9px, -89.4px) rotate(157deg);
    opacity: 0.784708788;
  }

  41.9607% {
    transform: translate(78.2px, -89.6px) rotate(157deg);
    opacity: 0.784708788;
  }

  46.1202% {
    transform: translate(90.2px, -85.8px) rotate(157deg);
    opacity: 0.784708788;
  }

  51.1287% {
    transform: translate(102.8px, -77.4px) rotate(157deg);
    opacity: 0.784708788;
  }

  57.3654% {
    transform: translate(116px, -64px) rotate(157deg);
    opacity: 0.784708788;
  }

  65.1344% {
    transform: translate(129.9px, -45px) rotate(157deg);
    opacity: 0.784708788;
  }

  74.6872% {
    transform: translate(144.3px, -20px) rotate(157deg);
    opacity: 0.784708788;
  }

  86.2425% {
    transform: translate(159.4px, 11.5px) rotate(157deg);
    opacity: 0.392354394;
  }

  100.0000% {
    transform: translate(175px, 50px) rotate(157deg);
    opacity: 0;
  }
}

@keyframes drop-right-45 {
  0.0000% {
    transform: translate(0px, 0px) rotate(355deg);
    opacity: 0.517017912;
  }

  10.7882% {
    transform: translate(-15.75px, -37.5px) rotate(355deg);
    opacity: 0.517017912;
  }

  21.2266% {
    transform: translate(-35px, -72px) rotate(355deg);
    opacity: 0.517017912;
  }

  30.4208% {
    transform: translate(-57.625px, -98.375px) rotate(355deg);
    opacity: 0.517017912;
  }

  34.4519% {
    transform: translate(-70.25px, -107px) rotate(355deg);
    opacity: 0.517017912;
  }

  38.2085% {
    transform: translate(-83.625px, -111.75px) rotate(355deg);
    opacity: 0.517017912;
  }

  41.9607% {
    transform: translate(-97.75px, -112px) rotate(355deg);
    opacity: 0.517017912;
  }

  46.1202% {
    transform: translate(-112.75px, -107.25px) rotate(355deg);
    opacity: 0.517017912;
  }

  51.1287% {
    transform: translate(-128.5px, -96.75px) rotate(355deg);
    opacity: 0.517017912;
  }

  57.3654% {
    transform: translate(-145px, -80px) rotate(355deg);
    opacity: 0.517017912;
  }

  65.1344% {
    transform: translate(-162.375px, -56.25px) rotate(355deg);
    opacity: 0.517017912;
  }

  74.6872% {
    transform: translate(-180.375px, -25px) rotate(355deg);
    opacity: 0.517017912;
  }

  86.2425% {
    transform: translate(-199.25px, 14.375px) rotate(355deg);
    opacity: 0.258508956;
  }

  100.0000% {
    transform: translate(-218.75px, 62.5px) rotate(355deg);
    opacity: 0;
  }
}

.confetti-left-46,
.confetti-right-46 {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f04813;
  top: calc(25% - 5px);
  left: calc(50% - 1.5px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-46 {
  animation: drop-left-46 1.7953237274s 1.2586983393s forwards;
}

.confetti-right-46 {
  animation: drop-right-46 1.3630098732s 0.5308633194s forwards;
}

@keyframes drop-left-46 {
  0.0000% {
    transform: translate(0px, 0px) rotate(309deg);
    opacity: 0.9882551921;
  }

  10.7882% {
    transform: translate(28.35px, -67.5px) rotate(309deg);
    opacity: 0.9882551921;
  }

  21.2266% {
    transform: translate(63px, -129.6px) rotate(309deg);
    opacity: 0.9882551921;
  }

  30.4208% {
    transform: translate(103.725px, -177.075px) rotate(309deg);
    opacity: 0.9882551921;
  }

  34.4519% {
    transform: translate(126.45px, -192.6px) rotate(309deg);
    opacity: 0.9882551921;
  }

  38.2085% {
    transform: translate(150.525px, -201.15px) rotate(309deg);
    opacity: 0.9882551921;
  }

  41.9607% {
    transform: translate(175.95px, -201.6px) rotate(309deg);
    opacity: 0.9882551921;
  }

  46.1202% {
    transform: translate(202.95px, -193.05px) rotate(309deg);
    opacity: 0.9882551921;
  }

  51.1287% {
    transform: translate(231.3px, -174.15px) rotate(309deg);
    opacity: 0.9882551921;
  }

  57.3654% {
    transform: translate(261px, -144px) rotate(309deg);
    opacity: 0.9882551921;
  }

  65.1344% {
    transform: translate(292.275px, -101.25px) rotate(309deg);
    opacity: 0.9882551921;
  }

  74.6872% {
    transform: translate(324.675px, -45px) rotate(309deg);
    opacity: 0.9882551921;
  }

  86.2425% {
    transform: translate(358.65px, 25.875px) rotate(309deg);
    opacity: 0.4941275961;
  }

  100.0000% {
    transform: translate(393.75px, 112.5px) rotate(309deg);
    opacity: 0;
  }
}

@keyframes drop-right-46 {
  0.0000% {
    transform: translate(0px, 0px) rotate(319deg);
    opacity: 0.9934502502;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(319deg);
    opacity: 0.9934502502;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(319deg);
    opacity: 0.9934502502;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(319deg);
    opacity: 0.9934502502;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(319deg);
    opacity: 0.9934502502;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(319deg);
    opacity: 0.9934502502;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(319deg);
    opacity: 0.9934502502;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(319deg);
    opacity: 0.9934502502;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(319deg);
    opacity: 0.9934502502;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(319deg);
    opacity: 0.9934502502;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(319deg);
    opacity: 0.9934502502;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(319deg);
    opacity: 0.9934502502;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(319deg);
    opacity: 0.4967251251;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(319deg);
    opacity: 0;
  }
}

.confetti-left-47,
.confetti-right-47 {
  width: 6px;
  height: 2.4px;
  background-color: #f04813;
  top: calc(25% - 6px);
  left: calc(50% - 1.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-47 {
  animation: drop-left-47 1.5005281585s 1.1939019693s forwards;
}

.confetti-right-47 {
  animation: drop-right-47 1.1581131932s 1.1317454745s forwards;
}

@keyframes drop-left-47 {
  0.0000% {
    transform: translate(0px, 0px) rotate(272deg);
    opacity: 1.3464237806;
  }

  10.7882% {
    transform: translate(12.6px, -30px) rotate(272deg);
    opacity: 1.3464237806;
  }

  21.2266% {
    transform: translate(28px, -57.6px) rotate(272deg);
    opacity: 1.3464237806;
  }

  30.4208% {
    transform: translate(46.1px, -78.7px) rotate(272deg);
    opacity: 1.3464237806;
  }

  34.4519% {
    transform: translate(56.2px, -85.6px) rotate(272deg);
    opacity: 1.3464237806;
  }

  38.2085% {
    transform: translate(66.9px, -89.4px) rotate(272deg);
    opacity: 1.3464237806;
  }

  41.9607% {
    transform: translate(78.2px, -89.6px) rotate(272deg);
    opacity: 1.3464237806;
  }

  46.1202% {
    transform: translate(90.2px, -85.8px) rotate(272deg);
    opacity: 1.3464237806;
  }

  51.1287% {
    transform: translate(102.8px, -77.4px) rotate(272deg);
    opacity: 1.3464237806;
  }

  57.3654% {
    transform: translate(116px, -64px) rotate(272deg);
    opacity: 1.3464237806;
  }

  65.1344% {
    transform: translate(129.9px, -45px) rotate(272deg);
    opacity: 1.3464237806;
  }

  74.6872% {
    transform: translate(144.3px, -20px) rotate(272deg);
    opacity: 1.3464237806;
  }

  86.2425% {
    transform: translate(159.4px, 11.5px) rotate(272deg);
    opacity: 0.6732118903;
  }

  100.0000% {
    transform: translate(175px, 50px) rotate(272deg);
    opacity: 0;
  }
}

@keyframes drop-right-47 {
  0.0000% {
    transform: translate(0px, 0px) rotate(20deg);
    opacity: 1.1231503517;
  }

  10.7882% {
    transform: translate(-18.9px, -45px) rotate(20deg);
    opacity: 1.1231503517;
  }

  21.2266% {
    transform: translate(-42px, -86.4px) rotate(20deg);
    opacity: 1.1231503517;
  }

  30.4208% {
    transform: translate(-69.15px, -118.05px) rotate(20deg);
    opacity: 1.1231503517;
  }

  34.4519% {
    transform: translate(-84.3px, -128.4px) rotate(20deg);
    opacity: 1.1231503517;
  }

  38.2085% {
    transform: translate(-100.35px, -134.1px) rotate(20deg);
    opacity: 1.1231503517;
  }

  41.9607% {
    transform: translate(-117.3px, -134.4px) rotate(20deg);
    opacity: 1.1231503517;
  }

  46.1202% {
    transform: translate(-135.3px, -128.7px) rotate(20deg);
    opacity: 1.1231503517;
  }

  51.1287% {
    transform: translate(-154.2px, -116.1px) rotate(20deg);
    opacity: 1.1231503517;
  }

  57.3654% {
    transform: translate(-174px, -96px) rotate(20deg);
    opacity: 1.1231503517;
  }

  65.1344% {
    transform: translate(-194.85px, -67.5px) rotate(20deg);
    opacity: 1.1231503517;
  }

  74.6872% {
    transform: translate(-216.45px, -30px) rotate(20deg);
    opacity: 1.1231503517;
  }

  86.2425% {
    transform: translate(-239.1px, 17.25px) rotate(20deg);
    opacity: 0.5615751758;
  }

  100.0000% {
    transform: translate(-262.5px, 75px) rotate(20deg);
    opacity: 0;
  }
}

.confetti-left-48,
.confetti-right-48 {
  background-color: transparent;
  border-bottom: 3.5px solid #ff04d2;
  border-left: 1.75px solid transparent;
  border-right: 1.75px solid transparent;
  height: 0;
  width: 3.5px;
  top: calc(25% - 7px);
  left: calc(50% - 2.1px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-48 {
  animation: drop-left-48 1.325287071s 0.7612134995s forwards;
}

.confetti-right-48 {
  animation: drop-right-48 1.102822868s 1.3553451337s forwards;
}

@keyframes drop-left-48 {
  0.0000% {
    transform: translate(0px, 0px) rotate(28deg);
    opacity: 0.8618208101;
  }

  10.7882% {
    transform: translate(25.2px, -60px) rotate(28deg);
    opacity: 0.8618208101;
  }

  21.2266% {
    transform: translate(56px, -115.2px) rotate(28deg);
    opacity: 0.8618208101;
  }

  30.4208% {
    transform: translate(92.2px, -157.4px) rotate(28deg);
    opacity: 0.8618208101;
  }

  34.4519% {
    transform: translate(112.4px, -171.2px) rotate(28deg);
    opacity: 0.8618208101;
  }

  38.2085% {
    transform: translate(133.8px, -178.8px) rotate(28deg);
    opacity: 0.8618208101;
  }

  41.9607% {
    transform: translate(156.4px, -179.2px) rotate(28deg);
    opacity: 0.8618208101;
  }

  46.1202% {
    transform: translate(180.4px, -171.6px) rotate(28deg);
    opacity: 0.8618208101;
  }

  51.1287% {
    transform: translate(205.6px, -154.8px) rotate(28deg);
    opacity: 0.8618208101;
  }

  57.3654% {
    transform: translate(232px, -128px) rotate(28deg);
    opacity: 0.8618208101;
  }

  65.1344% {
    transform: translate(259.8px, -90px) rotate(28deg);
    opacity: 0.8618208101;
  }

  74.6872% {
    transform: translate(288.6px, -40px) rotate(28deg);
    opacity: 0.8618208101;
  }

  86.2425% {
    transform: translate(318.8px, 23px) rotate(28deg);
    opacity: 0.430910405;
  }

  100.0000% {
    transform: translate(350px, 100px) rotate(28deg);
    opacity: 0;
  }
}

@keyframes drop-right-48 {
  0.0000% {
    transform: translate(0px, 0px) rotate(196deg);
    opacity: 1.1406462544;
  }

  10.7882% {
    transform: translate(-12.6px, -30px) rotate(196deg);
    opacity: 1.1406462544;
  }

  21.2266% {
    transform: translate(-28px, -57.6px) rotate(196deg);
    opacity: 1.1406462544;
  }

  30.4208% {
    transform: translate(-46.1px, -78.7px) rotate(196deg);
    opacity: 1.1406462544;
  }

  34.4519% {
    transform: translate(-56.2px, -85.6px) rotate(196deg);
    opacity: 1.1406462544;
  }

  38.2085% {
    transform: translate(-66.9px, -89.4px) rotate(196deg);
    opacity: 1.1406462544;
  }

  41.9607% {
    transform: translate(-78.2px, -89.6px) rotate(196deg);
    opacity: 1.1406462544;
  }

  46.1202% {
    transform: translate(-90.2px, -85.8px) rotate(196deg);
    opacity: 1.1406462544;
  }

  51.1287% {
    transform: translate(-102.8px, -77.4px) rotate(196deg);
    opacity: 1.1406462544;
  }

  57.3654% {
    transform: translate(-116px, -64px) rotate(196deg);
    opacity: 1.1406462544;
  }

  65.1344% {
    transform: translate(-129.9px, -45px) rotate(196deg);
    opacity: 1.1406462544;
  }

  74.6872% {
    transform: translate(-144.3px, -20px) rotate(196deg);
    opacity: 1.1406462544;
  }

  86.2425% {
    transform: translate(-159.4px, 11.5px) rotate(196deg);
    opacity: 0.5703231272;
  }

  100.0000% {
    transform: translate(-175px, 50px) rotate(196deg);
    opacity: 0;
  }
}

.confetti-left-49,
.confetti-right-49 {
  background-color: transparent;
  border-bottom: 4px solid #fed500;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  height: 0;
  width: 4px;
  top: calc(25% - 8px);
  left: calc(50% - 2.4px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-49 {
  animation: drop-left-49 1.646685391s 0.7388253798s forwards;
}

.confetti-right-49 {
  animation: drop-right-49 1.5633571743s 0.5838035674s forwards;
}

@keyframes drop-left-49 {
  0.0000% {
    transform: translate(0px, 0px) rotate(151deg);
    opacity: 0.5207978709;
  }

  10.7882% {
    transform: translate(9.45px, -22.5px) rotate(151deg);
    opacity: 0.5207978709;
  }

  21.2266% {
    transform: translate(21px, -43.2px) rotate(151deg);
    opacity: 0.5207978709;
  }

  30.4208% {
    transform: translate(34.575px, -59.025px) rotate(151deg);
    opacity: 0.5207978709;
  }

  34.4519% {
    transform: translate(42.15px, -64.2px) rotate(151deg);
    opacity: 0.5207978709;
  }

  38.2085% {
    transform: translate(50.175px, -67.05px) rotate(151deg);
    opacity: 0.5207978709;
  }

  41.9607% {
    transform: translate(58.65px, -67.2px) rotate(151deg);
    opacity: 0.5207978709;
  }

  46.1202% {
    transform: translate(67.65px, -64.35px) rotate(151deg);
    opacity: 0.5207978709;
  }

  51.1287% {
    transform: translate(77.1px, -58.05px) rotate(151deg);
    opacity: 0.5207978709;
  }

  57.3654% {
    transform: translate(87px, -48px) rotate(151deg);
    opacity: 0.5207978709;
  }

  65.1344% {
    transform: translate(97.425px, -33.75px) rotate(151deg);
    opacity: 0.5207978709;
  }

  74.6872% {
    transform: translate(108.225px, -15px) rotate(151deg);
    opacity: 0.5207978709;
  }

  86.2425% {
    transform: translate(119.55px, 8.625px) rotate(151deg);
    opacity: 0.2603989354;
  }

  100.0000% {
    transform: translate(131.25px, 37.5px) rotate(151deg);
    opacity: 0;
  }
}

@keyframes drop-right-49 {
  0.0000% {
    transform: translate(0px, 0px) rotate(193deg);
    opacity: 0.7306543135;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(193deg);
    opacity: 0.7306543135;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(193deg);
    opacity: 0.7306543135;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(193deg);
    opacity: 0.7306543135;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(193deg);
    opacity: 0.7306543135;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(193deg);
    opacity: 0.7306543135;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(193deg);
    opacity: 0.7306543135;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(193deg);
    opacity: 0.7306543135;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(193deg);
    opacity: 0.7306543135;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(193deg);
    opacity: 0.7306543135;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(193deg);
    opacity: 0.7306543135;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(193deg);
    opacity: 0.7306543135;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(193deg);
    opacity: 0.3653271568;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(193deg);
    opacity: 0;
  }
}

.confetti-left-50,
.confetti-right-50 {
  width: 3.6px;
  height: 3.6px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #03a561;
  top: calc(25% - 6px);
  left: calc(50% - 1.8px);
  opacity: 0;
  transform: translate(0px, 0px) rotate(0deg);
}

.confetti-left-50 {
  animation: drop-left-50 1.1336642014s 1.028025654s forwards;
}

.confetti-right-50 {
  animation: drop-right-50 1.5665646996s 1.0266586823s forwards;
}

@keyframes drop-left-50 {
  0.0000% {
    transform: translate(0px, 0px) rotate(55deg);
    opacity: 0.7530514615;
  }

  10.7882% {
    transform: translate(28.35px, -67.5px) rotate(55deg);
    opacity: 0.7530514615;
  }

  21.2266% {
    transform: translate(63px, -129.6px) rotate(55deg);
    opacity: 0.7530514615;
  }

  30.4208% {
    transform: translate(103.725px, -177.075px) rotate(55deg);
    opacity: 0.7530514615;
  }

  34.4519% {
    transform: translate(126.45px, -192.6px) rotate(55deg);
    opacity: 0.7530514615;
  }

  38.2085% {
    transform: translate(150.525px, -201.15px) rotate(55deg);
    opacity: 0.7530514615;
  }

  41.9607% {
    transform: translate(175.95px, -201.6px) rotate(55deg);
    opacity: 0.7530514615;
  }

  46.1202% {
    transform: translate(202.95px, -193.05px) rotate(55deg);
    opacity: 0.7530514615;
  }

  51.1287% {
    transform: translate(231.3px, -174.15px) rotate(55deg);
    opacity: 0.7530514615;
  }

  57.3654% {
    transform: translate(261px, -144px) rotate(55deg);
    opacity: 0.7530514615;
  }

  65.1344% {
    transform: translate(292.275px, -101.25px) rotate(55deg);
    opacity: 0.7530514615;
  }

  74.6872% {
    transform: translate(324.675px, -45px) rotate(55deg);
    opacity: 0.7530514615;
  }

  86.2425% {
    transform: translate(358.65px, 25.875px) rotate(55deg);
    opacity: 0.3765257308;
  }

  100.0000% {
    transform: translate(393.75px, 112.5px) rotate(55deg);
    opacity: 0;
  }
}

@keyframes drop-right-50 {
  0.0000% {
    transform: translate(0px, 0px) rotate(353deg);
    opacity: 0.8749801587;
  }

  10.7882% {
    transform: translate(-6.3px, -15px) rotate(353deg);
    opacity: 0.8749801587;
  }

  21.2266% {
    transform: translate(-14px, -28.8px) rotate(353deg);
    opacity: 0.8749801587;
  }

  30.4208% {
    transform: translate(-23.05px, -39.35px) rotate(353deg);
    opacity: 0.8749801587;
  }

  34.4519% {
    transform: translate(-28.1px, -42.8px) rotate(353deg);
    opacity: 0.8749801587;
  }

  38.2085% {
    transform: translate(-33.45px, -44.7px) rotate(353deg);
    opacity: 0.8749801587;
  }

  41.9607% {
    transform: translate(-39.1px, -44.8px) rotate(353deg);
    opacity: 0.8749801587;
  }

  46.1202% {
    transform: translate(-45.1px, -42.9px) rotate(353deg);
    opacity: 0.8749801587;
  }

  51.1287% {
    transform: translate(-51.4px, -38.7px) rotate(353deg);
    opacity: 0.8749801587;
  }

  57.3654% {
    transform: translate(-58px, -32px) rotate(353deg);
    opacity: 0.8749801587;
  }

  65.1344% {
    transform: translate(-64.95px, -22.5px) rotate(353deg);
    opacity: 0.8749801587;
  }

  74.6872% {
    transform: translate(-72.15px, -10px) rotate(353deg);
    opacity: 0.8749801587;
  }

  86.2425% {
    transform: translate(-79.7px, 5.75px) rotate(353deg);
    opacity: 0.4374900794;
  }

  100.0000% {
    transform: translate(-87.5px, 25px) rotate(353deg);
    opacity: 0;
  }
}

.confetti {
  position: relative;
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 154px;
}

.confetti__wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.banner {
  width: 100%;
  border-radius: 6px;
  text-align: center;
  padding: 4px 16px;
  position: relative;
  z-index: 2;
}

.step-btn {
  background: #26597d !important;
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}

.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4CAF50;
}

.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}

.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
}


@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.step-white-color {
  background: #fff;
}

.step-gray-color {
  background: #eaeaea;
}

.MuiSnackbar-anchorOriginBottomCenter {
  z-index: 10000 !important;
}

.question-input textarea {
  background: #fff;
}

.question-input input:focus {
  outline: 0;
  border-bottom: 1px solid #1976d2;
}

.question-input:after {
  border: 0 !important;
}

@media only screen and (max-width: 600px) {
  .modal{
    .modal-dialog.dkg-applyjob-ModalDailog{
      min-width: 100%;
      max-width: 100%;
      .modal-content{
        .dkg-applyjob-header{
          .dk-detViewTitle{
            font-size: 14px;
            padding: 5px 0px 5px 0px;
          }
        }
        .dkg-applyjob-modal-body{
          background: #fff !important;
          &.dkg-applyjob-modalbody-234{
            .MuiStepper-horizontal{
              height: 100px !important;
            }
            .step-div{
              background: #eaeaea;
              .step-white-bg{
                height: 54vh !important;
               span{
                font-family: "Roboto","Helvetica","Arial",sans-serif !important;
                font-size:1rem !important;
                color:#000 !important;
                font-weight: 400 !important;
               }
               p{
                font-weight: 400 !important;
                font-family: "Roboto","Helvetica","Arial",sans-serif !important;
                font-size:1rem !important;
                color:#333333 !important;
               }
              }
              .step-white-bg1{
                height: 60vh !important;
                overflow-y:auto;
               span{
                font-family: "Roboto","Helvetica","Arial",sans-serif !important;
                font-size:1rem !important;
                color:#000 !important;
                font-weight: 400 !important;
               }
               p{
                font-weight: 400 !important;
                font-family: "Roboto","Helvetica","Arial",sans-serif !important;
                font-size:1rem !important;
                color:#333333 !important;
               }
              }

              .step-white-bg2{
                height: 70.3vh !important;
                overflow-y:auto;
              }

              .step-white-bg3{
                height: 59vh !important;
                overflow-y:auto;
               span{
                font-family: "Roboto","Helvetica","Arial",sans-serif !important;
                font-size:1rem !important;
                color:#000 !important;
                font-weight: 400 !important;
               }
               p{
                font-weight: 400 !important;
                font-family: "Roboto","Helvetica","Arial",sans-serif !important;
                font-size:1rem !important;
                color:#333333 !important;
               }
              }

              .MuiFormGroup-root.css-1f4qvys-MuiFormGroup-root{
                 margin-right: 0 !important;
                 padding: 5px;
              }
            }
            .MuiBox-root.css-hike3q{
              padding: 7px 10px;
              position: fixed;
              bottom: 0;
              width: 100%;
              .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary{
                 .MuiButton-endIcon{
                  svg{
                    width: 14px;
                    height: 14px;
                  }
                 }
              }
              .MuiButtonBase-root.MuiButton-root{
                .MuiButton-startIcon{
                  svg{
                    width: 14px;
                    height: 14px;
                  }
                }
              }
            }
            // .MuiBox-root{
            //   .MuiStepper-root.MuiStepper-horizontal{
            //     color: #00a4ff;
            //     &:nth-child(02){

            //     }
            //   }
            // }
          }
        }
      }
    }
  }
}


.my-tick {
  display: block;
  height: 60px;
  width: 60px;
  color: #4caf50;
  margin-top: 15px;
  margin-bottom: 20px;
  /* SVG path use currentColor to inherit this */
}

.circle {
  stroke-dasharray: 76;
  stroke-dashoffset: 76;
  -webkit-animation: draw 1s forwards;
          animation: draw 1s forwards;
}

.tick {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  -webkit-animation: draw 1s forwards 1s;
          animation: draw 1s forwards 1s;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
.checkboxJob{
  align-items:baseline !important;
  span{
    align-items:flex-start !important;
  }
}
.MuiDialog-paperScrollPaper{
  overflow: unset !important;
  background:none !important;
}
.confirmBtn:hover{
   background:#b6b4b4 !important
}
@media only screen and (max-width: 600px) {
.stepHeight{
  height:100px
}
.checkboxHeight{
  height:115px !important;
}
}
.checkboxHeight{
  height:79px;
}
.MuiStepper-horizontal{
  height: 100px !important;
}