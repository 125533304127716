.dkg-services-mainpageCon{
    // margin-top: 154px;
    // background-color: #1f394c;
    // padding: 0;
    // height: calc(100vh - 168px);
    // overflow-y: auto;
    // overflow-x: hidden;
    margin-top: 154px;
    background-color: #eaeaea;
    padding: 15px 0;
    padding-bottom: 0;
    height: calc(100vh - 168px);
    overflow-x: hidden;
    overflow-y: hidden;
    .dkg-services-mainCol{
        background-color: #eaeaea;
        padding: 0;
        .dkg-services-page-title{
            font-weight: 700;
            font-size: 24px;
            color: #333;
            line-height: 1.2em;
            text-align: center;
            margin-top: 10px;
        }
        .dkg-services-page-text{
            font-size: 1.1rem;
            color: #333;
            text-align: justify;
            padding: 10px 0;
            padding-bottom: 0;
            width: 100%;
            text-align: center;
            max-width: 75%;
            margin: 0 auto;
        }
        .dkg-cnacc-legal-mainCon{
            margin-top: 30px;
            .dkg-cnacc-legal-banner{
                width: 100%;
                max-width: 52%;
                margin: 0 auto;
                background-color: #FFDE59;
                position: relative; 
                .dkg-banner-upper-cross{
                    color: #f3f3f3;
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    background-color: #FFDE59;
                    // margin-bottom: 1em;

                }   
                .dkg-banner-upper-cross {
                    border: 20px solid #2D2E2C;
                    border-width: 20px 0;
                    border-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, rgb(45, 46, 44) 5px, rgb(45, 46, 44) 10px) 20 0;
                    .dkg-pd-legal-content{
                    //    border-left: 2px solid #2D2E2C;
                    //    border-right: 2px solid #2D2E2C;
                       height: 100%;
                       padding: 1.25rem 0;
                       padding-bottom: 1.75rem;
                      .dkg-pd-legal-content-title{
                        font-size: 2.10rem;
                        text-align: center;
                        width: 100%;
                        color: #2D2E2C;
                        padding: 1rem 0;
                        padding-top: 1.75rem;
                        font-weight: bold;
                        .dkg-legal-doc-icon{
                            margin-left: 0.5rem;
                            position: relative;
                            top: -3px;
                           svg{
                            width: 26px;
                            height: 26px;
                            color: #EE404C;
                           } 
                        }
                      }
                      .dkg-pd-legal-content-body{
                        padding: 1.25rem 3.55rem;
                        padding-bottom: 3rem;
                        padding-top: 0.25rem;
                        .dkg-pd-legal-content-card{
                            background-color: #FEF1A3;
                            border-radius: 0;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            .dkg-legal-content-card-left{                                    
                                width: 20%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #a99f9f;
                                .dkg-legal-content-imgCon{
                                     width: 105px;
                                     height: 105px;
                                    .dkg-legal-content-img{
                                        width: 100%;
                                        height: auto;
                                        background-size: cover;
                                    }
                                }
                            }
                            .dkg-legal-content-card-right{
                                width: 80%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #a99f9f;
                                border-left: none;
                                .dkg-legal-card-content{
                                    text-align: justify;
                                    color: #2D2E2C;
                                    padding: 0 15px;
                                    font-size: 1rem;
                                }
                            }
                        }
                      }
                   }
                }
            }
            
        }
    }
    .dkg-services-container{
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        .dkg-services-mainRow{
            .dkg-services-mainCol{
                padding-bottom: 5px;
                .dkg-services-page-title{
                    font-weight: 700;
                    font-size: 24px;
                    color: #333;
                    line-height: 1.2em;
                    text-align: center;
                    margin-top: 10px;
    
                }
                .dkg-services-page-text{
                    font-size: 1.10rem;
                    color: #333;
                    text-align: justify;
                    padding: 10px 0;
                    padding-bottom: 0;
                    width: 100%;
                    text-align: center;
                    max-width: 75%;
                    margin: 0 auto;
                }
                .dkg-cnacc-legal-mainCon{
                    margin-top: 30px;
                    .dkg-cnacc-legal-banner{
                        width: 100%;
                        max-width: 52%;
                        margin: 0 auto;
                        background-color: #FFDE59;
                        position: relative; 
                        .dkg-banner-upper-cross{
                            color: #f3f3f3;
                            display: block;
                            width: 100%;
                            height: 100%;
                            padding: 0;
                            background-color: #FFDE59;
                            // margin-bottom: 1em;

                        }   
                        .dkg-banner-upper-cross {
                            border: 20px solid #2D2E2C;
                            border-width: 20px 0;
                            border-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, rgb(45, 46, 44) 5px, rgb(45, 46, 44) 10px) 20 0;
                            .dkg-pd-legal-content{
                            //    border-left: 2px solid #2D2E2C;
                            //    border-right: 2px solid #2D2E2C;
                               height: 100%;
                               padding: 1.25rem 0;
                               padding-bottom: 1.75rem;
                              .dkg-pd-legal-content-title{
                                font-size: 2.10rem;
                                text-align: center;
                                width: 100%;
                                color: #2D2E2C;
                                padding: 1rem 0;
                                padding-top: 1.75rem;
                                font-weight: bold;
                                .dkg-legal-doc-icon{
                                    margin-left: 0.5rem;
                                    position: relative;
                                    top: -3px;
                                   svg{
                                    width: 26px;
                                    height: 26px;
                                    color: #EE404C;
                                   } 
                                }
                              }
                              .dkg-pd-legal-content-body{
                                padding: 1.25rem 3.55rem;
                                padding-bottom: 3rem;
                                padding-top: 0.25rem;
                                .dkg-pd-legal-content-card{
                                    background-color: #FEF1A3;
                                    border-radius: 0;
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    .dkg-legal-content-card-left{                                    
                                        width: 20%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 1px solid #a99f9f;
                                        .dkg-legal-content-imgCon{
                                             width: 105px;
                                             height: 105px;
                                            .dkg-legal-content-img{
                                                width: 100%;
                                                height: auto;
                                                background-size: cover;
                                            }
                                        }
                                    }
                                    .dkg-legal-content-card-right{
                                        width: 80%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 1px solid #a99f9f;
                                        border-left: none;
                                        .dkg-legal-card-content{
                                            text-align: justify;
                                            color: #2D2E2C;
                                            padding: 0 15px;
                                            font-size: 1rem;
                                        }
                                    }
                                }
                              }
                           }
                        }
                    }
                    
                }
            }
            .dkg-services-box{
                background-color: #fff;
                padding: 15px;
                border: 1px solid #ddd;
                border-radius: 5px;
                @media (min-width: 992px){
                    &.col-lg-4 {
                        flex: 0 0 32.40%;
                        max-width: 32.40%;
                        margin: 7px;
                    }
                }
                
                @media (min-width: 768px){
                    &.col-md-4 {
                        flex: 0 0 32.40%;
                        max-width: 32.40%;
                        margin: 7px;
                    }
                }
                .dkg-services-imgCon{
                    width: 100%;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                    .dkg-services-img{
                        width: 60px;
                        height: 60px;
                    }
                }
                .dkg-service-desc-textCon{
                    .dkg-service-desc{
                        font-weight: 700;
                        font-size: 20px;
                        color: #2a2a2a;
                        line-height: 1.4em;
                        text-align: center;
                        margin-top: 1.15rem;
                        width: 100%;
                    }
                    .dkg-service-text{
                        color: #000;
                        text-align: center;
                    }
                }
                .dkg-service-box-btnCon{
                    margin-top: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .dkg-service-box-btn{
                        display: inline-block;
                        color: #3c97b6;
                        border-width: 1px;
                        border-radius: 40px;
                        letter-spacing: 0px;
                        font-size: 14px;
                        background-color: #ffffff;
                        padding: 7px 20px;
                        border: 1px solid #3c97b6;
                        text-transform: inherit;
                        box-shadow: none;
                        transition: all ease-in 0.5ms;
                        &:hover{
                            background-color: #3c97b6;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .dkg-services-boxsCon{
            width: 100%;
            .dkg-myservices-boxs{
                display: flex;
                .dkg-services-box{
                    background-color: #fff;
                    padding: 15px;
                    border: 1px solid #b0d8e5;
                    border-radius: 5px;
                    width: 100%;
                    max-width: 100%;
                    margin: 12px;
                    @media (min-width: 992px){
                        &.col-lg-4 {
                            flex: 0 0 32.40%;
                            max-width: 32.40%;
                            margin: 7px;
                        }
                    }
                    
                    @media (min-width: 768px){
                        &.col-md-4 {
                            flex: 0 0 32.40%;
                            max-width: 32.40%;
                            margin: 7px;
                        }
                    }
                    .dkg-services-imgCon{
                        width: 100%;
                       display: flex;
                       align-items: center;
                       justify-content: center;
                        .dkg-services-img{
                            width: 60px;
                            height: 60px;
                        }
                    }
                    .dkg-service-desc-textCon{
                        .dkg-service-desc{
                            font-weight: 700;
                            font-size: 18px;
                            color: #2a2a2a;
                            line-height: 1.4em;
                            text-align: center;
                            margin-top: 1.15rem;
                            width: 100%;
                        }
                        .dkg-service-text{
                            color: #000;
                            text-align: center;
                        }
                    }
                    .dkg-service-box-btnCon{
                        margin-top: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .dkg-service-box-btn{
                            display: inline-block;
                            border-width: 1px;
                            border-radius: 40px;
                            letter-spacing: 0px;
                            font-size: 14px;
                            padding: 7px 20px;
                            border: 1px solid #3c97b6;
                            text-transform: inherit;
                            box-shadow: none;
                            transition: all ease-in 0.5ms;
                            background-color: #ffffff;
                            color: #3c97b6;
                            &:hover{
                                background-color: #3c97b6;
                                color: #fff;    
                                border: 1px solid #3c97b6;
                            }
                        }
                    }
                    &:nth-child(01),
                    &:nth-child(04){
                        margin-left: 0;
                    }
                    &:nth-child(03),
                    &:nth-child(06){
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .dkg-about-service-blueCon{
        margin-top: 0.94375rem;
        border-top: 1px solid #1f394c;
        width: 100%;
        padding: 0.75rem 0.94375rem;
        background-color: #1f394c;
        height: calc(100vh - 295px);
        overflow-x: hidden;
        overflow-y: auto;
        .dkg-service-block-con{
            width: 100%;
            max-width: 1350px;
            margin: 0 auto;
            .dkg-service-mainRow{
                .dkg-about-service-leftcon{
                    padding-right: 2.5rem;
                    .dkg-about-service-imgCon{
                        width: 100%;
                        height: 427px;
                        .dkg-about-service-img{
                            width: 100%;
                            height: 100%;
                            position: relative;
                            top: 0.5rem;
                            object-fit: scale-down;
                        }
                    }
                }
                .dkg-about-service-rightcon {
                    padding-left: 2.5rem;
                    .dkg-about-us-titleCon{
                        .dkg-about-us-title{
                            font-size: 18px;
                            line-height: 24px;
                            margin-bottom: 1rem;
                            font-weight: 600;
                            color: #ddd;
                        }
                    }
                    .dkg-about-us-subtitleCon{
                        .dkg-about-us-subtitle{
                            font-size: 38px;
                            line-height: 38px;
                            font-weight: 600;
                            color: #ddd;
                            margin-top: 2.35rem;
                            margin-bottom: 0.75rem;
                        }
                    }
                    .dkg-about-us-desc{
                        p{
                            color: #ddd;
                            font-size: 1.15rem;
                            margin-bottom: 0.75rem;
                            text-align: justify;
                        }
                    }
                    .dkg-aboutus-cardCon{
                        margin-top: 2rem;
                        .dkg-aboutus-card-ul{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            gap: 4.5rem;
                            .dkg-aboutus-card-li{
                                width: 100%;
                                max-width: 30.33%;
                                list-style: none;

                                .dkg-aboutus-card{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                    width: 100%;
                                    height: 100px;
                                    background-color: #eaeaea;
                                    text-align: center;
                                    gap: 1rem;
                                    border-radius: 5px;
                                    &:hover{
                                       background-color: #f2f2f2;
                                        -moz-transform: scale(1.1);
                                        -o-transform: scale(1.1);
                                        -ms-transform: scale(1.1);
                                        -webkit-transform: scale(1.1);
                                        transform: scale(1.1);
                                    }
                                    .dkg-aboutus-icon{
                                        svg{
                                            width: 24px;
                                            height: 24px;
                                            color: #666;
                                        }
                                        .dkg-aboutus-img{
                                            width: 45px;
                                            height: 45px;
                                        }
                                    }
                                    .dkg-aboutus-cardtitle{
                                        color: #333;
                                        font-size: 1.1rem;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-services-mainpageCon{
        margin-top: 35px;
        height: calc(100vh - 50px);
        overflow-y: hidden;
        .dkg-services-mainCol{
            .dkg-services-page-text{
                max-width: 100%;
                text-align: justify;
                padding: 0 0.94375rem;
            }
        }
        .dkg-jobseeker-service-boxcols{
            padding: 0 0.94375rem !important;
            height: calc(100vh - 279px);
        }
        .dkg-services-container{
            .dkg-services-mainRow{
                .dkg-services-mainCol{
                    .dkg-services-page-title{
                        font-size: 1.5rem;
                    }
                    .dkg-services-page-text{
                        max-width: 100%;
                        font-size: 0.9rem;
                        text-align: justify;
                    }
                }
            }
            .dkg-services-boxsCon{
                margin: 1rem 0;
                .dkg-myservices-boxs{
                    flex-direction: column;
                    gap: 1rem;
                    .dkg-services-box{
                        margin: inherit !important;
                        // &:last-child{
                        //     margin-top: 1rem !important;
                        // }
                        .dkg-services-imgCon{
                            .dkg-services-img{
                                width: 50px;
                                height: 50%;
                            }
                        }
                        .dkg-service-desc-textCon{
                            .dkg-service-desc{
                                font-size: 1rem;
                            }
                            .dkg-service-text{
                                text-align: justify;
                                font-size: 0.9rem;
                            }
                        }
                        .dkg-service-box-btnCon{
                            .dkg-service-box-btn{
                                font-size: 0.85rem;
                            }
                        }
                        &:nth-child(03){
                            margin-bottom: 1rem !important;
                        }
                    }
                }
            }
        }
    }
}