
    .dkg-infoicon{
        margin-left: 8px;
        top: -2px;
        position: relative;
        svg{
            width: 20px;
            height: 20px;
            cursor: pointer;
    
        }
        &.dkg-pricing-info-icon{
            .dkg-pricing-info{
                width: 18px;
                height: 18px;
                color: #9c9797;
            }
        }
        .notdisplayed {
            display: none;
        }
        .displayed {
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            position: absolute;
            top: -9px;
            left: 35px;
            background-color: #1f394c;
            color: #ddd;
            padding: 8px;
            border: 1px solid transparent;
            box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.20), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
            border-radius: 4px;
            width: 420px;
            height: 120px;
            z-index: 9999;
            p{
                font-size: 13px;
                text-transform: lowercase !important;
                font-weight: normal;
                text-align: justify;  
                &::first-letter{
                    text-transform: capitalize !important;
                }          
            }
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-color: transparent #1f394c transparent transparent;
                border-style: solid;
                border-width: 8.5px 12px 8.5px 0;
                line-height: 0;
                _border-color: #000 #1f394c #000 #000;
                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                position: absolute;
                left: -12px;
                top: 10px;
            }
        }
    }
    .dkg-mobile-pophvr-modal-21 {
        .modal-dialog {
            position: fixed;
            margin: auto;
            right: 0;
            top: -15px;
            min-width: 80% !important;
            max-width: 80%;
            height: 100%;
            -webkit-transform: translate3d(0%, 0, 0);
            -ms-transform: translate3d(0%, 0, 0);
            -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
    
            .modal-content {
                height: 100%;
                overflow-y: auto;
                border-radius: 0;
    
                .dkg-referal-terms-modalheader-21 {
                    // background-color: #234158;
                    background-color: #234158;
                    border-radius: 0;
                    padding: 14px 15px !important;
                    width: 100%;
                    border-bottom: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    .modal-title {
                        color: #fff;
                        text-transform: uppercase;
                        margin: 0;
                        opacity: .90;
                        font-size: 1.2rem;
                        position: relative;
                        padding-top: 1px;
                        line-height: 1.8rem;
                        width: 100%;
                        text-align: center;
    
    
                    }
    
                    .close {
                        margin: 0;
                        padding: 0;
                        text-shadow: none;
                        opacity: .90;
                        color: #fff;
                        // font-size: 1.82rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                        height: 53px;
                        position: absolute;
                        right: 2px;
                        top: -5px;
    
                        &:hover {
                            background-color: #eaeaea;
                            color: #333;
                        }
                    }
                }
    
                .dkg-referal-terms-modalbody-21 {
                    background-color: #eaeaea;
                    padding: 15px;
                    height: calc(100vh - 84px);
                    overflow-y: auto;
    
                }
                .dkg-mobile-pophvr-modal-hdr{
                    background-color: #3c97b6;
                    border-bottom: none;
                    .close{
                        color: #fff;
                        opacity: 1;
                        &:hover{
                            opacity: 0.75;
                        }
                    }
                }
                .dkg-mobile-popuphvr-body{
                    background-color: #1f394c;
                    border: none;
                    border-radius: 0;
                    box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
                    color: #ddd;
                    height: calc(100vh - 45px);
                    overflow-y: auto;
                    .dkg-pophvr-content-con{
                        p{
                            color: #ddd;
                            font-size: 13px;
                            text-align: justify;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    
    .modal.dkg-mobile-pophvr-modal-21.fade .modal-dialog {
        right: -40%;
        -webkit-transition: opacity 0.3s linear, right .3s ease-out;
        -moz-transition: opacity 0.3s linear, right .3s ease-out;
        -o-transition: opacity 0.3s linear, right .3s ease-out;
        transition: opacity 0.3s linear, right .3s ease-out;
    }
    
    .modal.dkg-mobile-pophvr-modal-21.show .modal-dialog {
        right: 0;
    }
    
    .modal.dkg-mobile-pophvr-modal-21 {
        z-index: 9999 !important;
    }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-infoicon{
        z-index: 9;
        .displayed {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        position: absolute;
        top: 35px;
        left: -253px;
        height: auto;
        width: 320px;
            &::after{
                left: 50px;
                top: -11px;
                transform: rotate(333deg);
            }
        }
    }
    .dk-qulaifyTopHeadTitle{
        color: #000;
        .title{
            display: none;
        }
        .dkg-mobinfo-title {
            font-weight: 700;
            font-size: 22px;
            color: #333;
            line-height: 1.2em;
            text-align: center;
            text-transform: uppercase;
            display: block !important;
            text-align: center;
            .dkg-mo-pophvr-icon{
                margin-left: 8px;
                top: -2px;
                position: relative;
                svg {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }
            }

        }
    }
}