:root {
    --color-white-100: #fff;
    --color-white-200: #e4e6e7;
    --color-white-300: #c9cccf;
    --color-white-400: #949a9e;
    --color-white-500: #798086;
    --color-black-100: #101419;
    --color-black-200: #0c0f13;
    --color-black-300: #0c0f13;
    --color-black-400: #080a0c;
    --color-black-500: #060709;
    --color-pink-100: #fbd0e8;
    --color-pink-200: #f8a0d0;
    --color-pink-300: #f471b5;
    --color-pink-400: #ec4699;
    --color-pink-500: #da2576;
    --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.dkg-candmo-container {
    max-width: 75rem;
    height: auto;
    margin: 0 auto;
    padding: 0 1.25rem;
}
.dkg-candmo-brand {
    font-family: inherit;
    font-size: 0.85.6rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: var(--color-pink-500);
}
.dkg-candmo-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 100;
    margin: 0 auto;
    box-shadow: var(--shadow-medium);
    background-color: var(--color-white-100);
}
.dkg-candmo-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 2rem;
    width: 100%;
    height: 3.5rem;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    padding: 0 0.5rem;
}
@media screen and (max-width: 992px) {
    .dkg-candmo-navbar {
        position: fixed;
        top: 0;
        left: -100%;
        width: 75%;
        height: 100%;
        z-index: 10;
        opacity: 0;
        overflow-y: auto;
        visibility: hidden;
        box-shadow: var(--shadow-medium);
        background-color: var(--color-white-100);
        transition: all 0.5s ease;
   }
    .dkg-candmo-navbar.active {
        left: 0rem;
        opacity: 1;
        visibility: visible;
        overflow-x: hidden
   }
}
.dkg-cand-mo-menu-item {
    position: relative;
    display: inline-block;
    margin-left: 1.5rem;
}
.dkg-cand-mo-menu-link {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.25rem;
    font-family: inherit;
    font-size: 0.85rem;
    font-weight: 500;
    line-height: inherit;
    cursor: pointer;
    text-transform: capitalize;
    color: var(--color-black-500);
    transition: all 0.3s ease-in-out;
}
.dkg-cand-mo-menu-link > i.bx , svg {
    font-size: 0.8575rem;
    color: inherit;
}
.dkg-cand-mo-menu-link:hover {
    outline: none;
    color: var(--color-pink-500);
}
@media only screen and (min-width: 993px) {
    .dkg-cand-mo-menu-dropdown:hover > .dkg-cand-mo-submenu {
        display: block;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
   }
   .dkg-candmo-header{
    display: none;
   }
}
@media only screen and (max-width: 992px) {
    .dkg-cand-mo-menu {
        width: 100%;
        height: auto;
        padding: 0;
        margin-bottom : 0;
   } 
   .dkg-cand-mo-menu .dkg-top-close-tab{
        background-color: #eaeaea;
        padding: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 75%;
        border-top: 1px solid #ddd;
        z-index: 999;
        display: flex;
        align-items: center;
   }
   .dkg-cand-mo-menu .dkg-top-close-tab .dkg-close-popup-icon{
        color: #666;
        font-size: 0.85rem;
        display: inline-block;
        width: 100%;
        padding: 0.6rem 0.5rem;
        text-align: center;
   }
   .dkg-cand-mo-menu .dkg-top-close-tab .dkg-close-popup-icon:hover{
       background-color: #f2f2f2;
   }
   .dkg-cand-mo-menu .dkg-top-close-tab .dkg-close-popup-icon:first-child{
      border-right: 1px solid #ddd;
   }
   .dkg-cand-mo-menu .dkg-top-close-tab .dkg-close-popup-icon svg{
        color: #444;
        width: 18px;
        height: 18px;
        color: #777;
        padding-right: 0.5rem;
   }
    .dkg-cand-mo-menu .dkg-company-corss-logoCon{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0;
        background-color: #3c97b6;
    }
    .dkg-cand-mo-menu .dkg-company-corss-logoCon .dkg-candmo-brand{
        background-color: #fff;
        width: 125px;
        height: 35px !important;
        object-fit: contain
    }
   
    .dkg-cand-mo-menu .dkg-company-corss-logoCon .dkg-cross-icon{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #eaeaea;
        border: 1px solid #ddd
    }
    .dkg-cand-mo-menu .dkg-company-corss-logoCon .dkg-cross-icon:hover{
        background-color: #eaeaea;
    }
    .dkg-cand-mo-menu .dkg-myacc-telegram{
        background-color: #4098B4;
        height: 80px;
        padding: 0.95rem 0.75rem;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .dkg-cand-mo-menu .dkg-myacc-telegram .dkg-myacc-mo-userinfo,
    .dkg-cand-mo-menu .dkg-myacc-telegram .dkg-myacc-mo-userinfo .dk-accountDropdown,
    .dkg-cand-mo-menu .dkg-myacc-telegram .dkg-myacc-mo-userinfo .dk-accountDropdown .dropdown-toggle{
        width: 100%;
    }
    .dkg-candmo-header .dkg-myacc-mo-userinfo .dk-accountDropdown.dropdown button.dropdown-toggle span{
        width: 45px;
        height: 45px;
        border: 1px solid transparent
    }
    .dkg-candmo-header .dkg-myacc-mo-userinfo .dk-accountDropdown.dropdown button.dropdown-toggle span .img-fluid.img-circle{
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    .dkg-candmo-header .dkg-myacc-mo-userinfo .dk-accountDropdown.dropdown button.dropdown-toggle .title svg{
        display: none !important;
    }
    .dkg-cand-mo-menu .dkg-myacc-telegram .dkg-myacc-mo-userinfo .dk-accountDropdown .dropdown-toggle:after {
        position: absolute;
        bottom: 22px;
        color: #f8f6f6;
        right: 5px;
    }
    .dkg-cand-mo-menu .dkg-myacc-telegram .dkg-myacc-usertitle{
        margin-top: 0;
        margin-left: 0.75rem;
        position: absolute;
        left: 65px;
    }
    .dkg-cand-mo-menu .dkg-myacc-telegram .dkg-myacc-usertitle .dkg-candidate-name{
        display: inline-block;
        color: #f8f6f6;
        font-size: 0.85rem;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 0;
    }
    .dkg-cand-mo-menu .dkg-myacc-telegram .dkg-myacc-usertitle .dkg-candiate-email{
        display: inline-block;
        color: #f8f6f6;
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 1px;
        display: none;
    }
    .dkg-cand-mo-menu-item {
        display: block;
        margin: 0 auto;
   }
   .dkg-cand-mo-menu-item .dkg-cand-mo-menu-link {
        justify-content: flex-start;
        padding: 0.7rem 1.25rem;
        padding-left: 0.95rem;
        border-bottom: 1px solid #f4f0f0;
        width: 100%;
        position: relative;
        font-size: 0.9rem;
        color: #000 !important;
        font-family: 'Roboto';
   }
   .dkg-cand-mo-menu-link .dkg-mo-sidemenu-icon{
       padding-right: 1.25rem;
   }
   .dkg-cand-mo-menu-link .dkg-mo-sidemenu-icon svg{
       color: #3c97b6;
       width: 16px;
       height: 16px;
   }
   .dkg-cand-mo-menu .dkg-cand-mo-menu-item .dkg-cand-mo-menu-link i{
       position: absolute;
       right: 1.30rem;
       color: #666;
   }
   .dkg-cand-mo-menu .dkg-cand-mo-menu-item .dkg-cand-mo-menu-link.grey-active{
      background-color: #eaeaea;
      position: relative;
      color: #3c97b6 ! important;
   }
   .dkg-cand-mo-menu .dkg-cand-mo-menu-item .dkg-cand-mo-menu-link.grey-active:after {
        position: absolute;
        right: 1.3rem;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f061";
        color: #3c97b6;
    }
    .dkg-cand-mo-menu .dkg-cand-mo-menu-item .dkg-cand-mo-menu-link.dkg-chevronupdown-link.grey-active:after{
        display: none;
    }
    /* .dkg-cand-mo-menu .dkg-cand-mo-menu-item:nth-child(8) .dkg-cand-mo-menu-link.grey-active:after,
   .dkg-cand-mo-menu .dkg-cand-mo-menu-item:nth-child(9) .dkg-cand-mo-menu-link.grey-active:after,
   .dkg-cand-mo-menu .dkg-cand-mo-menu-item:nth-child(10) .dkg-cand-mo-menu-link.grey-active:after{
       display: none;
   }
   .dkg-cand-mo-menu .dkg-cand-mo-menu-item.dkg-cand-mo-menu-dropdown.active .dkg-cand-mo-menu-link.grey-active:after{
       display: none;
   } */
}
.dkg-cand-mo-submenu {
    position: absolute;
    top: 2.35rem;
    left: -2rem;
    min-width: 13rem;
    height: auto;
    padding: 0 1rem 1rem;
    opacity: 0;
    visibility: hidden;
    transform: translateY(1rem);
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 2px solid var(--color-pink-400);
    box-shadow: var(--shadow-medium);
    background-color: var(--color-white-100);
    transition: all 0.3s ease-in-out;
}
.dkg-cand-submenu-item {
    display: block;
    margin-top: 0.75rem;
}
.dkg-cand-mo-submenu-link {
    font-family: inherit;
    font-size: 0.85rem;
    font-weight: 500;
    line-height: inherit;
    cursor: pointer;
    color: var(--color-black-500);
    transition: all 0.35s ease;
}
.dkg-cand-mo-submenu-link:hover {
    outline: none;
    color: var(--color-pink-500);
}
@media only screen and (max-width: 992px) {
    .dkg-cand-mo-submenu {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 0;
        padding: 0px;
        border: none;
        outline: none;
        opacity: 1;
        overflow: hidden;
        visibility: visible;
        transform: translateY(0px);
        box-shadow: none;
        background: transparent;
   }
   .dkg-cand-mo-submenu.active{
        min-height: 165px;
        overflow-y: auto;
        background-color: #eaeaea;
        padding: 0;
        width: 100%;
   }
   .dkg-cand-mo-menu .dkg-cand-mo-menu-item .dkg-cand-mo-menu-link.grey-active
   .dkg-cand-mo-submenu.active .dkg-cand-submenu-item  .dkg-cand-mo-submenu-link.grey-light-active:after{
        position: absolute;
        right: 1.3rem;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f061";
        color: #3c97b6;
   }
   .dkg-cand-mo-submenu.active::-webkit-scrollbar {
        width: 8px;
        cursor: pointer !important;
    }

    .dkg-cand-mo-submenu.active::-webkit-scrollbar-track {
        background: #F1F1F1;
        cursor: pointer !important;
    }

    .dkg-cand-mo-submenu.active::-webkit-scrollbar-thumb {
        background-color: #ddd;
        border-radius: 4px;
        cursor: pointer !important;
    }
   .dkg-cand-mo-submenu .dkg-cand-submenu-item {
     margin: 0;
     width: 100%
   }
   .dkg-cand-mo-submenu .dkg-cand-submenu-item .dkg-cand-mo-submenu-link{
        display: inline-block;
        font-size: 0.85rem;
        font-weight: 500;
        width: 100%;
        padding: 0.5rem 0.75rem;
        padding-left: 5rem;
        border-bottom: 1px solid #f4f0f0;
        background-color: #fff;
        color: #666;
}
.dkg-cand-mo-submenu .dkg-cand-submenu-item .dkg-cand-mo-submenu-link:hover,
   .dkg-cand-mo-submenu .dkg-cand-submenu-item .dkg-cand-mo-submenu-link.grey-light-active{
       background-color: #f2f2f2;
   }
   .dkg-cand-mo-submenu .dkg-cand-submenu-item .dkg-cand-mo-submenu-link.grey-light-active:after{
        position: absolute;
        right: 0.69rem;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f061";
        color: #3c97b6;
    }
   
}
.dkg-cand-mo-burger {
    position: relative;
    display: none;
    cursor: pointer;
    user-select: none;
    width: 1.6rem;
    height: 1.15rem;
    opacity: 0;
    visibility: hidden;
    background: transparent;
}
.dkg-cand-mo-burger-line {
    position: absolute;
    display: block;
    right: 0;
    width: 100%;
    height: 2.1px;
    opacity: 1;
    border: none;
    outline: none;
    border-radius: 1rem;
    background: var(--color-black-500);
}
.dkg-cand-mo-burger-line:nth-child(1) {
    top: 0px;
}
.dkg-cand-mo-burger-line:nth-child(2) {
    top: 0.5rem;
    width: 70%;
}
.dkg-cand-mo-burger-line:nth-child(3) {
    top: 1rem;
}
@media only screen and (max-width: 992px) {
    .dkg-candmo-header .dkg-candmo-wrapper.dkg-candmo-container{
        padding: 0 0.75rem;
        box-shadow: 0 1px 2px 0 #0000001a;
    }
    .dkg-candmo-header .dkg-myacc-userinfo .dk-accountDropdown.dropdown button.dropdown-toggle span{
        width: 29px;
        height: 29px
    }
    .dkg-candmo-header .dkg-myacc-userinfo .dk-accountDropdown.dropdown button.dropdown-toggle span .img-fluid.img-circle{
        width: 29px;
        height: 29px
    }
    .dkg-candmo-header .dkg-myacc-userinfo .dk-accountDropdown.dropdown button.dropdown-toggle .title svg{
        display: none  !important;
    }
    .dkg-candmo-header .dkg-myacc-userinfo .dk-accountDropdown.dropdown .dropdown-menu{
        top: 39px !important;
    }
    .dkg-candmo-header .dkg-myacc-userinfo .dkg-login-faq-btnCon .dkg-login-faq-btn {
        background-color: #3c97b6;
        border-radius: 15px;
        color: #f3f3f3;
        display: inline-block;
        font-size: 12px;
        margin: 0;
        padding: 4px 9px;
        position: relative;
        text-align: center;
        top: 0;
        white-space: nowrap;
    }
    .dkg-cand-mo-burger {
        display: block;
        opacity: 1;
        visibility: visible;

   }
   .dkg-candmo-header .dkg-candmo-wrapper.dkg-candmo-container .dkg-cand-mo-burger i{
        font-size: 1.15rem
    }
   .dkg-candmo-wrapper.dkg-candmo-container .dkg-candmo-brand .img-fluid{
        width: 120px;
        height: 30px !important;
        position: relative;
        left: -10px;
   }
   .dkg-candmo-header .dkg-candmo-wrapper.dkg-candmo-container .dkg-cand-headerTitle{
       color: #333;
       text-transform: uppercase;
   }
   .dkg-candmo-header .dkg-candmo-wrapper.dkg-candmo-container .dkg-cand-headerTitle .dkg-main-title{
      color: #333;
   }
   .dkg-candmo-header .dkg-candmo-wrapper.dkg-candmo-container .dkg-cand-headerTitle .dkg-submenu-title{
     color: #333;
     text-transform: capitalize;
   }
   .dkg-candmo-wrapper.dkg-candmo-container .dkg-whatsapp-icon-con .whatsapp-icon-link{
        display: inline-block;
        width: 30px;
        height: 30px;
   }
   .dkg-candmo-wrapper.dkg-candmo-container .dkg-whatsapp-icon-con .whatsapp-icon-link .dkg-whatsapp-icon{
      width: 100%;
      height: auto;
      display: inline-block;
   }
}
.dkg-cand-mo-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s ease-in-out;
    background-color: rgba(0, 0, 0, 0.65);
}
@media only screen and (max-width: 992px) {
    .dkg-cand-mo-overlay.active {
        display: block;
        opacity: 1;
        visibility: visible;
   }
}
