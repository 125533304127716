.dk-caseStatusDropCon {
    .dropdown {
        position: relative;

        button.dk-caseStatusfliterbtn.dropdown-toggle.btn,
        button.dk-caseStatusfliterbtn.dropdown-toggle.btn:hover {
            box-shadow: none;
            background-color: transparent;
            padding: 0 !important;
            margin: 0;
            white-space: nowrap;
            font-size: 13px;
            text-transform: capitalize;
            color: #fff;
            width: 100%;
        }

        button.dk-caseStatusfliterbtn.dropdown-toggle::after {
            display: none;
        }

        .caseStatusMenu.dropdown-menu {
            border-radius: 0;
            padding: 0;
            transform: translate(-1px, 35px) !important;

            // border: none;
            .dropdown-item {
                text-align: left;
                font-size: 13px;
                padding: 6px 10px;
                border-bottom: 1px solid #aaa;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}